import { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { updateIdentificationsMergeAPI } from 'api/identifications'
import { useIdentificationsStore } from 'store/identifications'

import {
  IdentificationsDataTypes,
  IdentificationsMergeParamsTypes,
} from 'types/identifications'

import { DATASTATUS } from 'constants/status'

export const useMemberIdentificationsMerge = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: IdentificationsDataTypes
}) => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const identificationsMergeModStatus = useIdentificationsStore(
    (state) => state.identificationsMergeModStatus
  )
  const identificationsMergeModStatusSet = useIdentificationsStore(
    (state) => state.identificationsMergeModStatusSet
  )

  const [identificationsMergeParams, identificationsMergeParamsSet] =
    useState<IdentificationsMergeParamsTypes>({
      traderId: memberTraderId,
      identAcceptor: selectedRowData.ident,
      identDonor: '',
      addNewCardFlag: '0',
    })

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation({
    mutationFn: updateIdentificationsMergeAPI,
    mutationKey: 'updateIdentificationsMerge',
    onMutate: (values) => {
      identificationsMergeModStatusSet('loading')
    },
    onSuccess: (getOffersIdentsResponse) => {
      queryClient.invalidateQueries({
        queryKey: ['useIdentifications'],
      })
      identificationsMergeModStatusSet('success')
    },
    onError: (error) => {
      identificationsMergeModStatusSet('fail')
    },
  })

  const handleMerge = useCallback(
    async (mergeParams: IdentificationsMergeParamsTypes) => {
      try {
        const response = await mutateAsync({
          ...identificationsMergeParams,
          ...mergeParams,
        })
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        toast.success('Member identification merge update successful.')
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        if (message.outcomeCode === 'IDENT:HDA_MERGE_CUSTOMER:-1') {
          // toast.error(`Something went wrong.`)
          toast.error(message.outcomeUserMessage)
        } else {
          // toast.error(`Member identification merge update error.`)
          toast.error(message.outcomeUserMessage)
        }
        // modalClose()
      } finally {
        // console.log('')
        // modalClose()
      }
      modalClose()
    },
    [mutateAsync]
  )

  return {
    memberIdentificationsMergeModStatus: identificationsMergeModStatus,
    memberIdentificationsMergeParams: identificationsMergeParams,
    memberHandleIdentificationsMerge: handleMerge,
  }
}
