import { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
// import { toast } from 'react-toastify'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { getIdentificationsAPI } from 'api/identifications'
import { useIdentificationsLookupStore } from 'store/identifications'
import { DropDownItem } from 'types/components'
import { IdentificationsFetchParamsTypes } from 'types/identifications'

import { DATASTATUS } from 'constants/status'

export const useMemberIdentificationsLookup = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const identificationsLookupModStatus = useIdentificationsLookupStore(
    (state) => state.identificationsLookupModStatus
  )
  const identificationsLookupModStatusSet = useIdentificationsLookupStore(
    (state) => state.identificationsLookupModStatusSet
  )
  const identificationsLookupModErrors = useIdentificationsLookupStore(
    (state) => state.identificationsLookupModErrors
  )
  const identificationsLookupModErrorsSet = useIdentificationsLookupStore(
    (state) => state.identificationsLookupModErrorsSet
  )
  const identificationsLookup = useIdentificationsLookupStore(
    (state) => state.identificationsLookup
  )
  const identificationsLookupSet = useIdentificationsLookupStore(
    (state) => state.identificationsLookupSet
  )

  const [identificationsFetchParams, identificationsFetchParamsSet] =
    useState<IdentificationsFetchParamsTypes>(defaultParams)

  const {
    data: dataIdentificationsLookup,
    isLoading: isLoadingIdentificationsLookup,
    isError: isErrorIdentificationsLookup,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useIdentificationsLookup', identificationsFetchParams],
    queryFn: async () => {
      try {
        identificationsLookupModStatusSet('loading')
        const response = await getIdentificationsAPI(identificationsFetchParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        identificationsLookupModStatusSet('success')
        return response.idents
      } catch (e) {
        identificationsLookupModStatusSet('fail')
        identificationsLookupModErrorsSet(JSON.stringify(e))
      }
    },
  })

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation({
    mutationFn: getIdentificationsAPI,
    mutationKey: 'useIdentificationsLookup',
    onMutate: (values) => {
      identificationsLookupModStatusSet('loading')
    },
    onSuccess: (getIdentificationsIdentsResponse) => {
      queryClient.invalidateQueries({
        queryKey: ['useIdentificationsLookup', identificationsFetchParams],
      })
      identificationsLookupModStatusSet('success')
    },
    onError: (error) => {
      identificationsLookupSet([])
      identificationsLookupModStatusSet('fail')
    },
  })

  const identificationsIdentsOptionsLoader = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      if (inputValue.length < 3) return []
      const data = await mutateAsync(identificationsFetchParams)
      return data.idents.map((item) => ({
        label: String(item.ident),
        value: String(item.ident),
      }))
    },
    [mutateAsync, identificationsFetchParams]
  )

  const handleIdentificationsIdentsLookup = useCallback(
    async (inputValue: string) => {
      if (inputValue.length > 2)
        identificationsFetchParamsSet({
          ...defaultParams,
          ident: inputValue,
        })
    },
    [identificationsFetchParams, identificationsFetchParamsSet]
  )

  const identificationsAccountsOptionsLoader = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      if (inputValue.length < 3) return []
      const data = await mutateAsync(identificationsFetchParams)
      return data.idents.map((item) => ({
        label: String(item.accountId),
        value: String(item.accountId),
      }))
    },
    [mutateAsync, identificationsFetchParams]
  )

  const handleIdentificationsAccountsLookup = useCallback(
    async (inputValue: string) => {
      if (inputValue.length > 2)
        identificationsFetchParamsSet({
          ...defaultParams,
          accountId: parseInt(inputValue),
        })
    },
    [identificationsFetchParams, identificationsFetchParamsSet]
  )

  return {
    memberHandleIdentificationsIdentsLookup: handleIdentificationsIdentsLookup,
    memberHandleIdentificationsAccountsLookup:
      handleIdentificationsAccountsLookup,
    memberidentificationsIdentsOptionsLoader:
      identificationsIdentsOptionsLoader,
    memberIdentificationsAccountsOptionsLoader:
      identificationsAccountsOptionsLoader,
    memberIdentifications: identificationsLookup,
    memberIdentificationsModStatus: identificationsLookupModStatus,
    memberIdentificationsModErrors: identificationsLookupModErrors,
  }
}
