import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const FrequencyDefaultSchemas = DefaultSchemas

export const FrequencyDataSchemas = zod.object({
  frequencyId: zod.number(),
  frequencyName: zod.string(),
})

export const FrequencyAPISchemas = FrequencyDefaultSchemas.merge(
  zod.object({
    directPaymentInstructionFrequency: zod.array(FrequencyDataSchemas),
  })
)
