import * as Yup from 'yup'

export const icashRedemptionTxSchemas = Yup.object().shape({
  transactionValue: Yup.number(),
  creditValue: Yup.number(),
  loyaltyValue: Yup.number(),
  externalTransactionId: Yup.number().optional().nullable(),
  ident: Yup.string(),
  storeId: Yup.number(),
  deviceCode: Yup.string(),
  cashier: Yup.string(),
  guid: Yup.string(),
  transactionDate: Yup.string(),
  comments: Yup.string().optional(),
  traceNumber: Yup.number().optional(),
  reasonId: Yup.number().optional(),
})
