import { useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { getFrequency_API } from 'api/financials'
import { useFrequencyStore } from 'store/frequency'

export const useFrequencyQuery = () => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useFrequency'],
    queryFn: async () => {
      return await getFrequency_API()
    },
  })
}

export const useFrequency = () => {
  const frequencyModStatus = useFrequencyStore(
    (state) => state.frequencyModStatus
  )
  const frequencyModStatusSet = useFrequencyStore(
    (state) => state.frequencyModStatusSet
  )
  const frequencyModErrors = useFrequencyStore(
    (state) => state.frequencyModErrors
  )
  const frequencyModErrorsSet = useFrequencyStore(
    (state) => state.frequencyModErrorsSet
  )
  const frequency = useFrequencyStore((state) => state.frequency)
  const frequencySet = useFrequencyStore((state) => state.frequencySet)

  const queryClient = useQueryClient()

  const {
    data: dataFrequency,
    isLoading: isLoadingFrequency,
    isError: isErrorFrequency,
    isFetching: isFetchingFrequency,
    isSuccess: isSuccessFrequency,
    error: errorFrequency,
  } = useFrequencyQuery()

  useEffect(() => {
    if (dataFrequency)
      frequencySet(dataFrequency.directPaymentInstructionFrequency)
  }, [dataFrequency, frequencySet])

  useEffect(() => {
    if (isFetchingFrequency) frequencyModStatusSet('loading')
    if (isLoadingFrequency) frequencyModStatusSet('loading')
    if (isSuccessFrequency) frequencyModStatusSet('success')
    if (isErrorFrequency) frequencyModStatusSet('fail')
    if (errorFrequency) frequencyModErrorsSet(JSON.stringify(errorFrequency))
  }, [
    isFetchingFrequency,
    isLoadingFrequency,
    isSuccessFrequency,
    isErrorFrequency,
    errorFrequency,
    frequencyModStatusSet,
    frequencyModErrorsSet,
  ])

  const frequencyAsOptions = useMemo(() => {
    return frequency.map((item) => ({
      label: `${item.frequencyName}` || '',
      value: item.frequencyId.toString(),
    }))
  }, [frequency])

  return {
    frequency,
    frequencyAsOptions,
    frequencyModStatus,
  }
}
