import Modals from './Modals'
import Tabs from './Tabs'

const ActivityBalance = (): JSX.Element => {
  return (
    <div className="flex flex-col bg-white">
      <div className="flex flex-wrap-reverse justify-between px-4 py-4">
        <Tabs />
        <Modals />
      </div>
    </div>
  )
}

export default ActivityBalance
