import { Route, Routes } from 'react-router-dom'

import ForgotPassword from 'views/ForgotPassword'
import Login from 'views/Login'

import { ROUTES } from 'constants/routing'

const PublicRoutes = (): JSX.Element => (
  <Routes>
    <Route path={ROUTES.Login.path} element={<Login />} />
    <Route path={ROUTES.ForgotPassword.path} element={<ForgotPassword />} />
    <Route path="*" element={<Login />} />
  </Routes>
)

export default PublicRoutes
