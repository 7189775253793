import React from 'react'

import {
  MerchantDeviceTypes,
  MerchantIdentificationStatusesTypes,
  MerchantIdentificationTypes,
} from 'types/merchant'
import Form from './Form'

interface PropsTypes {
  onClose: () => void
  selectedMerchantIdentification: MerchantIdentificationTypes
}

const Index: React.FC<PropsTypes> = ({
  onClose,
  selectedMerchantIdentification,
}): React.ReactElement => {
  return (
    <Form
      onCloseModal={onClose}
      selectedMerchantIdentification={selectedMerchantIdentification}
    />
  )
}

export default Index
