import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import TSTable from 'components/Tanstack'
import { ToggleMenu } from 'components/Layout/ToggleMenu'

import InnerWrapper from '../InnerWrapper'
import Modal from 'components/Modal'
import { useMemberVouchersTable } from './useMemberVouchersTable'
import VouchersFilterForm from './VouchersFilterForm'
import { useMemberVouchers } from './useMemberVouchers'

import VouchersStoresModalForm from './VouchersStoresModalForm'

const Index = (): JSX.Element => {
  const {
    memberVouchers,
    memberVouchersModStatus,
    memberVouchersFilterParams,
    memberHandleVouchersFilter,
    memberHandleVouchersUnfilter,
  } = useMemberVouchers()

  const {
    memberVouchersSelected,
    memberVouchersActionModal,
    memberVouchersTableData,
    memberVouchersTableColumns,
    handleMemberActionModalClose,
  } = useMemberVouchersTable()

  return (
    <>
      <InnerWrapper title="Vouchers">
        {/* FILTER */}
        {/* <VouchersFilterForm
        formStatus={memberVouchersModStatus}
        formDefaultValues={memberVouchersFilterParams}
        onFilter={memberHandleVouchersFilter}
        onFilterClear={memberHandleVouchersUnfilter}
      /> */}

        {/* ADVANCE SEARCH  */}
        <div className="pb-6">
          <ToggleMenu
            type="search"
            label="Search Vouchers"
            isTriggeToggle={memberVouchersModStatus === 'success' || false}
          >
            <VouchersFilterForm
              formStatus={memberVouchersModStatus}
              formDefaultValues={memberVouchersFilterParams}
              onFilter={memberHandleVouchersFilter}
              onFilterClear={memberHandleVouchersUnfilter}
            />
          </ToggleMenu>
        </div>

        {/* FAIL */}
        {memberVouchersModStatus === 'fail' ? (
          <NoResults
            header="Failed to load data"
            subtitle="Please refresh the page and try again, or contact
        your system administrator"
            noImage
          />
        ) : null}

        {/* LOADING */}
        {memberVouchersModStatus === 'loading' ? <Spinner /> : null}

        {/* SUCCESS */}
        {memberVouchers.length && memberVouchersModStatus === 'success' ? (
          <TSTable.Table
            data={memberVouchersTableData}
            columns={memberVouchersTableColumns}
          />
        ) : null}

        {/* EMPTY */}
        {!memberVouchers.length && memberVouchersModStatus === 'success' ? (
          <NoResults
            header="No available data"
            subtitle="There is no available data to display"
            noImage
          />
        ) : null}
      </InnerWrapper>

      {/* UPDATE PAYMENT INSTRUCTIONS */}
      {memberVouchersActionModal === 'vouchers_stores_preview' &&
      memberVouchersSelected ? (
        <Modal
          open={true}
          title="Voucher Stores"
          onClose={handleMemberActionModalClose}
          noFooter
        >
          <VouchersStoresModalForm
            modalClose={handleMemberActionModalClose}
            selectedRowData={memberVouchersSelected}
          />
        </Modal>
      ) : null}
    </>
  )
}

export default Index
