import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getOffersAPI } from 'api/offers'
import { useOffersStore } from 'store/offers'
import { OffersFetchParamsTypes } from 'types/offers'

import { DATASTATUS } from 'constants/status'

export const useMemberOffers = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const offersModStatus = useOffersStore((state) => state.offersModStatus)
  const offersModStatusSet = useOffersStore((state) => state.offersModStatusSet)
  const offersModErrors = useOffersStore((state) => state.offersModErrors)
  const offersModErrorsSet = useOffersStore((state) => state.offersModErrorsSet)
  const offers = useOffersStore((state) => state.offers)
  const offersSet = useOffersStore((state) => state.offersSet)
  const [offersFilterParams, offersFilterParamsSet] =
    useState<OffersFetchParamsTypes>(defaultParams)
  const [isOffersFiltered, isOffersFilteredSet] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataOffers,
    isLoading: isLoadingOffers,
    isError: isErrorOffers,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useOffers', offersFilterParams],
    queryFn: async () => {
      try {
        offersModStatusSet('loading')
        const response = await getOffersAPI(offersFilterParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        offersModStatusSet('success')
        return response.offers
      } catch (e) {
        offersModStatusSet('fail')
        offersModErrorsSet(JSON.stringify(e))
      }
    },
  })

  const handleFilter = useCallback(
    async (filterParams: OffersFetchParamsTypes) => {
      offersFilterParamsSet(filterParams)
      isOffersFilteredSet(true)
    },
    [offersFilterParamsSet, isOffersFilteredSet]
  )

  const handleUnfilter = useCallback(() => {
    const test = { traderId: memberTraderId }
    offersFilterParamsSet(test)
    isOffersFilteredSet(false)
  }, [offersFilterParamsSet, isOffersFilteredSet])

  useEffect(() => {
    if (dataOffers) offersSet(dataOffers)
  }, [dataOffers, offersSet])

  return {
    memberHandleOffersFilter: handleFilter,
    memberHandleOffersUnfilter: handleUnfilter,
    memberOffersFilterParams: offersFilterParams,
    memberOffers: offers,
    memberOffersModStatus: offersModStatus,
  }
}
