import { NavLink } from 'react-router-dom'
import classnames from 'classnames'

import useMerchantStore from 'store/merchant'
import { accessControlStatus, AccessStatusTypes } from 'setups/controller'
import { MERCHANTTABSNAVIGATION } from 'constants/profileNavMerchant'

const Tabs = (): JSX.Element => {
  // START: CHECK WITH ACCESS STATUS
  const merchantProfile = useMerchantStore((state) => state.merchantProfile)
  const merchantProfileModStatus = useMerchantStore(
    (state) => state.merchantProfileModStatus
  )
  const MERCHANTTABSNAVIGATION_FILTERED = accessControlStatus(
    MERCHANTTABSNAVIGATION,
    'merchant',
    merchantProfile[0]?.memberThorStatus as AccessStatusTypes
  )
  // END: CHECK WITH ACCESS STATUS

  if (merchantProfileModStatus !== 'success') return <></>

  return (
    <div className="flex flex-grow">
      <nav className="flex flex-wrap z-1">
        {MERCHANTTABSNAVIGATION.map((item, index) => (
          <NavLink
            key={'profile-' + index}
            className={({ isActive }) =>
              classnames(
                'px-3 py-2 text-gray-700 border-primary ',
                isActive ? 'border-b-2' : null
              )
            }
            // activeClassName="border-b-2"
            to={`/merchant-profile/${merchantProfile[0]?.merchantTraderId}/${item.route.pathname}`}
          >
            <span className="tracking-wider uppercase font-light">
              {item.route.title}
            </span>
          </NavLink>
        ))}
      </nav>
    </div>
  )
}

export default Tabs
