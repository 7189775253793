import { http } from 'utils'

import {
  SubsquadsDefaultTypes,
  SubsquadsAPITypes,
  SubsquadsFetchParamsTypes,
} from 'types/subsquads'
import { SubsquadsAPISchemas } from 'schemas/subsquadsSchemas'

import { DATASTATUS } from 'constants/status'

export async function getSubsquadsAPI({
  traderId,
}: SubsquadsFetchParamsTypes): Promise<SubsquadsAPITypes> {
  let url = `/members/${traderId}/sub-squad`
  const params = new URLSearchParams()

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = SubsquadsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}
