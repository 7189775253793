import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import { updatePaymentInstruction_API, EMRACTIONS_API } from 'api/financials'
import { BankAccountsDefaultTypes } from 'types/financials'
import { FinancialUpdatePaymentInstructionTxAPITypes } from 'types/financialUpdatePaymentInstructionTxTypes'
import { MemberDetail } from 'types/member'
import { FrequencyDataTypes } from 'types/frequency'
import useMemberStore from 'store/member'

export const usePaymentInstructionTx = ({
  onSubmitSuccess,
  paymentFrequency,
}: {
  onSubmitSuccess: () => void
  paymentFrequency: FrequencyDataTypes[]
}) => {
  const { traderId } = useParams()
  const { member } = useMemberStore()
  const firstName = member?.firstName || ''
  const traderType = member?.traderType || ''

  const queryClient = useQueryClient()
  const {
    isLoading,
    isError,
    error,
    isSuccess,
    mutateAsync: onUpdatePaymentInstructionAsync,
  } = useMutation({
    mutationFn: updatePaymentInstruction_API,
    onSuccess: (_, data) => {
      // TRIGGER EMAIL
      EMRACTIONS_API(
        { isBankAccountTrigger: false },
        { isCancelInstructionTrigger: false },
        {
          traderId: parseInt(traderId || ''),
          traderType: traderType,
          firstName: firstName,
          paymentAmount: data.paymentAmount,
          paymentDate: data.firstPaymentDate,
          paymentFrequency: paymentFrequency.filter(
            (item) => item.frequencyId === data.frequency
          )[0].frequencyName,
        }
      )
      // console.log(data)
      // 'invalidate here'
      queryClient.invalidateQueries(['useDirectPayments'])
    },
  })

  const onSubmitAddPaymentInstruction = useCallback(
    async (formData: FinancialUpdatePaymentInstructionTxAPITypes) => {
      await onUpdatePaymentInstructionAsync(formData)
    },
    [traderId, firstName, onUpdatePaymentInstructionAsync]
  )

  useEffect(() => {
    if (error) {
      const e = error as BankAccountsDefaultTypes
      toast.error(e.outcomeUserMessage)
    }
    if (isSuccess) {
      toast.success('Payment instruction successfully updated.')
      onSubmitSuccess()
    }
  }, [error, isSuccess, onSubmitSuccess])

  return {
    onSubmitAddPaymentInstruction,
    onLoadingAddPaymentInstruction: isLoading,
  }
}
