import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getMemberProfilesAPI } from 'api/memberprofiles'
import { useMemberProfilesStore } from 'store/memberprofiles'
import { MemberProfilesFetchParamsTypes } from 'types/memberprofiles'

import { DATASTATUS } from 'constants/status'

// QUERY
export const useMemberProfilesQuery = ({
  memberprofilesFetchParams,
}: {
  memberprofilesFetchParams: MemberProfilesFetchParamsTypes
}) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useMemberProfiles', memberprofilesFetchParams],
    queryFn: async () => {
      return await getMemberProfilesAPI(memberprofilesFetchParams)
    },
  })
}

// INIT
export const useMemberProfilesInit = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId, lastNMemberProfiles: 1 }

  const statusSet = useMemberProfilesStore(
    (state) => state.memberprofilesModStatusSet
  )
  const errorSet = useMemberProfilesStore(
    (state) => state.memberprofilesModErrorsSet
  )
  const dataSet = useMemberProfilesStore((state) => state.memberprofilesSet)
  const [memberprofilesFetchParams, memberprofilesFetchParamsSet] =
    useState<MemberProfilesFetchParamsTypes>(defaultParams)

  const { data, isLoading, isSuccess, isError, error } = useMemberProfilesQuery(
    { memberprofilesFetchParams }
  )

  // EFFECTS DATA
  useEffect(() => {
    if (data) dataSet([data])
  }, [data, dataSet])

  // EFFECTS STATUSES/ERRORS
  useEffect(() => {
    if (isLoading) statusSet('loading')
    if (isSuccess) statusSet('success')
    if (isError) statusSet('fail')
    if (error) errorSet(JSON.stringify(error))
    return () => statusSet('idle')
  }, [isLoading, isSuccess, isError, error, statusSet, errorSet])
}

// DATA
export const useMemberProfiles = () => {
  const data = useMemberProfilesStore((state) => state.memberprofiles)
  const status = useMemberProfilesStore(
    (state) => state.memberprofilesModStatus
  )
  const errors = useMemberProfilesStore(
    (state) => state.memberprofilesModErrors
  )

  return {
    memberMemberProfiles: data,
    memberMemberProfilesModStatus: status,
    memberMemberProfilesModErrors: errors,
  }
}
