import Spinner from 'components/Spinner'
import MemberProfile from './MemberProfile'
import MemberProfileActions from './MemberProfileActions'
import MemberStatus from './MemberStatus'
import MemberVersions from './MemberVersions'
import MemberMemberships from './MemberMemberships'
import MemberInfos from './MemberInfos'
import MemberSubscriptionTypes from './MemberSubscriptionTypes'
import MemberCommsChannels from './MemberCommsChannels'

const Index = ({
  isLoading,
  isError,
}: {
  isLoading: boolean
  isError: boolean
}): JSX.Element => {
  if (isLoading)
    return (
      <div className="flex flex-col min-w-full p-4">
        <Spinner />
      </div>
    )

  return (
    <>
      <div className="flex flex-col min-w-full p-4">
        <div className="flex justify-between">
          <MemberProfile />
          <MemberProfileActions />
        </div>
      </div>

      <div className="flex flex-col min-w-full p-4">
        <MemberStatus />
      </div>

      <div className="flex flex-col min-w-full p-4">
        <MemberInfos />
      </div>

      <div className="flex flex-col min-w-full p-4">
        <MemberVersions />
      </div>

      <div className="flex flex-col min-w-full p-4">
        <MemberMemberships />
      </div>

      <div className="flex flex-col min-w-full p-4">
        <MemberSubscriptionTypes />
      </div>

      <div className="flex flex-col min-w-full p-4">
        <MemberCommsChannels />
      </div>
    </>
  )
}

export default Index
