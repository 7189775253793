import { Link } from 'react-router-dom'

const Index = ({ url, label }: { url: string; label: string }): JSX.Element => (
  <div className="text-center p-4">
    <Link className="text-xs underline" to={url}>
      <span className="tracking-wider font-light">{`See all ${label}`}</span>
    </Link>
  </div>
)

export default Index
