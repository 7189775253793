// STORE DATA WAS INITIALIZED FROM THE Wrapper.tsx

import { useParams } from 'react-router-dom'
import { useMemberProfilesStore } from 'store/memberprofiles'

export const useMemberProfiles = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const memberprofilesModStatus = useMemberProfilesStore(
    (state) => state.memberprofilesModStatus
  )
  const memberprofilesModStatusSet = useMemberProfilesStore(
    (state) => state.memberprofilesModStatusSet
  )
  const memberprofilesModErrors = useMemberProfilesStore(
    (state) => state.memberprofilesModErrors
  )
  const memberprofilesModErrorsSet = useMemberProfilesStore(
    (state) => state.memberprofilesModErrorsSet
  )
  const memberprofiles = useMemberProfilesStore((state) => state.memberprofiles)
  const memberprofilesSet = useMemberProfilesStore(
    (state) => state.memberprofilesSet
  )

  return {
    memberMemberProfiles: memberprofiles,
    memberMemberProfilesModStatus: memberprofilesModStatus,
  }
}
