import * as Yup from 'yup'
import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const OffersDefaultSchemas = DefaultSchemas

export const OffersDataSchemas = zod.object({
  voucherId: zod.number().nullable(),
  traderId: zod.number(),
  voucherCode: zod.string().nullable(),
  voucherName: zod.string().nullable(),
  description: zod.string().nullable(),
  offerId: zod.number(),
  offerTypeId: zod.number(),
  offerName: zod.string(),
  channelId: zod.number(),
  channelName: zod.string(),
  cmsCode: zod.string().nullable().optional(),
  startDate: zod.string().nullable(),
  endDate: zod.string().nullable(),
  flRedeemed: zod.number().nullable(),
  // TEMPORARILY DISABLED
  // cmsDetail: zod.string().nullable().optional(),
})

export const OffersAPISchemas = OffersDefaultSchemas.merge(
  zod.object({
    offers: zod.array(OffersDataSchemas),
  })
)

export const OffersFetchParamsSchemas = zod.object({
  traderId: zod.number(),
  offerId: zod.number().optional(),
  offerName: zod.string().optional(),
  pSize: zod.number().optional(),
  pNumber: zod.number().optional(),
})
