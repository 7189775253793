import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import TSTable from 'components/Tanstack'
import Modal from 'components/Modal'
import { ToggleMenu } from 'components/Layout/ToggleMenu'

import InnerWrapper from '../InnerWrapper'
import { useMemberOffersTable } from './useMemberOffersTable'
import OffersFilterForm from './OffersFilterForm'
import { useMemberOffers } from './useMemberOffers'

const Index: React.FC = (): React.ReactElement => {
  const {
    memberOffers,
    memberOffersModStatus,
    memberOffersFilterParams,
    memberHandleOffersFilter,
    memberHandleOffersUnfilter,
  } = useMemberOffers()

  const {
    memberOffersSelected,
    memberOffersActionModal,
    memberOffersTableData,
    memberOffersTableColumns,
    handleMemberActionModalClose,
  } = useMemberOffersTable()

  return (
    <InnerWrapper title="Offers">
      {/* FILTER */}
      {/* <OffersFilterForm
        formStatus={memberOffersModStatus}
        formDefaultValues={memberOffersFilterParams}
        onFilter={memberHandleOffersFilter}
        onFilterClear={memberHandleOffersUnfilter}
      /> */}

      {/* ADVANCE SEARCH  */}
      <div className="pb-6">
        <ToggleMenu
          type="search"
          label="Search Offers"
          isTriggeToggle={memberOffersModStatus === 'success' || false}
        >
          <OffersFilterForm
            formStatus={memberOffersModStatus}
            formDefaultValues={memberOffersFilterParams}
            onFilter={memberHandleOffersFilter}
            onFilterClear={memberHandleOffersUnfilter}
          />
        </ToggleMenu>
      </div>

      {/* FAIL */}
      {memberOffersModStatus === 'fail' ? (
        <NoResults
          header="Failed to load data"
          subtitle="Please refresh the page and try again, or contact
        your system administrator"
          noImage
        />
      ) : null}

      {/* LOADING */}
      {memberOffersModStatus === 'loading' ? <Spinner /> : null}

      {/* SUCCESS */}
      {memberOffers.length && memberOffersModStatus === 'success' ? (
        <TSTable.Table
          data={memberOffersTableData}
          columns={memberOffersTableColumns}
        />
      ) : null}

      {/* EMPTY */}
      {!memberOffers.length && memberOffersModStatus === 'success' ? (
        <NoResults
          header="No available data"
          subtitle="There is no available data to display"
          noImage
        />
      ) : null}
    </InnerWrapper>
  )
}

export default Index
