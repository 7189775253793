import { Controller } from 'react-hook-form'

// import { InputField } from 'components/InputField'
// import Input from 'components/Input'
import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import { IdentificationsDataTypes } from 'types/identifications'

import Dropdown from 'components/Dropdown'
import { useMemberIdentificationsStatusUpdate } from './useMemberIdentificationsStatusUpdate'
import { useMemberIdentificationsStatusUpdateForm } from './useMemberIdentificationsStatusUpdateForm'
import { useMemberIdentificationsStatuses } from './useMemberIdentificationsStatuses'

import { MERGE_OPTIONS } from 'constants/filters'

const Index = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: IdentificationsDataTypes
}): JSX.Element => {
  const {
    memberIdentificationsStatusesOptions,
    memberIdentificationsStatusesModStatus,
  } = useMemberIdentificationsStatuses()

  const {
    memberIdentificationsStatusUpdateParams,
    memberHandleIdentificationsStatusUpdate,
  } = useMemberIdentificationsStatusUpdate({
    modalClose,
    selectedRowData,
  })

  const { control, handleSubmit, setValue, watch } =
    useMemberIdentificationsStatusUpdateForm({
      defaultValues: memberIdentificationsStatusUpdateParams,
    })

  const isLoading =
    memberIdentificationsStatusesModStatus !== 'success' ? true : false

  if (isLoading) return <></>

  return (
    <FormsLayout.Wrapper
      onSubmit={handleSubmit(memberHandleIdentificationsStatusUpdate)}
    >
      <FormsLayout.Item>
        <Controller
          name="identStatusId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="To Status"
              defaultValue={
                memberIdentificationsStatusesOptions.filter(
                  (item) =>
                    parseInt(item.value) ===
                    memberIdentificationsStatusUpdateParams.identStatusId
                )[0]
              }
              options={memberIdentificationsStatusesOptions}
              className="w-full menu-outer-top tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) => {
                setValue('identStatusId', parseInt(value.value))
              }}
              overflowStyle
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.ActionWrapper>
        <Button key="submit" type="submit" className="mr-2 rounded bg-primary">
          <span className="tracking-wider font-bold">Update status</span>
        </Button>
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
