import React from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'
import 'react-toastify/dist/ReactToastify.css'
// import 'typeface-poppins'
// import 'typeface-roboto'
import MainWrapper from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

// if (process.env.NODE_ENV === 'development') {
//   const worker = require('./mocks/browser').default
//   worker.start()
// }

const rootElement = document.getElementById('root')
if (!rootElement) {
  throw new Error('Cannot find root element')
}

ReactModal.setAppElement(rootElement)

ReactDOM.render(
  <React.StrictMode>
    <MainWrapper />
  </React.StrictMode>,
  rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
