import classnames from 'classnames'
import styles from './Spinner.module.scss'

type Props = {
  color?: 'black' | 'white'
  className?: string
}

const Spinner = ({ color, className }: Props): JSX.Element => (
  <span
    className={classnames(
      styles.loader,
      { [styles.loaderWhite]: color === 'white' },
      className
    )}
  >
    Loading ...
  </span>
)

export default Spinner
