import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Colors from 'colors.json'

import { faSearch, faRemoveFormat } from '@fortawesome/free-solid-svg-icons'
import Input from 'components/Input'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import { Form, Formik, FormikHelpers } from 'formik'

import { MemberProfileUpdateSchemas } from 'schemas/profileUpdateDetailsSchemas'
import { useProfileUpdate } from './useUpdateDetails'
import { MEMBER_GENDER } from 'constants/filters'
import MerchantLookup from './HomeStoreSearch'
import { getMemberDetail } from 'api/members'

const Index: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}): React.ReactElement => {
  // STATES
  const { traderId } = useParams()
  const [enrollmentTraderName, setEnrollmentTraderName] = useState('')

  useEffect(() => {
    const fetchMemberDetails = async () => {
      if (!traderId) {
        console.warn('traderId is undefined')
        return
      }
      try {
        const response = await getMemberDetail(traderId)
        if (response.outcomeCode == 0 && response.enrollmentTraderName) {
          setEnrollmentTraderName(response.enrollmentTraderName)
        } else {
          setEnrollmentTraderName('')
        }
      } catch (error) {
        console.error('Error fetching member details:', error)
      }
    }

    fetchMemberDetails()
  }, [traderId])

  const {
    isLoading,
    initialValues,
    handleSubmitProfileUpdate,
    handleClearProfileUpdate,
  } = useProfileUpdate(Number(traderId), onCloseModal)

  if (isLoading) return <></>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={MemberProfileUpdateSchemas}
      onSubmit={handleSubmitProfileUpdate}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <div className="mb-4">
              <div className="flex gap-4 items-center">
                <Input
                  name="firstName"
                  label="First Name"
                  labelClassName="text-gray-500 w-full"
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  labelClassName="text-gray-500 w-full"
                />
                <Input
                  name="birthDate"
                  label="Birthday"
                  labelClassName="text-gray-500"
                  placeholder="YYYY-MM-DD"
                  // disabled
                />
              </div>

              <br />

              <div className="flex gap-4 items-start">
                <Dropdown
                  title="Gender"
                  defaultValue={
                    MEMBER_GENDER.filter(
                      (item) => String(item.value) === String(values.genderId)
                    )[0]
                  }
                  options={MEMBER_GENDER}
                  className="w-40"
                  borderColor="#C4C4C4"
                  onSelect={(item) => {
                    setFieldValue('genderId', item.value)
                  }}
                />
                <Input
                  name="email"
                  label="Email"
                  labelClassName="text-gray-500"
                  // disabled
                />
                <Input
                  name="mobile"
                  label="Mobile"
                  labelClassName="text-gray-500"
                />
              </div>
              <MerchantLookup
                isMerchantLookupToggle={true}
                title="Home Store"
                placeholder={enrollmentTraderName || 'Search merchant ...'}
                setFieldValue={(field, value) => {
                  if (field === 'storeId') {
                    // Set traderId for backend submission
                    setFieldValue('enrollmentTraderId', value)
                  }
                  // Set deviceCode for backend
                  setFieldValue('deviceCode', value)
                }}
              />
              <Input
                name="address1"
                label="Address 1"
                labelClassName="text-gray-500"
              />
              <Input
                name="address2"
                label="Address 2"
                labelClassName="text-gray-500"
              />
              <Input
                name="address3"
                label="Address 3"
                labelClassName="text-gray-500"
              />
              <Input
                name="address4"
                label="Address 4"
                labelClassName="text-gray-500"
              />
              <Input
                name="suburb"
                label="Suburb"
                labelClassName="text-gray-500"
              />
              <div className="flex gap-4 items-center">
                <Input
                  name="state"
                  label="State"
                  labelClassName="text-gray-500"
                />
                <Input
                  name="city"
                  label="City"
                  labelClassName="text-gray-500"
                />
                <Input
                  name="postCode"
                  label="Postal Code"
                  labelClassName="text-gray-500"
                />
              </div>

              <div className="flex gap-4 items-center">
                <Input
                  name="country"
                  label="Country"
                  labelClassName="text-gray-500"
                  disabled
                />
              </div>
            </div>

            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Confirm</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onCloseModal}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Index
