import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { TransactionsDataTypes, TXReasonsDataTypes } from 'types/transactions'

interface TransactionsStoreTypes {
  transactionsModErrors: StatusErrorTypes
  transactionsModErrorsSet: (transactionsModErrors: StatusErrorTypes) => void
  transactionsModStatus: StatusTypes
  transactionsModStatusSet: (transactionsStatus: StatusTypes) => void
  transactions: TransactionsDataTypes[]
  transactionsSet: (transactions: TransactionsDataTypes[]) => void
  transactionsLookup: TransactionsDataTypes[]
  transactionsLookupSet: (transactions: TransactionsDataTypes[]) => void
  transactionsSummary: {
    overallCount: number
    last30daysCount: number
  }
  transactionsSummarySet: () => void
}

export const useTransactionsStore = create<TransactionsStoreTypes>((set) => ({
  transactionsModErrors: '',
  transactionsModErrorsSet: (transactionsModErrors) => {
    set({
      transactionsModErrors,
    })
  },
  transactionsModStatus: 'idle',
  transactionsModStatusSet: (transactionsModStatus) => {
    set({
      transactionsModStatus,
    })
  },
  transactions: [],
  transactionsSet: (transactions) => {
    set({
      transactions,
    })
  },
  transactionsLookup: [],
  transactionsLookupSet: (transactionsLookup) => {
    set({
      transactionsLookup,
    })
  },
  transactionsSummary: {
    overallCount: 0,
    last30daysCount: 0,
  },
  transactionsSummarySet: () => {
    const today = new Date()
    const last30Days = new Date(new Date().setDate(today.getDate() - 30))
    const startDate = last30Days.toLocaleDateString('en-CA')
    const endDate = today.toLocaleDateString('en-CA')

    set((state) => {
      const last30daysCount = state.transactions.filter(
        (item) =>
          new Date(item.transactionDate) >= new Date(startDate) &&
          new Date(item.transactionDate) <= new Date(endDate)
      )
      return {
        transactionsSummary: {
          overallCount: state.transactions.length,
          last30daysCount: last30daysCount.length,
        },
      }
    })
  },
}))

interface TransactionsLookupStoreTypes {
  transactionsLookupModErrors: StatusErrorTypes
  transactionsLookupModErrorsSet: (
    transactionsLookupModErrors: StatusErrorTypes
  ) => void
  transactionsLookupModStatus: StatusTypes
  transactionsLookupModStatusSet: (
    transactionsLookupStatus: StatusTypes
  ) => void
  transactionsLookup: TransactionsDataTypes[]
  transactionsLookupSet: (transactionsLookup: TransactionsDataTypes[]) => void
}

export const useTransactionsLookupStore = create<TransactionsLookupStoreTypes>(
  (set) => ({
    transactionsLookupModErrors: '',
    transactionsLookupModErrorsSet: (transactionsLookupModErrors) => {
      set({
        transactionsLookupModErrors,
      })
    },
    transactionsLookupModStatus: 'idle',
    transactionsLookupModStatusSet: (transactionsLookupModStatus) => {
      set({
        transactionsLookupModStatus,
      })
    },
    transactionsLookup: [],
    transactionsLookupSet: (transactionsLookup) => {
      set({
        transactionsLookup,
      })
    },
  })
)

interface TransactionsActivityStoreTypes {
  transactionsActivityModErrors: StatusErrorTypes
  transactionsActivityModErrorsSet: (
    transactionsActivityModErrors: StatusErrorTypes
  ) => void
  transactionsActivityModStatus: StatusTypes
  transactionsActivityModStatusSet: (
    transactionsActivityStatus: StatusTypes
  ) => void
  transactionsActivity: TransactionsDataTypes[]
  transactionsActivitySet: (
    transactionsActivity: TransactionsDataTypes[]
  ) => void
}

export const useTransactionsActivityStore =
  create<TransactionsActivityStoreTypes>((set) => ({
    transactionsActivityModErrors: '',
    transactionsActivityModErrorsSet: (transactionsActivityModErrors) => {
      set({
        transactionsActivityModErrors,
      })
    },
    transactionsActivityModStatus: 'idle',
    transactionsActivityModStatusSet: (transactionsActivityModStatus) => {
      set({
        transactionsActivityModStatus,
      })
    },
    transactionsActivity: [],
    transactionsActivitySet: (transactionsActivity) => {
      set({
        transactionsActivity,
      })
    },
  }))

  interface TXReasonsStoreTypes {
    TXReasonsModErrors: StatusErrorTypes
    TXReasonsModErrorsSet: (TXReasonsModErrors: StatusErrorTypes) => void
    TXReasonsModStatus: StatusTypes
    TXReasonsModStatusSet: (TXReasonsStatus: StatusTypes) => void
    TXReasons: TXReasonsDataTypes[]
    TXReasonsSet: (TXReasons: TXReasonsDataTypes[]) => void
    TXReasonsLookup: TXReasonsDataTypes[]
    TXReasonsLookupSet: (TXReasons: TXReasonsDataTypes[]) => void
  }
  
  export const useTXReasonsStore = create<TXReasonsStoreTypes>((set) => ({
    TXReasonsModErrors: '',
    TXReasonsModErrorsSet: (TXReasonsModErrors) => {
      set({
        TXReasonsModErrors,
      })
    },
    TXReasonsModStatus: 'idle',
    TXReasonsModStatusSet: (TXReasonsModStatus) => {
      set({
        TXReasonsModStatus,
      })
    },
    TXReasons: [],
    TXReasonsSet: (TXReasons) => {
      set({
        TXReasons,
      })
    },
    TXReasonsLookup: [],
    TXReasonsLookupSet: (TXReasonsLookup) => {
      set({
        TXReasonsLookup,
      })
    },
  }))
  