import { http } from 'utils'

import {
  SubsquadData,
  SubsquadDetail,
  SubsquadMember,
  SubsquadNotify,
  SubsquadQTF,
} from 'types/subsquad'

const mockAPI = process.env.REACT_APP_MOCK_API_URL

export async function getSubsquads(
  groupName?: string,
  groupURN?: string,
  traderId?: number,
  cardNumber?: number
): Promise<[SubsquadData[], number]> {
  let url = '/squad-groups'
  const params = new URLSearchParams()

  if (groupName) {
    params.set('groupName', groupName)
  }

  if (groupURN) {
    params.set('urn', groupURN)
  }

  if (traderId) {
    params.set('trader-id', traderId.toString())
  }

  if (cardNumber) {
    params.set('cardNumber', cardNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<{ groups: SubsquadData[] }>(url)

  const items = response.data.groups.map((record: SubsquadData) => ({
    groupId: record.groupId,
    groupName: record.groupName,
    groupUrn: record.groupUrn,
    memberCount: record.memberCount,
    status: record.status,
    createdDate: record.createdDate,
    cardNumber: record.cardNumber,
    qtfStartDate: record.qtfStartDate,
    qtfEndDate: record.qtfEndDate,
  }))

  return [items, items.length]
}

export async function getGroupDetails(groupURN: string): Promise<SubsquadData> {
  const url = `/squad-groups?urn=${groupURN}`
  const response = await http.get<{ groups: SubsquadData[] }>(url)
  return response.data.groups[0]
}

export async function getSubsquadMembers(
  groupId: string,
  cardNumber: string,
  status: string
): Promise<SubsquadMember[]> {
  let url = '/squad-groups/members'
  const params = new URLSearchParams()

  if (groupId) {
    params.set('groupId', groupId)
  }

  if (cardNumber) {
    params.set('cardNumber', cardNumber)
  }

  if (status) {
    params.set('status', status)
  }

  url += `?${params.toString()}`

  const response = await http.get<{ members: SubsquadMember[] }>(url)

  const items = response.data.members.map((record: SubsquadMember) => ({
    name: record.name,
    earnedPoints: record.earnedPoints,
    groupId: record.groupId,
    note: record.note,
    traderId: record.traderId,
    membershipId: record.membershipId,
    startDate: record.startDate,
    endDate: record.endDate,
  }))

  return items
}

export async function getSubsquadQTFs(
  groupUrn: string,
  cardNumber: string,
  status: string
): Promise<SubsquadQTF[]> {
  let url = '/squad-groups/qtf'
  const params = new URLSearchParams()

  if (groupUrn) {
    params.set('groupUrn', groupUrn)
  }

  if (cardNumber) {
    params.set('cardNumber', cardNumber)
  }

  if (status) {
    params.set('status', status)
  }

  url += `?${params.toString()}`

  const response = await http.get<{ qtf: SubsquadQTF[] }>(mockAPI + url)

  const items = response.data.qtf.map((record: SubsquadQTF) => ({
    id: record.id,
    startDate: record.startDate,
    endDate: record.endDate,
    firstName: record.firstName,
    lastName: record.lastName,
    cardNumber: record.cardNumber,
  }))

  return items
}

export async function getSubsquadQTFNotify(
  id: string,
  cardNumber: string,
  firstName: string,
  lastName: string
): Promise<SubsquadNotify[]> {
  let url = '/squad-groups/qtfnotify'
  const params = new URLSearchParams()

  if (id) {
    params.set('id', id)
  }

  if (cardNumber) {
    params.set('cardNumber', cardNumber)
  }

  if (firstName) {
    params.set('firstName', firstName)
  }

  if (lastName) {
    params.set('lastName', lastName)
  }

  url += `?${params.toString()}`

  const response = await http.get<{ qtfnotify: SubsquadNotify[] }>(
    mockAPI + url
  )

  const items = response.data.qtfnotify.map((record: SubsquadNotify) => ({
    timestamp: record.timestamp,
    traderName: record.traderName,
    traderId: record.traderId,
    notificationStatus: record.notificationStatus,
  }))

  return items
}
