import Spinner from 'components/Spinner'
import SubsquadInfos from './SubsquadInfos'

const Index = ({
  isLoading,
  isError,
}: {
  isLoading: boolean
  isError: boolean
}): JSX.Element => {
  if (isLoading)
    return (
      <div className="flex flex-col min-w-full p-4">
        <Spinner />
      </div>
    )

  if (isError) return <></>

  return (
    <>
      <div className="flex flex-col min-w-full p-4">
        <SubsquadInfos />
      </div>
    </>
  )
}

export default Index
