import { useState } from 'react'
import { faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import ModalCreateMerchant from './ModalCreateMerchant'

const Buttons = (): JSX.Element => {
  const [showCreateMerchant, showCreateMerchantSet] = useState<boolean>(false)

  return (
    <>
      <button
        className="px-3 py-1 mx-1 border rounded-md border-primary text-primary"
        onClick={() => showCreateMerchantSet(true)}
        type="button"
      >
        <Icon icon={faPlus} className="mr-2" />
        <span className="tracking-wider font-light">Create Merchant</span>
      </button>

      <ModalCreateMerchant
        title="Create Merchant"
        open={showCreateMerchant}
        onClose={() => showCreateMerchantSet(false)}
      />
    </>
  )
}

export default Buttons
