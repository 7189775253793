import React from 'react'
import { Form, Formik } from 'formik'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Datepicker, { useDatepicker } from 'components/Datepicker'

import { formatDateToShort } from 'services/helpers'
import { useMemberVouchersStatuses } from './useMemberVouchersStatuses'
import { useMemberVouchersLookup } from './useMemberVouchersLookup'
import { useMemberVouchersFilterForm } from './useMemberVouchersFilterForm'

import { StatusTypes } from 'types/status'
import { VouchersFetchParamsTypes } from 'types/vouchers'
import { DropDownItem } from 'types/components'

const Index = ({
  formStatus,
  formDefaultValues,
  onFilter,
  onFilterClear,
}: {
  formStatus: StatusTypes
  formDefaultValues: VouchersFetchParamsTypes
  onFilter: (filterParams: VouchersFetchParamsTypes) => void
  onFilterClear: () => void
}): JSX.Element => {
  const { control, handleSubmit, setValue } = useMemberVouchersFilterForm({
    defaultValues: formDefaultValues,
  })

  const { memberVouchersStatusesOptions, memberVouchersStatusesModStatus } =
    useMemberVouchersStatuses()

  const {
    memberVouchersCodeOptionsLoader,
    memberVouchersNameOptionsLoader,
    memberVouchersIdentsModStatus,
  } = useMemberVouchersLookup()

  const { startDate, endDate, DatepickerInput, handlePick } = useDatepicker()

  const isFiltering = formStatus !== 'success' || false
  const isLoading = memberVouchersStatusesModStatus !== 'success' ? true : false

  if (isLoading)
    return (
      <span className="tracking-wider font-light">
        Preparing search options ...
      </span>
    )

  return (
    <FormsLayout.Wrapper onSubmit={handleSubmit(onFilter)}>
      <FormsLayout.Item>
        <Datepicker
          title="Date (Start Date - End Date)"
          name="dateRange"
          onChange={(dates: [Date, Date]) => {
            handlePick(dates)
            setValue(
              'startDate',
              dates[0] ? formatDateToShort(dates[0]) : undefined
            )
            setValue(
              'endDate',
              dates[1] ? formatDateToShort(dates[1]) : undefined
            )
          }}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          showYearDropdown
          scrollableYearDropdown
          className="p-2 border border-gray-400 tracking-wider"
          placeholderText="All"
          customInput={<DatepickerInput />}
          selectsRange
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="voucherName"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Voucher Names"
              loadOptions={memberVouchersNameOptionsLoader}
              onChange={(value: DropDownItem) => {
                if (value)
                  setValue('voucherName', value ? value.value : undefined)
              }}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              containerClassName="focus:border-primary"
              placeholder="Voucher names..."
              isClearable
              isSearchable
              async
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="voucherCode"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Voucher Codes"
              loadOptions={memberVouchersCodeOptionsLoader}
              onChange={(value: DropDownItem) => {
                if (value)
                  setValue('voucherCode', value ? value.value : undefined)
              }}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              containerClassName="focus:border-primary "
              placeholder="Voucher codes..."
              isClearable
              isSearchable
              async
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Status"
              options={memberVouchersStatusesOptions}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) =>
                setValue('status', value ? value.value : undefined)
              }
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          icon={faSearch}
          iconRight={false}
          type="submit"
          disabled={isFiltering}
          loading={isFiltering}
          className="rounded bg-primary tracking-wider font-bold"
        >
          Search
        </Button>
        {/* {isMemberOffersFiltered ? (
            <Button
              key="clear"
              iconRight={false}
              type="button"
              className="rounded-lg"
              onClick={() => {
                handleMemberOffersFilterClear()
                reset()
              }}
            >
              <span className="tracking-wider font-bold">Clear</span>
            </Button>
          ) : null} */}
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
