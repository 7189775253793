import create from 'zustand'

import {
  getMerchants,
  getMerchantBatchAPI,
  postCreateMerchantAPI,
  patchUpdateMerchantAPI,
  postCreateMerchantIdentificationAPI,
  postCreateMerchantDeviceAPI,
  putUpdateMerchantDeviceAPI,
  getMerchantDeviceAPI,
  getMerchantTransactionAPI,
  getMerchantTransactionFilteredAPI,
  getMerchantIdentificationAPI,
  getMerchantIdentificationFilteredAPI,
  getMerchantIdentificationStatusesAPI,
  getMerchantIdentificationTypesAPI,
  putUpdateMerchantIdentificationStatusesAPI,
  getMerchantsLookup,
  getMerchantsLookupOptions,
  getMerchantsTraderLookupOptions,
  getMerchantAPI,
  getMerchantProfileAPI,
} from 'api/merchant'
import { MerchantType } from 'types/transactions'
import { MerchantLookupTypes } from 'types/merchanTxTypes'
import {
  MerchantProfileTypes,
  MerchantDataTypes,
  MerchantTransactionTypes,
  MerchantIdentificationTypes,
  MerchantIdentificationFilterParamsTypes,
  MerchantIdentificationStatusesTypes,
  MerchantIdentificationTypesTypes,
  MerchantDeviceTypes,
  MerchantBatchTypes,
  CreateMerchantServiceTypes,
  UpdateMerchantServiceTypes,
  CreateMerchantIdentificationServiceTypes,
  CreateMerchantDeviceTypes,
  UpdateMerchantDeviceTypes,
  UpdateMerchantIndetificationStatusesTypes,
  MerchantTransactionFilterParamsTypes,
} from 'types/merchant'
import { StatusTypes, StatusErrorTypes } from 'types/status'

interface MerchantStoreTypes {
  merchantTraderIdNewlyCreated: number | null
  merchantBatchLoading: boolean
  merchantBatchStatus: boolean
  merchantBatch: MerchantBatchTypes[]
  merchantCreateLoading: boolean
  merchantCreateStatus: boolean
  merchant: MerchantType[]
  merchantLookupLoading: boolean
  merchantLookupStatus: boolean
  merchantLookup: MerchantLookupTypes[]
  getMerchantLookup: (merchantQuery: string) => void
  getMerchant: () => void
  getMerchantBatch: () => void
  setMerchant: (merchant: MerchantType[]) => void
  createMerchant: (payload: CreateMerchantServiceTypes) => void
  merchantUpdateLoading: boolean
  merchantUpdateStatus: boolean
  updateMerchant: (payload: UpdateMerchantServiceTypes) => void
  merchantProfile: MerchantProfileTypes[]
  merchantProfileLoading: boolean
  merchantProfileStatus: boolean
  merchantProfileModStatus: StatusTypes
  merchantProfileModError: StatusErrorTypes
  getMerchantProfile: (merchantTraderId: string) => void
  merchantIndentificationCreateLoading: boolean
  merchantIndentificationCreateStatus: boolean
  createMerchantIdentification: (
    merchantTraderId: number,
    payload: CreateMerchantIdentificationServiceTypes
  ) => void
  merchantTransactionBackup: MerchantTransactionTypes[]
  merchantTransaction: MerchantTransactionTypes[]
  merchantTransactionLoading: boolean
  merchantTransactionStatus: boolean
  merchantTransactionModStatus: StatusTypes
  merchantTransactionModError: StatusErrorTypes
  getMerchantTransactionFiltered: (
    merchantTraderId: number,
    filterParams: MerchantTransactionFilterParamsTypes
  ) => void
  getMerchantTransaction: (merchantTraderId: number) => void
  merchantIdentificationBackup: MerchantIdentificationTypes[]
  merchantIdentification: MerchantIdentificationTypes[]
  merchantIdentificationLoading: boolean
  merchantIdentificationStatus: boolean
  getMerchantIdentificationFiltered: (
    merchantTraderId: number,
    filterParams: MerchantIdentificationFilterParamsTypes
  ) => void
  getMerchantIdentification: (merchantTraderId: number) => void
  merchantIdentificationStatuses: MerchantIdentificationStatusesTypes[]
  merchantIdentificationStatusesLoading: boolean
  merchantIdentificationStatusesStatus: boolean
  getMerchantIdentificationStatuses: () => void
  merchantIdentificationTypes: MerchantIdentificationTypesTypes[]
  merchantIdentificationTypesLoading: boolean
  merchantIdentificationTypesStatus: boolean
  getMerchantIdentificationTypes: () => void
  merchantIdentificationStatusesUpdateLoading: boolean
  merchantIdentificationStatusesUpdateStatus: boolean
  updateMerchantIdentificationStatuses: (
    merchantTraderId: number,
    payload: UpdateMerchantIndetificationStatusesTypes
  ) => void
  merchantDevice: MerchantDeviceTypes[]
  merchantDeviceLoading: boolean
  merchantDeviceStatus: boolean
  getMerchantDevice: (merchantTraderId: number) => void
  merchantDeviceCreateLoading: boolean
  merchantDeviceCreateStatus: boolean
  createMerchantDevice: (
    merchantTraderId: number,
    payload: CreateMerchantDeviceTypes
  ) => void
  merchantDeviceUpdateLoading: boolean
  merchantDeviceUpdateStatus: boolean
  updateMerchantDevice: (
    merchantTraderId: number,
    payload: UpdateMerchantDeviceTypes
  ) => void
}

const useMerchantStore = create<MerchantStoreTypes>((set) => ({
  merchant: [],
  getMerchant: () => {
    return getMerchants()
      .then((merchant: MerchantType[]) => {
        set({
          merchant,
        })
        return merchant
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  },
  setMerchant: (merchant: MerchantType[]) => {
    set({
      merchant,
    })
  },
  // start: Merchant Profile
  merchantProfile: [],
  merchantProfileLoading: true,
  merchantProfileStatus: true,
  merchantProfileModStatus: 'idle',
  merchantProfileModError: '',
  getMerchantProfile: async (merchantTraderId) => {
    try {
      set({ merchantProfileLoading: true, merchantProfileModStatus: 'loading' })
      const merchantProfile = await getMerchantProfileAPI(merchantTraderId)
      set({
        merchantProfile,
        merchantProfileLoading: false,
        merchantTraderIdNewlyCreated: null,
        merchantProfileModStatus: 'success',
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantProfileStatus: false,
        merchantProfileLoading: false,
        merchantTraderIdNewlyCreated: null,
        merchantProfileModtatus: 'fail',
        merchantProfileModError: JSON.stringify(error),
      }))
    }
  },
  // start: Merchant Batch Lookup
  merchantBatchLoading: true,
  merchantBatchStatus: true,
  merchantBatch: [],
  getMerchantBatch: async () => {
    try {
      set({ merchantBatchLoading: true })
      const merchantBatch = await getMerchantBatchAPI()
      set({
        merchantBatch,
        merchantBatchLoading: false,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantBatchStatus: false,
        merchantBatchLoading: false,
      }))
    }
  },
  // start: Merchant Create
  merchantCreateLoading: false,
  merchantCreateStatus: false,
  merchantTraderIdNewlyCreated: null,
  createMerchant: async (payload: CreateMerchantServiceTypes) => {
    try {
      set({ merchantCreateLoading: true })
      const merchant = await postCreateMerchantAPI(payload)
      set({
        merchantCreateLoading: false,
        merchantCreateStatus: true,
        merchantTraderIdNewlyCreated: merchant.traderId,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantBatchLoading: false,
        merchantCreateStatus: false,
      }))
      throw error
    }
  },
  // start: Merchant Update
  merchantUpdateLoading: false,
  merchantUpdateStatus: false,
  updateMerchant: async (payload) => {
    try {
      set({ merchantUpdateLoading: true })
      await patchUpdateMerchantAPI(payload)
      set({ merchantUpdateLoading: false, merchantUpdateStatus: true })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantUpdateLoading: false,
        merchantUpdateStatus: false,
      }))
      throw error
    }
  },
  // start: Merchant Transaction List
  merchantTransactionLoading: true,
  merchantTransactionStatus: true,
  merchantTransactionBackup: [],
  merchantTransaction: [],
  merchantTransactionModStatus: 'idle',
  merchantTransactionModError: '',
  getMerchantTransactionFiltered: async (merchantTraderId, filterParams) => {
    try {
      set({
        merchantTransactionLoading: true,
        merchantTransactionModStatus: 'loading',
      })
      const merchantTransactionFiltered =
        await getMerchantTransactionFilteredAPI(merchantTraderId, filterParams)
      set((state) => ({
        merchantTransaction: merchantTransactionFiltered,
        merchantTransactionBackup: state.merchantTransaction,
        merchantTransactionLoading: false,
        merchantTransactionModStatus: 'success',
      }))
    } catch (error) {
      set((state) => ({
        ...state,
        merchantTransactionStatus: false,
        merchantTransactionLoading: false,
        merchantTransactionModStatus: 'fail',
        merchantProfileModError: JSON.stringify(error),
      }))
    }
  },
  getMerchantTransaction: async (merchantTraderId) => {
    try {
      set({
        merchantTransactionLoading: true,
        merchantTransactionModStatus: 'loading',
      })
      const merchantTransaction = await getMerchantTransactionAPI(
        merchantTraderId
      )
      set({
        merchantTransaction,
        merchantTransactionBackup: [],
        merchantTransactionLoading: false,
        merchantTransactionModStatus: 'success',
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantTransactionStatus: false,
        merchantTransactionLoading: false,
        merchantTransactionModStatus: 'fail',
        merchantProfileModError: JSON.stringify(error),
      }))
    }
  },
  // start: Merchant Identification List
  merchantIdentificationLoading: true,
  merchantIdentificationStatus: true,
  merchantIdentificationBackup: [],
  merchantIdentification: [],
  getMerchantIdentificationFiltered: async (merchantTraderId, filterParams) => {
    try {
      set({ merchantIdentificationLoading: true })
      const merchantIdentificationFiltered =
        await getMerchantIdentificationFilteredAPI(
          merchantTraderId,
          filterParams
        )
      set((state) => ({
        merchantIdentification: merchantIdentificationFiltered,
        merchantIdentificationBackup: state.merchantIdentification,
        merchantIdentificationLoading: false,
      }))
    } catch (error) {
      set((state) => ({
        ...state,
        merchantIdentificationStatus: false,
        merchantIdentificationLoading: false,
      }))
    }
  },
  getMerchantIdentification: async (merchantTraderId) => {
    try {
      set({ merchantIdentificationLoading: true })
      const merchantIdentification = await getMerchantIdentificationAPI(
        merchantTraderId
      )
      set({
        merchantIdentification,
        merchantIdentificationBackup: [],
        merchantIdentificationLoading: false,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantIdentificationStatus: false,
        merchantIdentificationLoading: false,
      }))
    }
  },
  // start: Merchant Identification Statuses List
  merchantIdentificationStatusesLoading: true,
  merchantIdentificationStatusesStatus: true,
  merchantIdentificationStatuses: [],
  getMerchantIdentificationStatuses: async () => {
    try {
      set({ merchantIdentificationStatusesLoading: true })
      const merchantIdentificationStatuses =
        await getMerchantIdentificationStatusesAPI()
      set({
        merchantIdentificationStatuses,
        merchantIdentificationStatusesLoading: false,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantIdentificationStatusesStatus: false,
        merchantIdentificationStatusesLoading: false,
      }))
    }
  },
  // start: Merchant Identification Types List
  merchantIdentificationTypesLoading: true,
  merchantIdentificationTypesStatus: true,
  merchantIdentificationTypes: [],
  getMerchantIdentificationTypes: async () => {
    try {
      set({ merchantIdentificationTypesLoading: true })
      const merchantIdentificationTypes =
        await getMerchantIdentificationTypesAPI()
      set({
        merchantIdentificationTypes,
        merchantIdentificationTypesLoading: false,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantIdentificationTypesStatus: false,
        merchantIdentificationTypesLoading: false,
      }))
    }
  },
  // start: Merchant Identification Statuses Update
  merchantIdentificationStatusesUpdateLoading: false,
  merchantIdentificationStatusesUpdateStatus: false,
  updateMerchantIdentificationStatuses: async (merchantTraderId, payload) => {
    try {
      set({
        merchantIdentificationStatusesUpdateLoading: true,
      })
      await putUpdateMerchantIdentificationStatusesAPI(
        merchantTraderId,
        payload
      )
      set({
        merchantIdentificationLoading: true,
      })
      const merchantIdentification = await getMerchantIdentificationAPI(
        merchantTraderId
      )
      set({
        merchantIdentificationStatusesUpdateLoading: false,
        merchantIdentificationStatusesUpdateStatus: true,
        merchantIdentification,
        merchantIdentificationLoading: false,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantIdentificationStatusesUpdateLoading: false,
        merchantIdentificationStatusesUpdateStatus: false,
        merchantIdentificationLoading: false,
      }))
      throw error
    }
  },
  // start: Merchant Identification Create
  merchantIndentificationCreateLoading: false,
  merchantIndentificationCreateStatus: false,
  createMerchantIdentification: async (merchantTraderId, payload) => {
    try {
      set({
        merchantIndentificationCreateLoading: true,
      })
      await postCreateMerchantIdentificationAPI(payload)
      set({
        merchantIdentificationLoading: true,
      })
      const merchantIdentification = await getMerchantIdentificationAPI(
        merchantTraderId
      )
      set({
        merchantIndentificationCreateLoading: false,
        merchantIndentificationCreateStatus: true,
        merchantIdentification,
        merchantIdentificationLoading: false,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantIndentificationCreateLoading: false,
        merchantIndentificationCreateStatus: false,
        merchantIdentificationLoading: false,
      }))
      throw error
    }
  },
  // start: Merchant Device List
  merchantDeviceLoading: true,
  merchantDeviceStatus: true,
  merchantDevice: [],
  getMerchantDevice: async (merchantTraderId) => {
    try {
      set({ merchantDeviceLoading: true })
      const merchantDevice = await getMerchantDeviceAPI(merchantTraderId)
      set({
        merchantDevice,
        merchantDeviceLoading: false,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantDeviceStatus: false,
        merchantDeviceLoading: false,
      }))
    }
  },
  // start: Merchant Device Create
  merchantDeviceCreateLoading: false,
  merchantDeviceCreateStatus: false,
  createMerchantDevice: async (merchantTraderId, payload) => {
    try {
      set({ merchantDeviceCreateLoading: true })
      await postCreateMerchantDeviceAPI(payload)
      set({ merchantDeviceLoading: true })
      const merchantDevice = await getMerchantDeviceAPI(merchantTraderId)
      set({
        merchantDeviceCreateLoading: false,
        merchantDeviceCreateStatus: true,
        merchantDevice,
        merchantDeviceLoading: false,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantDeviceCreateLoading: false,
        merchantDeviceCreateStatus: false,
        merchantDeviceLoading: false,
      }))
      throw error
    }
  },
  // start: Merchant Device Update
  merchantDeviceUpdateLoading: false,
  merchantDeviceUpdateStatus: false,
  updateMerchantDevice: async (merchantTraderId, payload) => {
    try {
      set({ merchantUpdateLoading: true })
      await putUpdateMerchantDeviceAPI(payload)
      set({ merchantDeviceLoading: true })
      const merchantDevice = await getMerchantDeviceAPI(merchantTraderId)
      set({
        merchantUpdateLoading: false,
        merchantUpdateStatus: true,
        merchantDevice,
        merchantDeviceLoading: false,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantUpdateLoading: false,
        merchantUpdateStatus: false,
        merchantDeviceLoading: false,
      }))
      throw error
    }
  },
  // start: Merchant Lookup
  merchantLookupLoading: true,
  merchantLookupStatus: true,
  merchantLookup: [],
  getMerchantLookup: async (merchantQuery) => {
    try {
      const merchantLookup = await getMerchantsTraderLookupOptions(
        merchantQuery
      )
      set({
        merchantLookup,
        merchantLookupLoading: false,
      })
    } catch (error) {
      set((state) => ({
        ...state,
        merchantLookupStatus: false,
        merchantLookupLoading: false,
      }))
    }
  },
}))

export default useMerchantStore
