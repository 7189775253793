import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import TSTable from 'components/Tanstack'
import { ToggleMenu } from 'components/Layout/ToggleMenu'

import InnerWrapper from '../InnerWrapper'
import { useMemberHistoryTable } from './useMemberHistoryTable'
import HistoryFilterForm from './HistoryFilterForm'
import { useMemberHistory } from './useMemberHistory'

const Index = (): JSX.Element => {
  const {
    memberHistory,
    memberHistoryModStatus,
    memberHistoryFilterParams,
    memberHandleHistoryFilter,
    memberHandleHistoryUnfilter,
  } = useMemberHistory()
  
  const {
    memberHistorySelected,
    memberHistoryActionModal,
    memberHistoryTableData,
    memberHistoryTableColumns,
    handleMemberActionModalClose,
  } = useMemberHistoryTable()

  return (
    <InnerWrapper title="History">
      {/* ADVANCE SEARCH  */}
      <div className="pb-6">
        <ToggleMenu
          type="search"
          label="Search History"
          isTriggeToggle={memberHistoryModStatus === 'success' || false}
        >
          <HistoryFilterForm
            formStatus={memberHistoryModStatus}
            formDefaultValues={memberHistoryFilterParams}
            onFilter={memberHandleHistoryFilter}
            onFilterClear={memberHandleHistoryUnfilter}
          />
        </ToggleMenu>
      </div>

      {/* FAIL */}
      {memberHistoryModStatus === 'fail' ? (
        <NoResults
          header="Failed to load data"
          subtitle="Please refresh the page and try again, or contact
        your system administrator"
          noImage
        />
      ) : null}

      {/* LOADING */}
      {memberHistoryModStatus === 'loading' ? <Spinner /> : null}

      {/* SUCCESS */}
      {memberHistory.length && memberHistoryModStatus === 'success' ? (
        <TSTable.Table
          data={memberHistoryTableData}
          columns={memberHistoryTableColumns}
        />
      ) : null}

      {/* EMPTY */}
      {!memberHistory.length && memberHistoryModStatus === 'success' ? (
        <NoResults
          header="No available data"
          subtitle="There is no available data to display"
          noImage
        />
      ) : null}
    </InnerWrapper>
  )
}

export default Index
