import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { OffersDataTypes } from 'types/offers'

interface OffersStoreTypes {
  offersModErrors: StatusErrorTypes
  offersModErrorsSet: (offersModErrors: StatusErrorTypes) => void
  offersModStatus: StatusTypes
  offersModStatusSet: (offersStatus: StatusTypes) => void
  offers: OffersDataTypes[]
  offersSet: (offers: OffersDataTypes[]) => void
  offersLookup: OffersDataTypes[]
  offersLookupSet: (offers: OffersDataTypes[]) => void
}

export const useOffersStore = create<OffersStoreTypes>((set) => ({
  offersModErrors: '',
  offersModErrorsSet: (offersModErrors) => {
    set({
      offersModErrors,
    })
  },
  offersModStatus: 'idle',
  offersModStatusSet: (offersModStatus) => {
    set({
      offersModStatus,
    })
  },
  offers: [],
  offersSet: (offers) => {
    set({
      offers,
    })
  },
  offersLookup: [],
  offersLookupSet: (offersLookup) => {
    set({
      offersLookup,
    })
  },
}))

interface OffersLookupStoreTypes {
  offersLookupModErrors: StatusErrorTypes
  offersLookupModErrorsSet: (offersLookupModErrors: StatusErrorTypes) => void
  offersLookupModStatus: StatusTypes
  offersLookupModStatusSet: (offersLookupStatus: StatusTypes) => void
  offersLookup: OffersDataTypes[]
  offersLookupSet: (offersLookup: OffersDataTypes[]) => void
}

export const useOffersLookupStore = create<OffersLookupStoreTypes>((set) => ({
  offersLookupModErrors: '',
  offersLookupModErrorsSet: (offersLookupModErrors) => {
    set({
      offersLookupModErrors,
    })
  },
  offersLookupModStatus: 'idle',
  offersLookupModStatusSet: (offersLookupModStatus) => {
    set({
      offersLookupModStatus,
    })
  },
  offersLookup: [],
  offersLookupSet: (offersLookup) => {
    set({
      offersLookup,
    })
  },
}))
