import Modal from 'components/Modal'
import UpdateStatus from 'views/MemberProfile/Profile/UpdateStatus'

const ModalUpdateStatus = ({
  title,
  open,
  onClose,
}: {
  title: string | undefined
  open: boolean
  onClose: () => void
}): JSX.Element => {
  return (
    <Modal open={open} title={title} onClose={onClose} noFooter>
      <UpdateStatus onClose={onClose} />
    </Modal>
  )
}

export default ModalUpdateStatus
