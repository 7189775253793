import { useState } from 'react'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import TSTable from 'components/Tanstack'

import { useMerchantDevicesStore } from 'store/merchantDevices'
import { MerchantDevicesDataTypes } from 'types/merchantDevices'

export const useMerchantDevicesTable = () => {
    const merchantDevices = useMerchantDevicesStore((state) => state.merchantDevices)
    
    const[selectedMerchantDevices, selectedMerchantDevicesSet] = useState<MerchantDevicesDataTypes | null>(null)

    const columnHelper = createColumnHelper<MerchantDevicesDataTypes>()
    const columns = [
        columnHelper.accessor ('deviceId', {
            header: () => 'Device ID',
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor ('deviceCode', {
            header: () => 'Device Code',
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor ('startDate', {
            header: () => 'Start Date',
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor ('endDate', {
            header: () => 'End Date',
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('deviceTypeId', {
            header: () => 'Device Type Id',
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('comments', {
            header: () => 'Comments',
            cell: (info) => info.getValue(),
        })
        // columnHelper.display({
        //     id: 'actions',
        //     header: () => '',
        //     // cell: (info) => info.getValue(),
        //     cell: ({ row }) =>
        //        (
        //         <div className="flex gap-2 px-4">
        //           <TSTable.TableCellButton
        //             onClick={() => {
        //                 selectedMerchantDevicesSet(row.original)
        //             }}
        //             label="Preview"
        //             icon={faEye}
        //           />
        //         </div>
        //       ),
        //   }),
        ] as ColumnDef<MerchantDevicesDataTypes>[]
        
        return {
            merchantDevicesSelected: selectedMerchantDevices,
            merchantDevicesTableData: merchantDevices,
            merchantDevicesTableColumns: columns,
          }
}

/*const DEVICES_COLUMNS = ['Device ID', 'Device Code', 'Start Date', 'End Date']*/
   
/*  
for first param of accessor

merchantId: zod.number(),
    merchantName: zod.string(),
    merchantCode: zod.number(),
    deviceId: zod.number(),
    deviceCode: zod.string(),
    startDate: zod.string(),
    endDate: zod.string(),
    comments: zod.string().nullable(),*/ 