import Modal from 'components/Modal'
import UpdateDetails from 'views/MerchantProfile/Profile/UpdateDetails'

type Props = {
  title: string | undefined
  open: boolean
  onClose: () => void
}

const Index = ({ title, open, onClose }: Props): JSX.Element => {
  return (
    <Modal open={open} title={title} onClose={onClose} noFooter>
      <UpdateDetails onClose={onClose} />
    </Modal>
  )
}

export default Index
