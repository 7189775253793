import { Logo } from 'components/ui'
import { ROUTES } from 'constants/routing'
import { Link } from 'react-router-dom'
import RightNav from './RightNav'
import DebugInfo from './DebugInfo'

const projectENV = process.env.REACT_APP_ENV

const Header = (): JSX.Element => (
  <header className="relative z-10 flex flex-col items-center justify-between min-w-full py-4 lg:px-4 lg:flex-row lg:fixed bg-primary lg:h-14">
    {/* start: Debugging Info Environment */}
    <DebugInfo />
    {/* end: Debugging Info Environment */}

    <Link to={ROUTES.MemberLookup.path} className="text-white w-44">
      <Logo />
    </Link>

    <h3 className="py-4 text-white lg:py-0 tracking-wider">
      Help Desk Assistant
    </h3>

    <RightNav />
  </header>
)

export default Header
