import React from 'react'

import { DateTime } from 'luxon'

export const TableCellDate = ({
  date,
}: {
  date: string
}): React.ReactElement => {
  return (
    <>
      {date ? (
        <p className="flex flex-col">
          <span>
            {DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)}
          </span>
          <small className="text-primary text-opacity-40">
            {DateTime.fromISO(date).toLocaleString(
              DateTime.DATETIME_FULL_WITH_SECONDS
            )}
          </small>
        </p>
      ) : (
        '---'
      )}
    </>
  )
}
