import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const SubsquadsDefaultSchemas = DefaultSchemas

export const SubsquadsDataSchemas = zod.object({
  status: zod.number().nullable().optional(),
  groupId: zod.number().nullable().optional(),
  groupUrn: zod.string().nullable().optional(),
  createDate: zod.string().nullable().optional(),
  groupName: zod.string().nullable().optional(),
  flActive: zod.number().nullable().optional(),
})

export const SubsquadsAPISchemas =
  SubsquadsDefaultSchemas.merge(SubsquadsDataSchemas)

export const SubsquadsFetchParamsSchemas = zod.object({
  traderId: zod.number(),
})
