import React from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

export const TableCellButton = ({
  label,
  icon,
  onClick,
  disabled = false,
}: {
  label: string
  icon?: IconDefinition
  onClick: () => void
  disabled?: boolean
}): React.ReactElement => {
  return (
    <button
      title={label}
      type="button"
      className="relative px-1 text-center transition duration-150 rounded-sm focus:outline-none focus:shadow-outlin group"
      data-tip="Change Status"
      onClick={disabled ? () => null : onClick}
      disabled={disabled}
    >
      {icon && (
        <Icon
          icon={icon}
          className={`text-primary ${
            disabled
              ? 'text-opacity-20'
              : ' text-opacity-40 group-hover:text-primary'
          }`}
        />
      )}
      {!icon && <span>{label}</span>} {/* Display label if no icon */}
    </button>
  )
}
