import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getTXReasonssAPI } from 'api/transactions'
import { useTXReasonsStore } from 'store/transactions'
import { DropDownItem } from 'types/components'

import { DATASTATUS } from 'constants/status'

export const useMemberTXReasons = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const TXReasonsModStatus = useTXReasonsStore(
    (state) => state.TXReasonsModStatus
  )
  const TXReasonsModStatusSet = useTXReasonsStore(
    (state) => state.TXReasonsModStatusSet
  )
  const TXReasonsModErrors = useTXReasonsStore(
    (state) => state.TXReasonsModErrors
  )
  const TXReasonsModErrorsSet = useTXReasonsStore(
    (state) => state.TXReasonsModErrorsSet
  )
  const TXReasons = useTXReasonsStore((state) => state.TXReasons)
  const TXReasonsSet = useTXReasonsStore(
    (state) => state.TXReasonsSet
  )

  const {
    data: dataComms,
    isLoading: isLoadingComms,
    isError: isErrorComms,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useTXReasons'],
    queryFn: async () => {
      try {
        TXReasonsModStatusSet('loading')
        const response = await getTXReasonssAPI()
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        TXReasonsModStatusSet('success')
        return response.reasons
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)), ' ERR')
        TXReasonsModStatusSet('fail')
        TXReasonsModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataComms) TXReasonsSet(dataComms)
  }, [dataComms, TXReasonsSet])

  const TXReasonsOptions: DropDownItem[] = useMemo(() => {
    return TXReasons.map((item) => ({
      label: String(item.reasonName),
      value: String(item.reasonId),
    }))
  }, [TXReasons])

  return {
    membersTXReasonsOptions: TXReasonsOptions,
    memberTXReasons: TXReasons,
    memberTXReasonsModStatus: TXReasonsModStatus,
  }
}
