import { Logo, LogoAccount } from 'components/ui'
import { useMemberProfilesStore } from 'store/memberprofiles'

const Index = (): JSX.Element => {
  const memberprofiles = useMemberProfilesStore((state) => state.memberprofiles)
  const isGeneric = process.env.REACT_APP_MODULE_CODE == 'HDA_MAINNZ'
  const project = process.env.REACT_APP_PROJ || 'subway'

  return (
    <div
      className={
        'flex flex-col items-center justify-center gap-2 relative p-8 text-white rounded-xl w-72 ' +
        (isGeneric ? 'bg-primary' : 'bg-white')
      }
    >
      {/* TODO: replace with actual logo from API */}
      {isGeneric ? (
        <Logo />
      ) : (
        <LogoAccount account="subway" project={project} />
      )}
      {memberprofiles.map((item) => (
        <p
          key={item.traderId}
          className="text-lg font-light tracking-wider text-black text-center"
        >
          {item.cardNumber ? item.cardNumber : '---'}
        </p>
      ))}
    </div>
  )
}

export default Index
