import { BankAccount, BankName, DirectPayment, Frequency } from 'types/finance'
import { http } from 'utils'

import { FrequencyAPITypes } from 'types/frequency'
import { FrequencyAPISchemas } from 'schemas/frequencySchemas'
import { BankThorAPITypes } from 'types/bankThor'
import { BankThorAPISchemas } from 'schemas/bankThroSchemas'
import { BankAPITypes } from 'types/bank'
import { BankAPISchemas } from 'schemas/bankSchemas'
import {
  BankAccountsDefaultTypes,
  BankAccountsAPITypes,
  BankAccountsDataTypes,
  PaymentInstructionsDefaultTypes,
  PaymentInstructionsAPITypes,
  PaymentInstructionsDataTypes,
} from 'types/financials'
import {
  BankAccountsAPISchemas,
  PaymentInstructionsAPISchemas,
} from 'schemas/financialsSchemas'
import { FinancialAddBankAccountTxAPITypes } from 'types/financialAddBankAccountTxTypes'
import { FinancialAddPaymentInstructionTxAPITypes } from 'types/financialAddPaymentInstructionTxTypes'
import { FinancialUpdatePaymentInstructionTxAPITypes } from 'types/financialUpdatePaymentInstructionTxTypes'
import { FinancialSkipPaymentInstructionTxAPITypes } from 'types/financialSkipPaymentInstructionTxTypes'
import { FinancialCancelPaymentInstructionTxAPITypes } from 'types/financialCancelPaymentInstructionTxTypes'
import { FinancialUpdateBankAccountTxTypes } from 'types/financialUpdateBankAccountTxTypes'
import { FinancialEMRActionsTxAPITypes } from 'types/financialEMRActionsTxTypes'

import { DATASTATUS } from 'constants/status'

// TRADER TYPE HAMPSTA = Cardholder
// TRADER TYPE MAD BUTCHER = Mad Butcher Xmas Club
// USE RELEVANT EMR ACTION ID AS PER TRADER TYPE
const HAMPSTA_EMR_ACTION_ID = process.env.REACT_APP_HAMPSTA_EMR_ACTION_ID
const HAMPSTA_CANCEL_EMR_ACTION_ID =
  process.env.REACT_APP_HAMPSTA_CANCEL_EMR_ACTION_ID
const HAMPSTA_BANKACCOUNT_EMR_ACTION_ID =
  process.env.REACT_APP_HAMPSTA_BANKACCOUNT_EMR_ACTION_ID
const MADBUTCHER_EMR_ACTION_ID = process.env.REACT_APP_MADBUTCHER_EMR_ACTION_ID
const MADBUTCHER_CANCEL_EMR_ACTION_ID =
  process.env.REACT_APP_MADBUTCHER_CANCEL_EMR_ACTION_ID
const MADBUTCHER_BANKACCOUNT_EMR_ACTION_ID =
  process.env.REACT_APP_MADBUTCHER_BANKACCOUNT_EMR_ACTION_ID

export async function getBankAccounts_API({
  traderId,
  startDate,
  endDate,
  pSize,
  pNumber,
}: {
  traderId: number
  startDate?: string
  endDate?: string
  pSize?: number
  pNumber?: number
}): Promise<BankAccountsAPITypes> {
  let url = `/financials/bank-account/${traderId}`
  const params = new URLSearchParams()

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = BankAccountsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      bankAccounts: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function updateBankAccountStatusAPI({
  traderId,
  bankAccountId,
  bankAccountName,
  accountStatusId,
  note,
}: FinancialUpdateBankAccountTxTypes): Promise<BankAccountsDefaultTypes> {
  let url = `/financials/bank-account/${traderId}`
  const params = new URLSearchParams()

  params.set('bankAccountId', bankAccountId.toString())
  params.set('bankAccountName', bankAccountName?.toString())
  params.set('accountStatusId', accountStatusId.toString())
  params.set('note', note.toString())

  url += `?${params.toString()}`

  const response = await http.patch(url)

  return response.data
}

export async function getDirectPayments_API({
  traderId,
  startDate,
  endDate,
  pSize,
  pNumber,
}: {
  traderId: number
  startDate?: string
  endDate?: string
  pSize?: number
  pNumber?: number
}): Promise<PaymentInstructionsAPITypes> {
  let url = `/financials/direct-payment/${traderId}`
  const params = new URLSearchParams()

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = PaymentInstructionsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      directPaymentInstruction: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getBanksThor_API(): Promise<BankThorAPITypes> {
  const url = '/financials/bank-name'

  const response = await http.get<unknown>(url)
  const validatedData = BankThorAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      bankNames: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getBanks_API({
  bankNumber,
  bankBranch,
  bankThorNames,
}: {
  bankNumber: string
  bankBranch: string
  bankThorNames: string[]
}): Promise<BankAPITypes> {
  const response = await http.get<unknown>(
    `https://txstrapiuat.tr4ns.com/api/nz-banks?filters[bankNumber][$eq]=${bankNumber}&filters[branchNumber][$eq]=${bankBranch}`,
    {
      headers: {
        Authorization:
          'Bearer cc3ee2226f292b5689664b7157d36853ce898db8f0903c426e0e6602772fb59749ca5f98087ff6c02bb01c22a0732c1abb4e14ea29b57692a825b9aa9e8ed919466a0e0d1ae8f087af18b33e320d7809f72435db63ea5f4f122529c4d6c9eb771d1f7d11965537a610abd85a8d2f2a2d7a0332bda8d4c877950799b41b4816b1',
      },
    }
  )

  const validatedData = BankAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      data: [],
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getFrequency_API(): Promise<FrequencyAPITypes> {
  const url = '/financials/direct-payment/frequency'

  const response = await http.get<unknown>(url)
  const validatedData = FrequencyAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      directPaymentInstructionFrequency: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getReason_API(): Promise<FrequencyAPITypes> {
  const url = '/financials/direct-payment/reason'

  const response = await http.get<unknown>(url)
  const validatedData = FrequencyAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      directPaymentInstructionFrequency: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function addBankAccount_API({
  traderId,
  bankId,
  bankAccountName,
  bankAccountNumber,
  accountStatusId,
  userId,
}: FinancialAddBankAccountTxAPITypes): Promise<BankAccountsAPITypes> {
  let url = `/financials/bank-account/${traderId}`

  const params = new URLSearchParams()

  if (bankId) {
    params.set('bankId', bankId.toString())
  }

  if (bankAccountNumber) {
    params.set('bankAccountNumber', bankAccountNumber.toString())
  }

  if (bankAccountName) {
    params.set('bankAccountName', bankAccountName)
  }

  if (accountStatusId) {
    params.set('accountStatusId', accountStatusId.toString())
  }

  if (userId) {
    params.set('userId', userId.toString())
  }

  url += `?${params.toString()}`

  const data = {
    bankId,
    bankAccountNumber,
    bankAccountName,
    accountStatusId,
    userId,
  }

  return await http.post(url, data)
}

export async function addPaymentInstruction_API({
  traderId,
  bankAccountNumber,
  paymentAmount,
  frequency,
  firstPaymentDate,
  endPaymentDate,
  instructionName,
}: FinancialAddPaymentInstructionTxAPITypes): Promise<PaymentInstructionsAPITypes> {
  let url = `/financials/direct-payment/${traderId}`

  const params = new URLSearchParams()

  if (bankAccountNumber) {
    params.set('bankAccountNumber', bankAccountNumber)
  }

  if (paymentAmount) {
    params.set('paymentAmount', paymentAmount.toString())
  }

  if (frequency) {
    params.set('frequency', frequency.toString())
  }

  if (firstPaymentDate) {
    params.set('firstPaymentDate', firstPaymentDate)
  }

  if (endPaymentDate) {
    params.set('endPaymentDate', endPaymentDate)
  }

  if (instructionName) {
    params.set('instructionName', instructionName)
  }

  url += `?${params.toString()}`

  const data = {
    bankAccountNumber,
    paymentAmount,
    frequency,
    firstPaymentDate,
    endPaymentDate,
    instructionName,
  }

  const response = await http.post(url, data)

  const validatedData = PaymentInstructionsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      directPaymentInstruction: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getPaymentFrequency(): Promise<FrequencyAPITypes> {
  const url = '/financials/direct-payment/frequency'

  const response = await http.get<unknown>(url)
  const validatedData = FrequencyAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      directPaymentInstructionFrequency: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function updatePaymentInstruction_API({
  paymentId,
  traderId,
  instructionName,
  bankAccountNumber,
  paymentAmount,
  frequency,
  firstPaymentDate,
  endPaymentDate,
  reasonId,
}: FinancialUpdatePaymentInstructionTxAPITypes): Promise<void> {
  const url = `/financials/direct-payment/${traderId}/${paymentId}`

  const data = {
    bankAccountNumber,
    paymentAmount,
    frequency,
    firstPaymentDate: firstPaymentDate.split('T')[0],
    endPaymentDate: endPaymentDate.split('T')[0],
    instructionName,
  }

  return await http.post(url, data)
}

export async function skipPaymentInstruction_API({
  paymentId,
  traderId,
  instructionName,
  bankAccountNumber,
  paymentAmount,
  frequency,
  firstPaymentDate,
  endPaymentDate,
  skipNumPayment,
  comment,
  reasonId,
}: FinancialSkipPaymentInstructionTxAPITypes): Promise<void> {
  const url = `/financials/direct-payment/${traderId}/${paymentId}/skip`

  const data = {
    bankAccountNumber,
    paymentAmount,
    frequency,
    firstPaymentDate: firstPaymentDate.split('T')[0],
    endPaymentDate: endPaymentDate.split('T')[0],
    instructionName,
    skipNumPayment,
    comment,
    reasonId,
  }

  return await http.post(url, data)
}

export async function cancelPaymentInstruction_API({
  paymentId,
  traderId,
  instructionName,
  bankAccountNumber,
  paymentAmount,
  frequency,
  firstPaymentDate,
  endPaymentDate,
  comment,
  reasonId,
}: FinancialCancelPaymentInstructionTxAPITypes): Promise<void> {
  const url = `/financials/direct-payment/${traderId}/${paymentId}/canceled`

  const data = {
    bankAccountNumber,
    paymentAmount,
    frequency,
    firstPaymentDate: firstPaymentDate.split('T')[0],
    endPaymentDate: endPaymentDate.split('T')[0],
    instructionName,
    comment,
  }

  return await http.post(url, data)
}

export async function EMRACTIONS_API(
  { isBankAccountTrigger }: { isBankAccountTrigger: boolean },
  {
    isCancelInstructionTrigger = false,
  }: { isCancelInstructionTrigger: boolean },
  {
    traderId,
    traderType,
    firstName,
    paymentAmount,
    paymentDate,
    paymentFrequency,
    bankAccount,
  }: FinancialEMRActionsTxAPITypes
): Promise<void> {
  const url = `/financials/emr-actions`

  let actionId = 0
  const isMadButcher = traderType === 'Mad Butcher Xmas Club' ? true : false

  if (isBankAccountTrigger) {
    actionId = isMadButcher
      ? parseInt(MADBUTCHER_BANKACCOUNT_EMR_ACTION_ID || '')
      : parseInt(HAMPSTA_BANKACCOUNT_EMR_ACTION_ID || '')
  } else if (isCancelInstructionTrigger) {
    actionId = isMadButcher
      ? parseInt(MADBUTCHER_CANCEL_EMR_ACTION_ID || '')
      : parseInt(HAMPSTA_CANCEL_EMR_ACTION_ID || '')
  } else {
    actionId = isMadButcher
      ? parseInt(MADBUTCHER_EMR_ACTION_ID || '')
      : parseInt(HAMPSTA_EMR_ACTION_ID || '')
  }

  const data = {
    actionId,
    traderId,
    firstName,
    paymentAmount,
    paymentDate: `${paymentDate.split('T')[0]} 00:00:00`,
    paymentFrequency,
    bankAccount,
  }

  await http.post(url, data)
}
