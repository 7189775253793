import {
  faAt,
  faBell,
  faCommentAlt,
  faEnvelope,
  faMailBulk,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import { CommsDataTypes } from 'types/comms'

const Index = ({
  communication,
}: {
  communication: CommsDataTypes
}): JSX.Element => {
  let icon = (
    <div className="flex items-center justify-center mr-4 bg-yellow-100 rounded-full h-7 w-7">
      <Icon className="text-blue-100" icon={faAt} />
    </div>
  )

  switch (communication.channelName.toLowerCase()) {
    case 'email':
      icon = (
        <div className="flex items-center justify-center mr-4 bg-yellow-100 rounded-full h-7 w-7">
          <Icon className="text-white text-opacity-75" icon={faAt} />
        </div>
      )
      break
    case 'postal':
      icon = (
        <div className="flex items-center justify-center mr-4 bg-blue-300 rounded-full h-7 w-7">
          <Icon className="text-white text-opacity-75" icon={faEnvelope} />
        </div>
      )
      break
    case 'sms':
      icon = (
        <div className="flex items-center justify-center mr-4 rounded-full h-7 w-7 bg-purple">
          <Icon className="text-white text-opacity-75" icon={faCommentAlt} />
        </div>
      )
      break
    case 'push':
      icon = (
        <div className="flex items-center justify-center mr-4 bg-green-200 rounded-full h-7 w-7">
          <Icon className="text-white text-opacity-75" icon={faBell} />
        </div>
      )
      break
    case 'all':
      icon = (
        <div className="flex items-center justify-center mr-4 rounded-full h-7 w-7 bg-orange">
          <Icon className="text-white text-opacity-75" icon={faMailBulk} />
        </div>
      )
      break
    default:
      icon = (
        <div className="flex items-center justify-center mr-4 bg-yellow-100 rounded-full h-7 w-7">
          <Icon className="text-blue-100" icon={faAt} />
        </div>
      )
      break
  }

  return (
    <>
      <div className="flex items-center">
        {icon}
        <span className="tracking-wider font-bold">
          {communication.actionName}
        </span>
      </div>
      <p className="text-gray-500">
        {DateTime.fromISO(communication.sentDate || '').toRelative()}
      </p>
    </>
  )
}

export default Index
