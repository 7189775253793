import { DateTime } from 'luxon'

export type KeyValue = { [key: string]: string }

// Convert array of strings to key value pair object
const splitStr = (x: string): KeyValue => {
  const y = x.split(':')
  return { [y[0].trim()]: y[1].trim() }
}

export function parseToObject(dataArray: string[]): KeyValue {
  const newParsed = dataArray.map(splitStr)

  const objectParsed = newParsed.reduce((result, item) => {
    const key = Object.keys(item)[0]
    result[key] = item[key]
    return result
  }, {})

  return objectParsed
}

// Converts Date to YYYY-MM-dd format
export function formatDateToShort(date: Date): string {
  const newDate = new Date(date),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [newDate.getFullYear(), mnth, day].join('-')
}

// Converts Date to YYYY-MM-ddThh:mm:ssZ format
export function formatDateTimeToShort(date: Date): string {
  const newDate = new Date(date)
  return newDate?.toISOString()
}

export function parseDate(dateString: string, type: 'date' | 'time'): string {
  if (type === 'date')
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED)
  else return DateTime.fromISO(dateString).toLocaleString(DateTime.TIME_SIMPLE)
}

// Hashing out bank account numbers
export function hasingOut(bankAccountNumber: string): string {
  return 'xx-xxxx-xxx' + bankAccountNumber.substring(11, 19)
}
