import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { getNotesAPI } from 'api/notes';
import { useNotesLookupStore } from 'store/notes';
import { DropDownItem } from 'types/components';
import { NotesFetchParamsTypes, Note } from 'types/notes';
import { DATASTATUS } from 'constants/status';

export const useMemberNotesLookup = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const notesLookupModStatus = useNotesLookupStore(
    (state) => state.notesLookupModStatus
  )
  const notesLookupModStatusSet = useNotesLookupStore(
    (state) => state.notesLookupModStatusSet
  )
  const notesLookupModErrors = useNotesLookupStore(
    (state) => state.notesLookupModErrors
  )
  const notesLookupModErrorsSet = useNotesLookupStore(
    (state) => state.notesLookupModErrorsSet
  )
  const notesLookup = useNotesLookupStore((state) => state.notesLookup)
  const notesLookupSet = useNotesLookupStore((state) => state.notesLookupSet)

  const [notesFetchParams, notesFetchParamsSet] =
    useState<NotesFetchParamsTypes>({
      traderId: memberTraderId,
    })

  const [allNotes, setAllNotes] = useState<Note[]>([]);

  const {
    data: dataNotesLookup,
    isLoading: isLoadingNotesLookup,
    isError: isErrorNotesLookup,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useNotesLookup', notesFetchParams],
    queryFn: async () => {
      try {
        notesLookupModStatusSet('loading')
        const response = await getNotesAPI(notesFetchParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        notesLookupModStatusSet('success')
        return response.notes
      } catch (e) {
        notesLookupModStatusSet('fail')
        notesLookupModErrorsSet(JSON.stringify(e))
        return []
      }
    },
    onSuccess: (data) => {
      setAllNotes(data as Note[])
    }
  })

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation({
    mutationFn: getNotesAPI,
    mutationKey: 'useNotesLookup',
    onMutate: () => {
      notesLookupModStatusSet('loading')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useNotesLookup', notesFetchParams],
      })
      notesLookupModStatusSet('success')
    },
    onError: (error) => {
      notesLookupSet([])
      notesLookupModStatusSet('fail')
    },
  })

  const notesSourceNameOptionsLoader = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      if (inputValue.length < 3) return []
      
      // Filter out duplicates
      const uniqueNotes = Array.from(
        new Set(
          allNotes
            .filter(note =>
              note.sourceName?.toLowerCase().includes(inputValue.toLowerCase())
            )
            .map(note => note.sourceName)
        )
      );
      
      return uniqueNotes.map(sourceName => ({
        label: String(sourceName),
        value: String(sourceName),
      }));
    },
    [allNotes]
  )

  const handleNotesSourceNameLookup = useCallback(
    async (inputValue: string) => {
      if (inputValue.length > 2) {
        notesFetchParamsSet((prevParams) => ({
          ...prevParams,
          sourceName: inputValue,
        }));
      } else if (inputValue.length === 0) {
        notesFetchParamsSet((prevParams) => ({
          ...prevParams,
          sourceName: '',
        }));
      }
    },
    [notesFetchParamsSet]
  )

  return {
    memberHandleNotesSourceNameLookup: handleNotesSourceNameLookup,
    memberNotesSourceNameOptionsLoader: notesSourceNameOptionsLoader,
    memberNotesLookup: notesLookup,
    memberNotesLookupModStatus: notesLookupModStatus,
    memberNotesLookupModErrors: notesLookupModErrors,
  }
}
