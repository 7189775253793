import packageJSON from '../../package.json'

const Index = (): JSX.Element => {
  return (
    <p className="font-light tracking-wider text-xs text-center">
      <small>v {packageJSON.version}</small>
    </p>
  )
}

export default Index
