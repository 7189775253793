import ModalDevice from 'views/MerchantProfile/Main/Buttons/ModalDevice'
import ModalIdentification from 'views/MerchantProfile/Main/Buttons/ModalIdentification'
import { accessControlSubNavigation } from 'setups/controller'

const projectClient = process.env.REACT_APP_PROJ

export const MerchantModalModulesData = [
  {
    module: 'Identification Create',
    label: 'Identification',
    value: '1',
    route: {
      title: 'identifications create',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModalIdentification,
  },
  {
    module: 'Device Create',
    label: 'Devices',
    value: '2',
    route: {
      title: 'devices create',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModalDevice,
  },
]

export const MERCHANTMODALNAVIGATION = accessControlSubNavigation(
  projectClient,
  MerchantModalModulesData,
  'merchant'
)
