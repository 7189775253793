import { useCallback, useState } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Modal from 'components/Modal'
import IconDropdown from 'components/IconDropdown'
import BankAccountAddModalForm from './BankAccountAddModalForm'
import { FinancialsModalActionsTypes } from 'types/financials'

const Buttons = (): JSX.Element => {
  const [modalToggle, modalToggleSet] = useState<{
    toggle: boolean
    value: FinancialsModalActionsTypes | null
  }>({ toggle: false, value: null })
  const OPTIONS: { label: string; value: FinancialsModalActionsTypes }[] = [
    { label: 'Add Bank Account', value: 'financials_bankaccount_add' },
  ]

  const handleOnCloseModal = useCallback(() => {
    modalToggleSet({ toggle: false, value: null })
  }, [modalToggleSet])

  return (
    <>
      {/* Dropdown  */}
      <div>
        <IconDropdown
          title={
            <span className="tracking-wider font-light ml-1">
              Create Entries
            </span>
          }
          icon={faPlus}
          iconClassName="text-primary"
          iconPosition="left"
          options={OPTIONS}
          toggleClassName="py-2 px-4 rounded-md text-primary border border-primary mx-1"
          itemClassName="py-1"
          menuClassName="border-gray-500 shadow-md px-2 py-3"
          displayArrowDown
          onSelect={(value) => {
            modalToggleSet({
              toggle: true,
              value: value as FinancialsModalActionsTypes,
            })
          }}
        />
      </div>
      {/* Modals */}
      <Modal
        open={modalToggle.toggle}
        title={
          OPTIONS.filter((item) => item.value === modalToggle.value)[0]?.label
        }
        onClose={handleOnCloseModal}
        noFooter
      >
        {modalToggle.value === 'financials_bankaccount_add' ? (
          <BankAccountAddModalForm onCloseModal={handleOnCloseModal} />
        ) : null}
      </Modal>
    </>
  )
}

export default Buttons
