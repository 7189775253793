import { Notifications } from 'types/notifications'
// import { http } from 'utils'

/**
 * Get HDA notifications.
 *
 * @param instance Region
 */
export function getHDANotifications(instance: string): Notifications[] {
  // let url = `/notes/${traderId}`
  // const params = new URLSearchParams()

  // if (sourceName) {
  //   params.set('sourceName', sourceName)
  // }

  // if (noteType) {
  //   params.set('noteType', noteType)
  // }

  // if (startDate) {
  //   params.set('startDate', startDate)
  // }

  // if (endDate) {
  //   params.set('endDate', endDate)
  // }

  // if (priorityLevel) {
  //   params.set('priorityLevel', priorityLevel)
  // }

  // if (pNumber) {
  //   params.set('pNumber', pNumber.toString())
  // }

  // if (pSize) {
  //   params.set('pSize', pSize.toString())
  // }

  // url += `?${params.toString()}`

  // const response = await http.get<{ notes: Note[] }>(url)

  // const items = response.data.notes.map((record: Note) => ({
  //   noteType: record.noteType,
  //   priorityLevel: record.priorityLevel,
  //   sourceName: record.sourceName,
  //   lastModifiedDate: record.lastModifiedDate.replace(/\s/g, 'T'),
  //   noteDescription: record.noteDescription,
  //   noteId: record.noteId,
  // }))

  const updates = [
    {
      title: 'Merchant Module Added',
      description: 'Can create, update merchant details and status.',
      date: '2023-11-05',
      project: 'subway',
    },
    {
      title: 'Merchant Transactions, Devices and Identifier',
      description:
        'Can add merchant devices and identifiers. Enable to view merchant transactions.',
      date: '2023-11-05',
      project: 'subway',
    },
  ]

  return updates
}
