import { NavLink, useLocation } from 'react-router-dom'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

import { SIDEBARNAVIGATION } from 'constants/sidebar'

const SideNav = (): React.ReactElement => {
  const location = useLocation()

  return (
    <>
      <nav className="min-h-full bg-gray-700 lg:w-20 lg:fixed">
        <ul className="break-words lg:mt-14">
          {SIDEBARNAVIGATION.map((item, index) => {
            return (
              <li
                key={index}
                className={classnames(
                  location.pathname === item.route.path.path
                    ? 'menu_item_active'
                    : ''
                )}
              >
                <NavLink
                  to={item.route.path.path}
                  className="flex items-center justify-center py-4 text-white lg:flex-col hover:bg-white hover:bg-opacity-20"
                  // onClick={() => setSidebar(false)}
                >
                  {item.route.icon ? (
                    <Icon
                      icon={item.route.icon}
                      className="mr-4 text-xl cursor-pointer lg:mr-0 lg:mb-2"
                      color="white"
                    />
                  ) : null}
                  <span className="text-center tracking-wider font-light text-xs">
                    {item.route.title}
                  </span>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </nav>
    </>
  )
}

export default SideNav
