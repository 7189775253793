import { useMemberProfile } from './useMemberProfile'

const Index = (): JSX.Element => {
  const { memberMemberProfiles, memberMemberProfilesModStatus } =
    useMemberProfile()

  return (
    <>
      {memberMemberProfiles.map((item) => (
        <div key={item.traderId} className="flex flex-col gap-2">
          <p className="flex flex-col gap-2 tracking-wider">
            <span className="text-xl uppercase font-bold">
              {`${item.firstName} ${item.lastName}`}
            </span>
            <span className="text-md uppercase font-bold">{item.traderId}</span>
          </p>
        </div>
      ))}
    </>
  )
}

export default Index
