import DashboardLoader from 'components/DashboardLoader'
import Counter from '../Counter'
import CountersContainer from '../CountersContainer'
import DashboardCard from '../DashboardCard'
import SeeAll from '../SeeAll'

import VouchersItem from './VouchersItem'

import { useMemberVouchers } from './useMemberVouchers'

export const Vouchers = (): JSX.Element => {
  const { memberVouchers, memberVouchersSummary, memberVouchersModStatus } =
    useMemberVouchers()

  const isLoading = memberVouchersModStatus === 'loading' || false
  const vouchersRecent = memberVouchers.slice(0, 3)
  const isVouchers = vouchersRecent.length ? true : false

  console.log({ memberVouchers, memberVouchersSummary })

  return (
    <DashboardCard title="Vouchers">
      <CountersContainer>
        <Counter
          isValidating={isLoading}
          label="Sent"
          value={memberVouchersSummary.active.toString()}
        />

        <Counter
          isValidating={isLoading}
          label="Opened"
          value={memberVouchersSummary.notActive.toString()}
        />

        <Counter
          isValidating={isLoading}
          label="Clicked"
          value={memberVouchersSummary.expired.toString()}
        />

        <Counter
          isValidating={isLoading}
          label="Redeemed"
          value={memberVouchersSummary.redeemed.toString()}
        />
      </CountersContainer>

      <div className="flex flex-col flex-grow gap-1">
        <p className="tracking-wider font-light mb-4">Recent Vouchers</p>

        {isLoading ? (
          <DashboardLoader />
        ) : !isVouchers ? (
          <p className="py-2">
            <span className="tracking-wider font-light">
              No vouchers available
            </span>
          </p>
        ) : (
          <>
            {vouchersRecent.map((item) => (
              <div
                key={item.voucherCode}
                className="flex justify-between p-4 hover:bg-primary hover:bg-opacity-10 rounded-lg"
              >
                <VouchersItem voucher={item} />
              </div>
            ))}
          </>
        )}
      </div>
      {isVouchers ? <SeeAll url={'vouchers'} label="Vouchers" /> : null}
    </DashboardCard>
  )
}
