import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getBankAccounts_API } from 'api/financials'
import { useBankAccountsStore } from 'store/financials'
import { BankAccountsFetchDataTypes } from 'types/financials'

import { DATASTATUS } from 'constants/status'

export const useMemberBankAccounts = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const bankAccountsModStatus = useBankAccountsStore(
    (state) => state.bankaccountsModStatus
  )
  const bankAccountsModStatusSet = useBankAccountsStore(
    (state) => state.bankaccountsModStatusSet
  )
  const bankAccountsModErrors = useBankAccountsStore(
    (state) => state.bankaccountsModErrors
  )
  const bankAccountsModErrorsSet = useBankAccountsStore(
    (state) => state.bankaccountsModErrorsSet
  )
  const bankAccounts = useBankAccountsStore((state) => state.bankaccounts)
  const bankAccountsSet = useBankAccountsStore((state) => state.bankaccountsSet)
  const [bankAccountsFilterParams, bankAccountsFilterParamsSet] =
    useState<BankAccountsFetchDataTypes>(defaultParams)
  const [isbankAccountsFiltered, isbankAccountsFilteredSet] =
    useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataBankAccounts,
    isLoading: isLoadingBankAccounts,
    isError: isErrorBankAccounts,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useBankAccounts', bankAccountsFilterParams],
    queryFn: async () => {
      try {
        bankAccountsModStatusSet('loading')
        const response = await getBankAccounts_API(bankAccountsFilterParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        bankAccountsModStatusSet('success')
        return response.bankAccounts
      } catch (e) {
        bankAccountsModStatusSet('fail')
        bankAccountsModErrorsSet(JSON.stringify(e))
      }
    },
  })

  const handleFilter = useCallback(
    async (fetchParams: BankAccountsFetchDataTypes) => {
      bankAccountsFilterParamsSet(fetchParams)
      isbankAccountsFilteredSet(true)
    },
    [bankAccountsFilterParamsSet, isbankAccountsFilteredSet]
  )

  const handleUnfilter = useCallback(() => {
    const test = { traderId: memberTraderId }
    bankAccountsFilterParamsSet(test)
    isbankAccountsFilteredSet(false)
  }, [bankAccountsFilterParamsSet, isbankAccountsFilteredSet])

  useEffect(() => {
    if (dataBankAccounts) bankAccountsSet(dataBankAccounts)
  }, [dataBankAccounts, bankAccountsSet])

  return {
    memberHandleBankAccountsFilter: handleFilter,
    memberHandleBankAccountsUnfilter: handleUnfilter,
    bankAccountsFilterParams: bankAccountsFilterParams,
    memberBankAccounts: bankAccounts,
    memberBankAccountsModStatus: bankAccountsModStatus,
  }
}
