import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getCommsStatusesAPI } from 'api/comms'
import { useCommsStatusesStore } from 'store/comms'
import { DropDownItem } from 'types/components'

import { DATASTATUS } from 'constants/status'

export const useMemberCommsStatuses = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const commsStatusesModStatus = useCommsStatusesStore(
    (state) => state.commsStatusesModStatus
  )
  const commsStatusesModStatusSet = useCommsStatusesStore(
    (state) => state.commsStatusesModStatusSet
  )
  const commsStatusesModErrors = useCommsStatusesStore(
    (state) => state.commsStatusesModErrors
  )
  const commsStatusesModErrorsSet = useCommsStatusesStore(
    (state) => state.commsStatusesModErrorsSet
  )
  const commsStatuses = useCommsStatusesStore((state) => state.commsStatuses)
  const commsStatusesSet = useCommsStatusesStore(
    (state) => state.commsStatusesSet
  )

  const {
    data: dataComms,
    isLoading: isLoadingComms,
    isError: isErrorComms,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useCommsStatuses'],
    queryFn: async () => {
      try {
        commsStatusesModStatusSet('loading')
        const response = await getCommsStatusesAPI()
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        commsStatusesModStatusSet('success')
        return response.commsStatus
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)), ' ERR')
        commsStatusesModStatusSet('fail')
        commsStatusesModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataComms) commsStatusesSet(dataComms)
  }, [dataComms, commsStatusesSet])

  const commsStatusesOptions: DropDownItem[] = useMemo(() => {
    return commsStatuses.map((item) => ({
      label: String(item.statusName),
      value: String(item.statusId),
    }))
  }, [commsStatuses])

  return {
    memberCommsStatusesOptions: commsStatusesOptions,
    memberCommsStatuses: commsStatuses,
    memberCommsStatusesModStatus: commsStatusesModStatus,
  }
}
