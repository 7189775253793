import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { DateTime } from 'luxon'
import DatePicker from 'react-datepicker'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

type DatePickerProps = React.InputHTMLAttributes<HTMLInputElement> & {
  value?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const DatepickerFormatter = ({
  datetimeString,
  formatFor,
  showTimeSelect,
}: {
  datetimeString: Date
  formatFor: 'Display' | 'Data'
  showTimeSelect: boolean
}): string => {
  let formattedString = ''
  if (formatFor === 'Display') {
    if (datetimeString) {
      const dateTime = DateTime.fromISO(datetimeString.toISOString())
      formattedString = dateTime.toLocaleString(
        showTimeSelect ? DateTime.DATETIME_MED : DateTime.DATE_MED
      )
    } else {
      const defaultTime = new Date()
      const dateTime = DateTime.fromISO(defaultTime.toISOString())
      formattedString = dateTime.toLocaleString(
        showTimeSelect ? DateTime.DATETIME_MED : DateTime.DATE_MED
      )
    }
    return formattedString
  } else {
    //  format for Data
    const dateTime = DateTime.fromISO(datetimeString.toISOString())
    const formattedString = dateTime.toFormat('yyyy-MM-dd HH:mm:ss')
    return formattedString
  }
}

const DatetimePickerInput = ({
  title,
  dateFormat,
  setFieldName,
  setFieldValue,
  showTimeSelect = true,
  defaultDateValue,
}: {
  title: string
  dateFormat?: string
  setFieldName?: string
  setFieldValue?: (field: string, value: string | number | null) => void
  showTimeSelect?: boolean
  defaultDateValue?: string
}) => {
  const [startDate, setStartDate] = useState<Date>(
    !defaultDateValue ? new Date() : new Date(defaultDateValue)
  )
  const [endDate, setEndDate] = useState<Date>(new Date())

  useEffect(() => {
    if (setFieldValue && setFieldName) {
      const defaultTime = !defaultDateValue
        ? new Date()
        : new Date(defaultDateValue)
      // setFieldValue(setFieldName, defaultTime.toISOString().split('.')[0] + 'Z')
      setFieldValue(
        setFieldName,
        DatepickerFormatter({
          datetimeString: defaultTime,
          formatFor: 'Data',
          showTimeSelect,
        })
      )
    }
  }, [])

  const onChange = useCallback(
    (dates: Date | null): void => {
      let start, end
      if (dates === null) {
        start = new Date()
        end = new Date()
      } else {
        start = dates
        end = dates
      }
      setStartDate(start)
      setEndDate(end)

      // IF FORM SET FIELD FUNCTION IS AVAILABLE
      if (setFieldValue && setFieldName && start)
        // setFieldValue(setFieldName, start.toISOString().split('.')[0] + 'Z')
        setFieldValue(
          setFieldName,
          DatepickerFormatter({
            datetimeString: start,
            formatFor: 'Data',
            showTimeSelect,
          })
        )
    },
    [setStartDate, setEndDate]
  )

  const DatepickerInput = ({
    value,
    onClick,
  }: PropsWithChildren<DatePickerProps>): JSX.Element => {
    // const x = new Date(startDate)
    // const xx = DateTime.fromISO(startDate.toISOString())
    // const yy = xx.toLocaleString(DateTime.DATETIME_MED)
    // const zz = xx.toISO()
    // console.log(xx, yy, zz, ' XX...')
    // // "the input "Tue Aug 22 2023 10:03:33 GMT+1200 (New Zealand Standard Time)" can't be parsed as format YYYY-mm-dd"
    // // "the input "2023-08-21T22:11:50.998Z" can't be parsed as format YYYY-mm-dd"
    // // "the input "Tue Aug 22 2023" can't be parsed as format DATETIME_SHORT"
    // // "the input \"2023-08-21T22:14:10.600Z\" can't be parsed as format DATETIME_SHORT"
    // // "the input "2023-08-21T22:14:10.600Z" can't be parsed as format YYYY-MM-DDTHH:MM:SSZ"
    // // "the input "2023-08-21T22:14:10.600Z" can't be parsed as format YYYY-MM-DDThh:mm:ssZ"
    return (
      <button
        className="p-2 px-3 text-left border border-gray-400 tracking-wider w-full flex justify-between items-center"
        style={{ minWidth: '120px' }}
        onClick={onClick}
        type="button"
      >
        {DatepickerFormatter({
          datetimeString: startDate,
          formatFor: 'Display',
          showTimeSelect,
        })}
        {/* {DateTime.fromISO(startDate.toISOString())}
        {String(startDate.toISOString())} */}
        {!value && <Icon icon={faChevronDown} className="ml-10" />}
      </button>
    )
  }

  return (
    <div className="mb-4 w-full">
      <label>
        <span className="tracking-wider font-light">{title}</span>
      </label>
      <DatePicker
        showTimeSelect={showTimeSelect ? true : false}
        dateFormat={dateFormat}
        selected={startDate}
        onChange={onChange}
        customInput={<DatepickerInput />}
        showYearDropdown
        timeIntervals={1}
        popperProps={{ strategy: "fixed" }}
      />
    </div>
  )
}

export default DatetimePickerInput
