import React from 'react'
import { useParams } from 'react-router-dom'
import Colors from 'colors.json'
import { Form, Formik } from 'formik'

import DatetimePickerInput from 'components/DatetimePickerInput'
import Input from 'components/Input'
import Button from 'components/Button'
import { CreateMerchantDeviceSchemas } from 'schemas/createMerchantDeviceSchemas'
import { useCreateDevice } from './useCreateDevice'

const Index: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}): React.ReactElement => {
  // STATES
  const { isLoading, initialValues, handleSubmitCreateMerchantDevice } =
    useCreateDevice(onCloseModal)

  if (isLoading) return <></>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateMerchantDeviceSchemas}
      onSubmit={handleSubmitCreateMerchantDevice}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form className="flex flex-col">
            <div className="mb-4 ">
              <div className="flex gap-4 items-center">
                <Input
                  name="deviceCode"
                  label="Device Code"
                  containerClassName="w-full"
                  type="number"
                />
              </div>
              <div className="flex gap-4 justify-between">
                <DatetimePickerInput
                  title={'Start Date'}
                  setFieldName="startDate"
                  setFieldValue={setFieldValue}
                  showTimeSelect={false}
                />
                <DatetimePickerInput
                  title={'End Date'}
                  setFieldName="endDate"
                  setFieldValue={setFieldValue}
                  showTimeSelect={false}
                />
                {/* <Input
                  name="startDate"
                  label="Start Date (yyyy-mm-dd)"
                  containerClassName="w-full"
                /> */}
                {/* <Input
                  name="endDate"
                  label="End Date (yyyy-mm-dd)"
                  containerClassName="w-full"
                /> */}
              </div>
            </div>

            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Submit</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onCloseModal}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Index
