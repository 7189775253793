import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import TSTable from 'components/Tanstack'
import Modal from 'components/Modal'
import { ToggleMenu } from 'components/Layout/ToggleMenu'

import InnerWrapper from '../InnerWrapper'
import CommsFilterForm from './CommsFilterForm'
import CommsEmailPreview from './CommsEmailPreview'
import { EmailPreview } from './comms-email-preview'
import { useMemberCommsTable } from './useMemberCommsTable'
import { useMemberComms } from './useMemberComms'

function Comms(): JSX.Element {
  const {
    memberComms,
    memberCommsModStatus,
    memberCommsFilterParams,
    memberHandleCommsFilter,
    memberHandleCommsUnfilter,
  } = useMemberComms()

  const {
    memberCommsSelected,
    memberCommsActionModal,
    memberCommsTableData,
    memberCommsTableColumns,
    handleMemberActionModalClose,
  } = useMemberCommsTable()

  return (
    <>
      <InnerWrapper title="Communications">
        {/* FILTER */}
        {/* <CommsFilterForm
          formStatus={memberCommsModStatus}
          formDefaultValues={memberCommsFilterParams}
          onFilter={memberHandleCommsFilter}
          onFilterClear={memberHandleCommsUnfilter}
        /> */}

        {/* ADVANCE SEARCH  */}
        <div className="pb-6">
          <ToggleMenu
            type="search"
            label="Search Communications"
            isTriggeToggle={memberCommsModStatus === 'success' || false}
          >
            <CommsFilterForm
              formStatus={memberCommsModStatus}
              formDefaultValues={memberCommsFilterParams}
              onFilter={memberHandleCommsFilter}
              onFilterClear={memberHandleCommsUnfilter}
            />
          </ToggleMenu>
        </div>

        {/* FAIL */}
        {memberCommsModStatus === 'fail' ? (
          <NoResults
            header="Failed to load data"
            subtitle="Please refresh the page and try again, or contact
        your system administrator"
            noImage
          />
        ) : null}

        {/* LOADING */}
        {memberCommsModStatus === 'loading' ? <Spinner /> : null}

        {/* SUCCESS */}
        {memberComms.length && memberCommsModStatus === 'success' ? (
          <TSTable.Table
            data={memberCommsTableData}
            columns={memberCommsTableColumns}
          />
        ) : null}

        {/* EMPTY */}
        {!memberComms.length && memberCommsModStatus === 'success' ? (
          <NoResults
            header="No available data"
            subtitle="There is no available data to display"
            noImage
          />
        ) : null}
      </InnerWrapper>

      {/* PUSH/SMS PREVIEW */}
      {/* TO DO UAT API HAS ISSUE */}
      {/* {memberCommsActionModal === 'sms_push_preview' && memberCommsSelected ? (
        <Modal
          open={true}
          title="SMS/Push Preview"
          onClose={handleMemberActionModalClose}
          noFooter
        >
          <CommsEmailPreview
            previewType={memberCommsActionModal}
            selectedComms={memberCommsSelected}
          />
        </Modal>
      ) : null} */}

      {/* EMAIL PREVIEW */}
      {memberCommsActionModal === 'email_preview' && memberCommsSelected ? (
        <Modal
          open={true}
          title="Email Preview"
          onClose={handleMemberActionModalClose}
          noFooter
        >
          adasdasdasd
          <EmailPreview commsData={memberCommsSelected} />
        </Modal>
      ) : null}
    </>
  )
}

export default Comms
