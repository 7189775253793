import { HDAACCOUNTS, HDAACCOUNTSARRAY } from 'constants/accouts'

type AccountsType = keyof typeof HDAACCOUNTS

interface Props {
  account: AccountsType
  project: string
}

const Logo = ({ account, project }: Props): JSX.Element => {
  const asset = HDAACCOUNTSARRAY.filter((item) => item.project === project)
  console.log("test1", HDAACCOUNTSARRAY)
  return (
    <div className="flex justify-center items-center">
      <img src={asset[0].logo} alt={asset[0].alt} width="100%" />
    </div>
  )
}

export default Logo
