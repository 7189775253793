import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from 'react-router-dom'

import { FinancialAddBankAccountTxSchemas } from 'schemas/financialAddBankAccountTxSchemas'
import { FinancialAddBankAccountTxFormTypes } from 'types/financialAddBankAccountTxTypes'

export const useBankAccountTxForm = () => {
  const { traderId } = useParams()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<FinancialAddBankAccountTxFormTypes>({
    resolver: zodResolver(FinancialAddBankAccountTxSchemas),
    defaultValues: {
      bankAccountName: 'JAZ',
      bankAccountNumber: '',
      accountStatusId: 1,
      bankId: 100,
      traderId: traderId ? parseInt(traderId) : 0,
      userId: traderId ? parseInt(traderId) : 0,
    },
  })

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
  }
}
