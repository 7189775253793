import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'

import { getIdentificationsOwnershipsAPI } from 'api/identifications'
import { useIdentificationsOwnershipsStore } from 'store/identifications'
import { DropDownItem } from 'types/components'

export const useMemberIdentificationsOwnerships = () => {
  const identificationsOwnershipsModStatus = useIdentificationsOwnershipsStore(
    (state) => state.identificationsOwnershipsModStatus
  )
  const identificationsOwnershipsModStatusSet =
    useIdentificationsOwnershipsStore(
      (state) => state.identificationsOwnershipsModStatusSet
    )
  const identificationsOwnershipsModErrors = useIdentificationsOwnershipsStore(
    (state) => state.identificationsOwnershipsModErrors
  )
  const identificationsOwnershipsModErrorsSet =
    useIdentificationsOwnershipsStore(
      (state) => state.identificationsOwnershipsModErrorsSet
    )
  const identificationsOwnerships = useIdentificationsOwnershipsStore(
    (state) => state.identificationsOwnerships
  )
  const identificationsOwnershipsSet = useIdentificationsOwnershipsStore(
    (state) => state.identificationsOwnershipsSet
  )

  const {
    data: dataIdentificationsOwnerships,
    isLoading: isLoadingIdentificationsOwnerships,
    isError: isErrorIdentificationsOwnerships,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useIdentificationsOwnerships'],
    queryFn: async () => {
      try {
        identificationsOwnershipsModStatusSet('loading')
        const response = await getIdentificationsOwnershipsAPI()
        identificationsOwnershipsModStatusSet('success')
        return response
      } catch (e) {
        identificationsOwnershipsModStatusSet('fail')
        identificationsOwnershipsModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataIdentificationsOwnerships)
      identificationsOwnershipsSet(dataIdentificationsOwnerships)
  }, [dataIdentificationsOwnerships, identificationsOwnershipsSet])

  const identificationsOwnershipsOptions: DropDownItem[] = useMemo(() => {
    return identificationsOwnerships.map((item) => ({
      label: String(item.description),
      value: String(item.ownershipMode),
    }))
  }, [identificationsOwnerships])

  return {
    memberIdentificationsOwnershipsOptions: identificationsOwnershipsOptions,
    memberIdentificationsOwnerships: identificationsOwnerships,
    memberIdentificationsOwnershipsModStatus:
      identificationsOwnershipsModStatus,
    memberIdentificationsOwnershipsModErrors:
      identificationsOwnershipsModErrors,
  }
}
