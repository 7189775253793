import Modal from 'components/Modal'
import CreateMerchant from 'views/MerchantLookup/ManualTx/CreateMerchant'

const ModalCreateMerchant = ({
  title,
  open,
  onClose,
}: {
  title: string | undefined
  open: boolean
  onClose: () => void
}): JSX.Element => {
  return (
    <Modal open={open} title={title} onClose={onClose} noFooter>
      <CreateMerchant onClose={onClose} />
    </Modal>
  )
}

export default ModalCreateMerchant
