import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Card from 'components/Card'
import SubsquadStatus from 'components/SubsquadStatus'
import { CircularProgress } from 'components/ui'
import { SUBSQUADTABSNAVIGATION } from 'constants/profileNavSubsquad'
import { isUndefined } from 'lodash'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import useSubSquadStore from 'store/subsquad'

type Props = {
  className?: string
  contentClassName?: string
}

function SubsquadProfile({
  children,
  contentClassName,
}: PropsWithChildren<Props>): React.ReactElement {
  const { groupURN, groupId } = useParams()
  const [groupDataFetchLimitError, setGroupDataFetchError] = useState(false)

  const subsquad = useSubSquadStore((state) => state.subsquad)
  const getSubsquad = useSubSquadStore((state) => state.getSubSquad)
  const activeGroup = useSubSquadStore((state) => state.activeGroup)

  const setActiveGroup = useSubSquadStore((state) => state.setActiveGroup)

  useEffect(() => {
    try {
      getSubsquad(groupURN!)
      // setActiveGroup({ activeGroup: groupId });
    } catch (error) {
      setGroupDataFetchError(true)
    }
  }, [getSubsquad, groupURN, setActiveGroup, subsquad?.status])

  return groupDataFetchLimitError ? (
    <h2>
      Failed to load data. Please refresh the page and try again, or contact
      your system administrator.
    </h2>
  ) : (
    <div>
      {isUndefined(subsquad) ? (
        <div className="flex m-52">
          <div className="m-auto">
            <CircularProgress />
            <p className="mt-3">Fetching data. Please wait...</p>
          </div>
        </div>
      ) : (
        <>
          <Link
            to="/subsquad-lookup"
            className="m-2 mb-10 text-xs text-gray-500 uppercase"
          >
            <Icon icon={faChevronLeft} /> Back to search
          </Link>
          <Card className="flex flex-wrap items-baseline p-6 mt-2 bg-white">
            <h1 className="mr-12">{subsquad.groupName}</h1>
            <div className="flex flex-row">
              <p className="mr-6">
                <span className="font-bold">ID:&nbsp;</span>
                {groupId}
              </p>
              <p className="mr-6">
                <strong>URN:&nbsp;</strong>
                {subsquad.groupUrn}
              </p>
              <p className="mr-6">
                <strong>Active Members:&nbsp;</strong>
                {subsquad.memberCount}
              </p>
              {/* <p className="mr-6">
                <strong>Created Date:&nbsp;</strong>
                {subsquad.groupUrn}
              </p>
              <div className="flex flex-row">
                <p className="mr-1">
                  <strong>Status:&nbsp;</strong>
                </p>
                <SubsquadStatus status={subsquad.status} />
              </div> */}
            </div>
          </Card>
          <div className="mt-6 bg-white">
            <div className="flex">
              <nav className="flex flex-col sm:flex-row">
                {SUBSQUADTABSNAVIGATION.map((item) => {
                  return (
                    <>
                      {item.route.title.toLowerCase() === 'members' &&
                      (location.pathname.split('/').pop() === groupId ||
                        location.pathname.split('/').pop() === '') ? (
                        <button
                          key={'squad-' + item.route.title}
                          className={classNames(
                            'text-primary border-b-2 font-medium border-primary',
                            item.route.className
                          )}
                          onClick={() =>
                            // navigate(
                            //   `/subsquad/${groupId}/${item.pathname}`
                            // )
                            null
                          }
                        >
                          {item.route.title}
                        </button>
                      ) : (
                        <button
                          key={'squad-' + item.route.title}
                          className={classNames(
                            location.pathname.split('/').pop() ===
                              item.route.pathname
                              ? 'text-primary border-b-2 font-medium border-primary'
                              : '',
                            item.route.className
                          )}
                          onClick={() =>
                            // navigate(
                            //   `/subsquad/${groupId}/${item.pathname}`
                            // )
                            null
                          }
                        >
                          {item.route.title}
                        </button>
                      )}
                    </>
                  )
                })}
              </nav>
            </div>
          </div>
          {/* Content */}
          <div className={classNames('bg-white mt-1 p-3', contentClassName)}>
            {children}
          </div>
        </>
      )}
    </div>
  )
}

export default SubsquadProfile
