import classnames from 'classnames'
import { PropsWithChildren } from 'react'
import { useParams } from 'react-router-dom'
import Main from '../../views/MerchantProfile/Main'
import SideBar from './SideBar'

interface PropsTypes {
  className?: string
  contentClassName?: string
}

const Index = ({
  children,
  className,
}: PropsWithChildren<PropsTypes>): JSX.Element => {
  return (
    <div
      className={classnames(
        'flex flex-col md:flex-row min-h-screen divide-x divide-gray-100',
        className
      )}
    >
      <div className="bg-white divide-y divide-gray-100 w-full md:w-1/5">
        <SideBar />
      </div>

      <div className="divide-y w-full md:w-4/5">
        <Main />
        <div className="flex">{children}</div>
      </div>
    </div>
  )
}

export default Index
