import React from 'react'
import { Form, Formik } from 'formik'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Datepicker, { useDatepicker } from 'components/Datepicker'

import { formatDateToShort } from 'services/helpers'
import { useMemberHistoryStatuses } from './useMemberHistoryStatuses'
import { useMemberHistoryLookup } from './useMemberHistoryLookup'
import { useMemberHistoryFilterForm } from './useMemberHistoryFilterForm'

import { StatusTypes } from 'types/status'
import { HistoryFetchParamsTypes } from 'types/histories'
import { DropDownItem } from 'types/components'

const Index = ({
  formStatus,
  formDefaultValues,
  onFilter,
  onFilterClear,
}: {
  formStatus: StatusTypes
  formDefaultValues: HistoryFetchParamsTypes
  onFilter: (filterParams: HistoryFetchParamsTypes) => void
  onFilterClear: () => void
}): JSX.Element => {
  const { control, handleSubmit, setValue } = useMemberHistoryFilterForm({
    defaultValues: formDefaultValues,
  })

  const { memberHistoryStatusesModStatus } =
    useMemberHistoryStatuses()

  const {
    memberHistoryNameOptionsLoader,
    memberHistoryIdentsModStatus,
  } = useMemberHistoryLookup()

  const { startDate, endDate, DatepickerInput, handlePick } = useDatepicker()

  const isFiltering = formStatus !== 'success' || false
  const isLoading = memberHistoryStatusesModStatus !== 'success' ? true : false

  if (isLoading)
    return (
      <span className="tracking-wider font-light">
        Preparing search options ...
      </span>
    )

  return (
    <FormsLayout.Wrapper onSubmit={handleSubmit(onFilter)}>
      <FormsLayout.Item>
        <Datepicker
          title="Date"
          name="dateRange"
          onChange={(dates: [Date, Date]) => {
            handlePick(dates)
            setValue(
              'startDate',
              dates[0] ? formatDateToShort(dates[0]) : undefined
            )
            setValue(
              'endDate',
              dates[1] ? formatDateToShort(dates[1]) : undefined
            )
          }}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          showYearDropdown
          scrollableYearDropdown
          className="p-2 border border-gray-400 tracking-wider"
          placeholderText="All"
          customInput={<DatepickerInput />}
          selectsRange
        />
      </FormsLayout.Item>
      {/* <FormsLayout.Item>
        <Controller
          name="editedBy"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Edited By"
              loadOptions={memberHistoryNameOptionsLoader}
              onChange={(value: DropDownItem) => {
                if (value) setValue('editedBy', value ? value.value : undefined)
              }}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              containerClassName="focus:border-primary"
              placeholder="Edited By..."
              isClearable
              isSearchable
              async
            />
          )}
        />
      </FormsLayout.Item> */}
      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          icon={faSearch}
          iconRight={false}
          type="submit"
          disabled={isFiltering}
          loading={isFiltering}
          className="rounded bg-primary tracking-wider font-bold"
        >
          Search
        </Button>
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
