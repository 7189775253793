import React from 'react'
import { useParams } from 'react-router-dom'
import Colors from 'colors.json'
import { Form, Formik } from 'formik'

import Input from 'components/Input'
import Button from 'components/Button'
import { CreateMerchantIdentificationSchemas } from 'schemas/createMerchantIdentificationSchemas'
import { useCreateIdentification } from './useCreateIdentification'

const Index: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}): React.ReactElement => {
  // STATES
  const { isLoading, initialValues, handleSubmitCreateMerchantIdentification } =
    useCreateIdentification(onCloseModal)

  if (isLoading) return <></>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateMerchantIdentificationSchemas}
      onSubmit={handleSubmitCreateMerchantIdentification}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form className="flex flex-col">
            <div className="mb-4">
              <div className="flex  gap-4 items-center">
                <Input
                  name="traderTransIdent"
                  label="Identification"
                  containerClassName="w-full"
                />
              </div>
              <div className="flex  gap-4 items-center">
                <Input
                  type="text"
                  name="password"
                  label="Password"
                  containerClassName="w-full"
                />
              </div>
            </div>

            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Submit</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onCloseModal}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Index
