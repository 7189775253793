import { Route, Routes } from 'react-router'

import PrivateRoute from 'components/PrivateRoute'
import MemberLookup from 'views/MemberLookup'
import MemberProfile from 'views/MemberProfile'
import SubsquadLookup from 'views/SubsquadLookup'
import SubsquadMembers from 'views/SubsquadProfile/Members'
import MerchantLookup from 'views/MerchantLookup'
// import MerchantLookupSubway from 'views/MerchantLookupSubway'
import MerchantProfile from 'views/MerchantProfile'
import NotFound from 'views/NotFound'
// import Subsquad from 'views/Subsquad'
// import Members from 'views/SubsquadProfile/Members'
// import SubsquadProfileQTF from 'views/SubsquadProfile/Qtf'

import { ROUTES } from 'constants/routing'

const PrivateRoutes = (): JSX.Element => {
  return (
    <Routes>
      <PrivateRoute
        path={ROUTES.MemberLookup.path}
        element={<MemberLookup />}
        permissions={ROUTES.MemberLookup.permissionIds}
      />

      <PrivateRoute
        path={ROUTES.MemberProfile.path}
        element={<MemberProfile />}
        permissions={ROUTES.MemberProfile.permissionIds}
      />

      <PrivateRoute
        path={ROUTES.SubsquadLookup.path}
        element={<SubsquadLookup />}
        permissions={ROUTES.SubsquadLookup.permissionIds}
      />
      <PrivateRoute
        path={ROUTES.SubsquadMembers.path}
        element={<SubsquadMembers />}
        permissions={ROUTES.SubsquadMembers.permissionIds}
      />

      <PrivateRoute
        path={ROUTES.MerchantLookup.path}
        element={<MerchantLookup />}
        permissions={ROUTES.MerchantLookup.permissionIds}
      />

      {/* <PrivateRoute
        path={ROUTES.MerchantLookupSubway.path}
        element={<MerchantLookupSubway />}
        permissions={ROUTES.MerchantLookupSubway.permissionIds}
      /> */}

      <PrivateRoute
        path={ROUTES.MerchantProfile.path}
        element={<MerchantProfile />}
        permissions={ROUTES.MerchantProfile.permissionIds}
      />

      {/* <PrivateRoute
        exact
        path={ROUTES.Subsquad.path}
        component={Subsquad}
        user={user}
        permissions={ROUTES.Subsquad.permissionIds}
      />
      <PrivateRoute
        exact
        path={ROUTES.SubsquadProfile.path}
        component={Members}
        user={user}
        permissions={ROUTES.SubsquadProfile.permissionIds}
      />
      <PrivateRoute
        exact
        path={ROUTES.SubsquadProfileQTFs.path}
        component={SubsquadProfileQTF}
        user={user}
        permissions={ROUTES.SubsquadProfileQTFs.permissionIds}
      /> */}

      {/* <Route
        path={ROUTES.Login.path || ''}
        render={() => <Redirect to={ROUTES.MemberLookup.path} />}
      /> */}

      <Route path="/" element={<MemberLookup />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default PrivateRoutes
