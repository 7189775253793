import React from 'react'
const projectENV = process.env.REACT_APP_ENV

const DebugInfo = (): React.ReactElement => {
  if (!['development'].includes(projectENV || '')) return <></>
  return (
    <div className="absolute left-2 top-2 p-2 bg-white text-gray-400 rounded-lg  capitalize">
      <p className="flex flex-col">
        <span className="text-xs">{projectENV}</span>
      </p>
    </div>
  )
}

export default DebugInfo
