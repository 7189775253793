import { NotesDataTypes } from 'types/notes'

const Index = ({ note }: { note: NotesDataTypes }): JSX.Element => {
  return (
    <>
      <div className="flex items-center">
        <span className="tracking-wider font-bold">{note.noteDescription}</span>
      </div>
      <p className="text-gray-500 uppercase">{note.priorityLevel}</p>
    </>
  )
}

export default Index
