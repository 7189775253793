import * as Yup from 'yup'
import { z as zod } from 'zod'

export const FinancialUpdatePaymentInstructionTxSchemas = zod.object({
  traderId: zod.number(),
  paymentId: zod.number(),
  instructionName: zod
    .string()
    .min(1, { message: 'Instruction Name is Required.' }),
  bankAccountNumber: zod
    .string()
    .min(1, { message: 'Account Number is Required.' }),
  paymentAmount: zod
    .number()
    .min(1, { message: 'Payment Amount is Required.' }),
  frequency: zod.number().min(1, { message: 'Payment Frequency is Required.' }),
  firstPaymentDate: zod
    .string()
    .min(1, { message: 'First Payment is Required.' }),
  endPaymentDate: zod.string().min(1, { message: 'End Payment is Required.' }),
  reasonId: zod.number(),
  comment: zod.string().optional(),
})
