export const MODULES = [
  {
    name: 'member',
    subs: [
      { name: '*' },
      { name: 'lookup' },
      { name: 'dashboard' },
      { name: 'transaction' },
      { name: 'communication' },
      { name: 'communication main nz' },
      { name: 'voucher' },
      { name: 'offer' },
      { name: 'notes' },
      { name: 'membership' },
      { name: 'membership subway' },
      { name: 'identification' },
      { name: 'identification hampsta' },
      { name: 'identification generic' },
      { name: 'history' },
      { name: 'notes create' },
      { name: 'purchase' },
      { name: 'purchase reversal' },
      { name: 'loyalty redemption' },
      { name: 'loyalty redemption reversal' },
      { name: 'loyalty allocation' },
      { name: 'loyalty allocation reversal' },
      { name: 'icash topup' },
      { name: 'icash topup reversal' },
      { name: 'icash redemption' },
      { name: 'icash redemption reversal' },
      { name: 'send email for password reset' },
      { name: 'save to pdf' },
      { name: 'update status' },
      { name: 'update member details' },
      { name: 'update member details generic' },
      { name: 'bank accounts' },
      { name: 'direct payments' },
    ],
    access: {
      status: [
        { status: 'registered', subs: [{ name: '*' }] },
        { status: 'cancelled', subs: [{ name: '*' }] },
        { status: 'suspended', subs: [{ name: '*' }] },
        { status: 'unregistered', subs: [{ name: '*' }] },
      ],
    },
  },
  {
    name: 'subsquad',
    subs: [{ name: '*' }, { name: 'lookup' }, { name: 'member' }],
    access: {
      status: [],
    },
  },
  {
    name: 'merchant',
    subs: [
      { name: '*' },
      { name: 'lookup' },
      { name: 'transaction' },
      { name: 'identification' },
      { name: 'device' },
      { name: 'identification create' },
      { name: 'device create' },
      { name: 'update merchant profile' },
      { name: 'update merchant status' },
    ],
    access: {
      status: [
        { status: 'current', subs: [{ name: '*' }] },
        { status: 'terminated', subs: [] },
        { status: 'closed', subs: [{ name: 'update merchant status' }] },
      ],
    },
  },
]
