import Alert from 'components/Alert'
import Button from 'components/Button'
import Input from 'components/Input'
import LoginShell from 'components/LoginShell'
import { ROUTES } from 'constants/routing'
import { Form, Formik, FormikProps } from 'formik'
import { NavLink } from 'react-router-dom'
import { useAuth } from 'services/amplify'
import * as yup from 'yup'

type FormValues = {
  username: string
  password: string
}

const initialValues: FormValues = {
  username: process.env.REACT_APP_TEST_USER || '',
  password: process.env.REACT_APP_TEST_PASS || '',
}

const Login = (): JSX.Element => {
  const auth = useAuth()

  return (
    <LoginShell>
      <h1 className="mb-6 text-lg font-bold text-center text-primary tracking-wider">
        HELP DESK ASSISTANT
      </h1>

      <Formik
        initialValues={initialValues}
        validationSchema={yup.object({
          username: yup.string().label('Username').required().trim(),
          password: yup.string().label('Password').required().trim(),
        })}
        onSubmit={({ username, password }, { setSubmitting, setStatus }) => {
          auth
            .signIn(username, password)
            .catch((error) => {
              setStatus(error.message)
            })
            .finally(() => {
              setSubmitting(false)
            })
        }}
      >
        {({ status }: FormikProps<FormValues>) => (
          <Form className="w-2/3 mb-4">
            {status && <Alert>{status}</Alert>}
            {/* {hasSession ? <Alert variant="info">Logging in..</Alert> : null} */}

            <Input
              name="username"
              type="text"
              label="Username"
              labelClassName="text-gray-500"
            />

            <Input
              name="password"
              type="password"
              label="Password"
              labelClassName="text-gray-500"
            />

            <Button type="submit" className="mx-auto rounded bg-primary w-full">
              <span className="tracking-wider font-bold">Login</span>
            </Button>
          </Form>
        )}
      </Formik>

      <NavLink to={ROUTES.ForgotPassword.path}>
        <p className="tracking-wider">Forgot your password?</p>
      </NavLink>
    </LoginShell>
  )
}

export default Login
