import { formatter } from 'utils'

import { useMemberProfileSubsTypes } from './useMemberProfileSubsTypes'

const Index = (): JSX.Element => {
  const { memberMemberProfiles } = useMemberProfileSubsTypes()

  return (
    <>
      {memberMemberProfiles.map((item) => (
        <div key={item.traderId} className="flex flex-col gap-2">
          <p className="text-xs uppercase font-light tracking-wider">
            SUBSCRIPTIONS
          </p>
          <div className="flex flex-wrap gap-2 items-center">
            <span
              className={`px-3 py-1 rounded-full tracking-wider ${
                !item.flInfo
                  ? 'bg-gray-200 text-gray-400'
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              Information
            </span>
            <span
              className={`px-3 py-1 rounded-full tracking-wider ${
                !item.flMarketing
                  ? 'bg-gray-200 text-gray-400'
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              Marketing
            </span>
            <span
              className={`px-3 py-1 rounded-full tracking-wider ${
                !item.flNewsletter
                  ? 'bg-gray-200 text-gray-400'
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              Newsletter
            </span>
            <span
              className={`px-3 py-1 rounded-full tracking-wider ${
                !item.flInterest
                  ? 'bg-gray-200 text-gray-400'
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              Interests
            </span>
            <span
              className={`px-3 py-1 rounded-full tracking-wider ${
                !item.flThirdparty
                  ? 'bg-gray-200 text-gray-400'
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              Third Party
            </span>
            <span
              className={`px-3 py-1 rounded-full tracking-wider ${
                !item.flCompetition
                  ? 'bg-gray-200 text-gray-400'
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              Competitions
            </span>
          </div>
        </div>
      ))}
    </>
  )
}

export default Index
