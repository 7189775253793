import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import {
  MemberProfilesDataTypes,
  MemberProfilesAPITypes,
} from 'types/memberprofiles'

interface MemberProfilesStoreTypes {
  memberprofilesModErrors: StatusErrorTypes
  memberprofilesModErrorsSet: (
    memberprofilesModErrors: StatusErrorTypes
  ) => void
  memberprofilesModStatus: StatusTypes
  memberprofilesModStatusSet: (memberprofilesStatus: StatusTypes) => void
  memberprofiles: MemberProfilesAPITypes[]
  memberprofilesSet: (memberprofiles: MemberProfilesAPITypes[]) => void
  memberprofilesLookup: MemberProfilesAPITypes[]
  memberprofilesLookupSet: (memberprofiles: MemberProfilesAPITypes[]) => void
}

export const useMemberProfilesStore = create<MemberProfilesStoreTypes>(
  (set) => ({
    memberprofilesModErrors: '',
    memberprofilesModErrorsSet: (memberprofilesModErrors) => {
      set({
        memberprofilesModErrors,
      })
    },
    memberprofilesModStatus: 'idle',
    memberprofilesModStatusSet: (memberprofilesModStatus) => {
      set({
        memberprofilesModStatus,
      })
    },
    memberprofiles: [],
    memberprofilesSet: (memberprofiles) => {
      set({
        memberprofiles,
      })
    },
    memberprofilesLookup: [],
    memberprofilesLookupSet: (memberprofilesLookup) => {
      set({
        memberprofilesLookup,
      })
    },
  })
)

interface MemberProfilesLookupStoreTypes {
  memberprofilesLookupModErrors: StatusErrorTypes
  memberprofilesLookupModErrorsSet: (
    memberprofilesLookupModErrors: StatusErrorTypes
  ) => void
  memberprofilesLookupModStatus: StatusTypes
  memberprofilesLookupModStatusSet: (
    memberprofilesLookupStatus: StatusTypes
  ) => void
  memberprofilesLookup: MemberProfilesDataTypes[]
  memberprofilesLookupSet: (
    memberprofilesLookup: MemberProfilesDataTypes[]
  ) => void
}

export const useMemberProfilesLookupStore =
  create<MemberProfilesLookupStoreTypes>((set) => ({
    memberprofilesLookupModErrors: '',
    memberprofilesLookupModErrorsSet: (memberprofilesLookupModErrors) => {
      set({
        memberprofilesLookupModErrors,
      })
    },
    memberprofilesLookupModStatus: 'idle',
    memberprofilesLookupModStatusSet: (memberprofilesLookupModStatus) => {
      set({
        memberprofilesLookupModStatus,
      })
    },
    memberprofilesLookup: [],
    memberprofilesLookupSet: (memberprofilesLookup) => {
      set({
        memberprofilesLookup,
      })
    },
  }))
