import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import Colors from 'colors.json'
import Modal from 'components/Modal'
import Button from 'components/Button'

import { useNavigate } from 'react-router'
import { useCognito } from 'services/amplify'
import { ROUTES } from 'constants/routing'

const TIMEOUT = 300_000

const IdleTimeout: React.FC = () => {
  const [state, setState] = useState<string>('Active')
  const [count, setCount] = useState<number>(0)
  const [remaining, setRemaining] = useState<number>(0)
  const [isOpen, isOpenSet] = useState<boolean>(false)

  const navigate = useNavigate()
  const cognito = useCognito()

  const onIdle = useCallback(() => {
    setState('Idle')
    isOpenSet(true)
  }, [setState])

  const onActive = useCallback(() => {
    setState('Active')
  }, [setState])

  const onAction = useCallback(() => {
    setCount(count + 1)
  }, [setCount])

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: TIMEOUT,
    throttle: 500,
  })

  const handleRefresh = useCallback(() => {
    window.location.reload()
  }, [])

  const handleLogout = useCallback(() => {
    isOpenSet(false)
    navigate(ROUTES.Login.path)
    cognito.signOut()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  // useEffect(() => {
  //   const handleBeforeUnload = (e: BeforeUnloadEvent): string => {
  //     e.preventDefault()
  //     e.returnValue = ''
  //     const message = 'Are you sure you want to leave?'
  //     return message
  //   }
  //   window.addEventListener('beforeunload', handleBeforeUnload)
  //   return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  // }, [])

  return (
    <Modal
      open={isOpen}
      title="Looks like you've been idle for a while"
      onClose={handleRefresh}
      noFooter
    >
      <p className="pb-4">
        <span className="tracking-wider font-light">
          Would you like to continue using the application?
        </span>
      </p>
      <footer className="flex justify-end pb-4 pl-4 pr-4">
        <Button
          type="submit"
          className="mr-2 rounded bg-primary"
          onClick={handleRefresh}
        >
          <span className="tracking-wider font-bold">
            Yes, I will continue.
          </span>
        </Button>
        <Button
          className="bg-white border rounded border-primary"
          onClick={handleLogout}
          style={{ color: Colors.primary }}
        >
          <span className="tracking-wider font-bold">
            No, I want to logout.
          </span>
        </Button>
      </footer>
    </Modal>
  )
}

export default IdleTimeout
