import error404 from 'assets/images/not-found.png'
import { ROUTES } from 'constants/routing'
import { NavLink } from 'react-router-dom'

const NotFound = (): JSX.Element => (
  <div className="flex p-12 bg-white content-container">
    <div className="flex flex-col items-center w-full">
      <img src={error404} alt="Forest Avatar" />
      <h2 className="py-5 font-normal text-gray-500">ERROR: 404</h2>
      <p className="w-2/5 text-lg font-normal text-center">
        Sorry, the link you followed is either outdated, inaccurate, or the
        server has been instructed not to let you have it.
      </p>
      <NavLink
        className="px-12 mx-auto mt-10 uppercase"
        to={ROUTES.MemberLookup.path}
      >
        Go back to Member Lookup
      </NavLink>
    </div>
  </div>
)

export default NotFound
