import { http } from 'utils'
import { MemberToken, User } from '../types/users'

/**
 * Logs the user in.
 *
 * @param email user's email address
 * @param password user's password
 */
export async function login(): Promise<User> {
  const response = await http.get<MemberToken>('/user')

  const { expiration, token, allowed } = response.data

  return {
    expiration,
    token,
    allowed,
  }
}

/**
 * Logs the user out.
 */
export async function logout(): Promise<void> {
  await http.post('/logout', {})
}
