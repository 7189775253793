import Modal from 'components/Modal'
import Device from 'views/MerchantProfile/ManualTx/Device'

type Props = {
  title: string | undefined
  open: boolean
  onClose: () => void
}

const ModalDeviceCode = ({ title, open, onClose }: Props): JSX.Element => {
  return (
    <Modal open={open} title={title} onClose={onClose} noFooter>
      <Device onClose={onClose} />
    </Modal>
  )
}

export default ModalDeviceCode
