import jwt_decode from 'jwt-decode'

import { PurchaseTxServiceTypes } from 'types/purchaseTxTypes'
import { PurchaseRedemptionReversalTxServiceTypes } from 'types/purchaseReversalTxTypes'
import { PurchaseReversalTxServiceTypes } from 'types/purchaseReversalTxTypes'
import { LSKEY_TOKEN } from 'constants/app'
import { http, ls } from 'utils'

export async function purchaseAPI({
  transactionValue,
  creditValue,
  loyaltyValue,
  externalTransactionId,
  ident,
  storeId,
  deviceCode,
  cashier,
  guid,
  transactionDate,
  comments,
  traceNumber,
  reasonId,
}: PurchaseTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/purchase'
  const data = {
    transactionValue,
    creditValue,
    loyaltyValue,
    externalTransactionId,
    ident,
    storeId,
    deviceCode,
    cashier,
    guid,
    transactionDate,
    comments,
    traceNumber,
    reasonId,
  }
  return await http.post(url, data)
}

export async function purchaseReversalAPI({
  externalTransactionId,
  ident,
  storeId,
  cashier,
  comments,
  traceNumber,
  reasonId,
}: PurchaseReversalTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/purchase-reversal'
  const data = {
    externalTransactionId,
    ident,
    storeId,
    cashier,
    comments,
    traceNumber,  
    reasonId,
  }
  return await http.post(url, data)
}

export async function purchaseRedemption(
  transactionValue: number,
  creditValue: number,
  loyaltyValue: number,
  externalTransactionId: number,
  ident: string
): Promise<void> {
  const url = '/manual-transaction/purchase-redemption'

  const data = {
    transactionValue,
    creditValue,
    loyaltyValue,
    externalTransactionId,
    ident,
  }

  return await http.post(url, data)
}

export async function purchaseRedemptionReversal({
  externalTransactionId,
  ident,
  storeId,
  cashier,
  comments,
  traceNumber,
  reasonId,
}: PurchaseRedemptionReversalTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/purchase-redemption-reversal'

  const data = {
    externalTransactionId,
    ident,
    storeId,
    cashier,
    comments,
    traceNumber,   
    reasonId, 
  }

  return await http.post(url, data)
}
