import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { getMerchants } from 'api/merchant'
import { getTransactions } from 'api/transactions'
import Button from 'components/Button'
import Input from 'components/Input'
import Dropdown, { OptionType } from 'components/Dropdown'
import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import { TRANSACTION_STATUS, TRANSACTION_TYPES } from 'constants/filters'
import { Form, Formik } from 'formik'
import { isEmpty, isUndefined } from 'lodash'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useParams } from 'react-router-dom'
import { OptionTypeBase } from 'react-select'
import { formatDateToShort } from 'services/helpers'
import useSWR from 'swr'
import { DropDownItem } from 'types/components'
// import data from 'mocks/json/transactions.json';
import { MerchantType, Transaction } from 'types/transactions'
import InnerWrapper from '../InnerWrapper'
import TransactionItem from './TransctionItem'
import TransactionFilter from './TransactionFilter'
import useTransactions from './useTransactions'

const TRANSACTION_COLUMNS = [
  // 'Country',
  'Trans Date',
  'Processed Date',
  'Trans Id',
  'Ext Trans Id',
  'Trans Card',
  'Merchant',
  'Trans Type',
  'Account',
  'CR Value',
  'Loyalty Value',
  'Purchase Value',
]

function Transactions(): React.ReactElement {
  const { traderId } = useParams()
  const [transactionFetchLimitError, setTransactionFetchLimitError] =
    useState(false)
  const [, setMerchantFetchLimitError] = useState(false)
  const [offset, setOffset] = useState(0)
  const [perPage] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [postData, setPostData] = useState<Transaction[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const { transactions, transactionsLoading } = useTransactions({
    memberTraderId: String(traderId),
  })

  useEffect(() => {
    if (transactions) {
      const slice = transactions.slice(offset, offset + perPage)
      setPostData(slice)
      setPageCount(Math.ceil(transactions.length / perPage))
    }
  }, [offset, perPage, transactions])

  function handlePageClick({ selected }: { selected: number }): void {
    setOffset(selected * perPage)
    setCurrentPage(selected + 1)
  }

  return (
    <InnerWrapper title="Transactions">
      <TransactionFilter />

      {transactionFetchLimitError ? (
        <NoResults
          header="Failed to load data"
          subtitle="Please refresh the page and try again, or contact
        your system administrator"
          noImage
        />
      ) : transactionsLoading ? (
        <>
          <NoResults header="Loading" subtitle="Fetching data" noImage />
          <Spinner />
        </>
      ) : (
        <>
          {isEmpty(transactions) ? (
            <NoResults noImage />
          ) : (
            <Table
              columns={TRANSACTION_COLUMNS}
              data={transactions}
              pageCount={pageCount}
              currentPage={currentPage}
              perPage={perPage}
              onPageChange={handlePageClick}
              itemCount={postData.length}
              showActions
            >
              {postData.map((item: Transaction) => (
                <TransactionItem key={item.transactionId} transaction={item} />
              ))}
            </Table>
          )}
        </>
      )}
    </InnerWrapper>
  )
}

export default Transactions
