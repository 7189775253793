import React, { Dispatch, PropsWithChildren, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import { MerchantIdentificationTypes } from 'types/merchant'

interface PropsTypes {
  identification: MerchantIdentificationTypes
  selectedMerchantIdentificationSet: Dispatch<MerchantIdentificationTypes>
  isMerchantIdentificationEditSet: Dispatch<boolean>
}

const DeviceItem = ({
  identification,
  selectedMerchantIdentificationSet,
  isMerchantIdentificationEditSet,
}: PropsWithChildren<PropsTypes>): React.ReactElement => {
  return (
    <>
      <tr className="py-2 text-black bg-white border-b-2 cursor-pointer hover:bg-gray-600">
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{identification.ident}</span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{identification.identTypeName}</span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{identification.statusName}</span>
        </td>
        <td className="py-2 pl-6 text-right">
          <button
            type="button"
            className="relative px-1 ml-1 text-center transition duration-150 border border-gray-500 rounded-sm focus:outline-none focus:shadow-outline group hover:border-primary"
            data-tip="Change Status"
            onClick={() => {
              selectedMerchantIdentificationSet(identification)
              isMerchantIdentificationEditSet(true)
            }}
          >
            <Icon
              data-tip="Edit"
              icon={faPencilAlt}
              className="text-gray-500 group-hover:text-primary"
            />
          </button>
        </td>
      </tr>
    </>
  )
}

export default DeviceItem
