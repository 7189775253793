import { useState } from 'react'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'

import IconDropdown from 'components/IconDropdown'
import { useMemberProfileActions } from './useMemberProfileActions'

import { MEMBERELLIPSISNAVIGATION } from 'constants/profileEllipsisMember'

const Index = (): JSX.Element => {
  const {
    memberMemberProfiles,
    memberMemberProfilesModStatus,
    memberMemberProfilesModErrors,
    DROPDOWNMODALOPTIONS,
  } = useMemberProfileActions()

  const [optionModal, optionModalSet] = useState<{
    open: boolean
    key: string | null
  }>({ open: false, key: null })

  return (
    <>
      <IconDropdown
        icon={faEllipsisH}
        options={DROPDOWNMODALOPTIONS}
        iconColor="black"
        toggleClassName="rounded-full h-6 w-6 text-center border pl-1 z-10"
        menuClassName="w-72 px-2 py-3 border-gray-500 shadow-md"
        itemStyle={{
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
        }}
        onSelect={(value) =>
          optionModalSet({
            open: true,
            key: value,
          })
        }
      />

      {/* Modal Inits */}
      {MEMBERELLIPSISNAVIGATION.map(({ label, value, Modal }) => {
        if (Modal)
          return (
            <Modal
              key={value}
              title={label}
              open={optionModal.key === value ? optionModal.open : false}
              onClose={() =>
                optionModalSet({
                  open: false,
                  key: value || '',
                })
              }
              member={memberMemberProfiles[0]}
            />
          )
      })}
    </>
  )
}

export default Index
