import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const BankThorDefaultSchemas = DefaultSchemas

export const BankThorDataSchemas = zod.object({
  accountFormat: zod.string(),
  bankId: zod.number(),
  bankName: zod.string(),
})

export const BankThorAPISchemas = BankThorDefaultSchemas.merge(
  zod.object({
    bankNames: zod.array(BankThorDataSchemas),
  })
)
