import { useCallback, useState } from 'react'
import { faIdCard, faEye } from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import TSTable from 'components/Tanstack'
import { useHistoryStore } from 'store/histories'
import { useTableUtils } from 'hooks/useTableUtils'
import { HistoryDataTypes, HistoryModalActionsTypes } from 'types/histories'

const ChangedFields = ({ fields }: { fields: (string | null)[] }) => {
  return (
    <div className="flex flex-col gap-2">
      {fields.map((item, index) => (
        <small key={`${item ?? 'null'}-${index}`}>{item ?? 'N/A'}</small>
      ))}
    </div>
  )
}

const FieldValues = ({ values }: { values: (string | null)[] | null }) => {
  if (!values) return <></>
  return (
    <div className="flex flex-col gap-2">
      {values.map((item, index) => (
        <small key={`${item}-${index}`}>
          {item === '1' ? 'Yes' : item === '0' ? 'No' : !item ? 'Null' : item}
        </small>
      ))}
    </div>
  )
}

export const useMemberHistoryTable = () => {
  const history = useHistoryStore((state) => state.histories)

  const {
    selectedModal,
    selectedModalSet,
    selectedRowData,
    selectedRowDataSet,
  } = useTableUtils<HistoryDataTypes, HistoryModalActionsTypes>()

  const handleActionModalClose = useCallback(() => {
    selectedRowDataSet(null)
    selectedModalSet(null)
  }, [selectedModalSet, selectedRowDataSet])

  const columnHelper = createColumnHelper<HistoryDataTypes>()
  const columns = [
    columnHelper.accessor('changedFields', {
      header: () => 'Changed Field',
      cell: (info) => <ChangedFields fields={info.getValue() || []} />,
    }),
    columnHelper.accessor('oldValues', {
      header: () => 'Old Value',
      cell: (info) => <FieldValues values={info.getValue()} />,
    }),
    columnHelper.accessor('newValues', {
      header: () => 'New Value',
      cell: (info) => <FieldValues values={info.getValue()} />,
    }),
    columnHelper.accessor('note', {
      header: () => 'Note',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('dateChanged', {
      header: () => 'Date Changed',
      cell: (info) => info.getValue(),
      // cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
    }),
    columnHelper.accessor('editedBy', {
      header: () => 'Edited By',
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => '',
      // cell: (info) => info.getValue(),
      cell: ({ row }) => (
        <div className="flex gap-2 px-4">
          {/* <TSTable.TableCellButton
            onClick={() => {
              selectedHisotrySet(row.original)
              selectedActionModalSet('preview')
            }}
            label="Preview"
            icon={faEye}
          /> */}
        </div>
      ),
    }),
  ] as ColumnDef<HistoryDataTypes>[]

  return {
    memberHistorySelected: selectedRowData,
    memberHistoryActionModal: selectedModal,
    memberHistoryTableData: history,
    memberHistoryTableColumns: columns,
    handleMemberActionModalClose: handleActionModalClose,
  }
}
