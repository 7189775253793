export const THORSTATUS = [
  { id: 1, name: 'Current' },
  { id: 2, name: 'On Hold' },
  { id: 3, name: 'Pending Activation' },
  { id: 4, name: 'Registered' },
  { id: 5, name: 'Terminated' },
  { id: 6, name: 'Unregistered' },
  { id: 7, name: 'Unused' },
  { id: 8, name: 'Closed' },
  { id: 9, name: 'Cancelled' },
  { id: 10, name: 'Test' },
  { id: 11, name: 'Other' },
  { id: 12, name: 'Suspended' },
  { id: 99, name: 'Undefined' },
]
