import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getCommsAPI } from 'api/comms'
import { useCommsStore } from 'store/comms'
import { CommsFetchParamsTypes } from 'types/comms'

import { DATASTATUS } from 'constants/status'

export const useMemberComms = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const commsModStatus = useCommsStore((state) => state.commsModStatus)
  const commsModStatusSet = useCommsStore((state) => state.commsModStatusSet)
  const commsModErrors = useCommsStore((state) => state.commsModErrors)
  const commsModErrorsSet = useCommsStore((state) => state.commsModErrorsSet)
  const comms = useCommsStore((state) => state.comms)
  const commsSet = useCommsStore((state) => state.commsSet)
  const [commsFilterParams, commsFilterParamsSet] =
    useState<CommsFetchParamsTypes>(defaultParams)
  const [isCommsFiltered, isCommsFilteredSet] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataComms,
    isLoading: isLoadingComms,
    isError: isErrorComms,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useComms', commsFilterParams],
    queryFn: async () => {
      try {
        commsModStatusSet('loading')
        const response = await getCommsAPI(commsFilterParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        commsModStatusSet('success')
        return response.comms
      } catch (e) {
        commsModStatusSet('fail')
        commsModErrorsSet(JSON.stringify(e))
      }
    },
  })

  const handleFilter = useCallback(
    async (filterParams: CommsFetchParamsTypes) => {
      commsFilterParamsSet(filterParams)
      isCommsFilteredSet(true)
    },
    [commsFilterParamsSet, isCommsFilteredSet]
  )

  const handleUnfilter = useCallback(() => {
    const test = { traderId: memberTraderId }
    commsFilterParamsSet(test)
    isCommsFilteredSet(false)
  }, [commsFilterParamsSet, isCommsFilteredSet])

  useEffect(() => {
    if (dataComms) commsSet(dataComms)
  }, [dataComms, commsSet])

  return {
    memberHandleCommsFilter: handleFilter,
    memberHandleCommsUnfilter: handleUnfilter,
    memberCommsFilterParams: commsFilterParams,
    memberComms: comms,
    memberCommsModStatus: commsModStatus,
  }
}
