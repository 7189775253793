import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import {
  CommsDataTypes,
  CommsStatusesDataTypes,
  CommsChannelsDataTypes,
  CommsEmailPreviewDataTypes,
} from 'types/comms'

interface CommsStoreTypes {
  commsModErrors: StatusErrorTypes
  commsModErrorsSet: (commsModErrors: StatusErrorTypes) => void
  commsModStatus: StatusTypes
  commsModStatusSet: (commsStatus: StatusTypes) => void
  comms: CommsDataTypes[]
  commsSet: (comms: CommsDataTypes[]) => void
  commsLookup: CommsDataTypes[]
  commsLookupSet: (comms: CommsDataTypes[]) => void
  commsSummary: {
    sent: number
    opened: number
    clicked: number
  }
  commsSummarySet: () => void
}

export const useCommsStore = create<CommsStoreTypes>((set) => ({
  commsModErrors: '',
  commsModErrorsSet: (commsModErrors) => {
    set({
      commsModErrors,
    })
  },
  commsModStatus: 'idle',
  commsModStatusSet: (commsModStatus) => {
    set({
      commsModStatus,
    })
  },
  comms: [],
  commsSet: (comms) => {
    set({
      comms,
    })
  },
  commsLookup: [],
  commsLookupSet: (commsLookup) => {
    set({
      commsLookup,
    })
  },
  commsSummary: {
    sent: 0,
    opened: 0,
    clicked: 0,
  },
  commsSummarySet: () => {
    set((state) => {
      const sent = state.comms.filter(
        (item) => item.statusName.toLowerCase() === 'sent'
      )
      const opened = state.comms.filter((item) => item.opened === 1)
      const clicked = state.comms.filter((item) => item.clicked === 1)

      return {
        commsSummary: {
          sent: sent.length,
          opened: opened.length,
          clicked: clicked.length,
        },
      }
    })
  },
}))

interface CommsStatusesStoreTypes {
  commsStatusesModErrors: StatusErrorTypes
  commsStatusesModErrorsSet: (commsStatusesModErrors: StatusErrorTypes) => void
  commsStatusesModStatus: StatusTypes
  commsStatusesModStatusSet: (commsStatusesStatus: StatusTypes) => void
  commsStatuses: CommsStatusesDataTypes[]
  commsStatusesSet: (commsStatuses: CommsStatusesDataTypes[]) => void
  commsStatusesLookup: CommsStatusesDataTypes[]
  commsStatusesLookupSet: (commsStatuses: CommsStatusesDataTypes[]) => void
}

export const useCommsStatusesStore = create<CommsStatusesStoreTypes>((set) => ({
  commsStatusesModErrors: '',
  commsStatusesModErrorsSet: (commsStatusesModErrors) => {
    set({
      commsStatusesModErrors,
    })
  },
  commsStatusesModStatus: 'idle',
  commsStatusesModStatusSet: (commsStatusesModStatus) => {
    set({
      commsStatusesModStatus,
    })
  },
  commsStatuses: [],
  commsStatusesSet: (commsStatuses) => {
    set({
      commsStatuses,
    })
  },
  commsStatusesLookup: [],
  commsStatusesLookupSet: (commsStatusesLookup) => {
    set({
      commsStatusesLookup,
    })
  },
}))

interface CommsChannelsStoreTypes {
  commsChannelsModErrors: StatusErrorTypes
  commsChannelsModErrorsSet: (commsChannelsModErrors: StatusErrorTypes) => void
  commsChannelsModStatus: StatusTypes
  commsChannelsModStatusSet: (commsChannelsStatus: StatusTypes) => void
  commsChannels: CommsChannelsDataTypes[]
  commsChannelsSet: (commsChannels: CommsChannelsDataTypes[]) => void
  commsChannelsLookup: CommsChannelsDataTypes[]
  commsChannelsLookupSet: (commsChannels: CommsChannelsDataTypes[]) => void
}

export const useCommsChannelsStore = create<CommsChannelsStoreTypes>((set) => ({
  commsChannelsModErrors: '',
  commsChannelsModErrorsSet: (commsChannelsModErrors) => {
    set({
      commsChannelsModErrors,
    })
  },
  commsChannelsModStatus: 'idle',
  commsChannelsModStatusSet: (commsChannelsModStatus) => {
    set({
      commsChannelsModStatus,
    })
  },
  commsChannels: [],
  commsChannelsSet: (commsChannels) => {
    set({
      commsChannels,
    })
  },
  commsChannelsLookup: [],
  commsChannelsLookupSet: (commsChannelsLookup) => {
    set({
      commsChannelsLookup,
    })
  },
}))

interface CommsEmailPreviewStoreTypes {
  commsEmailPreviewModErrors: StatusErrorTypes
  commsEmailPreviewModErrorsSet: (
    commsEmailPreviewModErrors: StatusErrorTypes
  ) => void
  commsEmailPreviewModStatus: StatusTypes
  commsEmailPreviewModStatusSet: (commsEmailPreviewStatus: StatusTypes) => void
  commsEmailPreview: CommsEmailPreviewDataTypes[]
  commsEmailPreviewSet: (
    commsEmailPreview: CommsEmailPreviewDataTypes[]
  ) => void
  commsEmailPreviewLookup: CommsEmailPreviewDataTypes[]
  commsEmailPreviewLookupSet: (
    commsEmailPreview: CommsEmailPreviewDataTypes[]
  ) => void
}

export const useCommsEmailPreviewStore = create<CommsEmailPreviewStoreTypes>(
  (set) => ({
    commsEmailPreviewModErrors: '',
    commsEmailPreviewModErrorsSet: (commsEmailPreviewModErrors) => {
      set({
        commsEmailPreviewModErrors,
      })
    },
    commsEmailPreviewModStatus: 'idle',
    commsEmailPreviewModStatusSet: (commsEmailPreviewModStatus) => {
      set({
        commsEmailPreviewModStatus,
      })
    },
    commsEmailPreview: [],
    commsEmailPreviewSet: (commsEmailPreview) => {
      set({
        commsEmailPreview,
      })
    },
    commsEmailPreviewLookup: [],
    commsEmailPreviewLookupSet: (commsEmailPreviewLookup) => {
      set({
        commsEmailPreviewLookup,
      })
    },
  })
)
