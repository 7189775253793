import { useState } from 'react'

export const useMemberCommsModal = () => {
  const [selectedActionModal, selectedActionModalSet] = useState<
    null | 'email_preview' | 'sms_push_preview'
  >(null)

  return {
    selectedActionModal,
    selectedActionModalSet,
  }
}
