import { useMemo } from 'react'
import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import { InputField } from 'components/InputField'
import Button from 'components/Button'
import { IdentificationsDataTypes } from 'types/identifications'

import Dropdown from 'components/Dropdown'
import { useMemberIdentificationsMerge } from './useMemberIdentificationsMerge'
import { useMemberIdentificationsMergeForm } from './useMemberIdentificationsMergeForm'

import {
  HAMPSTA_ADDREPLACE_OPTIONS,
  HAMPSTA_CARDCOLOR_OPTIONS,
} from 'constants/filters'

const Index = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: IdentificationsDataTypes
}): JSX.Element => {
  const {
    memberIdentificationsMergeModStatus,
    memberIdentificationsHampstaAddReplaceParams,
    memberHandleIdentificationsAddReplace,
  } = useMemberIdentificationsMerge({
    modalClose,
    selectedRowData,
  })

  const { control, handleSubmit, setValue, watch } =
    useMemberIdentificationsMergeForm({
      defaultValues: memberIdentificationsHampstaAddReplaceParams,
    })

  const OPTIONS = useMemo(() => {
    const { identTypeId, statusId } = selectedRowData
    if (identTypeId === 1 && statusId !== 3) return HAMPSTA_ADDREPLACE_OPTIONS
    return HAMPSTA_ADDREPLACE_OPTIONS.filter((item) => item.value === '0')
  }, [selectedRowData, HAMPSTA_ADDREPLACE_OPTIONS])

  return (
    <FormsLayout.Wrapper
      onSubmit={handleSubmit(memberHandleIdentificationsAddReplace)}
    >
      <FormsLayout.Item>
        <Controller
          name="flReplace"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Action Options"
              defaultValue={HAMPSTA_ADDREPLACE_OPTIONS[0]}
              options={OPTIONS}
              className="w-full menu-outer-top tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) => {
                setValue('flReplace', parseInt(value.value))
              }}
              overflowStyle
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.Item>
        <Controller
          name="cardColor"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Card Color"
              defaultValue={HAMPSTA_CARDCOLOR_OPTIONS[0]}
              options={HAMPSTA_CARDCOLOR_OPTIONS}
              className="w-full menu-outer-top tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) => {
                setValue('cardColor', value.value)
              }}
              overflowStyle
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.Item>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputField
              onChange={(e) => {
                setValue('name', e.target.value)
              }}
              type="input"
              name="name"
              label="Name *"
              containerClassName="flex flex-col flex-grow"
              rounded
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          type="submit"
          className="rounded bg-primary tracking-wider font-bold"
        >
          Add/Replace identifier
        </Button>
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
