import { Voucher, VoucherSummary } from 'types/voucher'
import { http } from 'utils'

import {
  VouchersAPITypes,
  VouchersFetchParamsTypes,
  VouchersStatusesAPITypes,
} from 'types/vouchers'
import {
  VouchersAPISchemas,
  VouchersStatusesAPISchemas,
} from 'schemas/vouchersSchemas'

import { DATASTATUS } from 'constants/status'
import { VOUCHER_STATUS_FILTERS } from 'constants/filters'

export async function getVouchersAPI({
  traderId,
  voucherName,
  startDate,
  endDate,
  voucherCode,
  status,
  pSize = 50,
  pNumber = 1,
}: VouchersFetchParamsTypes): Promise<VouchersAPITypes> {
  const urlDefault = `/vouchers/${traderId}`
  let url = urlDefault
  const params = new URLSearchParams()

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  if (voucherCode) {
    params.set('voucherCode', voucherCode)
  }

  if (voucherName) {
    params.set('voucherName', voucherName)
  }

  if (status) {
    if (status === 'Archived') {
      url += `/archived`
    } else {
      params.set('status', status.toUpperCase())
    }
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = VouchersAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      vouchers: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getVouchersStatusesAPI(): Promise<VouchersStatusesAPITypes> {
  // let url = `/communications/status`
  // const params = new URLSearchParams()

  // url += `?${params.toString()}`

  // const response = await http.get<unknown>(url)
  const data = {
    outcomeCode: '',
    outcomeMessage: '',
    outcomeUserMessage: '',
    vouchersStatus: VOUCHER_STATUS_FILTERS,
  }
  const validatedData = VouchersStatusesAPISchemas.safeParse(data)
  if (!validatedData.success) {
    return {
      vouchersStatus: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}
