import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const TransactionsDefaultSchemas = DefaultSchemas

export const TransactionsDataSchemas = zod.object({
  transactionId: zod.number(),
  transactionType: zod.string().nullable(),
  transactionDate: zod.string(),
  processedDate: zod.string(),
  purchaseValue: zod.number(),
  loyaltyValue: zod.number(),
  creditValue: zod.number(),
  storeName: zod.string(),
  ident: zod.string().nullable(),
  countryCode: zod.string().nullable(),
  externalTransactionId: zod.string().nullable(),
})

export const TransactionsAPISchemas = TransactionsDefaultSchemas.merge(
  zod.object({
    transactions: zod.array(TransactionsDataSchemas),
  })
)

export const TransactionsFetchParamsSchemas = zod.object({
  traderId: zod.number(),
  lastNTransactions: zod.number().optional(),
  transactionTypeId: zod.string().optional(),
  merchantId: zod.string().optional(),
  startDate: zod.string().optional(),
  endDate: zod.string().optional(),
  pSize: zod.number().optional(),
  pNumber: zod.number().optional(),
})

// Prototype - Transaction Details

export const ProductsDataSchemas = zod.object({
  quantity: zod.number(),
  productId: zod.number(),
  sku: zod.string(),
  productName: zod.string().nullable(),
  productValue: zod.number(),
})

export const CampaignsDataSchemas = zod.object({
  creditValue: zod.number(),
  purchaseValue: zod.number(),
  campaignId: zod.number(),
  campaignName: zod.string(),
  loyaltyValue: zod.number(),
})

export const TendersDataSchemas = zod.object({
  identifier: zod.string(),
  tenderValue: zod.number(),
  name: zod.string(),
})

export const CustomDataSchemas = zod.object({
  custom1: zod.string().nullable(),
  custom2: zod.string().nullable(),
  custom3: zod.string().nullable(),
  custom4: zod.string().nullable(),
  custom5: zod.string().nullable(),
})

export const TxReasonsDataSchemas = zod.object({
  reasonId: zod.number(),
  reasonName: zod.string(),
})

export const TXReasonsAPISchemas = TransactionsDefaultSchemas.merge(
  zod.object({
    reasons: zod.array(TxReasonsDataSchemas),
  })
)