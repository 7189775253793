import { useCallback, useState } from 'react'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import TSTable from 'components/Tanstack'
import { useVouchersStore } from 'store/vouchers'
import { useTableUtils } from 'hooks/useTableUtils'
import { VouchersDataTypes, VouchersModalActionsTypes } from 'types/vouchers'

export const useMemberVouchersTable = () => {
  const vouchers = useVouchersStore((state) => state.vouchers)

  const {
    selectedModal,
    selectedModalSet,
    selectedRowData,
    selectedRowDataSet,
  } = useTableUtils<VouchersDataTypes, VouchersModalActionsTypes>()

  const handleActionModalClose = useCallback(() => {
    selectedRowDataSet(null)
    selectedModalSet(null)
  }, [selectedModalSet, selectedRowDataSet])

  const columnHelper = createColumnHelper<VouchersDataTypes>()
  const columns = [
    columnHelper.accessor('voucherCode', {
      header: () => 'Code',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('name', {
      header: () => 'Name',
      cell: (info) => (
        <div className="w-80 whitespace-normal">
          <p>
            <span>{info.getValue()}</span>
          </p>
          {info.row.original.pluCode ? (
            <p className="">
              <b> PLU - {info.row.original.pluCode}</b>
            </p>
          ) : null}
          <article className="text-wrap">
            <p>{info.row.original.description}</p>
          </article>
        </div>
      ),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('startDate', {
      header: () => 'Start Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('endDate', {
      header: () => 'End Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('redemptionDate', {
      header: () => 'Redemption Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('redeemableAt', {
      header: () => 'Redeemable At',
      cell: (info) => {
        const isAllStores = info
          .getValue()
          .map((item) => item.toLowerCase())
          .includes('all stores')

        return (
          <p className="w-50 overflow-hidden overflow-ellipsis">
            {isAllStores ? 'All Stores' : `${info.getValue().length} Stores`}
          </p>
        )
      },
      // footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => 'Stores',
      // cell: (info) => info.getValue(),
      cell: ({ row }) => {
        const isAllStores = row.original.redeemableAt
          .map((item) => item.toLowerCase())
          .includes('all stores')

        if (isAllStores) return null

        return (
          <div className="flex gap-2 pr-10">
            <TSTable.TableCellButton
              onClick={() => {
                selectedRowDataSet(row.original)
                selectedModalSet('vouchers_stores_preview')
              }}
              label="View Stores"
            />
          </div>
        )
      },
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      cell: (info) =>
        info.getValue().charAt(0).toUpperCase() +
        info.getValue().slice(1).toLowerCase(),
      // footer: (props) => props.column.id,
    }),
  ] as ColumnDef<VouchersDataTypes>[]

  return {
    memberVouchersSelected: selectedRowData,
    memberVouchersActionModal: selectedModal,
    memberVouchersTableData: vouchers,
    memberVouchersTableColumns: columns,
    handleMemberActionModalClose: handleActionModalClose,
  }
}
