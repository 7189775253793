import create from 'zustand'

import {
  getMemberDetail,
  getMemberSubsquad,
  getMemberStatusesAPI,
} from 'api/members'
import {
  MemberDetail,
  MemberSubsquadDetail,
  MemberStatuses,
  MemberIdentificationsTypes,
} from 'types/member'
import { StatusTypes, StatusErrorTypes } from 'types/status'

interface MemberStoreTypes {
  memberStatuses: MemberStatuses[]
  member: MemberDetail | undefined
  memberSubsquad: MemberSubsquadDetail | undefined
  getMemberStatuses: (traderId: string) => void
  getMember: (traderId: string) => void
  setMember: (member: MemberDetail) => void
  //
  memberIdentificationsModErrors: StatusErrorTypes
  memberIdentificationsModErrorsSet: (
    memberIdentificationsModErrors: StatusErrorTypes
  ) => void
  memberIdentificationsModStatus: StatusTypes
  memberIdentificationsModStatusSet: (
    memberIdentificationsStatus: StatusTypes
  ) => void
  memberIdentifications: MemberIdentificationsTypes[]
  memberIdentificationsSet: (
    memberIdentifications: MemberIdentificationsTypes[]
  ) => void
}

const useMemberStore = create<MemberStoreTypes>((set) => ({
  memberStatuses: [],
  member: undefined,
  memberSubsquad: undefined,
  getMemberStatuses: (traderId: string) => {
    getMemberStatusesAPI({ traderId })
      .then((memberStatuses: MemberStatuses[]) => {
        set({
          memberStatuses,
        })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        // console.log('')
      })
  },
  getMember: (traderId: string) => {
    getMemberDetail(traderId)
      .then((member: MemberDetail) => {
        set({
          member,
        })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        // console.log('')
      })
    getMemberSubsquad(traderId)
      .then((memberSubsquad: MemberSubsquadDetail) => {
        set({
          memberSubsquad,
        })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        // console.log('')
      })
  },
  setMember: (member: MemberDetail) => {
    set({
      member,
    })
  },
  //
  memberIdentificationsModErrors: '',
  memberIdentificationsModErrorsSet: (memberIdentificationsModErrors) => {
    set({
      memberIdentificationsModErrors,
    })
  },
  memberIdentificationsModStatus: 'idle',
  memberIdentificationsModStatusSet: (memberIdentificationsModStatus) => {
    set({
      memberIdentificationsModStatus,
    })
  },
  memberIdentifications: [],
  memberIdentificationsSet: (memberIdentifications) => {
    set({
      memberIdentifications,
    })
  },
}))

export default useMemberStore
