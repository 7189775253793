import * as Yup from 'yup'
import { z as zod } from 'zod'

export const CommsDefaultSchemas = zod.object({
  outcomeCode: zod.string().nullable(),
  outcomeMessage: zod.string().nullable(),
  outcomeUserMessage: zod.string().nullable(),
})

export const CommsDataSchemas = zod.object({
  id: zod.number(),
  actionId: zod.number(),
  traderId: zod.number().nullable(),
  actionName: zod.string(),
  actionType: zod.string(),
  email: zod.string().nullable(),
  sentDate: zod.string().nullable(),
  broadcastTime: zod.string().nullable(),
  statusId: zod.number(),
  statusName: zod.string(),
  channelName: zod.string(),
  opened: zod.number(),
  clicked: zod.number(),
})

export const CommsAPISchemas = CommsDefaultSchemas.merge(
  zod.object({
    comms: zod.array(CommsDataSchemas),
  })
)

export const CommsFetchParamsSchemas = zod.object({
  traderId: zod.number(),
  startDate: zod.string().optional(),
  endDate: zod.string().optional(),
  channelId: zod.number().optional(),
  statusId: zod.number().optional(),
  pSize: zod.number().optional(),
  pNumber: zod.number().optional(),
})

export const CommsStatusesDataSchemas = zod.object({
  statusId: zod.number(),
  statusDescription: zod.string(),
  statusName: zod.string(),
})

export const CommsStatusesAPISchemas = CommsDefaultSchemas.merge(
  zod.object({
    commsStatus: zod.array(CommsStatusesDataSchemas),
  })
)

export const CommsChannelsDataSchemas = zod.object({
  channelId: zod.number(),
  channelName: zod.string(),
  channelDescription: zod.string(),
})

export const CommsChannelsAPISchemas = CommsDefaultSchemas.merge(
  zod.object({
    commsChannel: zod.array(CommsChannelsDataSchemas),
  })
)

export const CommsEmailPreviewDataSchemas = CommsDefaultSchemas.merge(
  zod.object({
    emailId: zod.string(),
    emailSubject: zod.string(),
    htmlPreviewUrl: zod.string(),
    textPreviewUrl: zod.string(),
    status: zod.string(),
    actionAttributes: zod.record(zod.string().nullable()),
  })
)

export const CommsEmailPreviewAPISchemas = CommsDefaultSchemas.merge(
  CommsEmailPreviewDataSchemas
)

export const CommsParamsSchemas = zod.object({
  actionId: zod.number().optional(),
})
