import React, { useCallback, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

export const useDatepicker = () => {
  const [startDate, startDateSet] = useState<Date | null>(null)
  const [endDate, endDateSet] = useState<Date | null>(null)

  const formatting = useMemo((): string => {
    let dateDisplay = ''
    if (startDate) dateDisplay = startDate.toLocaleDateString()
    if (endDate)
      dateDisplay = dateDisplay + ' - ' + endDate.toLocaleDateString()
    return dateDisplay || 'All'
  }, [startDate, endDate])

  const handleClear = useCallback(() => {
    startDateSet(null)
    endDateSet(null)
  }, [startDateSet, endDateSet])

  const handlePick = useCallback(
    (dates: [Date | null, Date | null] | null): void => {
      if (dates) {
        startDateSet(dates[0])
        endDateSet(dates[1])
      }
    },
    [startDateSet, endDateSet]
  )

  const DatepickerInput = ({
    title,
    value,
    onClick,
  }: {
    title?: string
    value?: string
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  }) => (
    <div className="flex flex-col gap-2">
      {title && (
        <label>
          <span className="tracking-wider font-light">{title}</span>
        </label>
      )}
      <button
        className="p-2 pr-10 text-left border border-gray-400 relative flex items-center"
        style={{ minWidth: '120px' }}
        onClick={!value ? onClick : () => null}
        type="button"
      >
        <span className="tracking-wider">{formatting}</span>
        {!value ? (
          <Icon
            icon={faChevronDown}
            className="absolute top-0 right-0 h-full ml-4 mr-2"
          />
        ) : (
          <Icon
            onClick={handleClear}
            icon={faTimes}
            className="absolute top-0 right-0 h-full ml-4 mr-3"
          />
        )}
      </button>
    </div>
  )

  return {
    startDate,
    endDate,
    handlePick,
    handleClear,
    DatepickerInput,
  }
}
