import { useMemberActivity } from './useMemberActivity'

const Index = (): JSX.Element => {
  const { memberTransactionsActivity, memberTransactionsActivityModStatus } =
    useMemberActivity()

  let value = memberTransactionsActivity[0]?.loyaltyValue
  switch (memberTransactionsActivity[0]?.transactionType?.toLowerCase()) {
    case 'icash topup':
      value = memberTransactionsActivity[0]?.creditValue
      break
    case 'icash topup reversal':
      value = memberTransactionsActivity[0]?.creditValue
      break
    case 'icash redemption':
      value = memberTransactionsActivity[0]?.creditValue
      break
    case 'icash redemption reversal':
      value = memberTransactionsActivity[0]?.creditValue
      break
    case 'purchase/icash redemption':
      value = memberTransactionsActivity[0]?.creditValue
      break
    default:
      value = memberTransactionsActivity[0]?.loyaltyValue
  }

  return (
    <section className="flex flex-col">
      {memberTransactionsActivityModStatus === 'success' ? (
        memberTransactionsActivity.length > 0 ? (
          <div>
            {memberTransactionsActivity.map((item) => (
              <div className="flex flex-col gap-2" key={item.transactionId}>
                <div className="flex flex-col flex-grow gap-2">
                  <p>
                    <span className="uppercase font-light text-xs tracking-wider">
                      Latest Activity
                    </span>
                  </p>
                  <p>
                    <span className="text-black font-bold uppercase ">
                      {item.transactionType || null}
                    </span>
                    <span className="font-light text-primary text-opacity-60 italic">
                      &nbsp;&nbsp;from&nbsp;&nbsp;
                    </span>
                    <span className="text-black font-bold uppercase ">
                      {item.storeName}
                    </span>
                    <br />
                    <span className="font-light text-primary text-opacity-60 italic">
                      for a value of&nbsp;&nbsp;
                    </span>
                    <span className="text-black font-bold uppercase ">
                      {/* {item.loyaltyValue.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })} */}
                      {value.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="flex flex-col gap-2">
            <span className="uppercase font-light text-xs tracking-wider">
              Latest Activity
            </span>
            <span className="font-light tracking-wider">
              There is no available activity
            </span>
          </p>
        )
      ) : (
        <p className="flex flex-col gap-0">
          <span className="text-xs uppercase font-light tracking-wider">
            Loading member`s activity profile
          </span>
        </p>
      )}
    </section>
  )
}

export default Index
