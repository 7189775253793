import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import { getBanks_API } from 'api/financials'
import { BankAccountsDefaultTypes } from 'types/financials'
import { FinancialAddBankAccountTxFormTypes } from 'types/financialAddBankAccountTxTypes'
import { MemberDetail } from 'types/member'
import useMemberStore from 'store/member'
import { hasingOut } from 'services/helpers'

export const useBanks = () => {
  const [bankName, bankNameSet] = useState<string | null>(null)
  const [isSearched, isSearchedSet] = useState(false)
  const queryClient = useQueryClient()
  const {
    isLoading,
    isError,
    error,
    isSuccess,
    mutateAsync: onGetBanksAsync,
  } = useMutation({
    mutationFn: getBanks_API,
    onSuccess: (dataResponse, data) => {
      if (dataResponse.data.length) {
        // const bankCMSName = dataResponse.data[0].attributes.bankName
        const bankCMSName = ['CMS new yotk']
        const bankThorNames = data.bankThorNames
        const matches = bankThorNames.every((word) =>
          bankCMSName.includes(word)
        )
        console.log({ matches, bankCMSName, bankThorNames })
        bankNameSet(dataResponse.data[0].attributes.bankName)
      } else {
        bankNameSet('Bank Unknown')
      }
      // queryClient.invalidateQueries(['useBankAccounts'])
    },
  })

  const onSearchBank = useCallback(
    async (bankAccountNumber: string, bankThorNames: string[]) => {
      const bank = bankAccountNumber.replaceAll('_', '')
      if (bank.length > 8 && !isSearched) {
        console.log({ bankThorNames })
        const detail = bank.split('-')
        const bankNumber = detail[0]
        const bankBranch = detail[1]
        await onGetBanksAsync({ bankNumber, bankBranch, bankThorNames })
        isSearchedSet(true)
      } else {
        isSearchedSet(false)
        bankNameSet(null)
      }
    },
    [isSearched, bankNameSet, onGetBanksAsync, isSearchedSet]
  )

  // useEffect(() => {
  //   if (error) {
  //     const e = error as BankAccountsDefaultTypes
  //     toast.error(e.outcomeUserMessage)
  //   }
  //   if (isSuccess) {
  //     toast.success('Bank account successfully added.')
  //     onSubmitSuccess()
  //   }
  // }, [error, isSuccess, onSubmitSuccess])

  return {
    memberBankName: bankName,
    memberBanksIsLoading: isLoading,
    memberBanksIsSuccess: isSuccess,
    memberBanksIsError: isError,
    onSearchBank,
  }
}
