import { useCallback, useEffect, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { FormikHelpers } from 'formik'

import { TRANSACTION_TYPES } from 'constants/filters'
import { formatDateToShort } from 'services/helpers'
import {
  TransactionFilterFormSubmitValuesTypes,
  TransactionFilterFormTypes,
} from 'types/transactions'
import useTransactionStore from 'store/transaction'

const TransactionFilterINITVAL: TransactionFilterFormTypes = {
  transactionType: TRANSACTION_TYPES[0],
  merchant: '',
  startDateRange: new Date(),
  endDateRange: new Date(),
}

const useTransactions = ({ memberTraderId }: { memberTraderId: string }) => {
  const transactions = useTransactionStore((state) => state.transactions)
  const transactionsLoading = useTransactionStore(
    (state) => state.transactionsLoading
  )
  const getTransactions = useTransactionStore((state) => state.getTransactions)
  const setFilteredTransactionsStatus = useTransactionStore(
    (state) => state.setFilteredTransactionsStatus
  )

  useEffect(() => {
    getTransactions(memberTraderId)
    setFilteredTransactionsStatus(false)
  }, [])

  return {
    transactions,
    transactionsLoading,
  }
}

export default useTransactions
