import { ROUTES } from './routing'

import { accessControlSubNavigation } from 'setups/controller'

const projectClient = process.env.REACT_APP_PROJ

export const MemberTabModulesData = [
  {
    module: 'Dashboard',
    route: {
      title: 'Dashboard',
      path: ROUTES.MemberProfileDashboard,
      pathname: '',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
  {
    module: 'Transaction',
    route: {
      title: 'Transactions',
      path: ROUTES.MemberProfileTransactions,
      pathname: 'transactions',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
  {
    module: 'Communication',
    route: {
      title: 'Comms',
      path: ROUTES.MemberProfileComms,
      pathname: 'comms',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
  {
    module: 'Communication Main NZ',
    route: {
      title: 'Comms',
      path: ROUTES.MemberProfileCommsMainNZ,
      pathname: 'comms_main_nz',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
  {
    module: 'Voucher',
    route: {
      title: 'Vouchers',
      path: ROUTES.MemberProfileVouchers,
      pathname: 'vouchers',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
  {
    module: 'Offer',
    route: {
      title: 'Offers',
      path: ROUTES.MemberProfileVouchers,
      pathname: 'offers',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
  {
    module: 'Notes',
    route: {
      title: 'Memos/Notes',
      path: ROUTES.MemberProfileMemos,
      pathname: 'memos',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
]

const MemberMoreTabModulesData = [
  {
    module: 'More',
    route: {
      title: '+ More',
      path: {
        path: '',
        permissionIds: [],
      },
      pathname: '',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
      childClassName:
        'text-gray-500 py-1 px-6 block hover:text-primary focus:outline-none',
      iconClassName: 'text-gray-500 hover:text-primary focus:outline-none',
      children: [
        {
          module: 'Membership',
          route: {
            title: 'Memberships',
            path: ROUTES.MemberProfileMemberships,
            pathname: 'memberships',
            className: '',
          },
        },
        {
          module: 'Membership Subway',
          route: {
            title: 'Memberships',
            path: ROUTES.MemberProfileMembershipsSubway,
            pathname: 'memberships_subway',
            className: '',
          },
        },
        // {
        //   module: 'Financials',
        //   route: {
        //     title: 'Financials',
        //     path: ROUTES.MemberProfileFinancials,
        //     pathname: 'financials',
        //     className: '',
        //   },
        // },

        {
          module: 'Identification',
          route: {
            title: 'Identification',
            path: ROUTES.MemberProfileIdentification,
            pathname: 'identification',
            className: '',
          },
        },
        {
          module: 'Identification Hampsta',
          route: {
            title: 'Identification',
            path: ROUTES.MemberProfileIdentificationHampsta,
            pathname: 'identification_hampsta',
            className: '',
          },
        },
        {
          module: 'Identification Generic',
          route: {
            title: 'Identification',
            path: ROUTES.MemberProfileIdentificationHampsta,
            pathname: 'identification_generic',
            className: '',
          },
        },
        {
          module: 'History',
          route: {
            title: 'History',
            path: ROUTES.MemberProfileHistory,
            pathname: 'history',
            className: '',
          },
        },
        {
          module: 'Bank Accounts',
          route: {
            title: 'Bank Accounts',
            path: ROUTES.MemberProfileBankAccounts,
            pathname: 'bank_accounts',
            className: '',
          },
        },
        {
          module: 'Direct Payments',
          route: {
            title: 'Direct Payments',
            path: ROUTES.MemberProfileDirectPayments,
            pathname: 'direct_payments',
            className: '',
          },
        },
      ],
    },
  },
]

export const MEMBERTABSNAVIGATION = [
  ...accessControlSubNavigation(
    projectClient,
    MemberTabModulesData.filter((item) => !['more'].includes(item.module)),
    'member'
  ),
  ...MemberMoreTabModulesData,
]

const moreTabs = MemberMoreTabModulesData.filter((item) =>
  ['More'].includes(item.module)
).map((item) => item.route.children)[0]

export const MEMBERMORETAB = MemberMoreTabModulesData

export const MEMBERMORETABSNAVIGATION = accessControlSubNavigation(
  projectClient,
  moreTabs?.length ? moreTabs : [],
  'member'
)

export const FinancialNavData = [
  {
    title: 'Bank Accounts',
    key: 'financial-bank-accounts',
  },
  {
    title: 'Direct Payment Instructions',
    key: 'financial-direct-payment',
  },
]
