import React, { Dispatch, PropsWithChildren, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import Currency from 'components/ui/Currency'
import { MerchantTransactionTypes } from 'types/merchant'

interface PropsTypes {
  transaction: MerchantTransactionTypes
  selectedMerchantTransactionSet: Dispatch<MerchantTransactionTypes>
  isMerchantTransactionEditSet: Dispatch<boolean>
}

const DeviceItem = ({
  transaction,
  selectedMerchantTransactionSet,
  isMerchantTransactionEditSet,
}: PropsWithChildren<PropsTypes>): React.ReactElement => {
  const parseTimeZero = (t: number): string => (t < 10 ? `0${t}` : `${t}`)
  const parseTime = (dateString: string, type: 'date' | 'time'): string => {
    const date = new Date(dateString)
    if (type === 'date')
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    // return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED)
    else
      return `${parseTimeZero(date.getHours())}:${parseTimeZero(
        date.getMinutes()
      )}:${parseTimeZero(date.getSeconds())}`
    // return DateTime.fromISO(dateString).toLocaleString(DateTime.TIME_SIMPLE)
  }

  return (
    <>
      <tr className="py-2 text-black bg-white border-b-2 cursor-pointer hover:bg-gray-600">
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          {transaction.countryCode && (
            <img
              // src={`https://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/${transaction.countryCode?.toLowerCase()}.svg`}
              // src={`https://www.countryflagicons.com/FLAT/64/${transaction.countryCode?.toUpperCase()}.png`}
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${transaction.countryCode?.toUpperCase()}.svg`}
              alt={transaction.countryCode}
              className="w-8 bg-cover"
            />
          )}
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <p className="tracking-wider">
            {transaction.transactionDate
              ? parseTime(transaction.transactionDate, 'date')
              : '---'}
          </p>
          <p className="tracking-wider">
            {transaction.transactionDate
              ? parseTime(transaction.transactionDate, 'time')
              : '---'}
          </p>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{transaction.transactionId}</span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{transaction.ident}</span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">
            {transaction.externalTransactionId}
          </span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{transaction.storeName}</span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{transaction.transactionType}</span>
        </td>
        <td className="py-2 pl-6">
          <p className="font-bold">
            {transaction.creditValue.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            <Currency countryCode={transaction.countryCode} />
          </p>
        </td>
        <td className="py-2 pl-6">
          <p className="font-bold">
            {transaction.loyaltyValue.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </p>
        </td>
        <td className="py-2 pl-6">
          <p className="font-bold">
            {transaction.purchaseValue.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            <Currency countryCode={transaction.countryCode} />
          </p>
        </td>
      </tr>
    </>
  )
}

export default DeviceItem
