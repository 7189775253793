import { getGroupDetails } from 'api/subsquad'
import { SubsquadData, SubsquadDetail } from 'types/subsquad'
import create from 'zustand'

export interface SubsquadModel {
  activeGroup: string
  subsquad: SubsquadData | undefined

  getSubSquad: (groupId: string) => void
  setSubSquad: (subsquad: SubsquadData) => void
  setActiveGroup: (activeGroup: string) => void
}

const useSubSquadStore = create<SubsquadModel>((set) => ({
  activeGroup: '6338451027393235',
  subsquad: undefined,

  getSubSquad: (groupId: string) => {
    // call api
    getGroupDetails(groupId)
      .then((subsquad: SubsquadData) => {
        // set member
        set({
          subsquad,
        })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  },

  setSubSquad: (subsquad: SubsquadData) => {
    set({
      subsquad,
    })
  },

  setActiveGroup: (activeGroup: string) => {
    set({
      activeGroup,
    })
  },
}))

export default useSubSquadStore
