import React, { useState } from 'react'
import TabTitle from './TabTitle'

type Props = {
  children: React.ReactElement[]
}

function Tabs({ children }: Props): React.ReactElement {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div>
      <ul>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            count={item.props.count}
          />
        ))}
      </ul>
      {children[selectedTab]}
    </div>
  )
}

export default Tabs
