import { resetPassword } from 'api/members'
import Colors from 'colors.json'
import Button from 'components/Button'
import Input from 'components/Input'
import Modal from 'components/Modal'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { MemberDetail } from 'types/member'
import { MemberProfilePDF } from 'components/PDF'
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'
import useMemberStore from 'store/member'

interface MemberProfileDocProps {
  open: boolean
  onClose: () => void
  
}

const ModalMemberProfileSaveToPDF = ({
  open,
  onClose,
  
}: MemberProfileDocProps): JSX.Element => {
  const member = useMemberStore((state) => state.member)

  if (!member) return <></>

  return (
    <Modal open={open} title="Download to PDF" onClose={onClose} noFooter>
      <p className="pb-4">
        <span className="tracking-wider font-light">
          You are about to download member profile.
        </span>
      </p>

      {/* <PDFViewer width={'100%'} height={'500px'}>
        <MemberProfilePDF member={member} />
      </PDFViewer> */}

      <footer className="flex justify-end pb-4 pl-4 pr-4">
        <Button type="submit" className="mr-2 rounded bg-primary">
          <PDFDownloadLink
            document={<MemberProfilePDF member={member} />}
            fileName={`${member.firstName}-${member.lastName}-MemberProfile.pdf`}
          >
            {({ blob, url, loading, error }) => {
              return loading ? (
                <span className="tracking-wider font-bold">Loading ...</span>
              ) : (
                <span className="tracking-wider font-bold">Download PDF</span>
              )
            }}
          </PDFDownloadLink>
        </Button>
        <Button
          className="bg-white border rounded border-primary"
          onClick={onClose}
          style={{ color: Colors.primary }}
        >
          <span className="tracking-wider font-bold">Cancel</span>
        </Button>
      </footer>
    </Modal>
  )
}

export default ModalMemberProfileSaveToPDF
