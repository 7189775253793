import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { HistoryFetchParamsSchemas } from 'schemas/historySchemas'
import { HistoryFetchParamsTypes } from 'types/histories'

export const useMemberHistoryFilterForm = ({
  defaultValues,
}: {
  defaultValues: HistoryFetchParamsTypes
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<HistoryFetchParamsTypes>({
    resolver: zodResolver(HistoryFetchParamsSchemas),
    defaultValues,
  })

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
  }
}
