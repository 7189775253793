import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { getIdentificationsTypesAPI } from 'api/identifications'
import { useIdentificationsTypesStore } from 'store/identifications'
import { DropDownItem } from 'types/components'

export const useMemberIdentificationsTypes = () => {
  const identificationsTypesModStatus = useIdentificationsTypesStore(
    (state) => state.identificationsTypesModStatus
  )
  const identificationsTypesModStatusSet = useIdentificationsTypesStore(
    (state) => state.identificationsTypesModStatusSet
  )
  const identificationsTypesModErrors = useIdentificationsTypesStore(
    (state) => state.identificationsTypesModErrors
  )
  const identificationsTypesModErrorsSet = useIdentificationsTypesStore(
    (state) => state.identificationsTypesModErrorsSet
  )
  const identificationsTypes = useIdentificationsTypesStore(
    (state) => state.identificationsTypes
  )
  const identificationsTypesSet = useIdentificationsTypesStore(
    (state) => state.identificationsTypesSet
  )

  const {
    data: dataIdentificationsTypes,
    isLoading: isLoadingIdentificationsTypes,
    isError: isErrorIdentificationsTypes,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useIdentificationsTypes'],
    queryFn: async () => {
      try {
        identificationsTypesModStatusSet('loading')
        const response = await getIdentificationsTypesAPI()
        identificationsTypesModStatusSet('success')
        return response
      } catch (e) {
        identificationsTypesModStatusSet('fail')
        identificationsTypesModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataIdentificationsTypes)
      identificationsTypesSet(dataIdentificationsTypes)
  }, [dataIdentificationsTypes, identificationsTypesSet])

  const identificationsTypesOptions: DropDownItem[] = useMemo(() => {
    return identificationsTypes.map((item) => ({
      label: String(item.typeName),
      value: String(item.typeId),
    }))
  }, [identificationsTypes])

  return {
    memberIdentificationsTypesOptions: identificationsTypesOptions,
    memberIdentificationsTypes: identificationsTypes,
    memberIdentificationsTypesModStatus: identificationsTypesModStatus,
    memberIdentificationsTypesModErrors: identificationsTypesModErrors,
  }
}
