import { useCallback, useEffect, useMemo, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { FormikHelpers } from 'formik'

import { TRANSACTION_TYPES } from 'constants/filters'
import { formatDateToShort } from 'services/helpers'
import {
  MerchantTransactionFilterFormTypes,
  MerchantTransactionFilterFormSubmitValuesTypes,
} from 'types/merchant'
import useMerchantStore from 'store/merchant'

const FILTERMERCHANTTRANSACTIONINITVAL: MerchantTransactionFilterFormTypes = {
  transactionType: TRANSACTION_TYPES[0],
  startDateRange: new Date(),
  endDateRange: new Date(),
}

const useTransactionsFilter = ({
  merchantTraderId,
}: {
  merchantTraderId: string
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [selectedMerchant, setSelectedMerchant] =
    useState<OptionTypeBase | null>(null)
  const [merchantId, setMerchantId] = useState<number>()
  const [filterStartDate, setFilterStartDate] = useState<string>()
  const [filterEndDate, setFilterEndDate] = useState<string>()
  const [transactionTypeId, setTransactionTypeId] = useState<number>()
  const [initialValues, initialValuesSet] =
    useState<MerchantTransactionFilterFormTypes>({
      ...FILTERMERCHANTTRANSACTIONINITVAL,
    })
  const merchantTransactionLoading = useMerchantStore(
    (state) => state.merchantTransactionLoading
  )
  const merchantTransactionStatus = useMerchantStore(
    (state) => state.merchantTransactionStatus
  )
  const merchantTransaction = useMerchantStore(
    (state) => state.merchantTransaction
  )
  const merchantTransactionBackup = useMerchantStore(
    (state) => state.merchantTransactionBackup
  )
  const getMerchantTransactionFiltered = useMerchantStore(
    (state) => state.getMerchantTransactionFiltered
  )
  const getMerchantTransaction = useMerchantStore(
    (state) => state.getMerchantTransaction
  )

  const isFilteredTransactions = useMemo(() => {
    return merchantTransactionBackup.length ? true : false
  }, [merchantTransactionBackup])
  const transactionsLoading = useMemo(() => {
    return merchantTransactionLoading || false
  }, [merchantTransactionLoading])

  // start: Transaction filter handler
  const handleTransactionFilter = useCallback(
    async (
      values: MerchantTransactionFilterFormSubmitValuesTypes,
      actions: FormikHelpers<MerchantTransactionFilterFormSubmitValuesTypes>
    ) => {
      startDate
        ? setFilterStartDate(formatDateToShort(startDate || new Date()))
        : null
      endDate
        ? setFilterEndDate(formatDateToShort(endDate || new Date()))
        : null
      setTransactionTypeId(parseInt(values.transactionType.value))
      const queryStartDate = startDate
        ? formatDateToShort(startDate || new Date())
        : undefined
      const queryEndDate = endDate
        ? formatDateToShort(endDate || new Date())
        : undefined
      const queryTransactionTypeId =
        values.transactionType.value === '0'
          ? undefined
          : Number(values.transactionType.value)
      await getMerchantTransactionFiltered(Number(merchantTraderId), {
        transactionTypeId: queryTransactionTypeId,
        startDate: queryStartDate,
        endDate: queryEndDate,
      })
      actions.setSubmitting(false)
    },
    [
      selectedMerchant,
      startDate,
      endDate,
      setMerchantId,
      setFilterStartDate,
      setFilterEndDate,
      setTransactionTypeId,
      getMerchantTransactionFiltered,
    ]
  )

  const handleTransactionFilterClear = useCallback(async () => {
    await getMerchantTransaction(Number(merchantTraderId))
  }, [getMerchantTransaction])

  return {
    handleTransactionFilter,
    handleTransactionFilterClear,
    merchantId,
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
    transactionTypeId,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedMerchant,
    setSelectedMerchant,
    initialValues,
    isFilteredTransactions,
    transactionsLoading,
  }
}

export default useTransactionsFilter
