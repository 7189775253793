import { FC } from 'react'

interface Props {
  title: string
}

const InnerWrapper: FC<Props> = ({ title, children }) => (
  <div className="w-full p-6 bg-white">
    <h3 className="mb-6">
      <span className="tracking-wider font-bold text-xl">{title}</span>
    </h3>
    {children}
  </div>
)

export default InnerWrapper
