import { Link } from 'react-router-dom'

import Button from 'components/Button'
import { useSubsquadInfos } from './useSubsquadInfos'

const Index = (): JSX.Element => {
  const { memberSubsquads } = useSubsquadInfos()

  return (
    <>
      {memberSubsquads.map((item) => (
        <div key={item.groupId} className="flex flex-col gap-2">
          <p className="text-xs uppercase font-light tracking-wider">
            Subsquad URN
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item?.groupUrn || '---'}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">
            Subsquad Group
          </p>
          <p className="flex gap-2 items-center tracking-wider">
            {item.groupName || '---'}
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase pt-2">
            <Link to={`/subsquad-members/${item.groupUrn}/${item.groupId}`}>
              <Button
                key="button"
                iconRight={false}
                type="submit"
                className="rounded bg-primary tracking-wider font-bold"
              >
                To Subsquad
              </Button>
            </Link>
          </p>
        </div>
      ))}
    </>
  )
}

export default Index
