import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { BankThorDataTypes } from 'types/bankThor'

interface BankThorStoreTypes {
  bankThorModErrors: StatusErrorTypes
  bankThorModErrorsSet: (bankThorModErrors: StatusErrorTypes) => void
  bankThorModStatus: StatusTypes
  bankThorModStatusSet: (bankThorStatus: StatusTypes) => void
  bankThor: BankThorDataTypes[]
  bankThorSet: (bankThor: BankThorDataTypes[]) => void
  bankThorLookup: BankThorDataTypes[]
  bankThorLookupSet: (bankThor: BankThorDataTypes[]) => void
}

export const useBankThorStore = create<BankThorStoreTypes>((set) => ({
  bankThorModErrors: '',
  bankThorModErrorsSet: (bankThorModErrors) => {
    set({
      bankThorModErrors,
    })
  },
  bankThorModStatus: 'idle',
  bankThorModStatusSet: (bankThorModStatus) => {
    set({
      bankThorModStatus,
    })
  },
  bankThor: [],
  bankThorSet: (bankThor) => {
    set({
      bankThor,
    })
  },
  bankThorLookup: [],
  bankThorLookupSet: (bankThorLookup) => {
    set({
      bankThorLookup,
    })
  },
}))
