import { getMerchantAPI, getMerchants } from 'api/merchant'
import NoResults from 'components/NoResults'
import SearchBar from 'components/SearchBar'
import Spinner from 'components/Spinner'
import { isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { MerchantLookupTypes } from 'types/merchanTxTypes'
import ResultTable from './ResultTable'
import MerchantButtons from './Buttons'

// const perPage = 10

function MemberLookup(): React.ReactElement {
  // const [showNoResults, setShowNoResults] = useState(false)
  const [loading, setLoading] = useState(false)

  // const [offset, setOffset] = useState(0)
  // const [perPage] = useState(10)
  // const [pageCount, setPageCount] = useState(0)
  const [postData, setPostData] = useState<MerchantLookupTypes[] | null>(null)

  // const [searchValues, setSearchValues] = useState(undefined)
  // const [, setCloseForm] = useState(() => {})

  // const [members, setMembers] = useState<MemberData[] | null>(null)

  const fetchMerchants = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (dataArray: any): Promise<void> => {
      if (!dataArray) {
        return
      }

      // setSearchValues(dataArray)

      try {
        await getMerchantAPI(
          dataArray.id || dataArray.traderId,
          dataArray.name || dataArray.merchantName,
          dataArray.mcode || dataArray.merchantCode
        )
          .then((list) => {
            // setMembers(list)
            // if (list.length === 0) {
            //   setShowNoResults(true)
            // }

            // const slice = list.slice(offset, offset + perPage)
            setPostData(list)
            // setPageCount(Math.ceil(list.length / perPage))
          })
          .finally(() => {
            setLoading(false)
          })
      } catch (error) {
        // error.message
        toast.error(
          'No result found. Try adjusting search or filter to find what you are looking for.'
        )
      }
    },
    []
  )

  // useEffect(() => {
  //   fetchMembers(searchValues)
  // }, [fetchMembers, searchValues])

  return (
    <div className="p-12 bg-white content-container">
      <div className="flex flex-col lg:flex-row lg:justify-between gap-2">
        <div>
          <h3>
            <span className="tracking-wider font-bold text-xl">Merchant</span>
          </h3>
          <p>
            <span className="tracking-wider font-light">
              Look up for merchant using merchant Trader ID / Name
            </span>
          </p>
          <p className="text-gray-500">
            <span className="tracking-wider font-light text-xs">
              * Use the searchbar to lookup for merchant information
            </span>
          </p>
          <br />
          <p>
            <span className="tracking-wider font-light">
              or create a new merchant
            </span>
          </p>
          <br />
          <p>
            <MerchantButtons />
          </p>
        </div>

        <div className="w-3/4 lg:w-1/2">
          <SearchBar
            placeholder="Search for merchant ..."
            startFetch={fetchMerchants}
            startLoading={() => setLoading(true)}
            stopLoading={() => setLoading(false)}
            closeSearchForm={() => {
              return
            }}
            isMerchantLookup
          />
        </div>
      </div>

      <div className="my-6">
        {loading ? (
          <Spinner />
        ) : postData == null ? (
          <></>
        ) : isEmpty(postData) ? (
          <NoResults noImage />
        ) : (
          <ResultTable postData={postData} />
        )}
      </div>
    </div>
  )
}

export default MemberLookup
