import { getSubsquadMembers } from 'api/subsquad'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import { isUndefined } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useSubSquadStore from 'store/subsquad'
import useSWR from 'swr'
import { SubsquadMember } from 'types/subsquad'
import SubsquadProfile from 'views/SubsquadProfile'

function Members(): React.ReactElement {
  const [groupMemberFetchLimitError, setGroupMemberFetchError] = useState(false)

  const subsquad = useSubSquadStore((state) => state.subsquad)
  const activeGroup = useSubSquadStore((state) => state.activeGroup)

  const columns = [
    'Trader Id',
    'Membership Id',
    'Name',
    'Earned Points',
    'Note',
    'Start Date',
    'End Date',
  ]

  const { data: groupMembers, isValidating: isMemberValidating } = useSWR(
    subsquad
      ? ['/squad-groups/members', subsquad.groupId, activeGroup, 'active']
      : null,
    (_, groupId, cardNumber, status) =>
      getSubsquadMembers(groupId, cardNumber, status),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 5 times.
        if (retryCount && retryCount >= 5) {
          if (groupMembers) setGroupMemberFetchError(true)

          return
        }
        // Retry after 3 seconds.
        setTimeout(
          () => revalidate({ retryCount: (retryCount as number) + 1 }),
          3000
        )
      },
    }
  )

  useEffect(() => {
    isMemberValidating && setGroupMemberFetchError(false)
  }, [isMemberValidating])

  return (
    <SubsquadProfile>
      {groupMemberFetchLimitError ? (
        <h2>
          Failed to load data. Please refresh the page and try again, or contact
          your system administrator.
        </h2>
      ) : (
        <>
          {isUndefined(groupMembers) ? (
            <div className="flex justify-center w-screen m-auto my-4 place-self-center">
              <Spinner />
            </div>
          ) : (
            <>
              <h3 className="mb-3">Members</h3>
              <Table
                columns={columns}
                data={groupMembers}
                pageCount={10}
                currentPage={1}
                paginate={false}
                onPageChange={() => null}
              >
                {groupMembers.map((member: SubsquadMember, index: number) => (
                  <tr
                    key={index}
                    className="py-2 text-black bg-white border-b-2 cursor-pointer hover:bg-gray-600"
                    onClick={() => null}
                  >
                    <td className="py-2 pl-6">
                      <Link
                        to={`/member-profile/${member.traderId}`}
                        className="underline text-blue"
                      >
                        {member.traderId}
                      </Link>
                    </td>
                    <td className="py-2 pl-6">
                      <p>{member.membershipId}</p>
                    </td>
                    <td className="py-2 pl-6">
                      <p>{member.name}</p>
                    </td>
                    <td className="py-2 pl-6">
                      <p>{member.earnedPoints}</p>
                    </td>
                    <td className="py-2 pl-6">
                      <p>{member.note}</p>
                    </td>
                    <td className="py-2 pl-6">
                      <p>{member.startDate}</p>
                    </td>
                    <td className="py-2 pl-6">
                      <p>{member.endDate}</p>
                    </td>
                  </tr>
                ))}
              </Table>
            </>
          )}
        </>
      )}
    </SubsquadProfile>
  )
}

export default Members
