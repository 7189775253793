import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import Modals from './Modals'
import MerchantProfileInfo from './MerchantProfileInfo'
import MerchantExternalInfo from './MerchantExternalInfo'
import MerchantStatusInfo from './MerchantStatusInfo'

const SideBar = (): JSX.Element => {
  return (
    <>
      <div className="flex flex-col min-w-full p-6">
        <Link
          to="/merchant-lookup"
          className="mb-6 text-xs text-gray-500 uppercase"
        >
          <Icon icon={faChevronLeft} /> &nbsp; Back to search
        </Link>
        <div className="flex justify-between">
          <MerchantProfileInfo />
          <Modals />
        </div>
        <MerchantExternalInfo />
        <MerchantStatusInfo />
      </div>
    </>
  )
}

export default SideBar
