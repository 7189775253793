import React from 'react'
import { useParams } from 'react-router-dom'
import Colors from 'colors.json'

import { faSearch, faRemoveFormat } from '@fortawesome/free-solid-svg-icons'
import Input from 'components/Input'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import { Form, Formik, FormikHelpers } from 'formik'

import { ProfileUpdateStatusSchemas } from 'schemas/profileUpdateStatusSchemas'
import { useProfileUpdate } from './useUpdateStatus'
import { MEMBER_GENDER } from 'constants/filters'
import { DropDownItem } from 'types/components'

const Index: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}): React.ReactElement => {
  // STATES
  const { traderId } = useParams()
  const {
    member,
    memberStatuses,
    isLoading,
    initialValues,
    handleSubmitStatusUpdate,
    handleClearProfileUpdate,
  } = useProfileUpdate(Number(traderId))

  if (isLoading) return <></>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ProfileUpdateStatusSchemas}
      onSubmit={handleSubmitStatusUpdate}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <div className="mb-4" style={{ height: '250px' }}>
              <Dropdown
                title="Status"
                defaultValue={{
                  label: member?.memberThorStatusName || '',
                  value: String(member?.memberThorStatusId),
                }}
                options={memberStatuses.map((status) => ({
                  label: status.statusName,
                  value: String(status.statusId),
                }))}
                borderColor="#C4C4C4"
                onSelect={(item) => {
                  setFieldValue('newStatusId', item.value)
                }}
              />
            </div>

            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Confirm</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onCloseModal}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Index
