import { z as zod } from 'zod'

export const financialUpdateBankAccountTxSchemas = zod.object({
  traderId: zod.number(),
  accountStatusId: zod.string().min(1, { message: 'Status is Required.' }),
  note: zod.string(),
  bankAccountId: zod.number().min(1, { message: 'Account ID is Required.' }),
  bankAccountName: zod
    .string()
    .min(1, { message: 'Account Name is Required.' }),
})
