import { countryIsoCodeData } from 'constants/countries'

const Index: React.FC<{ countryCode: string | undefined | null }> = ({
  countryCode,
}) => {
  if (!countryCode) return <></>

  return (
    <span className="px-2 font-light">
      <small>
        {
          countryIsoCodeData.filter(
            (item) =>
              item.countryCode.toUpperCase() === countryCode.toUpperCase()
          )[0].currency
        }
      </small>
    </span>
  )
}

export default Index
