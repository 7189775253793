import * as Yup from 'yup'
import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const NotesDefaultSchemas = DefaultSchemas

export const NotesDataSchemas = zod.object({
  noteId: zod.number(),
  noteType: zod.string(),
  noteDescription: zod.string().nullable(),
  sourceName: zod.string().nullable(),
  username: zod.string().nullable(),
  priorityLevel: zod.string().nullable(),
  lastModifiedDate: zod.string(),
})

export const NotesAPISchemas = NotesDefaultSchemas.merge(
  zod.object({
    notes: zod.array(NotesDataSchemas),
  })
)

export const NotesFetchParamsSchemas = zod.object({
  traderId: zod.number(),
  startDate: zod.string().optional(),
  endDate: zod.string().optional(),
  sourceName: zod.string().optional(),
  noteType: zod.string().optional(),
  priorityLevel: zod.string().optional(),
  pSize: zod.number().optional(),
  pNumber: zod.number().optional(),
})

export const NotesUpdateParamsSchemas = zod.object({
  traderId: zod.number(),
  noteId: zod.number(),
  noteTypeId: zod.string(),
  priorityLevel: zod.string(),
  noteDescription: zod.string(),
  creationDate: zod.string(),
  sourceName: zod.string(),
})