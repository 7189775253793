import { useCallback, useEffect, useState } from 'react'

import { faBell, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import avatar from 'assets/images/avatar-profile.png'
import IconDropdown from 'components/IconDropdown'
import { ROUTES } from 'constants/routing'
import { useNavigate } from 'react-router'
import { useCognito } from 'services/amplify'

import SignalNotifictionNav from './component/SignalNotificationNav'

import packageJSON from '../../../package.json'

const RightNav = (): JSX.Element => {
  const [username, usernameSet] = useState<string>('')
  const navigate = useNavigate()
  const cognito = useCognito()

  const handleUser = useCallback(async () => {
    const session = await cognito.getSession()
    if (session) {
      const user = session.getIdToken()
      usernameSet(user.payload['cognito:username'])
    }
  }, [cognito, usernameSet])

  useEffect(() => {
    handleUser()
  }, [])

  return (
    <ul className="flex items-center">
      <li>
        <small className="text-white">v {packageJSON.version}</small>
      </li>
      <li className="mx-2 relative">
        <SignalNotifictionNav instance="subway">
          <Icon icon={faBell} color="white" />
        </SignalNotifictionNav>
      </li>
      <li className="mx-2 flex gap-2 items-center">
        <span className="text-white tracking-wider">{username}</span>
        <img
          src={avatar}
          alt="User Avatar"
          style={{ borderRadius: '50%', width: '30px', height: '30px' }}
        />
      </li>
      <li className="mx-2">
        <IconDropdown
          icon={faEllipsisV}
          options={[{ label: 'Logout', value: 'logout' }]}
          itemClassName="w-40 min-w-full"
          right
          onSelect={(value) => {
            if (value === 'logout') {
              navigate(ROUTES.Login.path)
              cognito.signOut()
            }
          }}
        />
      </li>
    </ul>
  )
}

export default RightNav
