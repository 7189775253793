import { useState } from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import IconDropdown from 'components/IconDropdown'
import useMerchantStore from 'store/merchant'
import { accessControlStatus, AccessStatusTypes } from 'setups/controller'
import { MERCHANTMODALNAVIGATION } from 'constants/profileModalMerchant'

const Buttons = (): JSX.Element => {
  const [showTransactionModal, showTransactionModalSet] = useState<{
    open: boolean
    key: string
  }>({ open: false, key: '' })

  // START: CHECK WITH ACCESS STATUS
  const merchantProfile = useMerchantStore((state) => state.merchantProfile)
  // if (!merchantProfile.length) return <></>
  const MERCHANTTABSNAVIGATION_FILTERED = accessControlStatus(
    MERCHANTMODALNAVIGATION,
    'merchant',
    merchantProfile[0]?.memberThorStatus as AccessStatusTypes
  )
  // END: CHECK WITH ACCESS STATUS

  const OPTIONS: { label: string; value: string }[] =
    MERCHANTTABSNAVIGATION_FILTERED.map((item) => ({
      label: item.label || '',
      value: item.value || '',
    }))

  return (
    <>
      <div className="z-5 flex justify-end flex-grow pb-2">
        <IconDropdown
          title={<span className="tracking-wider font-light">Add Details</span>}
          icon={faPlus}
          iconClassName="text-primary"
          iconPosition="left"
          options={OPTIONS}
          toggleClassName="py-1 px-3 rounded-md text-primary border border-primary mx-1"
          itemClassName="py-1"
          menuClassName="border-gray-500 shadow-md px-2 py-3"
          displayArrowDown
          onSelect={(value) =>
            showTransactionModalSet({
              open: true,
              key: value,
            })
          }
        />
      </div>

      {MERCHANTTABSNAVIGATION_FILTERED.map(({ label, value, Modal }) => {
        if (Modal)
          return (
            <Modal
              key={value}
              title={`Add Merchant ${label}`}
              open={
                showTransactionModal.key === value
                  ? showTransactionModal.open
                  : false
              }
              onClose={() =>
                showTransactionModalSet({
                  open: false,
                  key: value || '',
                })
              }
            />
          )
      })}
    </>
  )
}

export default Buttons
