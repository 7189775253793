import { MODULES } from './modules'
import { CLIENTS } from './clients'

export const ACCESS_CONFIG = {
  application: {
    types: ['loyalty, generic'],
    modules: MODULES,
    clients: CLIENTS,
  },
}
