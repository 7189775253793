import { createNote, getSourceNames } from 'api/notes'
import Colors from 'colors.json'
import Button from 'components/Button'
import Dropdown, { OptionType } from 'components/Dropdown'
import Input from 'components/Input'
import Modal from 'components/Modal'
import { NOTE_TYPE_OPTIONS, PRIORITY_LEVEL_OPTIONS } from 'constants/filters'
import { Form, Formik } from 'formik'
import { isUndefined } from 'lodash'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useSWR from 'swr'
import { APIResponse } from 'types/app'
import { DropDownItem } from 'types/components'
import { SourceName } from 'types/notes'
import { useQueryClient } from 'react-query'

type FormValues = {
  sourceName: DropDownItem
  noteType: DropDownItem
  priorityLevel: DropDownItem
  noteDescription: string
}

interface Props {
  open: boolean
  onClose: () => void
}

const ModalAddNote = ({ open, onClose }: Props): JSX.Element => {
  const { traderId } = useParams()
  const queryClient = useQueryClient()

  const initialValues: FormValues = {
    sourceName: { value: '---', label: '---' },
    noteType: { value: 'default', label: '---' },
    priorityLevel: PRIORITY_LEVEL_OPTIONS[0],
    noteDescription: '',
  }

  const [, setSourceNameFetchLimitError] = useState(false)

  const { data: sourcenames } = useSWR(
    ['/notes/sourcename'],
    (_) => getSourceNames(),
    {
      onErrorRetry: (_error, _key, _config, revalidate, { retryCount }) => {
        // Only retry up to 5 times.
        if (retryCount && retryCount >= 5) {
          if (sourcenames) setSourceNameFetchLimitError(true)
          return
        }
        // Retry after 3 seconds.
        setTimeout(
          () => revalidate({ retryCount: (retryCount as number) + 1 }),
          3000
        )
      },
    }
  )

  const sourceNamesList = isUndefined(sourcenames)
    ? null
    : sourcenames.map((sourceName: SourceName) => ({
        value: sourceName.id,
        label: sourceName.sourceName,
      }))

  const filterSourceName = (inputValue: string): string =>
    sourceNamesList?.filter((i: DropDownItem) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )

  const loadSourceName = (
    inputValue: string,
    callback: (inputValue: string) => void
  ): void => {
    setTimeout(() => {
      callback(filterSourceName(inputValue))
    }, 1000)
  }

  if (!traderId) {
    return <>Failed to load data</>
  }

  return (
    <Modal open={open} title="Add Note" onClose={onClose} noFooter>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            await createNote(parseInt(traderId), {
              noteTypeId: parseInt(values.noteType.value),
              priorityLevel: values.priorityLevel.label,
              sourceName: values.sourceName.label,
              noteDescription: values.noteDescription,
            })
            onClose()
            toast.success('Note created!')
            queryClient.invalidateQueries({ queryKey: ['useNotesDashboard'] })
            queryClient.invalidateQueries({ queryKey: ['useNotes'] })
            actions.setSubmitting(false)
          } catch (error) {
            if (error instanceof Error) {
              toast.error(error.message)
              return
            }

            toast.error((error as APIResponse).outcomeUserMessage)
          }
        }}
      >
        {(formikProps) => {
          const { values, isSubmitting, setFieldValue } = formikProps
          return (
            <Form>
              <Dropdown
                title="Source Name"
                value={values.sourceName}
                loadOptions={loadSourceName}
                onChange={(value: OptionType) => {
                  setFieldValue('sourceName', value)
                }}
                className="w-full mb-5"
                borderColor="#C4C4C4"
                containerClassName="focus:border-primary"
                placeholder="Search for source name..."
                isClearable
                isSearchable
                async
              />
              <Dropdown
                title="Note Type:"
                options={NOTE_TYPE_OPTIONS}
                value={values.noteType}
                className="mb-5"
                borderColor="#C4C4C4"
                onSelect={(item: DropDownItem) => {
                  setFieldValue('noteType', item)
                }}
                disabled={isSubmitting}
                roundedCorners
              />
              <Dropdown
                title="Priority Level:"
                options={PRIORITY_LEVEL_OPTIONS}
                value={values.priorityLevel}
                className="mb-5"
                borderColor="#C4C4C4"
                onSelect={(item: DropDownItem) => {
                  setFieldValue('priorityLevel', item)
                }}
                disabled={isSubmitting}
                roundedCorners
              />
              <Input
                name="noteDescription"
                type="text"
                label="Note *"
                rounded
                component="textarea"
              />
              <footer className="flex justify-end pb-4 pl-4 pr-4">
                <Button
                  type="submit"
                  className="mr-2 rounded bg-primary"
                  disabled={isSubmitting}
                >
                  Confirm
                </Button>
                <Button
                  className="bg-white border rounded border-primary"
                  onClick={onClose}
                  style={{ color: Colors.primary }}
                >
                  Cancel
                </Button>
              </footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ModalAddNote
