import { useCallback, useState } from 'react'
import { faIdCard, faEye } from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import TSTable from 'components/Tanstack'
import { useMembershipsStore } from 'store/memberships'
import { useTableUtils } from 'hooks/useTableUtils'
import {
  MembershipsDataTypes,
  MembershipsModalActionsTypes,
} from 'types/memberships'

export const useMemberMembershipsTable = () => {
  const memberships = useMembershipsStore((state) => state.memberships)

  const {
    selectedModal,
    selectedModalSet,
    selectedRowData,
    selectedRowDataSet,
  } = useTableUtils<MembershipsDataTypes, MembershipsModalActionsTypes>()

  const handleActionModalClose = useCallback(() => {
    selectedRowDataSet(null)
    selectedModalSet(null)
  }, [selectedModalSet, selectedRowDataSet])

  const columnHelper = createColumnHelper<MembershipsDataTypes>()
  const columns = [
    columnHelper.accessor('groupId', {
      header: () => 'ID',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    // columnHelper.accessor('groupId', {
    //   header: () => 'Offer Name',
    //   cell: (info) => (
    //     <p className="flex flex-col">
    //       <span>{info.getValue()}</span>
    //       <small className="text-primary text-opacity-40">
    //         {info.row.original.groupId}
    //       </small>
    //     </p>
    //   ),
    //   // footer: (props) => props.column.id,
    // }),
    columnHelper.accessor('groupName', {
      header: () => 'Group Name',
      cell: (info) => (
        <p className="flex flex-col">
          <span>{info.getValue()}</span>
        </p>
      ),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('startDate', {
      header: () => 'Start Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('endDate', {
      header: () => 'End Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('groupType', {
      header: () => 'Group Type',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => '',
      // cell: (info) => info.getValue(),
      cell: ({ row }) => (
        <div className="flex gap-2 px-4">
          {/* <TSTable.TableCellButton
            onClick={() => {
              selectedMembershipsSet(row.original)
              selectedActionModalSet('preview')
            }}
            label="Preview"
            icon={faEye}
          /> */}
        </div>
      ),
    }),
  ] as ColumnDef<MembershipsDataTypes>[]

  return {
    memberMembershipsSelected: selectedRowData,
    memberMembershipsActionModal: selectedModal,
    memberMembershipsTableData: memberships,
    memberMembershipsTableColumns: columns,
    handleMemberActionModalClose: handleActionModalClose,
  }
}
