import { useNavigate, useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'

import IconDropdown from 'components/IconDropdown'
import { DropDownItem } from 'types/components'
import useMemberStore from 'store/member'
import { accessControlStatus, AccessStatusTypes } from 'setups/controller'
import {
  MEMBERTABSNAVIGATION,
  MEMBERMORETAB,
  MEMBERMORETABSNAVIGATION,
} from 'constants/profileNavMember'

const Tabs = (): JSX.Element => {
  const navigate = useNavigate()
  const { traderId } = useParams()
  // START: CHECK WITH ACCESS STATUS
  const memberProfile = useMemberStore((state) => state.member)
  const MEMBERTABSNAVIGATION_FILTERED = accessControlStatus(
    MEMBERTABSNAVIGATION,
    'member',
    memberProfile?.memberThorStatusName as AccessStatusTypes
  )
  const MEMBERTABSNAVIGATIONMORE_FILTERED = accessControlStatus(
    MEMBERMORETABSNAVIGATION,
    'member',
    memberProfile?.memberThorStatusName as AccessStatusTypes
  )
  // END: CHECK WITH ACCESS STATUS

  const MOREOPTIONS: DropDownItem[] = MEMBERTABSNAVIGATIONMORE_FILTERED.map(
    (item) => ({
      label: item.route.title,
      value: item.route.pathname || '',
    })
  )

  return (
    <div className="flex flex-grow">
      <nav className="flex flex-wrap z-1">
        {MEMBERTABSNAVIGATION_FILTERED.filter(
          (item) => item.module !== 'More'
        ).map((item, index) => (
          <NavLink
            key={'profile-' + index}
            className={({ isActive }) =>
              classnames(
                'px-3 py-2 text-gray-700 border-primary ',
                isActive ? 'border-b-2' : null
              )
            }
            // activeClassName="border-b-2"
            to={`/member-profile/${traderId}/${item.route.pathname}`}
          >
            <span className="tracking-wider uppercase font-light">
              {item.route.title}
            </span>
          </NavLink>
        ))}
        {MEMBERMORETAB.map((item, index) => (
          <div key={index} className="px-3 py-2 text-gray-700">
            <IconDropdown
              title={
                <span className="tracking-wider uppercase font-light">
                  {item.route.title}
                </span>
              }
              options={MOREOPTIONS}
              itemClassName={item.route.childClassName}
              menuClassName="shadow-md z-10"
              onSelect={(value) => {
                navigate(`/member-profile/${traderId}/${value}`)
              }}
            />
          </div>
        ))}
      </nav>
    </div>
  )
}

export default Tabs
