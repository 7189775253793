import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import Button from 'components/Button'
import Dropdown, { OptionType } from 'components/Dropdown'
import { TRANSACTION_STATUS, TRANSACTION_TYPES } from 'constants/filters'
import { Form, Formik } from 'formik'

import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useParams } from 'react-router-dom'
import { OptionTypeBase } from 'react-select'
import { formatDateToShort } from 'services/helpers'
import { DropDownItem } from 'types/components'

import useIdentificationFilter from './useIdentificationFilter'
import useTransactionsMerchantLookup from './useTransactionsMerchantLookup'

type DatePickerProps = React.InputHTMLAttributes<HTMLInputElement> & {
  value?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function Transactions(): React.ReactElement {
  const [isOptions, isOptionsSet] = useState<boolean>(false)
  const { traderId } = useParams()
  const { merchantLookupDropdownOptions } = useTransactionsMerchantLookup({
    memberTraderId: String(traderId),
  })
  const {
    handleIdentificationFilter,
    handleIdentificationFilterClear,
    initialValues,
    isFilteredIdentification,
    identificationLoading,
    merchantIdentificationStatuses,
    merchantIdentificationStatusesLoading,
    merchantIdentificationTypes,
    merchantIdentificationTypesLoading,
  } = useIdentificationFilter({ merchantTraderId: String(traderId) })

  const identStatusOptions: DropDownItem[] = useMemo(() => {
    return merchantIdentificationStatuses.map((item) => ({
      label: String(item.statusName),
      value: String(item.statusId),
    }))
  }, [merchantIdentificationStatuses])

  const identTypesOptions: DropDownItem[] = useMemo(() => {
    return merchantIdentificationTypes.map((item) => ({
      label: String(item.typeName),
      value: String(item.typeId),
    }))
  }, [merchantIdentificationTypes])

  return (
    <Formik initialValues={initialValues} onSubmit={handleIdentificationFilter}>
      {({ values, setFieldValue }) => (
        <Form>
          <div className="flex flex-wrap items-end mt-4 mb-5 gap-2">
            <Dropdown
              title="Identification Status"
              value={values.identificationStatus}
              options={identStatusOptions}
              onSelect={(option: DropDownItem) => {
                setFieldValue('identificationStatus', option)
              }}
              className="w-40"
              borderColor="#C4C4C4"
            />
            <Dropdown
              title="Identification Type"
              value={values.identificationType}
              options={identTypesOptions}
              onSelect={(option: DropDownItem) => {
                setFieldValue('identificationType', option)
              }}
              className="w-40"
              borderColor="#C4C4C4"
            />
            <Button
              type="submit"
              icon={faSearch}
              className="ml-1 rounded-lg"
              iconRight={false}
              disabled={identificationLoading ? true : false}
            >
              <span className="tracking-wider font-bold">Filter</span>
            </Button>

            {isFilteredIdentification ? (
              <Button
                type="reset"
                className="ml-2 rounded-lg"
                iconRight={false}
                onClick={handleIdentificationFilterClear}
                disabled={identificationLoading ? true : false}
              >
                <span className="tracking-wider font-bold">Clear</span>
              </Button>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Transactions
