import { displayBalanceCurrency } from 'setups/controller'

const projectClient = process.env.REACT_APP_PROJ

export const countryIsoCodeData = [
  { name: 'Afghanistan', countryCode: 'AF', symbol: '؋', currency: 'AFN' },
  { name: 'Aland Islands', countryCode: 'AX', symbol: '€', currency: 'EUR' },
  { name: 'Albania', countryCode: 'AL', symbol: 'Lek', currency: 'ALL' },
  { name: 'Algeria', countryCode: 'DZ', symbol: 'دج', currency: 'DZD' },
  { name: 'American Samoa', countryCode: 'AS', symbol: '$', currency: 'USD' },
  { name: 'Andorra', countryCode: 'AD', symbol: '€', currency: 'EUR' },
  { name: 'Angola', countryCode: 'AO', symbol: 'Kz', currency: 'AOA' },
  { name: 'Anguilla', countryCode: 'AI', symbol: '$', currency: 'XCD' },
  { name: 'Antarctica', countryCode: 'AQ', symbol: '$', currency: 'AAD' },
  { name: 'Antigua and Barbuda', countryCode: 'AG', symbol: '$', currency: 'XCD' },
  { name: 'Argentina', countryCode: 'AR', symbol: '$', currency: 'ARS' },
  { name: 'Armenia', countryCode: 'AM', symbol: '֏', currency: 'AMD' },
  { name: 'Aruba', countryCode: 'AW', symbol: 'ƒ', currency: 'AWG' },
  { name: 'Australia', countryCode: 'AU', symbol: '$', currency: 'AUD' },
  { name: 'Austria', countryCode: 'AT', symbol: '€', currency: 'EUR' },
  { name: 'Azerbaijan', countryCode: 'AZ', symbol: 'm', currency: 'AZN' },
  { name: 'Bahamas', countryCode: 'BS', symbol: 'B$', currency: 'BSD' },
  { name: 'Bahrain', countryCode: 'BH', symbol: '.د.ب', currency: 'BHD' },
  { name: 'Bangladesh', countryCode: 'BD', symbol: '৳', currency: 'BDT' },
  { name: 'Barbados', countryCode: 'BB', symbol: 'Bds$', currency: 'BBD' },
  { name: 'Belarus', countryCode: 'BY', symbol: 'Br', currency: 'BYN' },
  { name: 'Belgium', countryCode: 'BE', symbol: '€', currency: 'EUR' },
  { name: 'Belize', countryCode: 'BZ', symbol: '$', currency: 'BZD' },
  { name: 'Benin', countryCode: 'BJ', symbol: 'CFA', currency: 'XOF' },
  { name: 'Bermuda', countryCode: 'BM', symbol: '$', currency: 'BMD' },
  { name: 'Bhutan', countryCode: 'BT', symbol: 'Nu.', currency: 'BTN' },
  { name: 'Bolivia', countryCode: 'BO', symbol: 'Bs.', currency: 'BOB' },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    countryCode: 'BQ',
    symbol: '$',
    currency: 'USD',
  },
  { name: 'Bosnia and Herzegovina', countryCode: 'BA', symbol: 'KM', currency: 'BAM' },
  { name: 'Botswana', countryCode: 'BW', symbol: 'P', currency: 'BWP' },
  { name: 'Bouvet Island', countryCode: 'BV', symbol: 'kr', currency: 'NOK' },
  { name: 'Brazil', countryCode: 'BR', symbol: 'R$', currency: 'BRL' },
  {
    name: 'British Indian Ocean Territory',
    countryCode: 'IO',
    symbol: '$',
    currency: 'USD',
  },
  { name: 'Brunei Darussalam', countryCode: 'BN', symbol: 'B$', currency: 'BND' },
  { name: 'Bulgaria', countryCode: 'BG', symbol: 'Лв.', currency: 'BGN' },
  { name: 'Burkina Faso', countryCode: 'BF', symbol: 'CFA', currency: 'XOF' },
  { name: 'Burundi', countryCode: 'BI', symbol: 'FBu', currency: 'BIF' },
  { name: 'Cambodia', countryCode: 'KH', symbol: 'KHR', currency: 'KHR' },
  { name: 'Cameroon', countryCode: 'CM', symbol: 'FCFA', currency: 'XAF' },
  { name: 'Canada', countryCode: 'CA', symbol: '$', currency: 'CAD' },
  { name: 'Cape Verde', countryCode: 'CV', symbol: '$', currency: 'CVE' },
  { name: 'Cayman Islands', countryCode: 'KY', symbol: '$', currency: 'KYD' },
  {
    name: 'Central African Republic',
    countryCode: 'CF',
    symbol: 'FCFA',
    currency: 'XAF',
  },
  { name: 'Chad', countryCode: 'TD', symbol: 'FCFA', currency: 'XAF' },
  { name: 'Chile', countryCode: 'CL', symbol: '$', currency: 'CLP' },
  { name: 'China', countryCode: 'CN', symbol: '¥', currency: 'CNY' },
  { name: 'Christmas Island', countryCode: 'CX', symbol: '$', currency: 'AUD' },
  { name: 'Cocos (Keeling) Islands', countryCode: 'CC', symbol: '$', currency: 'AUD' },
  { name: 'Colombia', countryCode: 'CO', symbol: '$', currency: 'COP' },
  { name: 'Comoros', countryCode: 'KM', symbol: 'CF', currency: 'KMF' },
  { name: 'Congo', countryCode: 'CG', symbol: 'FC', currency: 'XAF' },
  {
    name: 'Congo, Democratic Republic of the Congo',
    countryCode: 'CD',
    symbol: 'FC',
    currency: 'CDF',
  },
  { name: 'Cook Islands', countryCode: 'CK', symbol: '$', currency: 'NZD' },
  { name: 'Costa Rica', countryCode: 'CR', symbol: '₡', currency: 'CRC' },
  { name: "Cote D'Ivoire", countryCode: 'CI', symbol: 'CFA', currency: 'XOF' },
  { name: 'Croatia', countryCode: 'HR', symbol: 'kn', currency: 'HRK' },
  { name: 'Cuba', countryCode: 'CU', symbol: '$', currency: 'CUP' },
  { name: 'Curacao', countryCode: 'CW', symbol: 'ƒ', currency: 'ANG' },
  { name: 'Cyprus', countryCode: 'CY', symbol: '€', currency: 'EUR' },
  { name: 'Czech Republic', countryCode: 'CZ', symbol: 'Kč', currency: 'CZK' },
  { name: 'Denmark', countryCode: 'DK', symbol: 'Kr.', currency: 'DKK' },
  { name: 'Djibouti', countryCode: 'DJ', symbol: 'Fdj', currency: 'DJF' },
  { name: 'Dominica', countryCode: 'DM', symbol: '$', currency: 'XCD' },
  { name: 'Dominican Republic', countryCode: 'DO', symbol: '$', currency: 'DOP' },
  { name: 'Ecuador', countryCode: 'EC', symbol: '$', currency: 'USD' },
  { name: 'Egypt', countryCode: 'EG', symbol: 'ج.م', currency: 'EGP' },
  { name: 'El Salvador', countryCode: 'SV', symbol: '$', currency: 'USD' },
  { name: 'Equatorial Guinea', countryCode: 'GQ', symbol: 'FCFA', currency: 'XAF' },
  { name: 'Eritrea', countryCode: 'ER', symbol: 'Nfk', currency: 'ERN' },
  { name: 'Estonia', countryCode: 'EE', symbol: '€', currency: 'EUR' },
  { name: 'Ethiopia', countryCode: 'ET', symbol: 'Nkf', currency: 'ETB' },
  {
    name: 'Falkland Islands (Malvinas)',
    countryCode: 'FK',
    symbol: '£',
    currency: 'FKP',
  },
  { name: 'Faroe Islands', countryCode: 'FO', symbol: 'Kr.', currency: 'DKK' },
  { name: 'Fiji', countryCode: 'FJ', symbol: 'FJ$', currency: 'FJD' },
  { name: 'Finland', countryCode: 'FI', symbol: '€', currency: 'EUR' },
  { name: 'France', countryCode: 'FR', symbol: '€', currency: 'EUR' },
  { name: 'French Guiana', countryCode: 'GF', symbol: '€', currency: 'EUR' },
  { name: 'French Polynesia', countryCode: 'PF', symbol: '₣', currency: 'XPF' },
  {
    name: 'French Southern Territories',
    countryCode: 'TF',
    symbol: '€',
    currency: 'EUR',
  },
  { name: 'Gabon', countryCode: 'GA', symbol: 'FCFA', currency: 'XAF' },
  { name: 'Gambia', countryCode: 'GM', symbol: 'D', currency: 'GMD' },
  { name: 'Georgia', countryCode: 'GE', symbol: 'ლ', currency: 'GEL' },
  { name: 'Germany', countryCode: 'DE', symbol: '€', currency: 'EUR' },
  { name: 'Ghana', countryCode: 'GH', symbol: 'GH₵', currency: 'GHS' },
  { name: 'Gibraltar', countryCode: 'GI', symbol: '£', currency: 'GIP' },
  { name: 'Greece', countryCode: 'GR', symbol: '€', currency: 'EUR' },
  { name: 'Greenland', countryCode: 'GL', symbol: 'Kr.', currency: 'DKK' },
  { name: 'Grenada', countryCode: 'GD', symbol: '$', currency: 'XCD' },
  { name: 'Guadeloupe', countryCode: 'GP', symbol: '€', currency: 'EUR' },
  { name: 'Guam', countryCode: 'GU', symbol: '$', currency: 'USD' },
  { name: 'Guatemala', countryCode: 'GT', symbol: 'Q', currency: 'GTQ' },
  { name: 'Guernsey', countryCode: 'GG', symbol: '£', currency: 'GBP' },
  { name: 'Guinea', countryCode: 'GN', symbol: 'FG', currency: 'GNF' },
  { name: 'Guinea-Bissau', countryCode: 'GW', symbol: 'CFA', currency: 'XOF' },
  { name: 'Guyana', countryCode: 'GY', symbol: '$', currency: 'GYD' },
  { name: 'Haiti', countryCode: 'HT', symbol: 'G', currency: 'HTG' },
  {
    name: 'Heard Island and McDonald Islands',
    countryCode: 'HM',
    symbol: '$',
    currency: 'AUD',
  },
  {
    name: 'Holy See (Vatican City State)',
    countryCode: 'VA',
    symbol: '€',
    currency: 'EUR',
  },
  { name: 'Honduras', countryCode: 'HN', symbol: 'L', currency: 'HNL' },
  { name: 'Hong Kong', countryCode: 'HK', symbol: '$', currency: 'HKD' },
  { name: 'Hungary', countryCode: 'HU', symbol: 'Ft', currency: 'HUF' },
  { name: 'Iceland', countryCode: 'IS', symbol: 'kr', currency: 'ISK' },
  { name: 'India', countryCode: 'IN', symbol: '₹', currency: 'INR' },
  { name: 'Indonesia', countryCode: 'ID', symbol: 'Rp', currency: 'IDR' },
  {
    name: 'Iran, Islamic Republic of',
    countryCode: 'IR',
    symbol: '﷼',
    currency: 'IRR',
  },
  { name: 'Iraq', countryCode: 'IQ', symbol: 'د.ع', currency: 'IQD' },
  { name: 'Ireland', countryCode: 'IE', symbol: '€', currency: 'EUR' },
  { name: 'Isle of Man', countryCode: 'IM', symbol: '£', currency: 'GBP' },
  { name: 'Israel', countryCode: 'IL', symbol: '₪', currency: 'ILS' },
  { name: 'Italy', countryCode: 'IT', symbol: '€', currency: 'EUR' },
  { name: 'Jamaica', countryCode: 'JM', symbol: 'J$', currency: 'JMD' },
  { name: 'Japan', countryCode: 'JP', symbol: '¥', currency: 'JPY' },
  { name: 'Jersey', countryCode: 'JE', symbol: '£', currency: 'GBP' },
  { name: 'Jordan', countryCode: 'JO', symbol: 'ا.د', currency: 'JOD' },
  { name: 'Kazakhstan', countryCode: 'KZ', symbol: 'лв', currency: 'KZT' },
  { name: 'Kenya', countryCode: 'KE', symbol: 'KSh', currency: 'KES' },
  { name: 'Kiribati', countryCode: 'KI', symbol: '$', currency: 'AUD' },
  {
    name: "Korea, Democratic People's Republic of",
    countryCode: 'KP',
    symbol: '₩',
    currency: 'KPW',
  },
  { name: 'Korea, Republic of', countryCode: 'KR', symbol: '₩', currency: 'KRW' },
  { name: 'Kosovo', countryCode: 'XK', symbol: '€', currency: 'EUR' },
  { name: 'Kuwait', countryCode: 'KW', symbol: 'ك.د', currency: 'KWD' },
  { name: 'Kyrgyzstan', countryCode: 'KG', symbol: 'лв', currency: 'KGS' },
  {
    name: "Lao People's Democratic Republic",
    countryCode: 'LA',
    symbol: '₭',
    currency: 'LAK',
  },
  { name: 'Latvia', countryCode: 'LV', symbol: '€', currency: 'EUR' },
  { name: 'Lebanon', countryCode: 'LB', symbol: '£', currency: 'LBP' },
  { name: 'Lesotho', countryCode: 'LS', symbol: 'L', currency: 'LSL' },
  { name: 'Liberia', countryCode: 'LR', symbol: '$', currency: 'LRD' },
  {
    name: 'Libyan Arab Jamahiriya',
    countryCode: 'LY',
    symbol: 'د.ل',
    currency: 'LYD',
  },
  { name: 'Liechtenstein', countryCode: 'LI', symbol: 'CHf', currency: 'CHF' },
  { name: 'Lithuania', countryCode: 'LT', symbol: '€', currency: 'EUR' },
  { name: 'Luxembourg', countryCode: 'LU', symbol: '€', currency: 'EUR' },
  { name: 'Macao', countryCode: 'MO', symbol: '$', currency: 'MOP' },
  {
    name: 'Macedonia, the Former Yugoslav Republic of',
    countryCode: 'MK',
    symbol: 'ден',
    currency: 'MKD',
  },
  { name: 'Madagascar', countryCode: 'MG', symbol: 'Ar', currency: 'MGA' },
  { name: 'Malawi', countryCode: 'MW', symbol: 'MK', currency: 'MWK' },
  { name: 'Malaysia', countryCode: 'MY', symbol: 'RM', currency: 'MYR' },
  { name: 'Maldives', countryCode: 'MV', symbol: 'Rf', currency: 'MVR' },
  { name: 'Mali', countryCode: 'ML', symbol: 'CFA', currency: 'XOF' },
  { name: 'Malta', countryCode: 'MT', symbol: '€', currency: 'EUR' },
  { name: 'Marshall Islands', countryCode: 'MH', symbol: '$', currency: 'USD' },
  { name: 'Martinique', countryCode: 'MQ', symbol: '€', currency: 'EUR' },
  { name: 'Mauritania', countryCode: 'MR', symbol: 'MRU', currency: 'MRO' },
  { name: 'Mauritius', countryCode: 'MU', symbol: '₨', currency: 'MUR' },
  { name: 'Mayotte', countryCode: 'YT', symbol: '€', currency: 'EUR' },
  { name: 'Mexico', countryCode: 'MX', symbol: '$', currency: 'MXN' },
  {
    name: 'Micronesia, Federated States of',
    countryCode: 'FM',
    symbol: '$',
    currency: 'USD',
  },
  { name: 'Moldova, Republic of', countryCode: 'MD', symbol: 'L', currency: 'MDL' },
  { name: 'Monaco', countryCode: 'MC', symbol: '€', currency: 'EUR' },
  { name: 'Mongolia', countryCode: 'MN', symbol: '₮', currency: 'MNT' },
  { name: 'Montenegro', countryCode: 'ME', symbol: '€', currency: 'EUR' },
  { name: 'Montserrat', countryCode: 'MS', symbol: '$', currency: 'XCD' },
  { name: 'Morocco', countryCode: 'MA', symbol: 'DH', currency: 'MAD' },
  { name: 'Mozambique', countryCode: 'MZ', symbol: 'MT', currency: 'MZN' },
  { name: 'Myanmar', countryCode: 'MM', symbol: 'K', currency: 'MMK' },
  { name: 'Namibia', countryCode: 'NA', symbol: '$', currency: 'NAD' },
  { name: 'Nauru', countryCode: 'NR', symbol: '$', currency: 'AUD' },
  { name: 'Nepal', countryCode: 'NP', symbol: '₨', currency: 'NPR' },
  { name: 'Netherlands', countryCode: 'NL', symbol: '€', currency: 'EUR' },
  { name: 'Netherlands Antilles', countryCode: 'AN', symbol: 'NAf', currency: 'ANG' },
  { name: 'New Caledonia', countryCode: 'NC', symbol: '₣', currency: 'XPF' },
  { name: 'New Zealand', countryCode: 'NZ', symbol: '$', currency: 'NZD' },
  { name: 'Nicaragua', countryCode: 'NI', symbol: 'C$', currency: 'NIO' },
  { name: 'Niger', countryCode: 'NE', symbol: 'CFA', currency: 'XOF' },
  { name: 'Nigeria', countryCode: 'NG', symbol: '₦', currency: 'NGN' },
  { name: 'Niue', countryCode: 'NU', symbol: '$', currency: 'NZD' },
  { name: 'Norfolk Island', countryCode: 'NF', symbol: '$', currency: 'AUD' },
  {
    name: 'Northern Mariana Islands',
    countryCode: 'MP',
    symbol: '$',
    currency: 'USD',
  },
  { name: 'Norway', countryCode: 'NO', symbol: 'kr', currency: 'NOK' },
  { name: 'Oman', countryCode: 'OM', symbol: '.ع.ر', currency: 'OMR' },
  { name: 'Pakistan', countryCode: 'PK', symbol: '₨', currency: 'PKR' },
  { name: 'Palau', countryCode: 'PW', symbol: '$', currency: 'USD' },
  {
    name: 'Palestinian Territory, Occupied',
    countryCode: 'PS',
    symbol: '₪',
    currency: 'ILS',
  },
  { name: 'Panama', countryCode: 'PA', symbol: 'B/.', currency: 'PAB' },
  { name: 'Papua New Guinea', countryCode: 'PG', symbol: 'K', currency: 'PGK' },
  { name: 'Paraguay', countryCode: 'PY', symbol: '₲', currency: 'PYG' },
  { name: 'Peru', countryCode: 'PE', symbol: 'S/.', currency: 'PEN' },
  { name: 'Philippines', countryCode: 'PH', symbol: '₱', currency: 'PHP' },
  { name: 'Pitcairn', countryCode: 'PN', symbol: '$', currency: 'NZD' },
  { name: 'Poland', countryCode: 'PL', symbol: 'zł', currency: 'PLN' },
  { name: 'Portugal', countryCode: 'PT', symbol: '€', currency: 'EUR' },
  { name: 'Puerto Rico', countryCode: 'PR', symbol: '$', currency: 'USD' },
  { name: 'Qatar', countryCode: 'QA', symbol: 'ق.ر', currency: 'QAR' },
  { name: 'Reunion', countryCode: 'RE', symbol: '€', currency: 'EUR' },
  { name: 'Romania', countryCode: 'RO', symbol: 'lei', currency: 'RON' },
  { name: 'Russian Federation', countryCode: 'RU', symbol: '₽', currency: 'RUB' },
  { name: 'Rwanda', countryCode: 'RW', symbol: 'FRw', currency: 'RWF' },
  { name: 'Saint Barthelemy', countryCode: 'BL', symbol: '€', currency: 'EUR' },
  { name: 'Saint Helena', countryCode: 'SH', symbol: '£', currency: 'SHP' },
  { name: 'Saint Kitts and Nevis', countryCode: 'KN', symbol: '$', currency: 'XCD' },
  { name: 'Saint Lucia', countryCode: 'LC', symbol: '$', currency: 'XCD' },
  { name: 'Saint Martin', countryCode: 'MF', symbol: '€', currency: 'EUR' },
  {
    name: 'Saint Pierre and Miquelon',
    countryCode: 'PM',
    symbol: '€',
    currency: 'EUR',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
    symbol: '$',
    currency: 'XCD',
  },
  { name: 'Samoa', countryCode: 'WS', symbol: 'SAT', currency: 'WST' },
  { name: 'San Marino', countryCode: 'SM', symbol: '€', currency: 'EUR' },
  { name: 'Sao Tome and Principe', countryCode: 'ST', symbol: 'Db', currency: 'STD' },
  { name: 'Saudi Arabia', countryCode: 'SA', symbol: '﷼', currency: 'SAR' },
  { name: 'Senegal', countryCode: 'SN', symbol: 'CFA', currency: 'XOF' },
  { name: 'Serbia', countryCode: 'RS', symbol: 'din', currency: 'RSD' },
  { name: 'Serbia and Montenegro', countryCode: 'CS', symbol: 'din', currency: 'RSD' },
  { name: 'Seychelles', countryCode: 'SC', symbol: 'SRe', currency: 'SCR' },
  { name: 'Sierra Leone', countryCode: 'SL', symbol: 'Le', currency: 'SLL' },
  { name: 'Singapore', countryCode: 'SG', symbol: '$', currency: 'SGD' },
  { name: 'St Martin', countryCode: 'SX', symbol: 'ƒ', currency: 'ANG' },
  { name: 'Slovakia', countryCode: 'SK', symbol: '€', currency: 'EUR' },
  { name: 'Slovenia', countryCode: 'SI', symbol: '€', currency: 'EUR' },
  { name: 'Solomon Islands', countryCode: 'SB', symbol: 'Si$', currency: 'SBD' },
  { name: 'Somalia', countryCode: 'SO', symbol: 'Sh.so.', currency: 'SOS' },
  { name: 'South Africa', countryCode: 'ZA', symbol: 'R', currency: 'ZAR' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    countryCode: 'GS',
    symbol: '£',
    currency: 'GBP',
  },
  { name: 'South Sudan', countryCode: 'SS', symbol: '£', currency: 'SSP' },
  { name: 'Spain', countryCode: 'ES', symbol: '€', currency: 'EUR' },
  { name: 'Sri Lanka', countryCode: 'LK', symbol: 'Rs', currency: 'LKR' },
  { name: 'Sudan', countryCode: 'SD', symbol: '.س.ج', currency: 'SDG' },
  { name: 'Suriname', countryCode: 'SR', symbol: '$', currency: 'SRD' },
  { name: 'Svalbard and Jan Mayen', countryCode: 'SJ', symbol: 'kr', currency: 'NOK' },
  { name: 'Swaziland', countryCode: 'SZ', symbol: 'E', currency: 'SZL' },
  { name: 'Sweden', countryCode: 'SE', symbol: 'kr', currency: 'SEK' },
  { name: 'Switzerland', countryCode: 'CH', symbol: 'CHf', currency: 'CHF' },
  { name: 'Syrian Arab Republic', countryCode: 'SY', symbol: 'LS', currency: 'SYP' },
  {
    name: 'Taiwan, Province of China',
    countryCode: 'TW',
    symbol: '$',
    currency: 'TWD',
  },
  { name: 'Tajikistan', countryCode: 'TJ', symbol: 'SM', currency: 'TJS' },
  {
    name: 'Tanzania, United Republic of',
    countryCode: 'TZ',
    symbol: 'TSh',
    currency: 'TZS',
  },
  { name: 'Thailand', countryCode: 'TH', symbol: '฿', currency: 'THB' },
  { name: 'Timor-Leste', countryCode: 'TL', symbol: '$', currency: 'USD' },
  { name: 'Togo', countryCode: 'TG', symbol: 'CFA', currency: 'XOF' },
  { name: 'Tokelau', countryCode: 'TK', symbol: '$', currency: 'NZD' },
  { name: 'Tonga', countryCode: 'TO', symbol: '$', currency: 'TOP' },
  { name: 'Trinidad and Tobago', countryCode: 'TT', symbol: '$', currency: 'TTD' },
  { name: 'Tunisia', countryCode: 'TN', symbol: 'ت.د', currency: 'TND' },
  { name: 'Turkey', countryCode: 'TR', symbol: '₺', currency: 'TRY' },
  { name: 'Turkmenistan', countryCode: 'TM', symbol: 'T', currency: 'TMT' },
  {
    name: 'Turks and Caicos Islands',
    countryCode: 'TC',
    symbol: '$',
    currency: 'USD',
  },
  { name: 'Tuvalu', countryCode: 'TV', symbol: '$', currency: 'AUD' },
  { name: 'Uganda', countryCode: 'UG', symbol: 'USh', currency: 'UGX' },
  { name: 'Ukraine', countryCode: 'UA', symbol: '₴', currency: 'UAH' },
  { name: 'United Arab Emirates', countryCode: 'AE', symbol: 'إ.د', currency: 'AED' },
  { name: 'United Kingdom', countryCode: 'GB', symbol: '£', currency: 'GBP' },
  { name: 'United States', countryCode: 'US', symbol: '$', currency: 'USD' },
  {
    name: 'United States Minor Outlying Islands',
    countryCode: 'UM',
    symbol: '$',
    currency: 'USD',
  },
  { name: 'Uruguay', countryCode: 'UY', symbol: '$', currency: 'UYU' },
  { name: 'Uzbekistan', countryCode: 'UZ', symbol: 'лв', currency: 'UZS' },
  { name: 'Vanuatu', countryCode: 'VU', symbol: 'VT', currency: 'VUV' },
  { name: 'Venezuela', countryCode: 'VE', symbol: 'Bs', currency: 'VEF' },
  { name: 'Viet Nam', countryCode: 'VN', symbol: '₫', currency: 'VND' },
  { name: 'Virgin Islands, British', countryCode: 'VG', symbol: '$', currency: 'USD' },
  { name: 'Virgin Islands, U.s.', countryCode: 'VI', symbol: '$', currency: 'USD' },
  { name: 'Wallis and Futuna', countryCode: 'WF', symbol: '₣', currency: 'XPF' },
  { name: 'Western Sahara', countryCode: 'EH', symbol: 'MAD', currency: 'MAD' },
  { name: 'Yemen', countryCode: 'YE', symbol: '﷼', currency: 'YER' },
  { name: 'Zambia', countryCode: 'ZM', symbol: 'ZK', currency: 'ZMW' },
  { name: 'Zimbabwe', countryCode: 'ZW', symbol: '$', currency: 'ZWL' },

]

export const CURRENCY = displayBalanceCurrency(
  projectClient,
  countryIsoCodeData
)
