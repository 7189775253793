import { faEdit } from '@fortawesome/free-solid-svg-icons'

import ModalUpdateMerchantProfile from 'components/MerchantProfileShell/SideBar/ModalUpdateMerchantProfile'
import ModalUpdateMerchantStatus from 'components/MerchantProfileShell/SideBar/ModalUpdateMerchantStatus'

import { accessControlSubNavigation } from 'setups/controller'

const projectClient = process.env.REACT_APP_PROJ

export const DROPDOWN_OPTIONS = [
  {
    module: 'Update Merchant Profile',
    label: 'Update Merchant Profile',
    value: '1',
    route: {
      title: 'update merchant profile',
      path: {
        path: '',
        permissionIds: [],
      },
      icon: faEdit,
    },
    Modal: ModalUpdateMerchantProfile,
  },
  {
    module: 'Update Merchant Status',
    label: 'Update Merchant Status',
    value: '2',
    route: {
      title: 'update merchant status',
      path: {
        path: '',
        permissionIds: [],
      },
      icon: faEdit,
    },
    Modal: ModalUpdateMerchantStatus,
  },
]

export const MERCHANTELLIPSISNAVIGATION = accessControlSubNavigation(
  projectClient,
  DROPDOWN_OPTIONS,
  'merchant'
)
