import { http } from 'utils'

import { OffersAPITypes, OffersFetchParamsTypes } from 'types/offers'
import { OffersAPISchemas } from 'schemas/offersSchemas'

import { DATASTATUS } from 'constants/status'

export async function getOffersAPI({
  traderId,
  offerId,
  offerName,
  pSize = 50,
  pNumber = 1,
}: OffersFetchParamsTypes): Promise<OffersAPITypes> {
  let url = `/offers/${traderId}`
  const params = new URLSearchParams()

  if (offerId) {
    params.set('offerId', offerId.toString())
  }

  if (offerName) {
    params.set('offerName', offerName)
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = OffersAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      offers: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}
