import { resetPassword } from 'api/members'
import Colors from 'colors.json'
import Button from 'components/Button'
import Input from 'components/Input'
import Modal from 'components/Modal'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import useMemberStore from 'store/member'
import { MemberDetail } from 'types/member'

interface Props {
  title: string | undefined
  open: boolean
  onClose: () => void
  // member?: MemberDetail 
}

const ModalResetPassword = ({   open, onClose, }: Props): JSX.Element => {
  const member = useMemberStore((state) => state.member)

  if (!member) return <></>

  return (
    <Modal open={open} title="Reset Password" onClose={onClose} noFooter>
      <p className="pb-4">
        <span className="tracking-wider font-light">
          Reset password email will be sent to this address.
        </span>
      </p>
      <Formik
        initialValues={{
          email: member.email,
        }}
        onSubmit={async (values, actions) => {
          try {
            await resetPassword({
              ident: values.email || '',
              identTypeId: 5,
            })

            onClose()
            toast.success('E-mail for password reset sent successfully!')
            actions.setSubmitting(false)
          } catch (error) {
            if (error instanceof Error) {
              toast.error(error.message)
            }
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-4 h-120">
              <Input
                name="email"
                label="Email"
                // containerClassName="flex flex-col flex-grow"
                disabled
              />
            </div>
            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Confirm</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onClose}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalResetPassword
