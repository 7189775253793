import { useMemo } from 'react'
import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import { InputField } from 'components/InputField'
import { TextareaField } from 'components/TextareaField'
import Button from 'components/Button'
import { NotesDataTypes } from 'types/notes'

import Dropdown from 'components/Dropdown'
import { useMemberNotesUpdate } from './useMemberNotesUpdate'
import { useMemberNotesUpdateForm } from './useMemberNotesUpdateForm'

import { NOTE_TYPE_OPTIONS, NOTE_LEVEL_OPTIONS } from 'constants/filters'

const Index = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: NotesDataTypes
}): JSX.Element => {
  const {
    memberNotesUpdateModStatus,
    memberNotesUpdateParams,
    memberHandleNotesUpdate,
  } = useMemberNotesUpdate({
    modalClose,
    selectedRowData,
  })

  const { control, handleSubmit, setValue, watch } = useMemberNotesUpdateForm({
    defaultValues: memberNotesUpdateParams,
  })

  const noteTypeIdValue = useMemo(() => {
    return (
      NOTE_TYPE_OPTIONS.find(
        (option) => option.label === selectedRowData.noteType
      ) || NOTE_TYPE_OPTIONS[0]
    )
  }, [selectedRowData.noteType])

  const priorityLevelValue = useMemo(() => {
    return (
      NOTE_LEVEL_OPTIONS.find(
        (option) => option.label === selectedRowData.priorityLevel
      ) || NOTE_LEVEL_OPTIONS[0]
    )
  }, [selectedRowData.noteType])

  return (
    <FormsLayout.Wrapper onSubmit={handleSubmit(memberHandleNotesUpdate)}>
      <FormsLayout.Item>
        <Controller
          name="noteTypeId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Note Type"
              defaultValue={noteTypeIdValue}
              options={NOTE_TYPE_OPTIONS}
              className="w-full menu-outer-top tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) => {
                setValue('noteTypeId', value.value)
              }}
              overflowStyle
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.Item>
        <Controller
          name="priorityLevel"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Priority Level"
              defaultValue={priorityLevelValue}
              options={NOTE_LEVEL_OPTIONS}
              className="w-full menu-outer-top tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) => {
                setValue('priorityLevel', value.value)
              }}
              overflowStyle
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.Item>
        <Controller
          name="sourceName"
          control={control}
          render={({ field }) => (
            
            <InputField
              {...field}
              defaultValue={field.value?.toString()}
              onChange={(e) => {
                setValue('sourceName', e.target.value)
              }}
              type="input"
              name="sourceName"
              label="Source Name"
              containerClassName="flex flex-col flex-grow"
              rounded
            />
          )}
        />
      </FormsLayout.Item>

      <div className="w-full">
        {/* <FormsLayout.Item> */}
        <Controller
          name="noteDescription"
          control={control}
          render={({ field }) => (  
            <TextareaField
              {...field}
              defaultValue={field.value?.toString()}
              onChange={(e) => {
                setValue('noteDescription', e.target.value)
              }}
              name="noteDescription"
              label="Note Description"
              containerClassName="flex flex-col flex-grow"
              className="min-h-[20px] max-h-[100px] overflow-x-hidden overflow-y-scroll"
              rounded
            />          
            // <InputField
            //   {...field}
            //   defaultValue={field.value?.toString()}
            //   onChange={(e) => {
            //     setValue('noteDescription', e.target.value)
            //   }}
            //   type="input"
            //   name="noteDescription"
            //   label="Note Description"
            //   containerClassName="flex flex-col flex-grow"
            //   rounded
            // />
          )}
        />
        {/* </FormsLayout.Item> */}
      </div>

      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          type="submit"
          className="rounded bg-primary tracking-wider font-bold"
        >
          Update Note
        </Button>
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
