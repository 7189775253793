import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { PropsWithChildren, CSSProperties } from 'react'
import ReactModal from 'react-modal'
import Colors from 'colors.json'
import Button from 'components/Button'

const modalSizes = {
  regular: {
    minWidth: '500px',
  },
  large: {
    minWidth: '1000px',
  },
}

type modalSizeTypes = keyof typeof modalSizes

type Props = {
  open?: boolean
  title?: string
  onClose?: () => void
  onConfirm?: () => void
  footerClassName?: string
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  confirmButtonClassName?: string
  cancelButtonClassName?: string
  contentStyle?: CSSProperties
  noFooter?: boolean
  modalSize?: modalSizeTypes
}

const Modal = ({
  open,
  title,
  children,
  onClose,
  onConfirm,
  footerClassName,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmButtonClassName,
  cancelButtonClassName,
  contentStyle,
  noFooter,
  modalSize = 'regular',
}: PropsWithChildren<Props>): JSX.Element => (
  <ReactModal
    className="fixed rounded-2xl bg-white flex flex-col overflow-x-scroll "
    isOpen={open || false}
    onRequestClose={onClose}
    contentLabel={title ? `${title} Modal` : 'Modal'}
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
      },
      content: {
        inset: 'auto',
        minWidth: modalSizes[modalSize].minWidth,
        maxWidth: '85vw',
        minHeight: 'auto',
        height: 'fit-content',
        maxHeight: '80vh',
        position: 'relative',
        ...contentStyle,
      },
    }}
  >
    <div className="relative">
      {title && (
        <div className="bg-white z-10 sticky top-0 p-4">
          <header className=" flex justify-between p-4 px-6 bg-primary rounded-lg">
            <h3 className="font-bold text-white tracking-wider">{title}</h3>
            <button
              type="button"
              className="text-white transition-opacity duration-150 opacity-90 hover:opacity-100"
              onClick={() => (onClose ? onClose() : null)}
            >
              <Icon icon={faTimes} />
            </button>
          </header>
        </div>
      )}

      <div className="px-8 py-2 pb-8">{children}</div>

      {noFooter ? null : (
        <footer
          className={classnames('flex justify-end px-4 pb-4', footerClassName)}
        >
          {confirmButtonLabel ? (
            <Button
              className={classnames(
                'rounded bg-primary mr-2',
                confirmButtonClassName
              )}
              onClick={() => (onConfirm ? onConfirm() : null)}
            >
              {confirmButtonLabel}
            </Button>
          ) : null}
          {/* <Button
            className={classnames(
              'rounded bg-primary mr-2',
              confirmButtonClassName
            )}
            onClick={() => (onConfirm ? onConfirm() : null)}
          >
            {confirmButtonLabel || 'Confirm'}
          </Button> */}
          <Button
            className={classnames(
              'rounded bg-white border border-primary',
              cancelButtonClassName
            )}
            onClick={() => (onClose ? onClose() : null)}
            style={{ color: Colors.primary }}
          >
            {cancelButtonLabel || 'Cancel'}
          </Button>
        </footer>
      )}
    </div>
  </ReactModal>
)

export default Modal
