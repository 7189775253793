import { useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { getBanksThor_API } from 'api/financials'
import { useBankThorStore } from 'store/bankThor'

export const useBankQuery = () => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useBankThor'],
    queryFn: async () => {
      return await getBanksThor_API()
    },
  })
}

export const useBanksThor = () => {
  const bankThorModStatus = useBankThorStore((state) => state.bankThorModStatus)
  const bankThorModStatusSet = useBankThorStore(
    (state) => state.bankThorModStatusSet
  )
  const bankThorModErrors = useBankThorStore((state) => state.bankThorModErrors)
  const bankThorModErrorsSet = useBankThorStore(
    (state) => state.bankThorModErrorsSet
  )
  const bankThor = useBankThorStore((state) => state.bankThor)
  const bankThorSet = useBankThorStore((state) => state.bankThorSet)

  const queryClient = useQueryClient()

  const {
    data: dataBankThor,
    isLoading: isLoadingBank,
    isError: isErrorBank,
    isFetching: isFetchingBank,
    isSuccess: isSuccessBank,
    error: errorBank,
  } = useBankQuery()

  useEffect(() => {
    if (dataBankThor) bankThorSet(dataBankThor.bankNames)
  }, [dataBankThor, bankThorSet])

  useEffect(() => {
    if (isFetchingBank) bankThorModStatusSet('loading')
    if (isLoadingBank) bankThorModStatusSet('loading')
    if (isSuccessBank) bankThorModStatusSet('success')
    if (isErrorBank) bankThorModStatusSet('fail')
    if (errorBank) bankThorModErrorsSet(JSON.stringify(errorBank))
  }, [
    isFetchingBank,
    isLoadingBank,
    isSuccessBank,
    isErrorBank,
    errorBank,
    bankThorModStatusSet,
    bankThorModErrorsSet,
  ])

  const bankThorAsOptions = useMemo(() => {
    return bankThor.map((item) => ({
      label: item.bankName,
      value: item.bankId.toString(),
    }))
  }, [bankThor])

  const bankThorNames = useMemo(() => {
    return bankThor.map((item) => item.bankName)
  }, [bankThor])

  return {
    bankThor,
    bankThorAsOptions,
    bankThorNames,
    bankThorModStatus,
  }
}
