import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getIdentificationsStatusesAPI } from 'api/identifications'
import { useIdentificationsStatusesStore } from 'store/identifications'
import { DropDownItem } from 'types/components'

import { DATASTATUS } from 'constants/status'

export const useMemberIdentificationsStatuses = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const identificationsStatusesModStatus = useIdentificationsStatusesStore(
    (state) => state.identificationsStatusesModStatus
  )
  const identificationsStatusesModStatusSet = useIdentificationsStatusesStore(
    (state) => state.identificationsStatusesModStatusSet
  )
  const identificationsStatusesModErrors = useIdentificationsStatusesStore(
    (state) => state.identificationsStatusesModErrors
  )
  const identificationsStatusesModErrorsSet = useIdentificationsStatusesStore(
    (state) => state.identificationsStatusesModErrorsSet
  )
  const identificationsStatuses = useIdentificationsStatusesStore(
    (state) => state.identificationsStatuses
  )
  const identificationsStatusesSet = useIdentificationsStatusesStore(
    (state) => state.identificationsStatusesSet
  )

  const {
    data: dataIdentifications,
    isLoading: isLoadingIdentifications,
    isError: isErrorIdentifications,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useIdentificationsStatuses'],
    queryFn: async () => {
      try {
        identificationsStatusesModStatusSet('loading')
        const response = await getIdentificationsStatusesAPI()
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        identificationsStatusesModStatusSet('success')
        return response
      } catch (e) {
        identificationsStatusesModStatusSet('fail')
        identificationsStatusesModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataIdentifications)
      identificationsStatusesSet(dataIdentifications.identStatus)
  }, [dataIdentifications, identificationsStatusesSet])

  const identificationsStatusesOptions: DropDownItem[] = useMemo(() => {
    return identificationsStatuses.map((item) => ({
      label: String(item.statusName),
      value: String(item.statusId),
    }))
  }, [identificationsStatuses])

  return {
    memberIdentificationsStatusesOptions: identificationsStatusesOptions,
    memberIdentificationsStatuses: identificationsStatuses,
    memberIdentificationsStatusesModStatus: identificationsStatusesModStatus,
  }
}
