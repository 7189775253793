import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { ReasonDataTypes } from 'types/reason'

interface ReasonStoreTypes {
  reasonModErrors: StatusErrorTypes
  reasonModErrorsSet: (reasonModErrors: StatusErrorTypes) => void
  reasonModStatus: StatusTypes
  reasonModStatusSet: (reasonStatus: StatusTypes) => void
  reason: ReasonDataTypes[]
  reasonSet: (reason: ReasonDataTypes[]) => void
  reasonLookup: ReasonDataTypes[]
  reasonLookupSet: (reason: ReasonDataTypes[]) => void
}

export const useReasonStore = create<ReasonStoreTypes>((set) => ({
  reasonModErrors: '',
  reasonModErrorsSet: (reasonModErrors) => {
    set({
      reasonModErrors,
    })
  },
  reasonModStatus: 'idle',
  reasonModStatusSet: (reasonModStatus) => {
    set({
      reasonModStatus,
    })
  },
  reason: [],
  reasonSet: (reason) => {
    set({
      reason,
    })
  },
  reasonLookup: [],
  reasonLookupSet: (reasonLookup) => {
    set({
      reasonLookup,
    })
  },
}))
