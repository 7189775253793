import React from 'react'

import Form from './Form'

const Index: React.FC<{ onClose: () => void }> = ({
  onClose,
}): React.ReactElement => {
  return <Form onCloseModal={onClose} />
}

export default Index
