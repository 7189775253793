import { useCallback, useState } from 'react'
import {
  faEdit,
  faArrowRight,
  faStopCircle,
} from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import TSTable from 'components/Tanstack'
import { useDirectPaymentsStore } from 'store/financials'
import { useTableUtils } from 'hooks/useTableUtils'
import {
  PaymentInstructionsDataTypes,
  FinancialsModalActionsTypes,
} from 'types/financials'
import { hasingOut } from 'services/helpers'

export const useMemberDirectPaymentsTable = () => {
  const directPayments = useDirectPaymentsStore((state) => state.directpayments)

  const {
    selectedModal,
    selectedModalSet,
    selectedRowData,
    selectedRowDataSet,
  } = useTableUtils<PaymentInstructionsDataTypes, FinancialsModalActionsTypes>()

  const handleActionModalClose = useCallback(() => {
    selectedRowDataSet(null)
    selectedModalSet(null)
  }, [selectedModalSet, selectedRowDataSet])

  const columnHelper = createColumnHelper<PaymentInstructionsDataTypes>()
  const columns = [
    columnHelper.accessor('id', {
      header: () => 'ID',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('instructionName', {
      header: () => 'Instruction Name',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('bankAccountNumber', {
      header: () => 'Account',
      cell: (info) => hasingOut(info.getValue()),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('paymentAmount', {
      header: () => 'Amount',
      cell: (info) => '$' + info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('frequencyName', {
      header: () => 'Frequency',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('firstPaymentDate', {
      header: () => 'First Payment Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('nextPaymentDate', {
      header: () => 'Next Payment Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('optMode', {
      header: () => 'Status',
      cell: (info) => {
        const status = info.getValue()
        switch (status) {
          case 0:
            return <span>Skip</span>
          case 1:
            return <span>Suspend</span>
          case 2:
            return <span>Inactive</span>
          case 3:
            return <span>Canceled</span>
          default:
            return <span className="text-green-200 font-bold">Active</span>
        }
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: () => '',
      cell: ({ row }) => (
        <div className="flex gap-2 px-4">
          <TSTable.TableCellButton
            onClick={() => {
              selectedRowDataSet(row.original)
              selectedModalSet('financials_paymentinstruction_update')
            }}
            label="Update Payment Instruction"
            icon={faEdit}
          />

          <TSTable.TableCellButton
            onClick={() => {
              selectedRowDataSet(row.original)
              selectedModalSet('financials_paymentinstruction_skip')
            }}
            label="Skip Payment Instruction"
            icon={faArrowRight}
          />

          <TSTable.TableCellButton
            onClick={() => {
              selectedRowDataSet(row.original)
              selectedModalSet('financials_paymentinstruction_cancel')
            }}
            label="Cancel Payment Instruction"
            icon={faStopCircle}
          />
        </div>
      ),
    }),
    // columnHelper.accessor('status', {
    //   header: () => 'Status',
    //   cell: (info) => info.getValue(),
    //   // footer: (props) => props.column.id,
    // }),
  ] as ColumnDef<PaymentInstructionsDataTypes>[]

  return {
    memberDirectPaymentsSelected: selectedRowData,
    memberDirectPaymentsActionModal: selectedModal,
    memberDirectPaymentsTableData: directPayments,
    memberDirectPaymentsTableColumns: columns,
    handleMemberActionModalClose: handleActionModalClose,
  }
}
