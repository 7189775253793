import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import {
  UpdateMerchantFormTypes,
  UpdateMerchantFormSubmitValuesTypes,
  UpdateMerchantFormSubmitActionsTypes,
} from 'types/merchant'
import useMerchantStore from 'store/merchant'

const UPDATEMERCHANTINITVAL: UpdateMerchantFormTypes = {
  traderId: 0,
  name: '',
  merchantCode: 0,
  externalId: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  suburb: '',
  city: '',
  postCode: '',
}

export const useUpdateMerchant = (
  traderId: number,
  onCloseModal: () => void
): {
  isLoading: boolean
  initialValues: UpdateMerchantFormTypes
  handleSubmitUpdateMerchant: (
    values: UpdateMerchantFormSubmitValuesTypes,
    actions: UpdateMerchantFormSubmitActionsTypes
  ) => void
} => {
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // MERCHANT UPDATE START
  const merchantProfile = useMerchantStore((state) => state.merchantProfile)
  const getMerchantProfile = useMerchantStore(
    (state) => state.getMerchantProfile
  )
  const updateMerchant = useMerchantStore((state) => state.updateMerchant)
  const merchantUpdateLoading = useMerchantStore(
    (state) => state.merchantUpdateLoading
  )
  const merchantUpdateStatus = useMerchantStore(
    (state) => state.merchantUpdateStatus
  )
  // MERCHANT UPDATE END
  // MISC STATES START
  const [initialValues, initialValuesSet] = useState<UpdateMerchantFormTypes>({
    ...UPDATEMERCHANTINITVAL,
    name: merchantProfile[0].merchantName,
    traderId: merchantProfile[0].merchantTraderId,
    merchantCode: merchantProfile[0].merchantCode,
    externalId: String(merchantProfile[0].externalId),
    addressLine1: merchantProfile[0].address1,
    addressLine2: merchantProfile[0].address2,
    addressLine3: merchantProfile[0].address3,
    addressLine4: merchantProfile[0].address4,
    suburb: merchantProfile[0].suburb,
    city: merchantProfile[0].city,
    postCode: merchantProfile[0].postcode,
  })
  // MISC STATES END

  const isLoading = useMemo(() => {
    return merchantUpdateLoading ? true : false
  }, [merchantUpdateLoading])

  const handleSubmitUpdateMerchant = useCallback(
    async (
      values: UpdateMerchantFormSubmitValuesTypes,
      actions: UpdateMerchantFormSubmitActionsTypes
    ) => {
      try {
        await updateMerchant(values)
        await getMerchantProfile(String(traderId))
        toast.success('Update merchant successful.')
        onCloseModal()
        // actions.setSubmitting(false)
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        if (message.outcomeCode === 'HDA_MERCHANT:UPDATE_MERCHANT:6') {
          toast.error(`Merchant Code already exist.`)
        } else {
          toast.error(`Update Merchant error.`)
        }
      }
    },
    [updateMerchant]
  )

  return {
    isLoading,
    initialValues,
    handleSubmitUpdateMerchant,
  }
}
