import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getNotesAPI } from 'api/notes'
import { useNotesStore } from 'store/notes'
import { NotesFetchParamsTypes } from 'types/notes'

import { DATASTATUS } from 'constants/status'

export const useMemberNotes = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const notesModStatus = useNotesStore((state) => state.notesModStatus)
  const notesModStatusSet = useNotesStore((state) => state.notesModStatusSet)
  const notesModErrors = useNotesStore((state) => state.notesModErrors)
  const notesModErrorsSet = useNotesStore((state) => state.notesModErrorsSet)
  const notes = useNotesStore((state) => state.notes)
  const notesSet = useNotesStore((state) => state.notesSet)
  const [notesFilterParams, notesFilterParamsSet] =
    useState<NotesFetchParamsTypes>(defaultParams)
  const notesSummary = useNotesStore((state) => state.notesSummary)
  const notesSummarySet = useNotesStore((state) => state.notesSummarySet)

  const [isNotesFiltered, isNotesFilteredSet] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataNotes,
    isLoading: isLoadingNotes,
    isError: isErrorNotes,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useNotesDashboard', notesFilterParams],
    queryFn: async () => {
      try {
        notesModStatusSet('loading')
        const response = await getNotesAPI(notesFilterParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        notesModStatusSet('success')
        return response.notes
      } catch (e) {
        notesModStatusSet('fail')
        notesModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataNotes) {
      notesSet(dataNotes)
      notesSummarySet()
    }
  }, [dataNotes, notesSet])

  return {
    memberNotesSummary: notesSummary,
    memberNotes: notes,
    memberNotesModStatus: notesModStatus,
  }
}
