import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Header from 'views/MemberProfile/Header'
import SidebarSubsquad from 'views/MemberProfile/Sidebar/Subsquad'
import SidebarMember from 'views/MemberProfile/Sidebar/Member'

import {
  useMemberProfilesInit,
  useMemberProfiles,
} from 'views/MemberProfile/useMemberProfiles'
import { useMemberActivity } from './useMemberActivity'
import {
  useMemberSubsquadsInit,
  useMemberSubsquads,
} from 'views/SubsquadProfile/useMemberSubsquads'

export const Wrapper = ({
  children,
  className,
  contentClassName,
}: {
  children: React.ReactNode
  className?: string
  contentClassName?: string
}): JSX.Element => {
  // initializers
  // init member profile
  useMemberProfilesInit()
  const { memberMemberProfiles, memberMemberProfilesModStatus } =
    useMemberProfiles()
  // init member activity
  const { memberTransactionsActivity, memberTransactionsActivityModStatus } =
    useMemberActivity()
  // init member subsquads
  useMemberSubsquadsInit()
  const { memberSubsquads, memberSubsquadsModStatus } = useMemberSubsquads()

  const isLoadingSubsquad = memberSubsquadsModStatus === 'loading' || false
  const isErrorSubsquad = memberSubsquadsModStatus === 'fail' || false
  const isLoadingProfile = memberMemberProfilesModStatus === 'loading' || false
  const isErrorProfile = memberMemberProfilesModStatus === 'fail' || false

  return (
    <section
      className={classnames(
        'flex flex-col md:flex-row min-h-screen divide-x divide-gray-100',
        className
      )}
    >
      <section className="bg-white divide-y divide-gray-100 w-full md:w-1/5">
        {/* BACK BUTTON */}
        <Link
          to="/member-lookup"
          className="flex gap-2 items-center min-w-full text-xs p-4 uppercase tracking-wider"
        >
          <Icon icon={faChevronLeft} /> Back to search
        </Link>

        {/* LOADING */}
        <SidebarSubsquad
          isLoading={isLoadingSubsquad}
          isError={isErrorSubsquad}
        />

        {/* LOADING */}
        <SidebarMember isLoading={isLoadingProfile} isError={isErrorProfile} />
      </section>

      <section className="divide-y w-full md:w-4/5">
        <div>
          {/* LOADING */}
          {/* {isLoading ? <Spinner /> : <Header />} */}
          <Header />
        </div>
        <div>{children}</div>
      </section>
    </section>
  )
}
