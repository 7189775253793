import Spinner from 'components/Spinner'

const Index = ({
  isValidating,
  label,
  value,
}: {
  isValidating: boolean
  label: string
  value?: string | undefined
}): JSX.Element => (
  <div className="flex flex-col px-4 py-8 text-center">
    <span className=" text-4xl font-bold">
      {isValidating ? <Spinner /> : value}
    </span>
    <span className="tracking-wider font-light">{label}</span>
  </div>
)

export default Index
