import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getVouchersAPI } from 'api/vouchers'
import { useVouchersStore } from 'store/vouchers'
import { VouchersFetchParamsTypes } from 'types/vouchers'

import { DATASTATUS } from 'constants/status'

export const useMemberVouchers = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const vouchersModStatus = useVouchersStore((state) => state.vouchersModStatus)
  const vouchersModStatusSet = useVouchersStore(
    (state) => state.vouchersModStatusSet
  )
  const vouchersModErrors = useVouchersStore((state) => state.vouchersModErrors)
  const vouchersModErrorsSet = useVouchersStore(
    (state) => state.vouchersModErrorsSet
  )
  const vouchers = useVouchersStore((state) => state.vouchers)
  const vouchersSet = useVouchersStore((state) => state.vouchersSet)
  const [vouchersFilterParams, vouchersFilterParamsSet] =
    useState<VouchersFetchParamsTypes>(defaultParams)
  const vouchersSummary = useVouchersStore((state) => state.vouchersSummary)
  const vouchersSummarySet = useVouchersStore(
    (state) => state.vouchersSummarySet
  )

  const [isVouchersFiltered, isVouchersFilteredSet] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataVouchers,
    isLoading: isLoadingVouchers,
    isError: isErrorVouchers,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useVouchersDashboard', vouchersFilterParams],
    queryFn: async () => {
      try {
        vouchersModStatusSet('loading')
        const response = await getVouchersAPI(vouchersFilterParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        vouchersModStatusSet('success')
        return response.vouchers
      } catch (e) {
        vouchersModStatusSet('fail')
        vouchersModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataVouchers) {
      vouchersSet(dataVouchers)
      vouchersSummarySet()
    }
  }, [dataVouchers, vouchersSet])

  return {
    memberVouchersSummary: vouchersSummary,
    memberVouchers: vouchers,
    memberVouchersModStatus: vouchersModStatus,
  }
}
