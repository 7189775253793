// STORE DATA WAS INITIALIZED FROM THE Wrapper.tsx

import { useParams } from 'react-router-dom'
import { useSubsquadsStore } from 'store/subsquads'
import { useMemberSubsquads } from 'views/SubsquadProfile/useMemberSubsquads'

export const useSubsquadInfos = () => {
  const {
    memberSubsquads,
    memberSubsquadsModStatus,
    memberSubsquadsModErrors,
  } = useMemberSubsquads()

  return {
    memberSubsquads,
    memberSubsquadsModStatus,
    memberSubsquadsModErrors,
  }
}
