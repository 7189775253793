import classnames from 'classnames'
import { FC, HTMLProps } from 'react'

const Card: FC<HTMLProps<HTMLDivElement>> = ({
  children,
  className,
}): JSX.Element => (
  <div
    className={classnames('rounded-md border border-gray-600 p-8', className)}
  >
    {children}
  </div>
)

export default Card
