export const permissionMap = {
  MemberLookup: 1,
  MemberProfile: 2,
  Transactions: 101,
  Vouchers: 102,
  Comms: 103,
  Memos: 104,
  Identification: 105,
  Membership: 106,
  Financials: 107,
  Subsquad: 108,
  History: 100,
  MerchantLookup: 109,
  MerchantProfile: 110,
  MerchantIdentification: 111,
  MerchantDevice: 112,
}
