import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { CommsFetchParamsSchemas } from 'schemas/commsSchemas'
import { CommsFetchParamsTypes } from 'types/comms'

export const useMemberCommsFilterForm = ({
  defaultValues,
}: {
  defaultValues: CommsFetchParamsTypes
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<CommsFetchParamsTypes>({
    resolver: zodResolver(CommsFetchParamsSchemas),
    defaultValues,
  })

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
  }
}
