import Footer from 'components/Footer'
import Header from 'components/Header'
import SideNav from 'components/Header/SideNav'
import IdleTimeout from 'components/ui/IdleTimeout'
import WindowClose from 'components/ui/WindowClose'
import DashboardLoader from 'components/DashboardLoader'
import { isUndefined } from 'lodash'
import PrivateRoutes from 'PrivateRoutes'
import PublicRoutes from 'PublicRoutes'
import { BrowserRouter } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { AuthContext, useCognito } from 'services/amplify'
import useAuthStore from 'store/auth'
import { SWRConfig } from 'swr'

import { QueryClient, QueryClientProvider } from 'react-query'
const queryClient = new QueryClient()

const App = (): JSX.Element => {
  const user = useAuthStore((state) => state.user)
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn)
  const isLoading = useAuthStore((state) => state.isLoading)

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-150">
        <DashboardLoader />
      </div>
    )
  }

  if (!isLoggedIn && !user) {
    return <PublicRoutes />
  }

  return (
    <main className="flex flex-col min-w-full min-h-screen bg-gray-150">
      <WindowClose />
      <IdleTimeout />
      <Header />
      <SideNav />

      <div className="flex flex-col flex-1 h-full p-6 lg:ml-20 lg:mt-14">
        <PrivateRoutes />
      </div>
      <Footer />
    </main>
  )
}

const Main = (): JSX.Element => {
  const cognito = useCognito()
  return (
    <AuthContext.Provider value={cognito}>
      <SWRConfig
        value={{
          errorRetryCount: 5,
          revalidateOnFocus: false,
          // revalidateOnMount: false,
        }}
      >
        <ToastContainer
          autoClose={5_000}
          position="bottom-center"
          transition={Slide}
          draggable={false}
          hideProgressBar
        />

        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </SWRConfig>
    </AuthContext.Provider>
  )
}

export default Main
