import DashboardLoader from 'components/DashboardLoader'
import Counter from '../Counter'
import CountersContainer from '../CountersContainer'
import DashboardCard from '../DashboardCard'
import SeeAll from '../SeeAll'

import CommsItem from './CommsItem'

import { useMemberComms } from './useMemberComms'

export const Communications = (): JSX.Element => {
  const { memberComms, memberCommsSummary, memberCommsModStatus } =
    useMemberComms()

  const isLoading = memberCommsModStatus === 'loading' || false
  const commsRecent = memberComms.slice(0, 3)
  const isComms = commsRecent.length ? true : false

  return (
    <DashboardCard title="Communications">
      <CountersContainer>
        <Counter
          isValidating={isLoading}
          label="Sent"
          value={memberCommsSummary.sent.toString()}
        />

        <Counter
          isValidating={isLoading}
          label="Opened"
          value={memberCommsSummary.opened.toString()}
        />

        <Counter
          isValidating={isLoading}
          label="Clicked"
          value={memberCommsSummary.clicked.toString()}
        />
      </CountersContainer>

      <div className="flex flex-col flex-grow gap-1">
        <p className="tracking-wider font-light mb-4">Recent Communications</p>

        {isLoading ? (
          <DashboardLoader />
        ) : !isComms ? (
          <p className="py-2">
            <span className="tracking-wider font-light">
              No communications available
            </span>
          </p>
        ) : (
          <>
            {commsRecent.map((item) => (
              <div
                key={item.actionId}
                className="flex justify-between p-4 hover:bg-primary hover:bg-opacity-10 rounded-lg"
              >
                <CommsItem communication={item} />
              </div>
            ))}
          </>
        )}
      </div>
      {isComms ? <SeeAll url={'comms'} label="Communications" /> : null}
    </DashboardCard>
  )
}
