import MerchantProfileShell from 'components/MerchantProfileShell'
import PrivateRoute from 'components/PrivateRoute'
import { ROUTES } from 'constants/routing'
import { useEffect } from 'react'
import { Routes, useParams } from 'react-router'
import useMerchantStore from 'store/merchant'
import Identification from 'views/MerchantProfile/Tabs/Identification'
import Transactions from 'views/MerchantProfile/Tabs/Transaction'
import Device from 'views/MerchantProfile/Tabs/Device'

const MerchantProfile = (): JSX.Element => {
  const { traderId } = useParams()
  const getMerchantProfile = useMerchantStore(
    (state) => state.getMerchantProfile
  )

  useEffect(() => {
    if (traderId) {
      getMerchantProfile(traderId)
    }
  }, [getMerchantProfile, traderId])

  return (
    <MerchantProfileShell>
      <Routes>
        <PrivateRoute
          path={ROUTES.MerchantProfileTransactions.path}
          element={<Transactions />}
          permissions={ROUTES.MerchantProfileTransactions.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MerchantProfileIdentification.path}
          element={<Identification />}
          permissions={ROUTES.MerchantProfileIdentification.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MerchantProfileDevice.path}
          element={<Device />}
          permissions={ROUTES.MerchantProfileDevice.permissionIds}
        />
      </Routes>
    </MerchantProfileShell>
  )
}
export default MerchantProfile
