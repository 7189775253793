import { http } from 'utils'

import {
  MemberProfilesDefaultTypes,
  MemberProfilesAPITypes,
  MemberProfilesFetchParamsTypes,
} from 'types/memberprofiles'
import { MemberProfilesAPISchemas } from 'schemas/memberprofilesSchemas'

import { DATASTATUS } from 'constants/status'

export async function getMemberProfilesAPI({
  traderId,
}: MemberProfilesFetchParamsTypes): Promise<MemberProfilesAPITypes> {
  let url = `/members/${traderId}`
  const params = new URLSearchParams()

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = MemberProfilesAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}
