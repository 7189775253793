import classnames from 'classnames'
import { Field, FieldAttributes, FormikErrors, useFormikContext } from 'formik'
import isUndefined from 'lodash/isUndefined'
import { PropsWithChildren, useMemo } from 'react'

type Props = FieldAttributes<Record<string, unknown>> & {
  name: string
  label: string
  error?: string
  as?: string
  labelClassName?: string
  errorClassName?: string
}

function SearchInput({
  name,
  label,
  error,
  // as,
  maxLength,
  disabled,
  placeholder,
  children,
  className,
  labelClassName,
  errorClassName,
  type,
}: PropsWithChildren<Props>): JSX.Element {
  const formik = useFormikContext()

  const isDisabled = useMemo(() => {
    if (formik && !disabled) {
      return formik.isSubmitting
    } else {
      return disabled
    }
  }, [disabled, formik])

  const actualError = useMemo(() => {
    // We allow "any" here because we can't possibly know what fields are available.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (formik && isUndefined(error) && (formik.touched as any)[name]) {
      return (formik.errors as FormikErrors<Record<string, string>>)[name]
    } else {
      return error
    }
  }, [name, error, formik])

  return (
    <div className="flex justify-between items-center w-full ">
      <label
        htmlFor={name}
        className={classnames(
          'table-cell items-center text-align text-sm text-gray-500 w-28 mb-2 font-light tracking-wider',
          labelClassName
        )}
      >
        {label}
      </label>

      <Field
        id={name}
        type={type || 'text'}
        name={name}
        autoComplete="off"
        disabled={isDisabled}
        placeholder={placeholder}
        className={classnames(
          'table-cell w-56 border-0 border-b border-gray-500 mx-2 p-1 text-black text-sm outline-none',
          {
            'bg-gray-100': isDisabled,
            'border-gray-600': !actualError,
            'border-red': !!actualError,
          },
          className
        )}
        maxLength={maxLength}
      >
        {children}
      </Field>
      {actualError && (
        <div
          className={classnames('text-red text-xs mt-1', errorClassName)}
          role="alert"
        >
          {actualError}
        </div>
      )}
    </div>
  )
}

export default SearchInput
