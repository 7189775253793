import { faSearch, faUsers, faStore } from '@fortawesome/free-solid-svg-icons' // faUsers
import { ROUTES } from './routing'

import { accessControlNavigation } from 'setups/controller'

const projectClient = process.env.REACT_APP_PROJ

const NavModuleData = [
  {
    module: 'Member',
    route: {
      title: 'Member',
      path: ROUTES.MemberLookup,
      icon: faSearch,
      className: 'mt-5 nav-text',
    },
  },
  {
    module: 'Subsquad',
    route: {
      title: 'Subsquad',
      path: ROUTES.SubsquadLookup,
      icon: faUsers,
      className: 'm-0 nav-text',
    },
  },
  {
    module: 'Merchant',
    route: {
      title: 'Merchant',
      path: ROUTES.MerchantLookup,
      icon: faStore,
      className: 'm-0 nav-text',
    },
  },
  {
    module: 'Merchant Subway',
    route: {
      title: 'Merchant',
      path: ROUTES.MerchantLookupSubway,
      icon: faStore,
      className: 'm-0 nav-text',
    },
  },
]

export const SIDEBARNAVIGATION = accessControlNavigation(
  projectClient,
  NavModuleData
)
