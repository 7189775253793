import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { NotesFetchParamsSchemas } from 'schemas/notesSchemas'
import { NotesFetchParamsTypes } from 'types/notes'

export const useMemberNotesFilterForm = ({
  defaultValues,
}: {
  defaultValues: NotesFetchParamsTypes
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<NotesFetchParamsTypes>({
    resolver: zodResolver(NotesFetchParamsSchemas),
    defaultValues,
  })

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    reset,
    watch,
  }
}
