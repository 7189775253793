import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { VouchersDataTypes, VouchersStatusesDataTypes } from 'types/vouchers'

interface VouchersStoreTypes {
  vouchersModErrors: StatusErrorTypes
  vouchersModErrorsSet: (vouchersModErrors: StatusErrorTypes) => void
  vouchersModStatus: StatusTypes
  vouchersModStatusSet: (vouchersStatus: StatusTypes) => void
  vouchers: VouchersDataTypes[]
  vouchersSet: (vouchers: VouchersDataTypes[]) => void
  vouchersLookup: VouchersDataTypes[]
  vouchersLookupSet: (vouchers: VouchersDataTypes[]) => void
  vouchersSummary: {
    active: number
    notActive: number
    expired: number
    redeemed: number
  }
  vouchersSummarySet: () => void
}

export const useVouchersStore = create<VouchersStoreTypes>((set) => ({
  vouchersModErrors: '',
  vouchersModErrorsSet: (vouchersModErrors) => {
    set({
      vouchersModErrors,
    })
  },
  vouchersModStatus: 'idle',
  vouchersModStatusSet: (vouchersModStatus) => {
    set({
      vouchersModStatus,
    })
  },
  vouchers: [],
  vouchersSet: (vouchers) => {
    set({
      vouchers,
    })
  },
  vouchersLookup: [],
  vouchersLookupSet: (vouchersLookup) => {
    set({
      vouchersLookup,
    })
  },
  vouchersSummary: {
    active: 0,
    notActive: 0,
    expired: 0,
    redeemed: 0,
  },
  vouchersSummarySet: () => {
    set((state) => {
      const active = state.vouchers.filter(
        (item) => item.status.toLowerCase() === 'active'
      )
      const notActive = state.vouchers.filter(
        (item) => item.status.toLowerCase() === 'not active'
      )
      const expired = state.vouchers.filter(
        (item) => item.status.toLowerCase() === 'expired'
      )
      const redeemed = state.vouchers.filter(
        (item) => item.status.toLowerCase() === 'redeemed'
      )
      return {
        vouchersSummary: {
          active: active.length,
          notActive: notActive.length,
          expired: expired.length,
          redeemed: redeemed.length,
        },
      }
    })
  },
}))

interface VouchersLookupStoreTypes {
  vouchersLookupModErrors: StatusErrorTypes
  vouchersLookupModErrorsSet: (
    vouchersLookupModErrors: StatusErrorTypes
  ) => void
  vouchersLookupModStatus: StatusTypes
  vouchersLookupModStatusSet: (vouchersLookupStatus: StatusTypes) => void
  vouchersLookup: VouchersDataTypes[]
  vouchersLookupSet: (vouchersLookup: VouchersDataTypes[]) => void
}

export const useVouchersLookupStore = create<VouchersLookupStoreTypes>(
  (set) => ({
    vouchersLookupModErrors: '',
    vouchersLookupModErrorsSet: (vouchersLookupModErrors) => {
      set({
        vouchersLookupModErrors,
      })
    },
    vouchersLookupModStatus: 'idle',
    vouchersLookupModStatusSet: (vouchersLookupModStatus) => {
      set({
        vouchersLookupModStatus,
      })
    },
    vouchersLookup: [],
    vouchersLookupSet: (vouchersLookup) => {
      set({
        vouchersLookup,
      })
    },
  })
)

interface VouchersStatusesStoreTypes {
  vouchersStatusesModErrors: StatusErrorTypes
  vouchersStatusesModErrorsSet: (
    vouchersStatusesModErrors: StatusErrorTypes
  ) => void
  vouchersStatusesModStatus: StatusTypes
  vouchersStatusesModStatusSet: (vouchersStatusesStatus: StatusTypes) => void
  vouchersStatuses: VouchersStatusesDataTypes[]
  vouchersStatusesSet: (vouchersStatuses: VouchersStatusesDataTypes[]) => void
  vouchersStatusesLookup: VouchersStatusesDataTypes[]
  vouchersStatusesLookupSet: (
    vouchersStatuses: VouchersStatusesDataTypes[]
  ) => void
}

export const useVouchersStatusesStore = create<VouchersStatusesStoreTypes>(
  (set) => ({
    vouchersStatusesModErrors: '',
    vouchersStatusesModErrorsSet: (vouchersStatusesModErrors) => {
      set({
        vouchersStatusesModErrors,
      })
    },
    vouchersStatusesModStatus: 'idle',
    vouchersStatusesModStatusSet: (vouchersStatusesModStatus) => {
      set({
        vouchersStatusesModStatus,
      })
    },
    vouchersStatuses: [],
    vouchersStatusesSet: (vouchersStatuses) => {
      set({
        vouchersStatuses,
      })
    },
    vouchersStatusesLookup: [],
    vouchersStatusesLookupSet: (vouchersStatusesLookup) => {
      set({
        vouchersStatusesLookup,
      })
    },
  })
)
