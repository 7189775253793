import DashboardLoader from 'components/DashboardLoader'
import Counter from '../Counter'
import CountersContainer from '../CountersContainer'
import DashboardCard from '../DashboardCard'
import SeeAll from '../SeeAll'

import NotesItem from './NotesItem'

import { useMemberNotes } from './useMemberNotes'

export const Notes = (): JSX.Element => {
  const { memberNotes, memberNotesSummary, memberNotesModStatus } =
    useMemberNotes()

  const isLoading = memberNotesModStatus === 'loading' || false
  const memosRecent = memberNotes.slice(0, 3)
  const isNotes = memosRecent.length ? true : false

  console.log({ memberNotes, memberNotesSummary })

  return (
    <DashboardCard title="Memos / Notes">
      <CountersContainer>
        <Counter
          isValidating={isLoading}
          label="High Priority"
          value={memberNotesSummary.high.toString()}
        />

        <Counter
          isValidating={isLoading}
          label="Medium Priority"
          value={memberNotesSummary.medium.toString()}
        />

        <Counter
          isValidating={isLoading}
          label="Low Priority"
          value={memberNotesSummary.low.toString()}
        />
      </CountersContainer>

      <div className="flex flex-col flex-grow gap-1">
        <p className="tracking-wider font-light mb-4">Recent Notes</p>

        {isLoading ? (
          <DashboardLoader />
        ) : !isNotes ? (
          <p className="py-2">
            <span className="tracking-wider font-light">
              No notes/memos available
            </span>
          </p>
        ) : (
          <>
            {memosRecent.map((item) => (
              <div
                key={item.noteId}
                className="flex justify-between p-4 hover:bg-primary hover:bg-opacity-10 rounded-lg"
              >
                <NotesItem note={item} />
              </div>
            ))}
          </>
        )}
      </div>
      {isNotes ? <SeeAll url={'memos'} label="Notes" /> : null}
    </DashboardCard>
  )
}
