import { ROUTES } from './routing'

import { accessControlSubNavigation } from 'setups/controller'

const projectClient = process.env.REACT_APP_PROJ

export const SubsquadTabModulesData = [
  {
    module: 'Member',
    route: {
      title: 'Members',
      path: ROUTES.MerchantProfileTransactions,
      pathname: 'members',
      className:
        'text-black py-2 mx-3 block hover:text-primary focus:font-bold focus:outline-none',
    },
  },
]

export const SUBSQUADTABSNAVIGATION = accessControlSubNavigation(
  projectClient,
  SubsquadTabModulesData,
  'subsquad'
)
