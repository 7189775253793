import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const MerchantDevicesDefaultSchemas = DefaultSchemas

export const MerchantDevicesDataSchemas = zod.object({
    merchantId: zod.number(),
    merchantName: zod.string(),
    merchantCode: zod.number(),
    deviceId: zod.number(),
    deviceCode: zod.string(),
    deviceTypeId: zod.number(),
    startDate: zod.string(),
    endDate: zod.string(),
    comments: zod.string().nullable(),
})

export const MerchantDevicesAPISchemas = MerchantDevicesDefaultSchemas.merge(
    zod.object({
        devices: zod.array(MerchantDevicesDataSchemas),
    })
)

export const AddMerchantDevicesAPISchemas = MerchantDevicesDefaultSchemas

export const UpdateMerchantDevicesAPISchemas = MerchantDevicesDefaultSchemas

export const MerchantDevicesFetchParamsSchemas = zod.object({
    traderId: zod.number().optional(),
    pSize: zod.number().optional(),
    pNumber: zod.number().optional(),
    merchantCode: zod.string().optional(),
    deviceTypeId: zod.number().optional(),
})

export const AddMerchantDevicesFetchParamsSchemas = zod.object({
    traderId: zod.number(),
    deviceCode: zod.number(),
    deviceTypeId: zod.number().optional(),
    startDate: zod.string(),
    endDate: zod.string(),
})

export const UpdateMerchantDevicesFetchParamsSchemas = zod.object({
    traderId: zod.number(),
    deviceCode: zod.number(),
    startDate: zod.string().optional(),
    endDate: zod.string().optional(),
})

