import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { NotesUpdateParamsSchemas } from 'schemas/notesSchemas'
import { NotesUpdateParamsTypes } from 'types/notes'

export const useMemberNotesUpdateForm = ({
  defaultValues,
}: {
  defaultValues: NotesUpdateParamsTypes
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<NotesUpdateParamsTypes>({
    resolver: zodResolver(NotesUpdateParamsSchemas),
    defaultValues,
  })

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    reset,
    watch,
  }
}
