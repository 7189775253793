import React from 'react'

import Modal from 'components/Modal'
import { Notifications } from 'types/notifications'

const Index = ({
  notifications,
  open,
  onClose,
}: {
  notifications: Notifications[]
  open: boolean
  onClose: () => void
}): JSX.Element => {
  return (
    <Modal
      contentStyle={{ minHeight: '45vh' }}
      open={open}
      title="HDA updates"
      onClose={onClose}
      noFooter
    >
      <ul className="flex flex-col gap-4">
        {notifications.map((item) => (
          <li key={item.title}>
            <div>
              <p>
                <strong>{item.title}</strong>
              </p>
              <p>{item.description}</p>
              <p>{item.date}</p>
            </div>
          </li>
        ))}
      </ul>
    </Modal>
  )
}

export default Index
