import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import {
  getMerchantsLookup,
  getMerchantsTraderLookupOptions,
} from 'api/merchant'

import useMerchantStore from 'store/merchant'
import {
  MerchantLookupTypes,
  MerchantLookupTxSetFieldTypes,
  MerchantLookupTxFormTypes,
  MerchantLookupTxFormSubmitValuesTypes,
  MerchantLookupTxFormSubmitActionsTypes,
} from 'types/merchanTxTypes'
import { DropDownItem } from 'types/components'

import { MemberDetail } from 'types/member'
import useMemberStore from 'store/member'

const MerchantLookupTxINITVAL: MerchantLookupTxFormTypes = {
  value: '',
  field: '',
}

export const useMerchantLookupTx = (
  traderId: string,
  setFieldValue: MerchantLookupTxSetFieldTypes
): {
  merchants: MerchantLookupTypes[]
  member: MemberDetail | undefined
  isLoading: boolean
  initialValues: MerchantLookupTxFormTypes
  handleSubmitMerchantLookupTx: (
    values: MerchantLookupTxFormSubmitValuesTypes
  ) => void
  handleSelectMerchantResultTx: (
    merchantCode: number,
    merchantDeviceCode: string | number | null
  ) => void
  merchantLookupDropdownOptions: (inputParam: string) => Promise<DropDownItem[]>
} => {
  // STATES
  const getMerchantLookup = useMerchantStore((state) => state.getMerchantLookup)
  const [merchants, merchantsSet] = useState<MerchantLookupTypes[]>([])
  const member = useMemberStore((state) => state.member)
  const [isLoading, isLoadingSet] = useState<boolean>(false)
  const [initialValues, initialValuesSet] = useState<MerchantLookupTxFormTypes>(
    {
      ...MerchantLookupTxINITVAL,
    }
  )

  const handleSelectMerchantResultTx = useCallback(
    (merchantCode: number, merchantDeviceCode: string | number | null) => {
      setFieldValue('storeId', merchantCode)
      setFieldValue('deviceCode', String(merchantDeviceCode))
    },
    [setFieldValue]
  )

  const handleSubmitMerchantLookupTx = useCallback(
    async (values: MerchantLookupTxFormSubmitValuesTypes) => {
      isLoadingSet(true)
      try {
        const merchants = await getMerchantsLookup(
          `?${values.field}=${values.value}`
        )
        if (!merchants.length) throw 'No merchants found.'
        merchantsSet(merchants)
        toast.success('Merchant lookup result available.')
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        toast.error(`ERR: ${message.outcomeUserMessage}`)
      }
      isLoadingSet(false)
    },
    [isLoadingSet, merchantsSet]
  )

  const merchantLookupDropdownOptions = async (inputParam: string) => {
    if (inputParam.length < 3) return []
    const merchantQuery = `?traderId=${traderId}&merchantName=${inputParam}`
    const merchantLookup = await getMerchantsTraderLookupOptions(merchantQuery)
    return merchantLookup.map((item) => ({
      label: `${item.merchantName} - ${item.deviceCode}`,
      value: `${String(item.traderId)}/${String(item.deviceCode)}`,
    }))
  }

  return {
    merchants,
    member,
    isLoading,
    initialValues,
    handleSelectMerchantResultTx,
    handleSubmitMerchantLookupTx,
    merchantLookupDropdownOptions,
  }
}
