import {
  faChevronDown,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Menu, Transition } from '@headlessui/react'
import classnames from 'classnames'
import React from 'react'

type Props = {
  title?: string | React.ReactElement
  options?: Array<{ label: string; value: string; icon?: IconDefinition }>
  right?: boolean
  disabled?: boolean
  toggleClassName?: string
  menuClassName?: string
  itemClassName?: string
  itemIconClassName?: string
  icon?: IconDefinition
  iconPosition?: string
  iconColor?: string
  iconClassName?: string
  onSelect?: (value: string) => void
  displayArrowDown?: boolean
  itemStyle?: React.CSSProperties
}

function IconDropdown({
  title,
  options,
  right,
  disabled,
  toggleClassName,
  menuClassName,
  itemClassName,
  itemIconClassName,
  icon,
  iconPosition = 'right',
  iconColor,
  iconClassName,
  displayArrowDown,
  itemStyle,
  onSelect,
}: Props): React.ReactElement {
  return (
    <div className="relative">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              className={classnames(toggleClassName)}
              disabled={disabled}
            >
              {iconPosition === 'left' && icon ? (
                <Icon
                  icon={icon}
                  className={classnames(iconClassName)}
                  color={iconColor ? iconColor : 'white'}
                  style={{ marginRight: 5 }}
                />
              ) : (
                ''
              )}
              {title}
              {iconPosition === 'right' && icon ? (
                <Icon
                  icon={icon}
                  className={classnames(iconClassName)}
                  color={iconColor ? iconColor : 'white'}
                  style={{ marginRight: 5 }}
                />
              ) : (
                ''
              )}

              {displayArrowDown && (
                <Icon
                  icon={faChevronDown}
                  className={classnames(iconClassName)}
                  color={iconColor ? iconColor : 'white'}
                  style={{ marginLeft: 5 }}
                />
              )}
            </Menu.Button>

            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              {options && (
                <Menu.Items
                  className={classnames(
                    'flex flex-col border bg-white border-gray-900 mt-1 absolute focus:outline-none z-50',
                    {
                      'right-0 origin-top-right': right,
                    },
                    menuClassName
                  )}
                >
                  {options.map((item, index) => (
                    <Menu.Item key={`${item?.value}-${index}`}>
                      <button
                        type="button"
                        className={classnames(
                          'p-3 py-2 text-left hover:bg-gray-300',
                          itemClassName
                        )}
                        style={itemStyle || undefined}
                        onClick={() => {
                          if (onSelect) {
                            onSelect(item.value)
                          }
                        }}
                      >
                        {item?.icon ? (
                          <Icon
                            icon={item?.icon}
                            className={`mr-2 ${itemIconClassName}`}
                          />
                        ) : (
                          ''
                        )}

                        <span className="tracking-wider font-light">
                          {item?.label}
                        </span>
                      </button>
                    </Menu.Item>
                  ))}
                </Menu.Items>
              )}
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

export default IconDropdown
