import Card from 'components/Card'

const Index = ({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}): JSX.Element => (
  <Card className="flex flex-col bg-white shadow-lg">
    <h3 className="tracking-wider font-bold text-3xl">{title}</h3>
    {children}
  </Card>
)

export default Index
