import React, { useCallback, useState } from 'react'

import { useParams } from 'react-router-dom'
import Dropdown, { OptionType } from 'components/Dropdown'
import { DropDownItem } from 'types/components'
import { Form, Formik } from 'formik'

import { MerchantLookupTxSetFieldTypes } from 'types/merchanTxTypes'
import { MerchantLookupTxSchemas } from 'schemas/merchantTxSchemas'
import { useMerchantLookupTx } from './useMerchantLookupTx'
import Input from 'components/Input'

export interface ColourOption {
  readonly value: string
  readonly label: string
  readonly color: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}

const Index: React.FC<{
  setFieldValue: MerchantLookupTxSetFieldTypes
  title?: string
  placeholder?: string
}> = ({ setFieldValue: setFieldValueMain, title, placeholder }) => {
  const { traderId } = useParams()
  const [selectedMerchant, setSelectedMerchant] = useState<string>('') // Stores merchant name for display
  const [selectedTraderId, setSelectedTraderId] = useState<string>('') // Stores traderId for form submission
  const {
    initialValues,
    handleSubmitMerchantLookupTx,
    merchantLookupDropdownOptions,
  } = useMerchantLookupTx(String(traderId), setFieldValueMain)

  // start: Merchant lookup dropdown options handler
  const merchantLoadOptions = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      return merchantLookupDropdownOptions(inputValue)
    },
    [merchantLookupDropdownOptions]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={MerchantLookupTxSchemas}
      onSubmit={handleSubmitMerchantLookupTx}
    >
      {({ values, setFieldValue }) => (
        <Form className="flex flex-col">
          <div className="mb-4">
            <Dropdown
              title={title || 'Merchant Lookup'}
              value={selectedMerchant}
              placeholder={placeholder || "Search merchant ..."}
              loadOptions={merchantLoadOptions}
              onChange={(option: OptionType) => {
                const merchant = String(option?.value).split('/')
                const traderId = merchant[0]
                const deviceCode = merchant[1]
                setFieldValueMain('storeId', traderId)
                setFieldValueMain('deviceCode', deviceCode)
                setSelectedTraderId(traderId)
              }}
              className="w-full"
              borderColor="#C4C4C4"
              containerClassName="focus:border-primary"
              isSearchable
              async
            />
          </div>
          <Input
            type="hidden"
            name="enrollmentTraderId"
            value={selectedTraderId}
          />
        </Form>
      )}
    </Formik>
  )
}

export default Index
