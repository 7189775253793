import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import { InputField } from 'components/InputField'
import Button from 'components/Button'
import { IdentificationsDataTypes } from 'types/identifications'

import Dropdown from 'components/Dropdown'
import { useMemberIdentificationsMerge } from './useMemberIdentificationsMerge'
import { useMemberIdentificationsMergeForm } from './useMemberIdentificationsMergeForm'

import { MERGE_OPTIONS } from 'constants/filters'

const Index = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: IdentificationsDataTypes
}): JSX.Element => {
  const {
    memberIdentificationsMergeModStatus,
    memberIdentificationsMergeParams,
    memberHandleIdentificationsMerge,
  } = useMemberIdentificationsMerge({
    modalClose,
    selectedRowData,
  })

  const { control, handleSubmit, setValue, watch } =
    useMemberIdentificationsMergeForm({
      defaultValues: memberIdentificationsMergeParams,
    })

  return (
    <FormsLayout.Wrapper
      onSubmit={handleSubmit(memberHandleIdentificationsMerge)}
    >
      <FormsLayout.Item>
        <Controller
          name="addNewCardFlag"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Merge Options"
              defaultValue={MERGE_OPTIONS[0]}
              options={MERGE_OPTIONS}
              className="w-full menu-outer-top tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) => {
                setValue('addNewCardFlag', value.value)
              }}
              overflowStyle
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.Item>
        <Controller
          name="identDonor"
          control={control}
          render={({ field }) => (
            <InputField
              onChange={(e) => {
                setValue('identDonor', e.target.value)
              }}
              type="number"
              name="identDonor"
              label="Card Number"
              helper={
                watch('addNewCardFlag') === '1'
                  ? '* Enter card number to be merged to this account. This new card number should not have available balance.'
                  : '* Enter card number to be merged to this account'
              }
              containerClassName="flex flex-col flex-grow"
              rounded
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          type="submit"
          className="rounded bg-primary tracking-wider font-bold"
        >
          Merge identifier
        </Button>
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
