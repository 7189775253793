import { formatter } from 'utils'

import { useMemberProfileVersion } from './useMemberProfileVersion'

const Index = (): JSX.Element => {
  const { memberMemberProfiles } = useMemberProfileVersion()

  return (
    <>
      {memberMemberProfiles.map((item) => (
        <div key={item.traderId} className="flex flex-col gap-2">
          <p className="text-xs uppercase font-light tracking-wider">
            Mailing Preference
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.mailingPreferenceName || '---'}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">
            Last Modified
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {formatter.displayDate(item.lastModifiedDate)}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">
            TNC Version
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.tncVersion || '---'}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">
            Privacy Version
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.privacyVersion || '---'}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">Ranking</p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.ranking || '---'}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">RAF</p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.promoCode || '---'}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">PROMO</p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.website || '---'}
          </p>
        </div>
      ))}
    </>
  )
}

export default Index
