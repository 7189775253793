import InnerWrapper from '../InnerWrapper'
import Modal from 'components/Modal'
import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import TSTable from 'components/Tanstack'
import { ToggleMenu } from 'components/Layout/ToggleMenu'

import IdentificationFilterForm from './IdentificationFilterForm/index_modal'
import IdentificationMergeModalForm from './IdentificationMergeModalForm'
import IdentificationStatusUpdateModalForm from './IdentificationStatusUpdateModalForm'
import IdentificationAddReplaceModalForm from './IdentificationAddReplaceModalForm'
import { useMemberIdentifications } from './useMemberIdentifications'
import { useMemberIdentificationsTable } from './useMemberIdentificationsTable'

const Index = (): JSX.Element => {
  const {
    memberIdentifications,
    memberIdentificationsModStatus,
    memberIdentificationsFilterParams,
    memberHandleIdentificationsFilter,
    memberHandleIdentificationsUnfilter,
  } = useMemberIdentifications()

  const {
    memberIdentificationsActionModal,
    memberIdentificationsSelected,
    memberIdentificationsTableData,
    memberIdentificationsTableColumns,
    handleMemberActionModalClose,
  } = useMemberIdentificationsTable()

  return (
    <>
      <InnerWrapper title="Identifications">
        {/* FILTER */}
        {/* <IdentificationFilterForm
          formStatus={memberIdentificationsModStatus}
          formDefaultValues={memberIdentificationsFilterParams}
          onFilter={memberHandleIdentificationsFilter}
          onFilterClear={memberHandleIdentificationsUnfilter}
        /> */}

        {/* ADVANCE SEARCH  */}
        <div className="pb-6">
          <ToggleMenu
            type="search"
            label="Search Identifiers"
            isTriggeToggle={
              memberIdentificationsModStatus === 'success' || false
            }
          >
            <IdentificationFilterForm
              formStatus={memberIdentificationsModStatus}
              formDefaultValues={memberIdentificationsFilterParams}
              onFilter={memberHandleIdentificationsFilter}
              onFilterClear={memberHandleIdentificationsUnfilter}
            />
          </ToggleMenu>
        </div>

        {/* FAIL */}
        {memberIdentificationsModStatus === 'fail' ? (
          <NoResults
            header="Failed to load data"
            subtitle="Please refresh the page and try again, or contact
        your system administrator"
            noImage
          />
        ) : null}

        {/* LOADING */}
        {memberIdentificationsModStatus === 'loading' ? <Spinner /> : null}

        {/* SUCCESS */}
        {memberIdentifications.length &&
        memberIdentificationsModStatus === 'success' ? (
          <TSTable.Table
            data={memberIdentificationsTableData}
            columns={memberIdentificationsTableColumns}
          />
        ) : null}

        {/* EMPTY */}
        {!memberIdentifications.length &&
        memberIdentificationsModStatus === 'success' ? (
          <NoResults
            header="No available data"
            subtitle="There is no available data to display"
            noImage
          />
        ) : null}
      </InnerWrapper>

      {/* ADD IDENT */}
      {/* ** HAMPSTA */}
      {/* {AddIdenOptionModal && selectedRow && (
        <Modal
          open={AddIdenOptionModal}
          title="Add/Replace Card"
          onClose={handleModalClose}
          noFooter
        >
          <AddIdentOptionForm
            traderId={memberTraderId}
            showModal={AddIdenOptionModal}
            showModalSet={handleAddIdenOptionModal}
            oldIdent={oldIdent}
            oldIdentType={oldIdentType}
            oldIdentStatusId={oldIdentStatusId}
          />
        </Modal>
      )} */}

      {/* MERGE IDENT */}
      {memberIdentificationsActionModal === 'idents_hampsta_addreplace' &&
      memberIdentificationsSelected ? (
        <Modal
          open={true}
          title="Add/Replace Card"
          onClose={handleMemberActionModalClose}
          noFooter
          modalSize="regular"
        >
          <IdentificationAddReplaceModalForm
            modalClose={handleMemberActionModalClose}
            selectedRowData={memberIdentificationsSelected}
          />
        </Modal>
      ) : null}

      {/* MERGE IDENT */}
      {memberIdentificationsActionModal === 'idents_merge' &&
      memberIdentificationsSelected ? (
        <Modal
          open={true}
          title="Merge Identifiers"
          onClose={handleMemberActionModalClose}
          noFooter
          modalSize="regular"
        >
          <IdentificationMergeModalForm
            modalClose={handleMemberActionModalClose}
            selectedRowData={memberIdentificationsSelected}
          />
        </Modal>
      ) : null}

      {/* UPDATE IDENT STATUS */}
      {memberIdentificationsActionModal === 'idents_status_update' &&
      memberIdentificationsSelected ? (
        <Modal
          open={true}
          title="Update Identifier Status"
          onClose={handleMemberActionModalClose}
          noFooter
        >
          <IdentificationStatusUpdateModalForm
            modalClose={handleMemberActionModalClose}
            selectedRowData={memberIdentificationsSelected}
          />
        </Modal>
      ) : null}

      {/* MODALS */}
      {/* EMAIL PREVIEW */}
      {/* {memberOffersActionModal === 'email_preview' && memberCommsSelected ? ( */}
      {/* <Modal open={true} title="Search Offers" onClose={() => false} noFooter>
        <IdentificationFilterForm
          formStatus={memberIdentificationsModStatus}
          formDefaultValues={memberIdentificationsFilterParams}
          onFilter={memberHandleIdentificationsFilter}
          onFilterClear={memberHandleIdentificationsUnfilter}
        />
      </Modal> */}
      {/* ) : null} */}
    </>
  )
}

export default Index
