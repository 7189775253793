import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import {
  CreateMerchantDeviceTypes,
  CreateMerchantDeviceFormTypes,
  CreateMerchantDeviceFormSubmitValuesTypes,
  CreateMerchantDeviceFormSubmitActionsTypes,
} from 'types/merchant'
import useMerchantStore from 'store/merchant'

const CREATEMERCHANTDEVICEINITVAL: CreateMerchantDeviceTypes = {
  traderId: 0,
  deviceCode: '',
  deviceTypeId: 5,
  startDate: '',
  endDate: '',
}

export const useCreateDevice = (
  onCloseModal: () => void
): {
  isLoading: boolean
  initialValues: CreateMerchantDeviceTypes
  handleSubmitCreateMerchantDevice: (
    values: CreateMerchantDeviceFormSubmitValuesTypes,
    actions: CreateMerchantDeviceFormSubmitActionsTypes
  ) => void
  // handleClearPurchaseTx: () => void
} => {
  const { traderId } = useParams()
  const merchantTraderId = Number(traderId) || 0
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // MERCHANT CREATE IDENT START
  const createMerchantDevice = useMerchantStore(
    (state) => state.createMerchantDevice
  )
  const merchantDeviceCreateLoading = useMerchantStore(
    (state) => state.merchantDeviceCreateLoading
  )
  // MERCHANT CREATE IDENT END
  // MISC STATES START
  const [initialValues, initialValuesSet] =
    useState<CreateMerchantDeviceFormTypes>({
      ...CREATEMERCHANTDEVICEINITVAL,
      traderId: merchantTraderId,
    })
  // MISC STATES END

  const isLoading = useMemo(() => {
    return merchantDeviceCreateLoading ? true : false
  }, [merchantDeviceCreateLoading])

  const handleSubmitCreateMerchantDevice = useCallback(
    async (
      values: CreateMerchantDeviceFormSubmitValuesTypes,
      actions: CreateMerchantDeviceFormSubmitActionsTypes
    ) => {
      try {
        await createMerchantDevice(merchantTraderId, values)
        toast.success('Merchant Device added successful.')
        onCloseModal()
        // actions.setSubmitting(false)
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        if (message.outcomeCode === 'HDA_MERCHANT:ADD_DEVICE:6. 6') {
          toast.error(`Merchant Device already exist.`)
        } else {
          toast.error(`Merchant Device error.`)
        }
      }
    },
    [createMerchantDevice]
  )

  const handleClearPurchaseTx = useCallback(() => {
    initialValuesSet({ ...CREATEMERCHANTDEVICEINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    isLoading,
    initialValues,
    handleSubmitCreateMerchantDevice,
    // handleClearPurchaseTx,
  }
}
