import { getHDANotifications } from 'api/notifications'
import { useCallback, useEffect, useState } from 'react'

import { Notifications } from 'types/notifications'

const useNotifications = (
  instance: string
): {
  notifications: Notifications[]
  isNotification: boolean
  isModalOpen: boolean
  handleToggleModal: () => void
  handleCloseModal: () => void
} => {
  const [isModalOpen, isModalOpenSet] = useState<boolean>(false)
  const [notifications, notificationsSet] = useState<Notifications[]>([])
  const [isNotification, isNotificationSet] = useState<boolean>(false)

  const handleGetNotifications = useCallback(
    async (instance: string) => {
      const response = await getHDANotifications(instance)
      const notifications = response
      if (notifications.length > 0) {
        notificationsSet(notifications)
        isNotificationSet(true)
      }
      // const result = await response.json();
    },
    [notificationsSet, isNotificationSet]
  )

  const handleToggleModal = useCallback(() => {
    isModalOpenSet(!isModalOpen)
  }, [isModalOpen, isModalOpenSet])

  const handleCloseModal = useCallback(() => {
    isModalOpenSet(false)
  }, [isModalOpenSet])

  useEffect(() => {
    handleGetNotifications(instance)
  }, [])

  return {
    notifications: notifications.filter((item) => item.project === instance),
    isNotification,
    isModalOpen,
    handleToggleModal,
    handleCloseModal,
  }
}

export default useNotifications
