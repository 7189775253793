import Modals from './Modals'
import Tabs from './Tabs'

import Activity from './Activity'
import Balance from './Balance'
import Logo from './Logo'

const Index = (): JSX.Element => {
  return (
    <div className="flex flex-col bg-white">
      <section className="flex p-4 m-4 bg-green-100 rounded-xl justify-between">
        <section className="flex flex-col gap-4">
          <Balance />
          <Activity />
        </section>
        <section>
          <Logo />
        </section>
      </section>

      <section className="flex flex-col gap-4 px-4 pt-4">
        <section>
          <Modals />
        </section>
        <section>
          <Tabs />
        </section>
      </section>
    </div>
  )
}

export default Index
