import { Auth, CognitoUser } from '@aws-amplify/auth'
import { Amplify, Hub } from '@aws-amplify/core'
import { HubCallback } from '@aws-amplify/core/lib/Hub'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { createContext, useCallback, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import useAuthStore from 'store/auth'

const COGNITO_CONFIG = {
  REGION: process.env.REACT_APP_COGNITO_REGION,
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
}

interface IAuthContext {
  signIn(username: string, password: string): Promise<CognitoUser>
  signOut(): Promise<void>
  forgotPassword(username: string): Promise<CognitoUser>
  forgotPasswordSubmit(
    username: string,
    code: string,
    newPassword: string
  ): Promise<string>
  getSession: () => Promise<CognitoUserSession | null>
}

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: COGNITO_CONFIG.REGION,
    userPoolId: COGNITO_CONFIG.USER_POOL_ID,
    userPoolWebClientId: COGNITO_CONFIG.APP_CLIENT_ID,
  },
})

const signIn = (username: string, password: string): Promise<CognitoUser> =>
  Auth.signIn(username, password)

const signOut = (): Promise<void> => Auth.signOut()

const forgotPassword = (username: string): Promise<CognitoUser> =>
  Auth.forgotPassword(username)

const forgotPasswordSubmit = (
  username: string,
  code: string,
  newPassword: string
): Promise<string> => Auth.forgotPasswordSubmit(username, code, newPassword)

const getSession = (): Promise<CognitoUserSession | null> =>
  Auth.currentSession()

export const useCognito = (): IAuthContext => {
  const authSignIn = useAuthStore((state) => state.signIn)
  const authSignOut = useAuthStore((state) => state.signOut)
  // const [user, setUser] = useState<IUser | null>()
  // const sessionLogin = useStoreActions((actions) => actions.session.login)
  // const sessionLogout = useStoreActions((actions) => actions.session.logout)
  // const setLoading = useStoreActions((actions) => actions.session.setLoading)

  const authListener: HubCallback = useCallback(
    ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          authSignIn(
            data.username,
            data.signInUserSession.accessToken.getJwtToken()
          )
          // setUser({
          //   username: data.username,
          //   cognitoToken: data.signInUserSession.accessToken,
          // })
          // sessionLogin({
          //   cognitoToken: data.signInUserSession.accessToken.getJwtToken(),
          // })
          break
        case 'signOut':
          authSignOut()
          // setUser(null)
          // sessionLogout()
          break
      }
    },
    [authSignIn, authSignOut]
  )

  useEffect(() => {
    getSession()
      .then((session) => {
        if (session && session.isValid()) {
          Auth.currentUserInfo()
            .then(({ username }) => {
              authSignIn(
                username,
                session.getAccessToken().getJwtToken()
              ).catch((error) => {
                authSignOut()

                // setUser(null)
                // sessionLogout()
              })

              // const cognitoToken = session.getAccessToken()
              // setUser({ username, cognitoToken })
              // sessionLogin({ cognitoToken: cognitoToken.getJwtToken() })
            })
            .catch((error) => {
              toast.error(error)
              authSignOut()

              // setUser(null)
              // sessionLogout()
            })
        }
      })
      .catch((error) => {
        // console.log({ error })
        // toast.error(error)
        authSignOut()
        // setLoading()
        // setUser(null)
        // sessionLogout()
      })
    // }, [sessionLogin, sessionLogout, setLoading])
  }, [authSignIn, authSignOut])

  useEffect(() => {
    Hub.listen('auth', authListener)
    return () => Hub.remove('auth', authListener)
  }, [authListener])

  return { signIn, signOut, forgotPassword, forgotPasswordSubmit, getSession }
}

export const AuthContext = createContext<IAuthContext>({
  signIn,
  signOut,
  forgotPassword,
  forgotPasswordSubmit,
  getSession,
})

export const useAuth = (): IAuthContext => useContext(AuthContext)
