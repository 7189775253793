import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Colors from 'colors.json'

import Dropdown, { OptionType } from 'components/Dropdown'
import Input from 'components/Input'
import Button from 'components/Button'
import { Form, Formik } from 'formik'

import { CreateMerchantSchemas } from 'schemas/createMerchantSchemas'
import { useCreateMerchant } from './useCreateMerchant'
import MerchantLookupTx from 'views/MemberProfile/ManualTx/MerchantLookupTx'

import { DropDownItem } from 'types/components'

const Index: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}): React.ReactElement => {
  // STATES
  const { traderId } = useParams()
  const [isAddress, isAddressSet] = useState<boolean>(false)
  const {
    merchantBatch,
    isLoading,
    initialValues,
    handleSubmitCreateMerchant,
  } = useCreateMerchant(Number(traderId), onCloseModal)

  const MERCHANTBATCH_OPTIONS: DropDownItem[] = merchantBatch.map((batch) => {
    return {
      label: `${batch.batchName}`,
      value: `${batch.batchId} + ${batch.countryId}`,
    }
  })

  // if (isLoading) return <></>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateMerchantSchemas}
      onSubmit={handleSubmitCreateMerchant}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form className="flex flex-col">
            <div className="mb-4">
              <div className="flex gap-4 items-center">
                <Input
                  type="text"
                  name="name"
                  label="Merchant Name"
                  required
                  containerClassName="w-full"
                />
              </div>
              <div className="flex gap-4 items-center">
                <Input
                  type="number"
                  name="merchantCode"
                  label="Merchant Code"
                  helper="?"
                  helperDescription="Submitting without a Merchant Code will use the Trader Id as the value."
                  required
                  containerClassName="w-full"
                />
              </div>
              <div className="">
                <Dropdown
                  title="Select Store Location"
                  options={MERCHANTBATCH_OPTIONS}
                  className="mb-5"
                  borderColor="#C4C4C4"
                  onSelect={(item: DropDownItem) => {
                    const batch = item.value.split('+')
                    setFieldValue('batchId', Number(batch[0]))
                    setFieldValue('countryId', Number(batch[1]))
                  }}
                  disabled={isSubmitting}
                  roundedCorners
                />
              </div>
              <div className="flex gap-4 items-center">
                <Input
                  type="text"
                  name="batchId"
                  label="Batch ID"
                  required
                  containerClassName="w-full"
                  disabled
                />
                <Input
                  type="text"
                  name="countryId"
                  label="Country ID"
                  required
                  containerClassName="w-full"
                  disabled
                />
              </div>

              <div className="flex gap-2 mb-4">
                <input
                  type={'checkbox'}
                  onClick={() => isAddressSet(!isAddress)}
                />
                <span className="font-light">Add more details</span>
              </div>
              {isAddress ? (
                <>
                  <div className="flex gap-4 items-center ">
                    <Input
                      type="text"
                      name="externalId"
                      label="External ID (optional)"
                      required
                      containerClassName="w-full"
                    />
                  </div>
                  <div className="flex gap-4 items-center">
                    <Input
                      type="text"
                      name="addressLine1"
                      label="Address 1 (optional)"
                      required
                      containerClassName="w-full"
                    />
                    <Input
                      type="text"
                      name="addressLine2"
                      label="Address 2 (optional)"
                      required
                      containerClassName="w-full"
                    />
                  </div>
                  <div className="flex gap-4 items-center">
                    <Input
                      type="text"
                      name="addressLine3"
                      label="Address 3 (optional)"
                      required
                      containerClassName="w-full"
                    />
                    <Input
                      type="text"
                      name="addressLine4"
                      label="Address 4 (optional)"
                      required
                      containerClassName="w-full"
                    />
                  </div>
                  <div className="flex gap-4 items-center">
                    <Input
                      type="text"
                      name="suburb"
                      label="Suburb (optional)"
                      required
                      containerClassName="w-full"
                    />
                    <Input
                      type="text"
                      name="city"
                      label="City (optional)"
                      required
                      containerClassName="w-full"
                    />
                    <Input
                      type="text"
                      name="postCode"
                      label="Postal Code (optional)"
                      required
                      containerClassName="w-full"
                    />
                  </div>
                </>
              ) : null}
            </div>
            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Submit</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onCloseModal}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Index
