import { VouchersDataTypes } from 'types/vouchers'

const Index = ({ voucher }: { voucher: VouchersDataTypes }): JSX.Element => {
  return (
    <>
      <div className="flex items-center">
        <span className="tracking-wider font-bold">{voucher.name}</span>
      </div>
      <p className="text-gray-500 uppercase">{voucher.status}</p>
    </>
  )
}

export default Index
