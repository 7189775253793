import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import { purchaseAPI } from 'api/purchase'
import { MerchantLookupTypes } from 'types/merchanTxTypes'
import {
  PurchaseTxFormTypes,
  PurchaseTxFormSubmitValuesTypes,
  PurchaseTxFormSubmitActionsTypes,
} from 'types/purchaseTxTypes'
import { MemberDetail } from 'types/member'
import useMemberStore from 'store/member'

const PURCHASETXINITVAL: PurchaseTxFormTypes = {
  transactionValue: 0,
  creditValue: 0,
  loyaltyValue: 0,
  externalTransactionId: 0,
  ident: '',
  storeId: 0,
  deviceCode: '',
  cashier: '',
  guid: '',
  transactionDate: '',
  comments: '',
  traceNumber: 0,
  reasonId: 0,
}

export const usePurchaseTx = (
  traderId: number,
  onCloseModal: () => void
): {
  member: MemberDetail | undefined
  isLoading: boolean
  initialValues: PurchaseTxFormTypes
  isMerchantLookupToggle: boolean
  handleIsMerchantLookupToggle: () => void
  handleMerchantSet: (merchant: MerchantLookupTypes[]) => void
  handleSubmitPurchaseTx: (
    values: PurchaseTxFormSubmitValuesTypes,
    actions: PurchaseTxFormSubmitActionsTypes
  ) => void
  handleClearPurchaseTx: () => void
} => {
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // STATES
  const [isMerchantLookupToggle, isMerchantLookupToggleSet] =
    useState<boolean>(false)
  const [isLoading, isLoadingSet] = useState<boolean>(false)
  const [purchaseTxMerchant, purchaseTxMerchantSet] = useState<
    MerchantLookupTypes[]
  >([])
  const member = useMemberStore((state) => state.member)
  const [initialValues, initialValuesSet] = useState<PurchaseTxFormTypes>({
    ...PURCHASETXINITVAL,
    cashier: authTokenUsername,
    externalTransactionId: formatter.externalIdentification(),
    ident: String(member?.cardNumber) || '',
  })

  const handleIsMerchantLookupToggle = useCallback(() => {
    isMerchantLookupToggleSet(!isMerchantLookupToggle)
  }, [isMerchantLookupToggle, isMerchantLookupToggleSet])

  const handleMerchantSet = useCallback(
    async (merchant: MerchantLookupTypes[]) => {
      try {
        purchaseTxMerchantSet(merchant)
        toast.success('Merchant Selected')
      } catch (error) {
        toast.error('Something went wrong.')
      }
    },
    [purchaseTxMerchantSet]
  )

  const handleSubmitPurchaseTx = useCallback(
    async (
      values: PurchaseTxFormSubmitValuesTypes,
      actions: PurchaseTxFormSubmitActionsTypes
    ) => {
      isLoadingSet(true)
      try {
        if (String(values.externalTransactionId) === "") {
          values.externalTransactionId = initialValues.externalTransactionId + 1
        }
        await purchaseAPI(values)
        toast.success('Purchase transaction successful.')
        // actions.setSubmitting(false)
        onCloseModal()
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        toast.error(message.outcomeUserMessage)
      }
      isLoadingSet(false)
    },
    [isLoadingSet]
  )

  const handleClearPurchaseTx = useCallback(() => {
    initialValuesSet({ ...PURCHASETXINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    member,
    isLoading,
    initialValues,
    isMerchantLookupToggle,
    handleIsMerchantLookupToggle,
    handleMerchantSet,
    handleSubmitPurchaseTx,
    handleClearPurchaseTx,
  }
}
