import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getDirectPayments_API } from 'api/financials'
import { useDirectPaymentsStore } from 'store/financials'
import { PaymentInstructionsFetchDataTypes } from 'types/financials'

import { DATASTATUS } from 'constants/status'

export const useMemberDirectPayments = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const directPaymentsModStatus = useDirectPaymentsStore(
    (state) => state.directpaymentsModStatus
  )
  const directPaymentsModStatusSet = useDirectPaymentsStore(
    (state) => state.directpaymentsModStatusSet
  )
  const directPaymentsModErrors = useDirectPaymentsStore(
    (state) => state.directpaymentsModErrorsSet
  )
  const directPaymentsModErrorsSet = useDirectPaymentsStore(
    (state) => state.directpaymentsModErrorsSet
  )
  const directPayments = useDirectPaymentsStore((state) => state.directpayments)
  const directPaymentsSet = useDirectPaymentsStore(
    (state) => state.directpaymentsSet
  )
  const [directPaymentsFilterParams, directPaymentsFilterParamsSet] =
    useState<PaymentInstructionsFetchDataTypes>(defaultParams)
  const [isDirectPaymentsFiltered, isDirectPaymentsFilteredSet] =
    useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataDirectPayments,
    isLoading: isLoadingDirectPayments,
    isError: isErrorDirectPayments,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useDirectPayments', directPaymentsFilterParams],
    queryFn: async () => {
      try {
        directPaymentsModStatusSet('loading')
        const response = await getDirectPayments_API(directPaymentsFilterParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        directPaymentsModStatusSet('success')
        return response.directPaymentInstruction
      } catch (e) {
        directPaymentsModStatusSet('fail')
        directPaymentsModErrorsSet(JSON.stringify(e))
      }
    },
  })

  const handleFilter = useCallback(
    async (fetchParams: PaymentInstructionsFetchDataTypes) => {
      directPaymentsFilterParamsSet(fetchParams)
      isDirectPaymentsFilteredSet(true)
    },
    [directPaymentsFilterParamsSet, isDirectPaymentsFilteredSet]
  )

  const handleUnfilter = useCallback(() => {
    const test = { traderId: memberTraderId }
    directPaymentsFilterParamsSet(test)
    isDirectPaymentsFilteredSet(false)
  }, [directPaymentsFilterParamsSet, isDirectPaymentsFilteredSet])

  useEffect(() => {
    if (dataDirectPayments) directPaymentsSet(dataDirectPayments)
  }, [dataDirectPayments, directPaymentsSet])

  return {
    memberHandleDirectPaymentsFilter: handleFilter,
    memberHandleDirectPaymentsUnfilter: handleUnfilter,
    memberDirectPaymentsFilterParams: directPaymentsFilterParams,
    memberDirectPayments: directPayments,
    memberDirectPaymentsModStatus: directPaymentsModStatus,
  }
}