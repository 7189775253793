import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { IdentificationsHampstaAddReplaceParamsSchemas } from 'schemas/identificationsSchemas'
import { IdentificationsHampstaAddReplaceParamsTypes } from 'types/identifications'

export const useMemberIdentificationsMergeForm = ({
  defaultValues,
}: {
  defaultValues: IdentificationsHampstaAddReplaceParamsTypes
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<IdentificationsHampstaAddReplaceParamsTypes>({
    resolver: zodResolver(IdentificationsHampstaAddReplaceParamsSchemas),
    defaultValues,
  })

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    reset,
    watch,
  }
}
