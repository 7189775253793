import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getHistoryStatusesAPI } from 'api/histories'
import { useHistoryStatusesStore } from 'store/histories'
import { DropDownItem } from 'types/components'

import { DATASTATUS } from 'constants/status'

export const useMemberHistoryStatuses = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const historyStatusesModStatus = useHistoryStatusesStore(
    (state) => state.historyStatusesModStatus
  )
  const historyStatusesModStatusSet = useHistoryStatusesStore(
    (state) => state.historyStatusesModStatusSet
  )
  const historyStatusesModErrors = useHistoryStatusesStore(
    (state) => state.historyStatusesModErrors
  )
  const historyStatusesModErrorsSet = useHistoryStatusesStore(
    (state) => state.historyStatusesModErrorsSet
  )
  const historyStatuses = useHistoryStatusesStore(
    (state) => state.historyStatuses
  )
  const historyStatusesSet = useHistoryStatusesStore(
    (state) => state.historyStatusesSet
  )

  const {
    data: dataHistory,
    isLoading: isLoadingHistory,
    isError: isErrorHistory,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useHistoryStatuses'],
    queryFn: async () => {
      try {
        historyStatusesModStatusSet('loading')
        const response = await getHistoryStatusesAPI()
        // if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        historyStatusesModStatusSet('success')
        return response.historyStatus
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)), ' ERR')
        historyStatusesModStatusSet('fail')
        historyStatusesModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataHistory) historyStatusesSet(dataHistory)
  }, [dataHistory, historyStatusesSet])

  const historyStatusesOptions: DropDownItem[] = useMemo(() => {
    return historyStatuses.map((item) => ({
      label: String(item.label),
      value: String(item.label),
    }))
  }, [historyStatuses])

  return {
    memberHistoryStatusesOptions: historyStatusesOptions,
    memberHistoryStatuses: historyStatuses,
    memberHistoryStatusesModStatus: historyStatusesModStatus,
  }
}
