import { http } from 'utils'

import {
  HistoryAPITypes,
  HistoryFetchParamsTypes,
  HistoryStatusesAPITypes,
} from 'types/histories'
import {
  HistoryAPISchemas,
  HistoryStatusesAPISchemas,
} from 'schemas/historySchemas'

import { DATASTATUS } from 'constants/status'

export async function getHistoryAPI({
  traderId,
  startDate,
  endDate,
  editedBy,
  pSize = 50,
  pNumber = 1,
}: HistoryFetchParamsTypes): Promise<HistoryAPITypes> {
  const urlDefault = `/members/${traderId}/history`
  let url = urlDefault
  const params = new URLSearchParams()

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  if (editedBy) {
    params.set('editedBy', editedBy)
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  // if (pNumber) {
  //   params.set('pNumber', pNumber.toString())
  // }

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = HistoryAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      memberHistory: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getHistoryStatusesAPI(): Promise<HistoryStatusesAPITypes> {
  const data = {
    outcomeCode: '',
    outcomeMessage: '',
    outcomeUserMessage: '',
  }
  const validatedData = HistoryStatusesAPISchemas.safeParse(data)
  if (!validatedData.success) {
    return {
      historyStatus: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}
