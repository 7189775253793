import { useMemo } from 'react'

import { useMemberProfiles } from 'views/MemberProfile/useMemberProfiles'

import { MEMBERELLIPSISNAVIGATION } from 'constants/profileEllipsisMember'

export const useMemberProfileActions = () => {
  const {
    memberMemberProfiles,
    memberMemberProfilesModStatus,
    memberMemberProfilesModErrors,
  } = useMemberProfiles()

  const DROPDOWNMODALOPTIONS = useMemo(() => {
    const memberThorStatus =
      memberMemberProfiles.length && memberMemberProfiles[0].memberThorStatus
        ? memberMemberProfiles[0].memberThorStatus
        : ''
    let options = MEMBERELLIPSISNAVIGATION.map((item) => ({
      label: item.label || '',
      value: item.value || '',
      icon: item.route.icon || undefined,
    }))
    if (['cancelled'].includes(memberThorStatus.toLowerCase())) {
      options = [options[1], options[2]]
    }
    return options
  }, [memberMemberProfiles])

  return {
    memberMemberProfiles,
    memberMemberProfilesModStatus,
    memberMemberProfilesModErrors,
    DROPDOWNMODALOPTIONS,
  }
}
