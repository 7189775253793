import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import useMerchantStore from 'store/merchant'
import { MerchantTransactionTypes } from 'types/merchant'

const Index = () => {
  const { traderId: merchantTraderId } = useParams()
  const [isMerchantTransactionEdit, isMerchantTransactionEditSet] =
    useState<boolean>(false)
  const [selectedMerchantTransaction, selectedMerchantTransactionSet] =
    useState<MerchantTransactionTypes | null>(null)
  const merchantTransaction = useMerchantStore(
    (state) => state.merchantTransaction
  )
  const merchantTransactionStatus = useMerchantStore(
    (state) => state.merchantTransactionStatus
  )
  const merchantTransactionLoading = useMerchantStore(
    (state) => state.merchantTransactionLoading
  )
  const getMerchantTransaction = useMerchantStore(
    (state) => state.getMerchantTransaction
  )
  const merchantTransactionModStatus = useMerchantStore(
    (state) => state.merchantTransactionModStatus
  )
  const merchantTransactionModError = useMerchantStore(
    (state) => state.merchantTransactionModError
  )

  useEffect(() => {
    if (merchantTraderId) getMerchantTransaction(Number(merchantTraderId))
  }, [merchantTraderId])

  return {
    isMerchantTransactionEdit,
    isMerchantTransactionEditSet,
    selectedMerchantTransaction,
    selectedMerchantTransactionSet,
    merchantTransaction,
    merchantTransactionStatus,
    merchantTransactionLoading,
    merchantTransactionModStatus,
    merchantTransactionModError,
  }
}

export default Index
