import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getTransactionsV2API } from 'api/transactions'
import { useTransactionsActivityStore } from 'store/transactions'
import { TransactionsFetchParamsTypes } from 'types/transactions'

import { DATASTATUS } from 'constants/status'

export const useMemberActivity = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId, lastNTransactions: 1 }

  const transactionsActivityModStatus = useTransactionsActivityStore(
    (state) => state.transactionsActivityModStatus
  )
  const transactionsActivityModStatusSet = useTransactionsActivityStore(
    (state) => state.transactionsActivityModStatusSet
  )
  const transactionsActivityModErrors = useTransactionsActivityStore(
    (state) => state.transactionsActivityModErrors
  )
  const transactionsActivityModErrorsSet = useTransactionsActivityStore(
    (state) => state.transactionsActivityModErrorsSet
  )
  const transactionsActivity = useTransactionsActivityStore(
    (state) => state.transactionsActivity
  )
  const transactionsActivitySet = useTransactionsActivityStore(
    (state) => state.transactionsActivitySet
  )
  const [
    transactionsActivityFilterParams,
    transactionsActivityFilterParamsSet,
  ] = useState<TransactionsFetchParamsTypes>(defaultParams)
  const [isTransactionsFiltered, isTransactionsFilteredSet] =
    useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataTransactions,
    isLoading: isLoadingTransactions,
    isError: isErrorTransactions,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useTransactionsActivity', transactionsActivityFilterParams],
    queryFn: async () => {
      try {
        transactionsActivityModStatusSet('loading')
        const response = await getTransactionsV2API(
          transactionsActivityFilterParams
        )
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        transactionsActivityModStatusSet('success')
        return response.transactions
      } catch (e) {
        transactionsActivityModStatusSet('fail')
        transactionsActivityModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataTransactions) transactionsActivitySet(dataTransactions)
  }, [dataTransactions, transactionsActivitySet])

  return {
    memberTransactionsActivity: transactionsActivity,
    memberTransactionsActivityModStatus: transactionsActivityModStatus,
  }
}
