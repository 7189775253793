import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getMembershipsAPI } from 'api/memberships'
import { useMembershipsStore } from 'store/memberships'
import { MembershipsFetchParamsTypes } from 'types/memberships'

import { DATASTATUS } from 'constants/status'

export const useMemberMemberships = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const membershipsModStatus = useMembershipsStore(
    (state) => state.membershipsModStatus
  )
  const membershipsModStatusSet = useMembershipsStore(
    (state) => state.membershipsModStatusSet
  )
  const membershipsModErrors = useMembershipsStore(
    (state) => state.membershipsModErrors
  )
  const membershipsModErrorsSet = useMembershipsStore(
    (state) => state.membershipsModErrorsSet
  )
  const memberships = useMembershipsStore((state) => state.memberships)
  const membershipsSet = useMembershipsStore((state) => state.membershipsSet)
  const [membershipsFilterParams, membershipsFilterParamsSet] =
    useState<MembershipsFetchParamsTypes>(defaultParams)
  const [isMembershipsFiltered, isMembershipsFilteredSet] =
    useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataMemberships,
    isLoading: isLoadingMemberships,
    isError: isErrorMemberships,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useMemberships', membershipsFilterParams],
    queryFn: async () => {
      try {
        membershipsModStatusSet('loading')
        const response = await getMembershipsAPI(membershipsFilterParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        membershipsModStatusSet('success')
        return response.memberGroups
      } catch (e) {
        membershipsModStatusSet('fail')
        membershipsModErrorsSet(JSON.stringify(e))
      }
    },
  })

  const handleFilter = useCallback(
    async (filterParams: MembershipsFetchParamsTypes) => {
      membershipsFilterParamsSet(filterParams)
      isMembershipsFilteredSet(true)
    },
    [membershipsFilterParamsSet, isMembershipsFilteredSet]
  )

  const handleUnfilter = useCallback(() => {
    const test = { traderId: memberTraderId }
    membershipsFilterParamsSet(test)
    isMembershipsFilteredSet(false)
  }, [membershipsFilterParamsSet, isMembershipsFilteredSet])

  useEffect(() => {
    if (dataMemberships) membershipsSet(dataMemberships)
  }, [dataMemberships, membershipsSet])

  return {
    memberHandleMembershipsFilter: handleFilter,
    memberHandleMembershipsUnfilter: handleUnfilter,
    memberMembershipsFilterParams: membershipsFilterParams,
    memberMemberships: memberships,
    memberMembershipsModStatus: membershipsModStatus,
  }
}
