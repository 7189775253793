// STORE DATA WAS INITIALIZED FROM THE Wrapper.tsx

import { useParams } from 'react-router-dom'
import { useTransactionsActivityStore } from 'store/transactions'

export const useMemberActivity = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const transactionsActivityModStatus = useTransactionsActivityStore(
    (state) => state.transactionsActivityModStatus
  )
  const transactionsActivityModStatusSet = useTransactionsActivityStore(
    (state) => state.transactionsActivityModStatusSet
  )
  const transactionsActivityModErrors = useTransactionsActivityStore(
    (state) => state.transactionsActivityModErrors
  )
  const transactionsActivityModErrorsSet = useTransactionsActivityStore(
    (state) => state.transactionsActivityModErrorsSet
  )
  const transactionsActivity = useTransactionsActivityStore(
    (state) => state.transactionsActivity
  )
  const transactionsActivitySet = useTransactionsActivityStore(
    (state) => state.transactionsActivitySet
  )

  return {
    memberTransactionsActivity: transactionsActivity,
    memberTransactionsActivityModStatus: transactionsActivityModStatus,
  }
}
