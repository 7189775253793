import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const VouchersDefaultSchemas = DefaultSchemas

export const VouchersDataSchemas = zod.object({
  pluCode: zod.string().nullable(),
  description: zod.string().nullable(),
  voucherCode: zod.string(),
  voucherValue: zod.number(),
  name: zod.string(),
  startDate: zod.string(),
  endDate: zod.string(),
  status: zod.string(),
  imageUrl: zod.string().nullable(),
  campaign: zod.string().nullable(),
  voucherType: zod.string(),
  redemptionDate: zod.string().nullable(),
  redeemableAt: zod.string().array(),
  offerId: zod.string().nullable(),
})

export const VouchersAPISchemas = VouchersDefaultSchemas.merge(
  zod.object({
    vouchers: zod.array(VouchersDataSchemas),
  })
)

export const VouchersFetchParamsSchemas = zod.object({
  traderId: zod.number(),
  voucherName: zod.string().optional(),
  voucherCode: zod.string().optional(),
  status: zod.string().optional(),
  startDate: zod.string().optional(),
  endDate: zod.string().optional(),
  pSize: zod.number().optional(),
  pNumber: zod.number().optional(),
})

export const VouchersStatusesDataSchemas = zod.object({
  label: zod.string(),
  value: zod.string(),
})

export const VouchersStatusesAPISchemas = VouchersDefaultSchemas.merge(
  zod.object({
    vouchersStatus: zod.array(VouchersStatusesDataSchemas),
  })
)
