import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { NotesDataTypes } from 'types/notes'

interface NotesStoreTypes {
  // UPDATE
  notesUpdateModErrors: StatusErrorTypes
  notesUpdateModErrorsSet: (notesModErrors: StatusErrorTypes) => void
  notesUpdateModStatus: StatusTypes
  notesUpdateModStatusSet: (notesUpdateStatus: StatusTypes) => void

  notesModErrors: StatusErrorTypes
  notesModErrorsSet: (notesModErrors: StatusErrorTypes) => void
  notesModStatus: StatusTypes
  notesModStatusSet: (notesStatus: StatusTypes) => void
  notes: NotesDataTypes[]
  notesSet: (notes: NotesDataTypes[]) => void
  notesLookup: NotesDataTypes[]
  notesLookupSet: (notes: NotesDataTypes[]) => void
  notesSummary: {
    high: number
    medium: number
    low: number
  }
  notesSummarySet: () => void
}

export const useNotesStore = create<NotesStoreTypes>((set) => ({
  // UPDATE
  notesUpdateModErrors: '',
  notesUpdateModErrorsSet: (notesUpdateModErrors) => {
    set({
      notesUpdateModErrors,
    })
  },
  notesUpdateModStatus: 'idle',
  notesUpdateModStatusSet: (notesUpdateModStatus) => {
    set({
      notesUpdateModStatus,
    })
  },
  // DEFAULT
  notesModErrors: '',
  notesModErrorsSet: (notesModErrors) => {
    set({
      notesModErrors,
    })
  },
  notesModStatus: 'idle',
  notesModStatusSet: (notesModStatus) => {
    set({
      notesModStatus,
    })
  },
  notes: [],
  notesSet: (notes) => {
    set({
      notes,
    })
  },
  notesLookup: [],
  notesLookupSet: (notesLookup) => {
    set({
      notesLookup,
    })
  },
  notesSummary: {
    high: 0,
    medium: 0,
    low: 0,
  },
  notesSummarySet: () => {
    set((state) => {
      const high = state.notes.filter(
        (item) =>
          item.priorityLevel && item.priorityLevel.toLowerCase() === 'high'
      )
      const medium = state.notes.filter(
        (item) =>
          item.priorityLevel && item.priorityLevel.toLowerCase() === 'medium'
      )
      const low = state.notes.filter(
        (item) =>
          item.priorityLevel && item.priorityLevel.toLowerCase() === 'low'
      )
      return {
        notesSummary: {
          high: high.length,
          medium: medium.length,
          low: low.length,
        },
      }
    })
  },
}))

interface NotesLookupStoreTypes {
  notesLookupModErrors: StatusErrorTypes
  notesLookupModErrorsSet: (notesLookupModErrors: StatusErrorTypes) => void
  notesLookupModStatus: StatusTypes
  notesLookupModStatusSet: (notesLookupStatus: StatusTypes) => void
  notesLookup: NotesDataTypes[]
  notesLookupSet: (notesLookup: NotesDataTypes[]) => void
}

export const useNotesLookupStore = create<NotesLookupStoreTypes>((set) => ({
  notesLookupModErrors: '',
  notesLookupModErrorsSet: (notesLookupModErrors) => {
    set({
      notesLookupModErrors,
    })
  },
  notesLookupModStatus: 'idle',
  notesLookupModStatusSet: (notesLookupModStatus) => {
    set({
      notesLookupModStatus,
    })
  },
  notesLookup: [],
  notesLookupSet: (notesLookup) => {
    set({
      notesLookup,
    })
  },
}))
