import { useMemo } from 'react'
import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import { InputField } from 'components/InputField'
import Datepicker, { useDatepicker } from 'components/Datepicker'

import { formatDateToShort } from 'services/helpers'

import { useReason } from './useReason'
import { useFrequency } from './useFrequency'
import { useBankAccount } from './useBankAccount'
import { usePaymentInstructionTxForm } from './usePaymentInstructionTxForm'
import { usePaymentInstructionTx } from './usePaymentInstructionTx'

import { PaymentInstructionsDataTypes } from 'types/financials'
import { DropDownItem } from 'types/components'

const ADVANCE_ACTIONS = [
  { label: 'Default', value: '' },
  { label: 'Skip', value: 'skip' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Cancel', value: 'cancel' },
  { label: 'Suspend', value: 'suspend' },
]

const Index = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: PaymentInstructionsDataTypes
}): JSX.Element => {
  const { startDate, endDate, DatepickerInput, handlePick } = useDatepicker()
  const { reason, reasonAsOptions } = useReason()
  const { frequency, frequencyAsOptions } = useFrequency()
  const { bankaccountsAsOptions } = useBankAccount()

  const { control, errors, handleSubmit, setValue, watch } =
    usePaymentInstructionTxForm({
      selectedRowData,
    })

  const { onSubmitSkipPaymentInstruction, onLoadingAddPaymentInstruction } =
    usePaymentInstructionTx({
      onSubmitSuccess: modalClose,
      paymentFrequency: frequency,
    })

  return (
    <FormsLayout.Wrapper
      onSubmit={handleSubmit(onSubmitSkipPaymentInstruction)}
    >
      <FormsLayout.Item>
        <Controller
          name="instructionName"
          control={control}
          render={({ field }) => (
            <InputField
              {...field}
              defaultValue={field.value}
              type="input"
              name="bankAccountNumber"
              label="Instruction Name"
              containerClassName="flex flex-col flex-grow"
              rounded
              error={errors.instructionName?.message}
              onChange={(e) => {
                field.onChange(String(e.target.value))
              }}
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.Item>
        <Controller
          name="paymentAmount"
          control={control}
          render={({ field }) => {
            return (
              <InputField
                {...field}
                defaultValue={field.value.toString()}
                type="input"
                name="bankAccountNumber"
                label="Amount"
                containerClassName="flex flex-col flex-grow"
                rounded
                error={errors.paymentAmount?.message}
                onChange={(e) => {
                  field.onChange(parseFloat(e.target.value))
                }}
              />
            )
          }}
        />
      </FormsLayout.Item>

      <div className="flex gap-4">
        <FormsLayout.Item>
          <>
            <Controller
              name="bankAccountNumber"
              control={control}
              render={({ field }) => (
                <>
                  <Dropdown
                    title="Bank Account"
                    options={bankaccountsAsOptions}
                    onSelect={(item: DropDownItem) =>
                      field.onChange(String(item.value))
                    }
                    className="w-full tracking-wider"
                    containerClassName="mb-5"
                    borderColor="#C4C4C4"
                  />
                </>
              )}
            />
          </>
        </FormsLayout.Item>

        <FormsLayout.Item>
          <div className="relative">
            <>
              <p className="mb-2">
                <span className="text-gray-400 tracking-wider">
                  Bank Account Number
                </span>
              </p>
              <p>
                <span className="text-gray-400 tracking-wider">
                  <b>{watch().bankAccountNumber}</b>
                </span>
              </p>
            </>
          </div>
        </FormsLayout.Item>
      </div>

      <div className="flex gap-4">
        <FormsLayout.Item>
          <>
            <Controller
              name="frequency"
              control={control}
              render={({ field }) => (
                <>
                  <Dropdown
                    title="Payment Frequency"
                    // value={values.status}
                    options={frequencyAsOptions}
                    onSelect={(item: DropDownItem) =>
                      field.onChange(parseInt(item.value))
                    }
                    className="w-full tracking-wider"
                    containerClassName="mb-5"
                    borderColor="#C4C4C4"
                  />
                </>
              )}
            />
            {errors.frequency ? (
              <span className="text-xs text-red tracking-wider">
                {errors.frequency?.message}
              </span>
            ) : null}
          </>
        </FormsLayout.Item>

        <FormsLayout.Item>
          <div className="relative">
            <>
              <p className="mb-2">
                <span className="text-gray-400 tracking-wider">
                  Payment Frequency
                </span>
              </p>
              {frequencyAsOptions
                .filter((item) => parseInt(item.value) === watch().frequency)
                .map((item) => (
                  <p key={item.value}>
                    <span className="text-gray-400 tracking-wider">
                      A Frequency of <b>{item.label}</b>
                    </span>
                  </p>
                ))}
            </>
          </div>
        </FormsLayout.Item>
      </div>

      <div className="flex gap-4">
        <FormsLayout.Item>
          <>
            <Controller
              name="firstPaymentDate"
              control={control}
              render={({ field }) => (
                <>
                  <Datepicker
                    title="Payment Date Start to End"
                    name="dateRange"
                    onChange={(dates: [Date, Date]) => {
                      handlePick(dates)
                      setValue(
                        'firstPaymentDate',
                        dates[0] ? formatDateToShort(dates[0]) : ''
                      )
                      setValue(
                        'endPaymentDate',
                        dates[1] ? formatDateToShort(dates[1]) : ''
                      )
                    }}
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    showYearDropdown
                    scrollableYearDropdown
                    className="p-2 border border-gray-400 tracking-wider"
                    placeholderText="--"
                    customInput={<DatepickerInput />}
                    selectsRange
                  />
                </>
              )}
            />
            {errors.firstPaymentDate || errors.endPaymentDate ? (
              <div className="mt-5">
                <span className="text-xs text-red tracking-wider">
                  Payment Dates are Required
                </span>
              </div>
            ) : null}
          </>
        </FormsLayout.Item>

        <FormsLayout.Item>
          <>
            <p className="mb-2">
              <span className="text-gray-400 tracking-wider">Payment Date</span>
            </p>
            <p>
              <span className=" text-gray-400 tracking-wider">
                Starts <b>{watch().firstPaymentDate}</b>
              </span>
            </p>
            <p>
              <span className=" text-gray-400 tracking-wider">
                Ends <b>{watch().endPaymentDate}</b>
              </span>
            </p>
          </>
        </FormsLayout.Item>
      </div>

      <FormsLayout.Item>
        <Controller
          name="skipNumPayment"
          control={control}
          render={({ field }) => (
            <InputField
              {...field}
              defaultValue={field.value?.toString()}
              type="number"
              name="skipNumPayment"
              label="Skip number of payments"
              containerClassName="flex flex-col flex-grow"
              rounded
              error={errors.skipNumPayment?.message}
              onChange={(e) => {
                field.onChange(parseInt(e.target.value))
              }}
            />
          )}
        />
      </FormsLayout.Item>

      <div className="flex gap-4">
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <InputField
              {...field}
              defaultValue={field.value?.toString()}
              type="input"
              name="comment"
              label="Comment"
              containerClassName="flex flex-col flex-grow"
              rounded
              error={errors.comment?.message}
              onChange={(e) => {
                field.onChange(String(e.target.value))
              }}
            />
          )}
        />
      </div>

      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          iconRight={false}
          type="submit"
          // disabled={}
          // loading={}
          className="rounded bg-primary tracking-wider font-bold"
        >
          {onLoadingAddPaymentInstruction ? 'Skipping ...' : 'Skip'}
        </Button>
        {/* {isMemberOffersFiltered ? (
            <Button
              key="clear"
              iconRight={false}
              type="button"
              className="rounded-lg"
              onClick={() => {
                handleMemberOffersFilterClear()
                reset()
              }}
            >
              <span className="tracking-wider font-bold">Clear</span>
            </Button>
          ) : null} */}
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
