import Currency from 'components/ui/Currency'

import { useMemberProfiles } from './useMemberProfiles'
import { displayBalanceCurrency } from 'setups/controller'
import { countryIsoCodeData } from 'constants/countries'

const projectClient = process.env.REACT_APP_PROJ

const Index = (): JSX.Element => {
  const { memberMemberProfiles, memberMemberProfilesModStatus } =
    useMemberProfiles()

  return (
    <section className="flex flex-col">
      {memberMemberProfilesModStatus === 'success' ? (
        memberMemberProfiles.length > 0 ? (
          <div>
            {memberMemberProfiles.map((item) => (
              <div className="flex gap-8 pt-4 " key={item.traderId}>
                <p className="flex flex-col gap-2">
                  <span className="uppercase font-light text-xs tracking-wider">
                    Loyalty Balance
                  </span>
                  <span className="text-black font-extrabold text-2xl">
                    {item.loyaltyBalance
                      ? item.loyaltyBalance.toFixed(2)
                      : '0.00'}
                  </span>
                </p>

                <p className="flex flex-col gap-2">
                  <span className="uppercase font-light text-xs tracking-wider">
                    Credit Balance
                  </span>
                  <span className="text-black font-extrabold text-2xl flex gap-1 items-center">
                    {item.creditBalance
                      ? item.creditBalance.toFixed(2)
                      : '0.00'}
                    <Currency
                      countryCode={
                        item.countryIsoCode ? item.countryIsoCode : displayBalanceCurrency(projectClient,countryIsoCodeData)[0].countryCode
                      }
                    />
                  </span>
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p className="flex flex-col gap-0">
            <span className="text-xs uppercase font-light tracking-wider">
              No available balances
            </span>
          </p>
        )
      ) : (
        <p className="flex flex-col gap-0">
          <span className="text-xs uppercase font-light tracking-wider">
            Loading member`s balances
          </span>
        </p>
      )}
    </section>
  )
}

export default Index
