import Alert from 'components/Alert'
import Button from 'components/Button'
import Input from 'components/Input'
import LoginShell from 'components/LoginShell'
import { ROUTES } from 'constants/routing'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth } from 'services/amplify'
import * as Yup from 'yup'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type FormValues = {
  username: string
  code: string
  password: string
  confirmPassword: string
}

const ResetPasswordSchema = Yup.object({
  code: Yup.string().label('Confirmation Code').required('Code is required'),
  password: Yup.string()
    .label('New Password')
    .required('Please enter a new password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least 8 characters, one letter, one number, and one special character.'
    ),
  confirmPassword: Yup.string()
    .label('Confirm New Password')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Please confirm your new password'),
})

function Forgotpassword(): React.ReactElement {
  const auth = useAuth()
  const [codeSent, setCodeSent] = useState(false)
  const [, setIsSendingCode] = useState(false)

  const [confirmed, setConfirmed] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev)
  }

  const initialValues: FormValues = {
    username: '',
    code: '',
    password: '',
    confirmPassword: '',
  }

  const handleError = (error: unknown) => {
    setErrorMessage(
      'Oops! Your verification code has expired or is incorrect. Please go back and request a new code.'
    )
  }

  return (
    <LoginShell>
      {!codeSent ? (
        <>
          <h1 className="mb-6 text-lg font-bold text-center text-primary tracking-wider">
            PASSWORD RESET
          </h1>

          <p className="mb-10 text-center text-gray-500 lg:w-9/12 tracking-wider">
            Enter your username and a verification code will be emailed to you.
            A verification code email will be sent if the username is registered
            with us.
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              username: Yup.string().label('Username').required(),
            })}
            onSubmit={async (form) => {
              setIsSendingCode(true)
              try {
                await auth.forgotPassword(form.username).then(() => {
                  setCodeSent(true)
                })
              } catch (error) {
                setIsSendingCode(false)
              }
            }}
          >
            {() => (
              <Form className="w-2/3 mb-4">
                {/* {status && (<Alert>{status}</Alert>)} */}

                <Input
                  name="username"
                  type="text"
                  label=""
                  placeholder="username"
                  labelClassName="text-gray-500"
                />

                <Button
                  type="submit"
                  className="mx-auto rounded bg-primary w-full"
                >
                  <span className="tracking-wider font-bold">
                    Reset Password
                  </span>
                </Button>
              </Form>
            )}
          </Formik>

          <NavLink to={ROUTES.Login.path}>
            <p className="tracking-wider">Back to login</p>
          </NavLink>
        </>
      ) : !confirmed ? (
        <>
          <h1 className="mb-6 text-lg font-normal text-center text-primary">
            Reset your password
          </h1>
          <p className="mb-10 text-center text-gray-500 lg:w-9/12">
            Enter your username and a confirmation code will be emailed to
            you...
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={ResetPasswordSchema}
            onSubmit={async (form) => {
              try {
                await auth.forgotPasswordSubmit(
                  form.username,
                  form.code,
                  form.password
                )
                setConfirmed(true)
                setErrorMessage('')
              } catch (error) {
                handleError(error)
              }
            }}
          >
            {({ errors }) => (
              <Form className="w-2/3 mb-4">
                {/* {status && (<Alert>{status}</Alert>)} */}
                {errorMessage && <Alert>{errorMessage}</Alert>}

                <Input
                  name="code"
                  type="text"
                  label="Confirmation Code"
                  placeholder="Enter code"
                  labelClassName="text-gray-500"
                />

                <div className="relative">
                  <Input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    label="New Password"
                    placeholder="Type your new password"
                    labelClassName="text-gray-500"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 text-gray-500"
                    style={{ top: '2.3rem' }} 
                    aria-label="Toggle password visibility"
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>

                <div className="relative">
                  <Input
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    label="Confirm New Password"
                    placeholder="Confirm your new password"
                    labelClassName="text-gray-500"
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute right-3 text-gray-500"
                    style={{ top: '2.3rem' }} 
                    aria-label="Toggle confirm password visibility"
                  >
                    <FontAwesomeIcon
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                    />
                  </button>
                </div>

                <Button
                  rounded
                  type="submit"
                  className="px-12 mx-auto mt-10 uppercase"
                >
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>

          <NavLink to={ROUTES.Login.path}>&lt; Back to Login</NavLink>
        </>
      ) : (
        <>
          <p className="my-6">Your Password has been reset.</p>
          <NavLink to={ROUTES.Login.path}>
            &lt; Click here to login with your new credentials.
          </NavLink>
        </>
      )}
    </LoginShell>
  )
}

export default Forgotpassword
