import * as Yup from 'yup'
import { z as zod } from 'zod'

export const FinancialAddBankAccountTxSchemas = zod.object({
  bankAccountName: zod
    .string()
    .min(1, { message: 'Account Name is Required.' }),
  bankAccountNumber: zod
    .string()
    .min(1, { message: 'Account Number is Required.' }),
  accountStatusId: zod.number().min(1, { message: 'Status is Required.' }),
  bankId: zod.number().min(1, { message: 'Bank is Required.' }),
  traderId: zod.number(),
  userId: zod.number(),
})
