import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import Table from 'components/Table'
import { ROUTES } from 'constants/routing'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { SubsquadData } from 'types/subsquad'

const columns = ['Identifier', 'Group Name', 'Group URN', 'Members']

interface Props {
  postData: SubsquadData[]
}

const ResultTable = ({ postData }: Props): JSX.Element => {
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)

  function handlePageClick({ selected }: { selected: number }): void {
    // setLoading(true)
    // setOffset(selected * perPage)
    setCurrentPage(selected + 1)
  }

  return (
    <Table
      data={postData}
      columns={columns}
      pageCount={0}
      showActions
      currentPage={currentPage}
      perPage={10}
      onPageChange={handlePageClick}
      itemCount={postData.length}
    >
      {postData.map((subsquad) => (
        <tr
          key={subsquad.groupId}
          className="py-2 text-black bg-white cursor-pointer hover:bg-gray-600"
          // redirect to member profile
          onClick={() =>
            navigate(
              `${ROUTES.Subsquad.path}/${subsquad.groupUrn}/${subsquad.groupId}/`
            )
          }
        >
          <td className="py-2 pl-6">
            <p>{subsquad.groupId}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{subsquad.groupName}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{subsquad.groupUrn}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{subsquad.memberCount}</p>
          </td>
          <td className="float-right py-2 pl-6 mr-4">
            <Icon icon={faChevronRight} />
          </td>
        </tr>
      ))}
    </Table>
  )
}

export default ResultTable
