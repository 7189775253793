import { DateTime } from 'luxon'
import { TransactionsDataTypes } from 'types/transactions'
import Currency from 'components/ui/Currency'

const TransactionItem = ({
  transaction,
}: {
  transaction: TransactionsDataTypes
}): JSX.Element => {
  // let icon = (
  //   <div className="flex items-center justify-center mr-4 bg-blue-300 rounded-full h-7 w-7">
  //     <Icon className="text-blue-100" icon={faCartPlus} />
  //   </div>
  // )
  let amount = transaction.purchaseValue
  let points = transaction.loyaltyValue

  switch (transaction.transactionType?.toLowerCase()) {
    case 'purchase':
      // icon = (
      //   <div className="flex items-center justify-center mr-4 bg-blue-300 rounded-full h-7 w-7">
      //     <Icon className="text-white text-opacity-75" icon={faCartPlus} />
      //   </div>
      // )
      amount = transaction.purchaseValue
      points = transaction.loyaltyValue
      break
    case 'purchase/loyalty redemption':
      // icon = (
      //   <div className="flex items-center justify-center mr-4 rounded-full h-7 w-7 bg-purple">
      //     <Icon className="text-white text-opacity-75" icon={faGift} />
      //   </div>
      // )
      amount = transaction.purchaseValue
      points = transaction.loyaltyValue
      break
    case 'purchase refund':
      // icon = (
      //   <div className="flex items-center justify-center mr-4 bg-green-200 rounded-full h-7 w-7">
      //     <Icon
      //       className="text-white text-opacity-75"
      //       icon={faHandHoldingUsd}
      //     />
      //   </div>
      // )
      amount = transaction.creditValue
      points = transaction.loyaltyValue
      break
    case 'loyalty allocation':
      // icon = (
      //   <div className="flex items-center justify-center mr-4 rounded-full h-7 w-7 bg-orange">
      //     <Icon className="text-white text-opacity-75" icon={faUserAlt} />
      //   </div>
      // )
      amount = transaction.purchaseValue
      points = transaction.loyaltyValue
      break
    case 'loyalty Allocation Refund':
      // icon = (
      //   <div className="flex items-center justify-center mr-4 bg-yellow-100 rounded-full h-7 w-7">
      //     <Icon className="text-white text-opacity-75" icon={faUserPlus} />
      //   </div>
      // )
      amount = transaction.creditValue
      points = transaction.loyaltyValue
      break
    case 'icash topup':
      // icon = (
      //   <div className="flex items-center justify-center mr-4 bg-yellow-100 rounded-full h-7 w-7">
      //     <Icon className="text-white text-opacity-75" icon={faUserPlus} />
      //   </div>
      // )
      amount = transaction.creditValue
      points = transaction.loyaltyValue
      break
    case 'icash topup reversal':
      // icon = (
      //   <div className="flex items-center justify-center mr-4 bg-yellow-100 rounded-full h-7 w-7">
      //     <Icon className="text-white text-opacity-75" icon={faUserPlus} />
      //   </div>
      // )
      amount = transaction.creditValue
      points = transaction.loyaltyValue
      break
    case 'icash redemption':
      // icon = (
      //   <div className="flex items-center justify-center mr-4 bg-yellow-100 rounded-full h-7 w-7">
      //     <Icon className="text-white text-opacity-75" icon={faUserPlus} />
      //   </div>
      // )
      amount = transaction.creditValue
      points = transaction.loyaltyValue
      break
    case 'icash redemption reversal':
      // icon = (
      //   <div className="flex items-center justify-center mr-4 bg-yellow-100 rounded-full h-7 w-7">
      //     <Icon className="text-white text-opacity-75" icon={faUserPlus} />
      //   </div>
      // )
      amount = transaction.creditValue
      points = transaction.loyaltyValue
      break
    default:
      // icon = (
      //   <div className="flex items-center justify-center mr-4 bg-blue-300 rounded-full h-7 w-7">
      //     <Icon className="text-blue-100" icon={faCartPlus} />
      //   </div>
      // )
      amount = transaction.purchaseValue
      points = transaction.loyaltyValue
      break
  }

  return (
    <>
      <div className="flex items-start">
        {transaction.countryCode && (
          <img
            // src={`https://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/${transaction.countryCode?.toLowerCase()}.svg`}
            // src={`https://www.countryflagicons.com/FLAT/64/${transaction.countryCode?.toUpperCase()}.png`}
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${transaction.countryCode?.toUpperCase()}.svg`}
            alt={transaction.countryCode}
            className="w-8 mr-6 mt-2 bg-cover"
          />
        )}
        <div className="flex flex-col gap-2">
          <p>
            <span className="tracking-wider font-bold">
              {`@ ${transaction.storeName}`}
            </span>
          </p>
          <p>
            <span className="tracking-wider  font-light ">
              {transaction.transactionType}
            </span>
          </p>
        </div>
      </div>
      <div className="flex flex-col text-right">
        <div className="flex gap-4">
          <p className="font-bold">
            {points.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            <span className="px-2 font-light">
              <small>PTS</small>
            </span>
          </p>
          <p className="font-bold">
            {amount.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            <Currency countryCode={transaction.countryCode} />
          </p>
        </div>
        <p className="text-gray-500">
          {DateTime.fromISO(transaction.transactionDate).toRelative()}
        </p>
      </div>
    </>
  )
}

export default TransactionItem
