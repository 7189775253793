import React from 'react'
import { useParams } from 'react-router-dom'
import Colors from 'colors.json'

import { faSearch, faRemoveFormat } from '@fortawesome/free-solid-svg-icons'
import Input from 'components/Input'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import { Form, Formik, FormikHelpers } from 'formik'

import { PurchaseTxSchemas } from 'schemas/purchaseTxSchemas'
import { useLoyaltyAllocationReversalTx } from './useLoyaltyAllocationReversalTx'
import MerchantLookupTx from 'views/MemberProfile/ManualTx/MerchantLookupTx'
import { useMemberTXReasons } from './useMemberTXReasons'
import { access } from 'fs'

const Index: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}): React.ReactElement => {
  // STATES
  const { traderId } = useParams()
  const { membersTXReasonsOptions, memberTXReasonsModStatus } =
  useMemberTXReasons()
  const {
    isLoading,
    initialValues,
    isMerchantLookupToggle,
    handleIsMerchantLookupToggle,
    handleSubmitLoyaltyAllocationReversalTx,
    handleClearLoyaltyAllocationReversalTx,
  } = useLoyaltyAllocationReversalTx(Number(traderId), onCloseModal)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PurchaseTxSchemas}
      onSubmit={handleSubmitLoyaltyAllocationReversalTx}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form className="flex flex-col">
            <div className="mb-4">
              <div className="flex gap-4 items-center">
                <Input
                  name="ident"
                  label="Identifier"
                  disabled
                  containerClassName="w-full"
                />
                <Input
                  name="externalTransactionId"
                  label="External Trans ID"
                  containerClassName="w-full"
                />
              </div>
              <Dropdown
                title="Transaction Reason"
                options={membersTXReasonsOptions}
                className="w-full tracking-wider mb-4"
                borderColor="#C4C4C4"
                onSelect={(data) =>
                  setFieldValue('reasonId', String(data.value))
                }
              />
              <Input
                type="input"
                name="traceNumber"
                label="Trace No."
                // required
                containerClassName="w-full"
              />
              <Input
                type="input"
                name="comments"
                label="Comment"
                // required
                containerClassName="w-full"
              />
            </div>

            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Submit</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onCloseModal}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Index
