import * as Yup from 'yup'

export const IcashTopupReversalTxSchemas = Yup.object().shape({
  externalTransactionId: Yup.number(),
  ident: Yup.string(),
  storeId: Yup.number(),
  cashier: Yup.string(),
  comments: Yup.string().optional(),
  traceNumber: Yup.number().optional(),
  reasonId: Yup.number().optional(),
})
