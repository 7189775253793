import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import { addBankAccount_API, EMRACTIONS_API } from 'api/financials'
import { BankAccountsDefaultTypes } from 'types/financials'
import { FinancialAddBankAccountTxFormTypes } from 'types/financialAddBankAccountTxTypes'
import { MemberDetail } from 'types/member'
import useMemberStore from 'store/member'
import { hasingOut } from 'services/helpers'

export const useBankAccountTx = ({
  onSubmitSuccess,
}: {
  onSubmitSuccess: () => void
}) => {
  const { traderId } = useParams()
  const { member } = useMemberStore()
  const firstName = member?.firstName || ''
  const traderType = member?.traderType || ''

  const queryClient = useQueryClient()
  const {
    isLoading,
    isError,
    error,
    isSuccess,
    mutateAsync: onAddAccountAsync,
  } = useMutation({
    mutationFn: addBankAccount_API,
    onSuccess: (_, data) => {
      // TRIGGER EMAIL
      const tempDate = new Date().toISOString().slice(0, 10)
      EMRACTIONS_API(
        { isBankAccountTrigger: true },
        { isCancelInstructionTrigger: false },
        {
          traderId: parseInt(traderId || ''),
          traderType: traderType,
          firstName: firstName,
          paymentAmount: 0,
          paymentDate: tempDate,
          paymentFrequency: 'Daily',
          bankAccount: hasingOut(data.bankAccountNumber),
        }
      )

      queryClient.invalidateQueries(['useBankAccounts'])
    },
  })

  const onSubmitAddAccount = useCallback(
    async (formData: FinancialAddBankAccountTxFormTypes) => {
      await onAddAccountAsync(formData)
    },
    [traderId, onAddAccountAsync]
  )

  useEffect(() => {
    if (error) {
      const e = error as BankAccountsDefaultTypes
      toast.error(e.outcomeUserMessage)
    }
    if (isSuccess) {
      toast.success('Bank account successfully added.')
      onSubmitSuccess()
    }
  }, [error, isSuccess, onSubmitSuccess])

  return {
    onSubmitAddAccount,
  }
}
