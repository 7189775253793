import React from 'react'

const Wrapper = ({
  onSubmit,
  children,
}: {
  onSubmit: () => void
  children: JSX.Element | JSX.Element[]
}): JSX.Element => {
  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col flex-wrap gap-6 overflow-visible">
        {children}
      </div>
    </form>
  )
}

const Item = ({ children }: { children: JSX.Element }): JSX.Element => {
  return <div className="w-60">{children}</div>
}

const ActionWrapper = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  return <div className="mt-4">{children}</div>
}

export const FormsLayout = {
  Wrapper,
  Item,
  ActionWrapper,
}
