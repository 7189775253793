import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { useFormikContext } from 'formik'
import { isUndefined } from 'lodash'
import React, { PropsWithChildren, useMemo } from 'react'
import Spinner from 'components/Spinner'

type Props = {
  variant?: 'primary' | 'link' | 'nav'
  type?: 'submit' | 'button' | 'reset'
  disabled?: boolean
  loading?: boolean
  to?: string
  className?: string
  onClick?: () => void
  style?: React.CSSProperties
  icon?: IconDefinition
  iconClassName?: string
  rounded?: boolean
  iconRight?: boolean
}

function Button({
  variant,
  type,
  disabled,
  loading,
  to,
  className,
  children,
  onClick,
  style,
  icon,
  iconClassName,
  rounded,
  iconRight,
}: PropsWithChildren<Props>): React.ReactElement {
  // const formik = useFormikContext()

  // const isLoading = useMemo(() => {
  //   return formik ? formik.isSubmitting : loading
  // }, [loading, formik])

  const isLoading = useMemo(() => {
    return loading
  }, [loading])

  const isDisabled = useMemo(() => {
    return disabled || isLoading
  }, [disabled, isLoading])

  const isPrimary = useMemo(() => {
    return variant === 'primary' || isUndefined(variant)
  }, [variant])

  return (
    <button
      type={type || 'button'}
      className={classnames(
        'block text-center py-2 transition duration-150 relative',
        {
          'px-6 rounded-md': rounded,
          'opacity-50 cursor-default': isDisabled,

          // Primary variant
          'px-6 bg-primary text-white': isPrimary,
          'hover:bg-primary-light': isPrimary && !isDisabled,

          // Link variant
          'px-6 text-primary': variant === 'link',
          'hover:text-primary-light hover:underline':
            variant === 'link' && !isDisabled,

          // Nav variant
          'text-gray-500 py-2 mr-3 block': variant === 'nav',
          'hover:text-primary focus:outline-none':
            variant === 'nav' && !isDisabled,
        },
        className
      )}
      style={style || undefined}
      disabled={isDisabled}
      onClick={() => {
        if (onClick) {
          onClick()
        } else if (to) {
          // history.push(to)
        }
      }}
    >
      {!isLoading && icon && !iconRight ? (
        <Icon
          icon={icon}
          className={classnames('relative inline-block mr-4', iconClassName)}
        />
      ) : undefined}

      {isLoading ? (
        <div className="flex items-center gap-2">
          <Spinner color="white" />
          <span className="font-thin tracking-wider">Please Wait...</span>
        </div>
      ) : (
        children
      )}

      {!isLoading && icon && iconRight ? (
        <Icon
          icon={icon}
          className={classnames('relative inline-block ml-4', iconClassName)}
        />
      ) : undefined}
    </button>
  )
}

export default Button
