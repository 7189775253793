import { FC } from 'react'

type Props = {
  title: string
  count: number | undefined
}

const Tab: FC<Props> = ({ children }) => <div>{children}</div>

export default Tab
