import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
// import { toast } from 'react-toastify'
import { useMutation } from 'react-query'

import { getHistoryAPI } from 'api/histories'
import { useHistoryLookupStore } from 'store/histories'
import { DropDownItem } from 'types/components'
import { HistoryFetchParamsTypes } from 'types/histories'

export const useMemberHistoryLookup = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const historyLookupModStatus = useHistoryLookupStore(
    (state) => state.historyLookupModStatus
  )
  const historyLookupModStatusSet = useHistoryLookupStore(
    (state) => state.historyLookupModStatusSet
  )
  const historyLookupModErrors = useHistoryLookupStore(
    (state) => state.historyLookupModErrors
  )
  const historyLookupModErrorsSet = useHistoryLookupStore(
    (state) => state.historyLookupModErrorsSet
  )
  const historyLookup = useHistoryLookupStore((state) => state.historyLookup)
  const historyLookupSet = useHistoryLookupStore(
    (state) => state.historyLookupSet
  )

  const mutation = useMutation({
    mutationFn: getHistoryAPI,
    mutationKey: 'useHistoryLookup',
    onMutate: (values) => {
      historyLookupModStatusSet('loading')
    },
    onSuccess: (getHistoryIdentsResponse) => {
      historyLookupSet(getHistoryIdentsResponse.memberHistory)
      historyLookupModStatusSet('success')
    },
    onError: (error) => {
      historyLookupSet([])
      historyLookupModStatusSet('fail')
    },
  })

  const handleHistoryLookup = useCallback(
    async (getHistoryIdentsParams: HistoryFetchParamsTypes) => {
      const historyLookupData = await mutation.mutateAsync(
        getHistoryIdentsParams
      )
      return historyLookupData.memberHistory
    },
    [mutation]
  )

  const editedByOptionsLoader = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      if (inputValue.length < 3) return []
      const historyLookupData = await handleHistoryLookup({
        traderId: memberTraderId,
        editedBy: inputValue,
      })
      return historyLookupData.map((item) => ({
        label: String(item.editedBy),
        value: String(item.editedBy),
      }))
    },
    [handleHistoryLookup]
  )

  return {
    memberHistoryNameOptionsLoader: editedByOptionsLoader,
    memberHistoryIdents: historyLookup,
    memberHistoryIdentsModStatus: historyLookupModStatus,
    memberHistoryIdentsModErrors: historyLookupModErrors,
  }
}
