import * as Yup from 'yup'

export const CreateMerchantSchemas = Yup.object().shape({
  batchId: Yup.number(),
  countryId: Yup.number(),
  name: Yup.string().required('This field is required.'),
  merchantCode: Yup.number()
    .nullable()
    .typeError('Please enter number value only')
    // .required('This field is required.')
    .moreThan(0, 'Value cannot be zero (0)'),
  externalId: Yup.string().notRequired().nullable(),
  addressLine1: Yup.string().notRequired().nullable(),
  addressLine2: Yup.string().notRequired().nullable(),
  addressLine3: Yup.string().notRequired().nullable(),
  addressLine4: Yup.string().notRequired().nullable(),
  suburb: Yup.string().notRequired().nullable(),
  city: Yup.string().notRequired().nullable(),
  postCode: Yup.string().notRequired().nullable(),
})
