import { useCallback, useEffect, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { FormikHelpers } from 'formik'

import { TRANSACTION_TYPES } from 'constants/filters'
import { formatDateToShort } from 'services/helpers'
import {
  TransactionFilterFormSubmitValuesTypes,
  TransactionFilterFormTypes,
} from 'types/transactions'
import useTransactionStore from 'store/transaction'

const TransactionFilterINITVAL: TransactionFilterFormTypes = {
  transactionType: TRANSACTION_TYPES[0],
  merchant: '',
  startDateRange: new Date(),
  endDateRange: new Date(),
}

const useTransactionsFilter = ({
  memberTraderId,
}: {
  memberTraderId: string
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [selectedMerchant, setSelectedMerchant] =
    useState<OptionTypeBase | null>(null)
  const [merchantId, setMerchantId] = useState<number>()
  const [filterStartDate, setFilterStartDate] = useState<string>()
  const [filterEndDate, setFilterEndDate] = useState<string>()
  const [transactionTypeId, setTransactionTypeId] = useState<number>()
  const [initialValues, initialValuesSet] =
    useState<TransactionFilterFormTypes>({
      ...TransactionFilterINITVAL,
    })
  const transactionsLoading = useTransactionStore(
    (state) => state.transactionsLoading
  )
  const getTransactions = useTransactionStore((state) => state.getTransactions)
  const isFilteredTransactions = useTransactionStore(
    (state) => state.isFilteredTransactions
  )
  const setFilteredTransactionsStatus = useTransactionStore(
    (state) => state.setFilteredTransactionsStatus
  )

  // start: Transaction filter handler
  const handleTransactionFilter = useCallback(
    async (
      values: TransactionFilterFormSubmitValuesTypes,
      actions: FormikHelpers<TransactionFilterFormSubmitValuesTypes>
    ) => {
      setMerchantId(parseInt(selectedMerchant?.value))
      startDate
        ? setFilterStartDate(formatDateToShort(startDate || new Date()))
        : null
      endDate
        ? setFilterEndDate(formatDateToShort(endDate || new Date()))
        : null
      setTransactionTypeId(parseInt(values.transactionType.value))
      actions.setSubmitting(false)
      const merchantId = values.merchant
      const queryStartDate = startDate
        ? formatDateToShort(startDate || new Date())
        : undefined
      const queryEndDate = endDate
        ? formatDateToShort(endDate || new Date())
        : undefined
      const queryTransactionTypeId =
        values.transactionType.value === '0'
          ? undefined
          : values.transactionType.value
      await getTransactions(
        memberTraderId,
        undefined,
        queryTransactionTypeId,
        merchantId,
        queryStartDate,
        queryEndDate
      )
      setFilteredTransactionsStatus(true)
    },
    [
      selectedMerchant,
      startDate,
      endDate,
      setMerchantId,
      setFilterStartDate,
      setFilterEndDate,
      setTransactionTypeId,
      getTransactions,
    ]
  )

  const handleTransactionFilterClear = useCallback(async () => {
    await getTransactions(memberTraderId)
    setFilteredTransactionsStatus(false)
  }, [getTransactions])

  return {
    handleTransactionFilter,
    handleTransactionFilterClear,
    merchantId,
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
    transactionTypeId,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedMerchant,
    setSelectedMerchant,
    initialValues,
    isFilteredTransactions,
    transactionsLoading,
  }
}

export default useTransactionsFilter
