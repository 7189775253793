import { useState } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import IconDropdown from 'components/IconDropdown'
import ModalSendEmail from './Buttons/ModalSendEmail'
import ModalAddNote from './Buttons/ModalAddNote'
import ModalAddMembership from './Buttons/ModalAddMembership'
import useMemberStore from 'store/member'
import { accessControlStatus, AccessStatusTypes } from 'setups/controller'
import { MEMBERMODALNAVIGATION } from 'constants/profileModalMember'

const Buttons = (): JSX.Element => {
  // START: CHECK WITH ACCESS STATUS
  const memberProfile = useMemberStore((state) => state.member)
  const MEMBERMODALNAVIGATION_FILTERED = accessControlStatus(
    MEMBERMODALNAVIGATION,
    'member',
    memberProfile?.memberThorStatusName as AccessStatusTypes
  )
  // END: CHECK WITH ACCESS STATUS

  const [showCreateTransactionModal, setShowCreateTransactionModal] = useState<{
    open: boolean
    key: string
  }>({ open: false, key: '' })

  const OPTIONS: { label: string; value: string }[] =
    MEMBERMODALNAVIGATION_FILTERED.map((item) => ({
      label: item.label || '',
      value: item.value || '',
    }))

  const [showAddNoteModal, setShowAddNoteModal] = useState<boolean>(false)
  const [showAddMembershipModal, setShowAddMembershipModal] = useState<boolean>(false)
  const [showSendEmail, setShowSendEmail] = useState<boolean>(false)

  return (
    <>
      <div className="z-10 flex justify-end flex-grow">
        {/* <IconDropdown
          title="Send New"
          icon={faPaperPlane}
          iconPosition="left"
          options={SEND_OPTIONS}
          toggleClassName="py-1 px-3 rounded-md bg-primary text-white border border-primary mx-1"
          itemClassName="py-1"
          menuClassName="border-gray-500 shadow-md px-2 py-3"
          displayArrowDown
          onSelect={(value) => {
            if (value === 'email') setShowSendEmail(true)
          }}
        /> */}

        <button
          className="px-4 py-2 mx-1 border rounded-md border-primary text-primary"
          onClick={() => setShowAddNoteModal(true)}
          type="button"
        >
          <Icon icon={faPlus} className="mr-2" />
          <span className="tracking-wider font-light">Add Note</span>
        </button>

        <button
          className="px-4 py-2 mx-1 border rounded-md border-primary text-primary"
          onClick={() => setShowAddMembershipModal(true)}
          type="button"
        >
          <Icon icon={faPlus} className="mr-2" />
          <span className="tracking-wider font-light">Add Membership</span>
        </button>

        <IconDropdown
          title={
            <span className="tracking-wider font-light ml-1">
              Create Transaction
            </span>
          }
          icon={faPlus}
          iconClassName="text-primary"
          iconPosition="left"
          options={OPTIONS}
          toggleClassName="py-2 px-4 rounded-md text-primary border border-primary mx-1"
          itemClassName="py-1"
          menuClassName="border-gray-500 shadow-md px-2 py-3"
          displayArrowDown
          onSelect={(value) => {
            setShowCreateTransactionModal({
              open: true,
              key: value,
            })
          }}
        />
      </div>

      <ModalSendEmail
        open={showSendEmail}
        onClose={() => setShowSendEmail(false)}
      />

      <ModalAddNote
        open={showAddNoteModal}
        onClose={() => setShowAddNoteModal(false)}
      />

      <ModalAddMembership
        open={showAddMembershipModal}
        onClose={() => setShowAddMembershipModal(false)}
      />

      {MEMBERMODALNAVIGATION_FILTERED.map(({ label, value, Modal }) => {
        if (Modal)
          return (
            <Modal
              key={value}
              title={`Add Merchant ${label}`}
              open={
                showCreateTransactionModal.key === value
                  ? showCreateTransactionModal.open
                  : false
              }
              onClose={() =>
                setShowCreateTransactionModal({
                  open: false,
                  key: value || '',
                })
              }
            />
          )
      })}
    </>
  )
}

export default Buttons
