import { formatter } from 'utils'

import { MemberDetail } from 'types/member'

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import createTw from 'react-pdf-tailwind'

const tw = createTw({
  theme: {
    fontFamily: {
      Roboto: ['Roboto'],
    },
    extend: {
      colors: {
        custom: '#bada55',
      },
    },
  },
})

interface Props {
  member: MemberDetail
}

interface ChannelStatusProps {
  value: number
}

const ChannelStatus = ({ value }: ChannelStatusProps): JSX.Element => {
  const isTrue = ['1'].includes(String(value))
  const bgColor = isTrue ? 'bg-green-600' : 'bg-red-600'
  const statusText = isTrue ? 'Unlimited' : 'Forbidden'
  return (
    <View style={tw(`flex flex-row`)}>
      <Text style={tw(`${bgColor} text-sm text-white p-2 rounded-lg`)}>
        {statusText}
      </Text>
    </View>
  )
}

const Index = ({ member }: Props): JSX.Element => {
  const PROJECT = process.env.REACT_APP_PROJ
  return (
    <Document>
      {/* <Page style={styles.body}> */}
      <Page size="A4" style={tw('flex flex-col Roboto')}>
        {/* INFO DETAILS */}
        <View style={tw('p-8')}>
          <View style={tw('flex flex-row justify-between items-end')}>
            {/* left */}
            <View style={tw('flex flex-col')}>
              <Text
                style={tw('text-xl font-bold leading-6')}
              >{`${member.firstName} ${member.lastName}`}</Text>
              <Text style={tw('text-sm font-normal leading-6')}>
                {member.email}
              </Text>
              <Text style={tw('text-sm font-normal leading-6')}>
                {member.traderId}
              </Text>
            </View>
            {/* right */}
            <View style={tw('flex flex-col bg-gray-100 px-8 py-8 rounded-lg')}>
              <View>
                <Image
                  source={`/accounts/${PROJECT}/logo.png`}
                  style={tw('w-40')}
                />
              </View>

              <View style={tw('flex flex-col p-4')}>
                <Text style={tw('text-xl font-bold leading-6')}>
                  {PROJECT?.toUpperCase()}
                </Text>
                <Text style={tw('text-sm font-normal leading-6')}>
                  CARD NUMBER
                </Text>
                <Text style={tw('text-sm font-normal leading-6')}>
                  {member.cardNumber}
                </Text>
              </View>
            </View>
          </View>

          {/* membership */}
          <View style={tw('flex flex-row  mt-4')}>
            <View style={tw('flex flex-col mr-4')}>
              <Text style={tw('text-sm font-normal leading-6')}>
                Member Type
              </Text>
              <Text style={tw('text-sm font-normal leading-6')}>
                {member.traderType}
              </Text>
            </View>
          </View>

          {/* balances */}
          <View style={tw('flex flex-row  mt-4')}>
            <View style={tw('flex flex-col mr-4')}>
              <Text style={tw('text-sm font-normal leading-6')}>
                Loyalty Balance
              </Text>
              <Text style={tw('text-sm font-normal leading-6')}>
                {member.loyaltyBalance}
              </Text>
            </View>
          </View>

          <View style={tw('flex flex-row  mt-4')}>
            <View style={tw('flex flex-col mr-4')}>
              <Text style={tw('text-sm font-normal leading-6')}>
                Credit Balance
              </Text>
              <Text style={tw('text-sm font-normal leading-6')}>
                {member.creditBalance}
              </Text>
            </View>
          </View>

          {/* communication */}
          <View style={tw('flex flex-col mt-8')}>
            <View style={tw('flex flex-col')}>
              <Text style={tw('text-xl font-bold')}>
                COMMUNICATION CHANNELS
              </Text>
            </View>
          </View>

          <View style={tw('flex flex-row mt-4')}>
            <View style={tw('flex flex-col mr-4')}>
              <Text style={tw('text-sm font-normal leading-6')}>
                Email Channel
              </Text>
              <View style={tw('mt-1')}>
                <ChannelStatus value={member.channelEmail} />
              </View>
            </View>
          </View>

          <View style={tw('flex flex-row  mt-4')}>
            <View style={tw('flex flex-col mr-4')}>
              <Text style={tw('text-sm font-normal leading-6')}>
                Postal Channel
              </Text>
              <View style={tw('mt-1')}>
                <ChannelStatus value={member.channelpostal} />
              </View>
            </View>
          </View>

          <View style={tw('flex flex-row  mt-4')}>
            <View style={tw('flex flex-col mr-4')}>
              <Text style={tw('text-sm font-normal leading-6')}>
                SMS Channel
              </Text>
              <View style={tw('mt-1')}>
                <ChannelStatus value={member.channelSMS} />
              </View>
            </View>
          </View>

          <View style={tw('flex flex-row  mt-4')}>
            <View style={tw('flex flex-col mr-4')}>
              <Text style={tw('text-sm font-normal leading-6')}>
                Push Notification Channel
              </Text>
              <View style={tw('mt-1')}>
                <ChannelStatus value={member.channelApp} />
              </View>
            </View>
          </View>

          {/* others */}
          <View style={tw('flex flex-col mt-8')}>
            <View style={tw('flex flex-col')}>
              <Text style={tw('text-xl font-bold')}>OTHER INFORMATIONS</Text>
            </View>
          </View>

          <View style={tw('flex flex-row  mt-4')}>
            <View style={tw('flex flex-col mr-4')}>
              <Text style={tw('text-sm font-normal leading-6')}>
                Profile Last Modified
              </Text>
              <Text style={tw('text-sm font-normal leading-6')}>
                {formatter.displayDate(member.lastModifiedDate)}
              </Text>
            </View>
          </View>

          <View style={tw('flex flex-row  mt-4')}>
            <View style={tw('flex flex-col mr-4')}>
              <Text style={tw('text-sm font-normal leading-6')}>
                Terms and Conditions Version
              </Text>
              <Text style={tw('text-sm font-normal leading-6')}>
                {member.tncVersion}
              </Text>
            </View>
          </View>

          <View style={tw('flex flex-row  mt-4')}>
            <View style={tw('flex flex-col mr-4')}>
              <Text style={tw('text-sm font-normal leading-6')}>
                Privacy Version
              </Text>
              <Text style={tw('text-sm font-normal leading-6')}>
                {member.privacyVersion}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      {/* </Page> */}
    </Document>
  )
}

export default Index
