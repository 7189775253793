import { useCallback, useEffect, useMemo, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { FormikHelpers } from 'formik'

import { TRANSACTION_TYPES } from 'constants/filters'
import { formatDateToShort } from 'services/helpers'
import {
  MerchantIdentificationFilterFormTypes,
  MerchantIdentificationFilterFormSubmitValuesTypes,
} from 'types/merchant'
import useMerchantStore from 'store/merchant'

const FILTERMERCHANTIDENTIFICATIONINITVAL: MerchantIdentificationFilterFormTypes =
  {
    identificationType: null,
    identificationStatus: null,
  }

const useIndentificationFilter = ({
  merchantTraderId,
}: {
  merchantTraderId: string
}) => {
  const [initialValues, initialValuesSet] =
    useState<MerchantIdentificationFilterFormTypes>({
      ...FILTERMERCHANTIDENTIFICATIONINITVAL,
    })
  const merchantIdentificationLoading = useMerchantStore(
    (state) => state.merchantIdentificationLoading
  )
  const merchantIdentificationStatus = useMerchantStore(
    (state) => state.merchantIdentificationStatus
  )
  const merchantIdentificationBackup = useMerchantStore(
    (state) => state.merchantIdentificationBackup
  )
  const getMerchantIdentificationFiltered = useMerchantStore(
    (state) => state.getMerchantIdentificationFiltered
  )
  const getMerchantIdentification = useMerchantStore(
    (state) => state.getMerchantIdentification
  )
  const merchantIdentificationStatuses = useMerchantStore(
    (state) => state.merchantIdentificationStatuses
  )
  const merchantIdentificationStatusesLoading = useMerchantStore(
    (state) => state.merchantIdentificationStatusesLoading
  )
  const getMerchantIdentificationStatuses = useMerchantStore(
    (state) => state.getMerchantIdentificationStatuses
  )
  const merchantIdentificationTypes = useMerchantStore(
    (state) => state.merchantIdentificationTypes
  )
  const merchantIdentificationTypesLoading = useMerchantStore(
    (state) => state.merchantIdentificationTypesLoading
  )
  const getMerchantIdentificationTypes = useMerchantStore(
    (state) => state.getMerchantIdentificationTypes
  )

  const isFilteredIdentification = useMemo(() => {
    return merchantIdentificationBackup.length ? true : false
  }, [merchantIdentificationBackup])
  const identificationLoading = useMemo(() => {
    return merchantIdentificationLoading || false
  }, [merchantIdentificationLoading])

  // start: Transaction filter handler
  const handleIdentificationFilter = useCallback(
    async (
      values: MerchantIdentificationFilterFormSubmitValuesTypes,
      actions: FormikHelpers<MerchantIdentificationFilterFormSubmitValuesTypes>
    ) => {
      // identificationStatusSet(values.identificationStatus?.value || null)
      // identificationTypeSet(values.identificationType?.value || null)
      await getMerchantIdentificationFiltered(Number(merchantTraderId), {
        identificationStatusId:
          Number(values.identificationStatus?.value) || undefined,
        identificationTypeId:
          Number(values.identificationType?.value) || undefined,
      })
      actions.setSubmitting(false)
    },
    [getMerchantIdentificationFiltered]
  )

  const handleIdentificationFilterClear = useCallback(async () => {
    await getMerchantIdentification(Number(merchantTraderId))
  }, [getMerchantIdentification])

  useEffect(() => {
    getMerchantIdentificationStatuses()
    getMerchantIdentificationTypes()
  }, [])

  return {
    handleIdentificationFilter,
    handleIdentificationFilterClear,
    initialValues,
    isFilteredIdentification,
    identificationLoading,
    merchantIdentificationStatuses,
    merchantIdentificationStatusesLoading,
    merchantIdentificationTypes,
    merchantIdentificationTypesLoading,
  }
}

export default useIndentificationFilter
