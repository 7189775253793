import classnames from 'classnames'
import { Field, FieldAttributes, FormikErrors, useFormikContext } from 'formik'
import isUndefined from 'lodash/isUndefined'
import React, { PropsWithChildren, useMemo } from 'react'

type Props = FieldAttributes<Record<string, unknown>> & {
  name: string
  label?: string
  error?: string
  as?: string
  labelClassName?: string
  errorClassName?: string
  rounded?: boolean
  component?: string
  containerClassName?: string
  containerStyle?: React.CSSProperties
  inputStyle?: React.CSSProperties
  filter?: boolean
  helper?: string
  helperDescription?: string
}

function Input({
  name,
  label,
  error,
  as,
  maxLength,
  disabled,
  placeholder,
  children,
  className,
  labelClassName,
  errorClassName,
  type,
  rounded,
  component,
  containerClassName,
  containerStyle,
  inputStyle,
  filter,
  helper,
  helperDescription,
}: PropsWithChildren<Props>): React.ReactElement {
  const formik = useFormikContext()

  const isDisabled = useMemo(() => {
    if (formik && !disabled) {
      return formik.isSubmitting
    } else {
      return disabled
    }
  }, [disabled, formik])

  const actualError = useMemo(() => {
    // We allow "any" here because we can't possibly know what fields are available.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (formik && isUndefined(error) && (formik.touched as any)[name]) {
      return (formik.errors as FormikErrors<Record<string, string>>)[name]
    } else {
      return error
    }
  }, [name, error, formik])

  return (
    <div
      className={classnames(filter ? 'mb-0' : 'mb-5', containerClassName)}
      style={containerStyle || undefined}
    >
      {label ? (
        <label
          htmlFor={name}
          className={classnames('flex gap-2 text-sm mb-2', labelClassName)}
        >
          <span className="tracking-wider font-light">{label}</span>
          {helper && (
            <span
              className="py-0 px-1.5 rounded-full bg-primary font-light text-white"
              title={helperDescription}
            >
              {helper}
            </span>
          )}
        </label>
      ) : null}

      <div>
        <Field
          id={name}
          type={type || 'text'}
          name={name}
          as={as}
          disabled={isDisabled}
          placeholder={placeholder}
          className={classnames(
            {
              rounded: rounded,
              'bg-gray-100': isDisabled,
              'border-gray-600': !actualError,
              'border-red': !!actualError,
            },
            className,
            !filter && 'block border p-2 text-black w-full tracking-wider'
          )}
          maxLength={maxLength}
          component={component}
          style={inputStyle || undefined}
        >
          {children}
        </Field>
      </div>

      {actualError && (
        <div
          className={classnames(
            'text-red text-xs mt-1 tracking-wider',
            errorClassName
          )}
          role="alert"
        >
          {actualError}
        </div>
      )}
    </div>
  )
}

export default Input
