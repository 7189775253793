import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { selectEmailPreviewAPI } from 'api/comms'
import { useCommsEmailPreviewStore } from 'store/comms'
import { CommsDataTypes, CommsParamsTypes } from 'types/comms'

import { DATASTATUS } from 'constants/status'
import { QUERY_KEYS } from 'constants/querykeys'

export const useEmailPreview = (params: CommsParamsTypes) => {
  const queryKey = [QUERY_KEYS.COMMS_EMAIL_PREVIEW, params]
  const queryFn = async () => {
    return await selectEmailPreviewAPI(params)
  }
  const query = useQuery({
    refetchOnWindowFocus: false,
    queryKey,
    queryFn,
  })

  return {
    ...query,
    memberCommsEmailPreview: [],
    memberCommsEmailPreviewModStatus: false,
  }
}
