export const HDAACCOUNTS = {
  subway: {
    bgColor: '',
    logo: '/accounts/subway/logo.png',
    alt: 'subway',
  },
  columbuscoffee: {
    bgColor: '',
    logo: '/accounts/columbuscoffee/logo.png',
    alt: 'columbuscoffee',
  },
  redrooster: {
    bgColor: '',
    logo: '/accounts/redrooster/logo.png',
    alt: 'redrooster',
  },
  oporto: {
    bgColor: '',
    logo: '/accounts/oporto/logo.png',
    alt: 'oporto',
  },
  hampsta: {
    bgColor: '',
    logo: '/accounts/hampsta/logo.png',
    alt: 'hampsta',
  },
  countdown: {
    bgColor: '',
    logo: '/accounts/countdown/logo.png',
    alt: 'countdown',
  },
  paperplus: {
    bgColor: '',
    logo: '/accounts/paperplus/logo.png',
    alt: 'paperplus',
  },
  westfield: {
    bgColor: '',
    logo: '/accounts/westfield/logo.png',
    alt: 'westfield',
  },
}

export const HDAACCOUNTSARRAY = [
  {
    project: 'subway',
    bgColor: '',
    logo: '/accounts/subway/logo.png',
    alt: 'subway',
  },
  {
    project: 'columbuscoffee',
    bgColor: '',
    logo: '/accounts/columbuscoffee/logo.png',
    alt: 'columbuscoffee',
  },
  {
    project: 'redrooster',
    bgColor: '',
    logo: '/accounts/redrooster/logo.png',
    alt: 'redrooster',
  },
  {
    project: 'oporto',
    bgColor: '',
    logo: '/accounts/oporto/logo.png',
    alt: 'oporto',
  },
  {
    project: 'hampsta',
    bgColor: '',
    logo: '/accounts/hampsta/logo.png',
    alt: 'hampsta',
  },
  {
    project: 'countdown',
    bgColor: '',
    logo: '/accounts/countdown/logo.png',
    alt: 'countdown',
  },
  {
    project: 'generic',
    bgColor: '',
    logo: '/accounts/generic/logo.png',
    alt: 'generic',
  },
  {
    project: 'paperplus',
    bgColor: '',
    logo: '/accounts/paperplus/logo.png',
    alt: 'paperplus',
  },
  {
    project: 'westfield',
    bgColor: '',
    logo: '/accounts/westfield/logo.png',
    alt: 'westfield',
  },
]
