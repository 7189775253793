import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { getMerchants } from 'api/merchant'
import { getTransactions } from 'api/transactions'
import Button from 'components/Button'
import Input from 'components/Input'
import Dropdown, { OptionType } from 'components/Dropdown'
import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import { TRANSACTION_STATUS, TRANSACTION_TYPES } from 'constants/filters'
import { Form, Formik } from 'formik'
import { isEmpty, isUndefined } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import Modal from 'components/Modal'
import { MerchantIdentificationTypes } from 'types/merchant'
import InnerWrapper from '../InnerWrapper'
import IdentificationItem from './IdentificationItem'
import IdentificationFilter from './IdentificationFilter'
import useMerchantIdentification from './useMerchantIdentification'
import IdentificationStatusForm from './IdentificationStatusForm'

const IDENTIFICATION_COLUMNS = ['Identification', 'Type', 'Status']

function DeviceCode(): React.ReactElement {
  const { traderId } = useParams()
  const [transactionFetchLimitError, setTransactionFetchLimitError] =
    useState(false)
  const [, setMerchantFetchLimitError] = useState(false)
  const [offset, setOffset] = useState(0)
  const [perPage] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [postData, setPostData] = useState<MerchantIdentificationTypes[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const {
    isMerchantIdentificationEdit,
    isMerchantIdentificationEditSet,
    selectedMerchantIdentification,
    selectedMerchantIdentificationSet,
    merchantIdentification,
    merchantIdentificationLoading,
    merchantIdentificationStatuses,
    merchantIdentificationStatusesLoading,
  } = useMerchantIdentification({
    merchantTraderId: Number(traderId),
  })

  useEffect(() => {
    if (merchantIdentification) {
      const slice = merchantIdentification.slice(offset, offset + perPage)
      setPostData(slice)
      setPageCount(Math.ceil(merchantIdentification.length / perPage))
    }
  }, [offset, perPage, merchantIdentification])

  function handlePageClick({ selected }: { selected: number }): void {
    setOffset(selected * perPage)
    setCurrentPage(selected + 1)
  }

  return (
    <InnerWrapper title="Identifications">
      <IdentificationFilter />

      {transactionFetchLimitError ? (
        <NoResults
          header="Failed to load data"
          subtitle="Please refresh the page and try again, or contact
        your system administrator"
          noImage
        />
      ) : merchantIdentificationLoading ? (
        <>
          <NoResults header="Loading" subtitle="Fetching data" noImage />
          <Spinner />
        </>
      ) : (
        <>
          {isEmpty(merchantIdentification) ? (
            <NoResults noImage />
          ) : (
            <>
              {/* START: TABLE */}
              <Table
                columns={IDENTIFICATION_COLUMNS}
                data={merchantIdentification}
                pageCount={pageCount}
                currentPage={currentPage}
                perPage={perPage}
                onPageChange={handlePageClick}
                itemCount={postData.length}
                showActions
              >
                {postData.map((item: MerchantIdentificationTypes) => (
                  // <div key={item.deviceId}>{item.deviceCode}</div>
                  <IdentificationItem
                    key={item.ident}
                    identification={item}
                    selectedMerchantIdentificationSet={
                      selectedMerchantIdentificationSet
                    }
                    isMerchantIdentificationEditSet={
                      isMerchantIdentificationEditSet
                    }
                  />
                ))}
              </Table>
              {/* END: TABLE */}

              {/* START: MODAL */}
              {selectedMerchantIdentification ? (
                <Modal
                  open={isMerchantIdentificationEdit}
                  title="Edit Merchant Identification Status"
                  onClose={() => isMerchantIdentificationEditSet(false)}
                  noFooter
                >
                  <IdentificationStatusForm
                    onClose={() => isMerchantIdentificationEditSet(false)}
                    selectedMerchantIdentification={
                      selectedMerchantIdentification
                    }
                  />
                </Modal>
              ) : null}
              {/* END: MODAL */}
            </>
          )}
        </>
      )}
    </InnerWrapper>
  )
}

export default DeviceCode
