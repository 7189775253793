import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import TSTable from 'components/Tanstack'
import { ToggleMenu } from 'components/Layout/ToggleMenu'

import InnerWrapper from '../InnerWrapper'
import { useMemberMembershipsTable } from './useMemberMembershipsTable'
import MembershipsFilterForm from './MembershipsFilterForm'
import { useMemberMemberships } from './useMemberMemberships'

const Index = (): JSX.Element => {
  const {
    memberMemberships,
    memberMembershipsModStatus,
    memberMembershipsFilterParams,
    memberHandleMembershipsFilter,
    memberHandleMembershipsUnfilter,
  } = useMemberMemberships()

  const {
    memberMembershipsSelected,
    memberMembershipsActionModal,
    memberMembershipsTableData,
    memberMembershipsTableColumns,
    handleMemberActionModalClose,
  } = useMemberMembershipsTable()

  return (
    <InnerWrapper title="Memberships">
      {/* FILTER */}
      {/* <MembershipsFilterForm
        formStatus={memberMembershipsModStatus}
        formDefaultValues={memberMembershipsFilterParams}
        onFilter={memberHandleMembershipsFilter}
        onFilterClear={memberHandleMembershipsUnfilter}
      /> */}

      {/* ADVANCE SEARCH  */}
      <div className="pb-6">
        <ToggleMenu
          type="search"
          label="Search Memberships"
          isTriggeToggle={memberMembershipsModStatus === 'success' || false}
        >
          <MembershipsFilterForm
            formStatus={memberMembershipsModStatus}
            formDefaultValues={memberMembershipsFilterParams}
            onFilter={memberHandleMembershipsFilter}
            onFilterClear={memberHandleMembershipsUnfilter}
          />
        </ToggleMenu>
      </div>

      {/* FAIL */}
      {memberMembershipsModStatus === 'fail' ? (
        <NoResults
          header="Failed to load data"
          subtitle="Please refresh the page and try again, or contact
        your system administrator"
          noImage
        />
      ) : null}

      {/* LOADING */}
      {memberMembershipsModStatus === 'loading' ? <Spinner /> : null}

      {/* SUCCESS */}
      {memberMemberships.length && memberMembershipsModStatus === 'success' ? (
        <TSTable.Table
          data={memberMembershipsTableData}
          columns={memberMembershipsTableColumns}
        />
      ) : null}

      {/* EMPTY */}
      {!memberMemberships.length && memberMembershipsModStatus === 'success' ? (
        <NoResults
          header="No available data"
          subtitle="There is no available data to display"
          noImage
        />
      ) : null}
    </InnerWrapper>
  )
}

export default Index
