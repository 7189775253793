import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const BankDefaultSchemas = DefaultSchemas

export const BankDataSchemas = zod.object({
  accountFormat: zod.string(),
  bankId: zod.number(),
  bankName: zod.string(),
})

export const BankAPISchemas = zod.object({
  data: zod.array(
    zod.object({
      attributes: zod.object({
        bankName: zod.string(),
      }),
      id: zod.number(),
    })
  ),
})
