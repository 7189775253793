import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import { MerchantTransactionTypes } from 'types/merchant'
import InnerWrapper from '../InnerWrapper'
import TransactionItem from './TransactionItem'
import TransactionFilter from './TransactionFilter'
import useMerchantTransaction from './useMerchantTransaction'

const TRANSACTION_COLUMNS = [
  'Country',
  'Date',
  'Trans Id',
  'Ident',
  'Ext Trans Id',
  'Merchant',
  'Trans Type',
  'CR Value',
  'Loyalty Value',
  'Purchase Value',
]

const Index = (): JSX.Element => {
  const [offset, setOffset] = useState(0)
  const [perPage] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [postData, setPostData] = useState<MerchantTransactionTypes[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const {
    isMerchantTransactionEdit,
    isMerchantTransactionEditSet,
    selectedMerchantTransaction,
    selectedMerchantTransactionSet,
    merchantTransaction,
    merchantTransactionStatus,
    merchantTransactionLoading,
    merchantTransactionModStatus,
  } = useMerchantTransaction()

  useEffect(() => {
    if (merchantTransactionModStatus === 'success') {
      const slice = merchantTransaction.slice(offset, offset + perPage)
      setPostData(slice)
      setPageCount(Math.ceil(merchantTransaction.length / perPage))
    }
  }, [offset, perPage, merchantTransaction, merchantTransactionModStatus])

  const handlePageClick = ({ selected }: { selected: number }): void => {
    setOffset(selected * perPage)
    setCurrentPage(selected + 1)
  }

  return (
    <InnerWrapper title="Transactions">
      <TransactionFilter />

      {merchantTransactionModStatus === 'fail' ? (
        <NoResults
          header="Failed to load data"
          subtitle="Please refresh the page and try again, or contact
        your system administrator"
          noImage
        />
      ) : merchantTransactionModStatus == 'loading' ? (
        <>
          <NoResults header="Loading" subtitle="Fetching data" noImage />
          <Spinner />
        </>
      ) : (
        <>
          {isEmpty(merchantTransaction) ? (
            <NoResults noImage />
          ) : (
            <>
              {/* START: TABLE */}
              <Table
                columns={TRANSACTION_COLUMNS}
                data={merchantTransaction}
                pageCount={pageCount}
                currentPage={currentPage}
                perPage={perPage}
                onPageChange={handlePageClick}
                itemCount={postData.length}
                showActions
              >
                {postData.map((item: MerchantTransactionTypes) => (
                  // <div key={item.deviceId}>{item.deviceCode}</div>
                  <TransactionItem
                    key={item.transactionId}
                    transaction={item}
                    selectedMerchantTransactionSet={
                      selectedMerchantTransactionSet
                    }
                    isMerchantTransactionEditSet={isMerchantTransactionEditSet}
                  />
                ))}
              </Table>
              {/* END: TABLE */}
            </>
          )}
        </>
      )}
    </InnerWrapper>
  )
}

export default Index
