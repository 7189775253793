import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import {
  MerchantBatchTypes,
  CreateMerchantFormTypes,
  CreateMerchantFormSubmitValuesTypes,
  CreateMerchantFormSubmitActionsTypes,
} from 'types/merchant'
import useMerchantStore from 'store/merchant'

const PURCHASETXINITVAL: CreateMerchantFormTypes = {
  batchId: 0,
  countryId: 0,
  name: '',
  merchantCode: null,
  externalId: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  suburb: '',
  city: '',
  postCode: '',
}

export const useCreateMerchant = (
  traderId: number,
  onCloseModal: () => void
): {
  merchantBatch: MerchantBatchTypes[]
  isLoading: boolean
  initialValues: CreateMerchantFormTypes
  handleSubmitCreateMerchant: (
    values: CreateMerchantFormSubmitValuesTypes,
    actions: CreateMerchantFormSubmitActionsTypes
  ) => void
  // handleClearPurchaseTx: () => void
} => {
  const navigate = useNavigate()
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // MERCHANT BATCH START
  const getMerchantBatch = useMerchantStore((state) => state.getMerchantBatch)
  const merchantBatch = useMerchantStore((state) => state.merchantBatch)
  const merchantBatchLoading = useMerchantStore(
    (state) => state.merchantBatchLoading
  )
  const merchantBatchStatus = useMerchantStore(
    (state) => state.merchantBatchStatus
  )
  // MERCHANT BATCH END
  // MERCHANT CREATE START
  const merchantTraderIdNewlyCreated = useMerchantStore(
    (state) => state.merchantTraderIdNewlyCreated
  )
  const createMerchant = useMerchantStore((state) => state.createMerchant)
  const merchantCreateLoading = useMerchantStore(
    (state) => state.merchantCreateLoading
  )
  const merchantCreateStatus = useMerchantStore(
    (state) => state.merchantCreateStatus
  )
  // MERCHANT CREATE END
  // MISC STATES START
  const [initialValues, initialValuesSet] = useState<CreateMerchantFormTypes>({
    ...PURCHASETXINITVAL,
  })
  // MISC STATES END

  const isLoading = useMemo(() => {
    return merchantCreateLoading || merchantBatchLoading ? true : false
  }, [merchantCreateLoading, merchantBatchLoading])

  useEffect(() => {
    getMerchantBatch()
  }, [])

  useEffect(() => {
    if (merchantTraderIdNewlyCreated)
      navigate(`/merchant-profile/${merchantTraderIdNewlyCreated}/transactions`)
  }, [merchantTraderIdNewlyCreated])

  const handleSubmitCreateMerchant = useCallback(
    async (
      values: CreateMerchantFormSubmitValuesTypes,
      actions: CreateMerchantFormSubmitActionsTypes
    ) => {
      try {
        const result = await createMerchant(values)
        console.log({ result })
        toast.success(`Create Merchant successful.`)
        onCloseModal()
        // actions.setSubmitting(false)
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        if (message.outcomeCode === 'HDA_MERCHANT:CREATE_MERCHANT:8. 8') {
          toast.error(`Merchant code already exist.`)
        } else {
          toast.error(`Merchant create error.`)
        }
      }
    },
    [createMerchant]
  )

  const handleClearPurchaseTx = useCallback(() => {
    initialValuesSet({ ...PURCHASETXINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    merchantBatch,
    isLoading,
    initialValues,
    handleSubmitCreateMerchant,
    // handleClearPurchaseTx,
  }
}
