import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { createRef } from 'react'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { DropDownItem, Styles } from 'types/components'

export type OptionType = { label: string; value: number }

type Props = {
  title: string | React.ReactElement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  options?: DropDownItem[]
  right?: boolean
  placeholder?: string
  disabled?: boolean
  className?: string
  menuClassName?: string
  itemClassName?: string
  onSelect?: (item: DropDownItem) => void
  defaultValue?: DropDownItem
  isClearable?: boolean
  isSearchable?: boolean
  roundedCorners?: boolean
  borderColor?: string
  containerMinWidth?: string
  titleStyle?: string
  containerClassName?: string
  overflowStyle?: boolean
  async?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadOptions?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInputChange?: (value: any) => void
}

function Dropdown({
  title,
  value,
  options,
  placeholder,
  disabled,
  className: toggleClassName,
  onSelect,
  defaultValue,
  isClearable,
  isSearchable,
  roundedCorners,
  borderColor,
  titleStyle,
  containerMinWidth,
  containerClassName,
  overflowStyle,
  async,
  loadOptions,
  onChange,
  onInputChange,
}: Props): JSX.Element {
  const customSelectStyle = {
    container: (styles: Styles) => ({
      ...styles,
      // marginLeft: '1rem',
      // marginBottom: '1rem',
      minWidth: containerMinWidth,
    }),
    control: (styles: Styles) => ({
      ...styles,
      boxShadow: 'none',
      borderColor: borderColor,
      ':hover': {
        borderColor: '#0746C0',
        borderWidth: '1px',
      },
      height: '33px',
      borderRadius: roundedCorners ? '0.25rem' : '0.125rem',
    }),
    option: (
      styles: Styles,
      state: { isFocused: boolean; isSelected: boolean }
    ) => ({
      ...styles,
      color: state.isSelected || state.isFocused ? 'black' : 'black', // TODO: double check | changed to ||
      backgroundColor: state.isSelected
        ? '#D0D3DD'
        : state.isFocused
        ? '#E2DFDF'
        : 'white',
      ':hover': {
        // color: state.isFocused ? 'white' : 'black',
        backgroundColor: state.isFocused ? '#E2DFDF' : 'white',
      },
    }),
    dropdownIndicator: (styles: Styles) => ({
      ...styles,
      color: 'black',
    }),
  }

  const overflowSelectStyle = {
    ...customSelectStyle,
    menu: (styles: Styles) => ({
      ...styles,
      position: 'relative',
      index: 1000,
    }),
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ValueContainer = ({ children, ...props }: any): JSX.Element => {
    const { vcChildRef } = props.selectProps

    return (
      <div className="flex items-center flex-grow">
        {isSearchable && <Icon icon={faSearch} className="m-2 mr-1" />}
        <components.ValueContainer {...props}>
          <div ref={vcChildRef}>{children}</div>
        </components.ValueContainer>
      </div>
    )
  }
  const vcChildRef = createRef()

  return (
    <div
      className={classnames('relative flex flex-col gap-2', containerClassName)}
    >
      {title && (
        <label className={`${titleStyle}`}>
          <span className="tracking-wider font-light">{title}</span>
        </label>
      )}
      {async ? (
        <AsyncSelect
          placeholder={placeholder}
          styles={customSelectStyle}
          className={toggleClassName}
          loadOptions={loadOptions}
          onChange={onChange}
          onInputChange={onInputChange}
          components={{
            // ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            ValueContainer,
          }}
          isClearable
          // cacheOptions
          defaultOptions
        />
      ) : (
        <Select
          vcChildRef={vcChildRef}
          isClearable={isClearable}
          isSearchable={isSearchable}
          styles={overflowStyle ? overflowSelectStyle : customSelectStyle}
          isDisabled={disabled}
          className={toggleClassName}
          defaultValue={defaultValue}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onSelect}
          components={{
            IndicatorSeparator: () => null,
            ValueContainer,
          }}
        />
      )}
    </div>
  )
}

Dropdown.defaultProps = {
  overflowStyle: false,
}

export default Dropdown
