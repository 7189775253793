import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getHistoryAPI } from 'api/histories'
import { useHistoryStore } from 'store/histories'
import { HistoryFetchParamsTypes } from 'types/histories'

import { DATASTATUS } from 'constants/status'

export const useMemberHistory = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const historyModStatus = useHistoryStore((state) => state.historyModStatus)
  const historyModStatusSet = useHistoryStore(
    (state) => state.historyModStatusSet
  )
  const historyModErrors = useHistoryStore((state) => state.historyModStatus)
  const historyModErrorsSet = useHistoryStore(
    (state) => state.historyModErrorsSet
  )
  const history = useHistoryStore((state) => state.histories)
  const historySet = useHistoryStore((state) => state.historySet)
  const [historyFilterParams, historyFilterParamsSet] =
    useState<HistoryFetchParamsTypes>(defaultParams)
  const [isHistoryFiltered, isHistoryFilteredSet] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataHistory,
    isLoading: isLoadingHistory,
    isError: isErrorHistory,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useHistory', historyFilterParams],
    queryFn: async () => {
      try {
        historyModStatusSet('loading')
        const response = await getHistoryAPI(historyFilterParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        historyModStatusSet('success')
        return response.memberHistory
      } catch (e) {
        historyModStatusSet('fail')
        historyModErrorsSet(JSON.stringify(e))
      }
    },
  })

  const handleFilter = useCallback(
    async (filterParams: HistoryFetchParamsTypes) => {
      historyFilterParamsSet(filterParams)
      isHistoryFilteredSet(true)
    },
    [historyFilterParamsSet, isHistoryFilteredSet]
  )

  const handleUnfilter = useCallback(() => {
    const test = { traderId: memberTraderId }
    historyFilterParamsSet(test)
    isHistoryFilteredSet(false)
  }, [historyFilterParamsSet, isHistoryFilteredSet])

  useEffect(() => {
    if (dataHistory) historySet(dataHistory)
  }, [dataHistory, historySet])

  return {
    memberHandleHistoryFilter: handleFilter,
    memberHandleHistoryUnfilter: handleUnfilter,
    memberHistoryFilterParams: historyFilterParams,
    memberHistory: history,
    memberHistoryModStatus: historyModStatus,
  }
}
