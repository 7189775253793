import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { VouchersFetchParamsSchemas } from 'schemas/vouchersSchemas'
import { VouchersFetchParamsTypes } from 'types/vouchers'

export const useMemberVouchersFilterForm = ({
  defaultValues,
}: {
  defaultValues: VouchersFetchParamsTypes
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<VouchersFetchParamsTypes>({
    resolver: zodResolver(VouchersFetchParamsSchemas),
    defaultValues,
  })

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
  }
}
