import {
  faChevronDown,
  faChevronUp,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { parseToObject } from 'services/helpers'
import MemberSearchForm from './MemberSearchForm'
import SearchTips from './SearchTips'
import SubsquadSearchForm from './SubsquadSearchForm'
import SubSquadSearchTips from './SubSquadSearchTips'
import MerchantSearchForm from './MerchantSearchForm'
import MerchantSearchTips from './MerchantSearchTips'

type SearchBarProps = {
  isMemberLookup?: boolean
  isSubsquadLookup?: boolean
  isMerchantLookup?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startFetch: (value: any) => void
  startLoading: () => void
  stopLoading: () => void
  closeSearchForm: (callback: () => void) => void
  placeholder: string
}

const searchArray = [
  'fname',
  'lname',
  'id',
  'card',
  'email',
  'phone',
  'gurn',
  'gname',
  'id',
  'card',
]
// const searchSubSquadArray = ['gurn', 'gname', 'id', 'card']

const SearchBar: FC<SearchBarProps> = ({
  isMemberLookup,
  isSubsquadLookup,
  isMerchantLookup,
  startLoading,
  stopLoading,
  startFetch,
  placeholder,
}): JSX.Element => {
  const [isInputFocus, setInputFocus] = useState(false)
  const [openSearchForm, setOpenSearchForm] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const showAdvancedSearch = (): void => {
    setInputFocus(false)
    setOpenSearchForm(!openSearchForm)
  }

  function handleTermChange(event: { target: { value: string } }): void {
    const newTerm = event.target.value
    setSearchValue(newTerm)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function useOutsideAlerter(ref: any): void {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent): void {
        if (ref.current && !ref.current.contains(event.target)) {
          setInputFocus(false)
          setOpenSearchForm(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  function handleKeyPress(event: KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter') {
      // split strings and validate that firstname is not the only field

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let parsedTerm: any = ''
      parsedTerm = searchValue.match(/(\S+\s*:\s*\S+)/g)

      if (parsedTerm === null) return

      const searchValues = parseToObject(parsedTerm)

      startLoading()

      if (parsedTerm.length === 1) {
        const field = parsedTerm[0]
          .substr(0, parsedTerm[0].indexOf(':'))
          .toLowerCase()

        // check string before colon is fname
        // if (field === 'fname') {
        //   // hide search options
        //   setInputFocus(false)
        //   stopLoading()
        //   toast.error(
        //     'First name must be accompanied with at least one (1) other field. Fill up other field(s).'
        //   )
        // }  
        if (!searchArray.includes(field.toLowerCase())) {
          setInputFocus(false)
          stopLoading()
          toast.error('Please input valid field name.')
        } else {
          // fetch members
          setInputFocus(false)
          startFetch(searchValues)
        }
      } else {
        // call get members
        setInputFocus(false)
        startFetch(searchValues)
      }
    }
  }

  function handleKeyPressSubsquad(
    event: KeyboardEvent<HTMLInputElement>
  ): void {
    if (event.key === 'Enter') {
      // split strings and validate that firstname is not the only field
      startLoading()
      const newTerm = searchValue
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let parsedTerm: any = ''

      if (newTerm !== null) {
        parsedTerm = newTerm.match(/(\S+\s*:\s*\S+)/g)
      }

      const searchValues = parseToObject(parsedTerm)

      // fetch members
      setInputFocus(false)
      startFetch(searchValues)
    }
  }

  // function handleKeyPressMerchant(
  //   event: KeyboardEvent<HTMLInputElement>
  // ): void {
  //   if (event.key === 'Enter') {
  //     // split strings and validate that firstname is not the only field
  //     startLoading()
  //     const newTerm = searchValue
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     let parsedTerm: any = ''

  //     if (newTerm !== null) {
  //       parsedTerm = newTerm.match(/(\S+\s*:\s*\S+)/g)
  //     }

  //     const searchValues = parseToObject(parsedTerm)
  //     // fetch members
  //     setInputFocus(false)
  //     startFetch(searchValues)
  //   }
  // }

  function handleClear(): void {
    setSearchValue('')
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div className="relative flex flex-col w-full" ref={wrapperRef}>
      <div className="relative table w-full border-separate focus-within:text-gray-500">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <button className="p-2 cursor-pointer focus:outline-none focus:shadow-outline">
            <Icon icon={faSearch} className="text-gray-500" />
          </button>
        </span>
        <input
          id="txtSearchTerm"
          type="text"
          autoComplete="off"
          placeholder={placeholder}
          className="box-border block w-full h-12 px-2 pl-10 font-sans text-sm border border-r-0 border-gray-500 rounded-md rounded-r-none outline-none"
          value={searchValue}
          onChange={handleTermChange}
          onKeyUp={isMemberLookup ? handleKeyPress : handleKeyPressSubsquad}
          onFocus={() => {
            setInputFocus(true)
            setOpenSearchForm(false)
          }}
        />
        {searchValue !== '' && (
          <span className="absolute inset-y-0 right-0 flex items-center mr-10">
            <button
              className="cursor-pointer focus:outline-none focus:shadow-outline"
              onClick={handleClear}
            >
              <Icon icon={faTimes} className="text-gray-500 hover:text-black" />
            </button>
          </span>
        )}

        <div className="table-cell w-px p-0 align-top border-none whitespace-nowrap bg-none">
          <button
            id="advancedSearchTrigger"
            type="button"
            className="h-12 px-4 py-1 text-sm border border-l-0 border-gray-500 rounded-md rounded-l-none focus:outline-none focus:shadow-outline"
            onClick={showAdvancedSearch}
          >
            <Icon
              icon={openSearchForm ? faChevronUp : faChevronDown}
              className="text-gray-500"
            />
          </button>
        </div>
      </div>

      {isInputFocus ? (
        <>
          {isMemberLookup ? <SearchTips /> : null}
          {isSubsquadLookup ? <SubSquadSearchTips /> : null}
          {isMerchantLookup ? <MerchantSearchTips /> : null}
        </>
      ) : null}

      {openSearchForm ? (
        <>
          {isMemberLookup ? (
            <MemberSearchForm
              startFetching={startFetch}
              startLoading={startLoading}
              closeSearchForm={() => setOpenSearchForm(false)}
            />
          ) : null}
          {isSubsquadLookup ? (
            <SubsquadSearchForm
              startFetching={startFetch}
              startLoading={startLoading}
              closeSearchForm={() => setOpenSearchForm(false)}
            />
          ) : null}
          {isMerchantLookup ? (
            <MerchantSearchForm
              startFetching={startFetch}
              startLoading={startLoading}
              closeSearchForm={() => setOpenSearchForm(false)}
            />
          ) : null}
        </>
      ) : null}
    </div>
  )
}

export default SearchBar
