import { useState } from 'react'
import { IconDefinition, faEllipsisH } from '@fortawesome/free-solid-svg-icons'

import IconDropdown from 'components/IconDropdown'
import useMerchantStore from 'store/merchant'
import { accessControlStatus, AccessStatusTypes } from 'setups/controller'
import { MERCHANTELLIPSISNAVIGATION } from 'constants/profileEllipsisMerchant'

const Modals = (): JSX.Element => {
  const merchantProfile = useMerchantStore((state) => state.merchantProfile)
  const merchantProfileModStatus = useMerchantStore(
    (state) => state.merchantProfileModStatus
  )
  const [modalState, modalStateSet] = useState<{
    open: boolean
    key: string
  }>({ open: false, key: '' })

  if (merchantProfileModStatus !== 'success') {
    return <></>
  }

  // START: CHECK WITH ACCESS STATUS
  const MERCHANTELLIPSISNAVIGATION_FILTERED = accessControlStatus(
    MERCHANTELLIPSISNAVIGATION,
    'merchant',
    merchantProfile[0].memberThorStatus as AccessStatusTypes
  )
  // END: CHECK WITH ACCESS STATUS

  const OPTIONS: {
    label: string
    value: string
    icon: IconDefinition | undefined
  }[] = MERCHANTELLIPSISNAVIGATION.map((item) => ({
    label: item.label || '',
    value: item.value || '',
    icon: item.route.icon || undefined,
  }))

  return (
    <>
      <IconDropdown
        icon={faEllipsisH}
        options={OPTIONS}
        iconColor="black"
        toggleClassName="rounded-full h-6 w-6 text-center border pl-1 z-10"
        menuClassName="w-72 px-2 py-3 border-gray-500 shadow-md"
        itemStyle={{
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
        }}
        onSelect={(value) =>
          modalStateSet({
            open: true,
            key: value,
          })
        }
      />

      {MERCHANTELLIPSISNAVIGATION.map(({ label, value, Modal }) => {
        if (Modal)
          return (
            <Modal
              key={value}
              title={label}
              open={modalState.key === value ? modalState.open : false}
              onClose={() =>
                modalStateSet({
                  open: false,
                  key: value || '',
                })
              }
            />
          )
      })}
    </>
  )
}

export default Modals
