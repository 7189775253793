import { useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { updateNotesAPI } from 'api/notes'
import { useNotesStore } from 'store/notes'

import { NotesDataTypes, NotesUpdateParamsTypes } from 'types/notes'

import { DATASTATUS } from 'constants/status'
import { NOTE_TYPE_OPTIONS } from 'constants/filters'

export const useMemberNotesUpdate = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: NotesDataTypes
}) => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const notesUpdateModStatus = useNotesStore(
    (state) => state.notesUpdateModStatus
  )
  const notesUpdateModStatusSet = useNotesStore(
    (state) => state.notesUpdateModStatusSet
  )

  const noteTypeIdValue = useMemo(() => {
    return (
      NOTE_TYPE_OPTIONS.find(
        (option) => option.label === selectedRowData.noteType
      ) || NOTE_TYPE_OPTIONS[0]
    )
  }, [selectedRowData.noteType])
  
  const [
    notesUpdateParams,
    notesUpdateparamsSet,
  ] = useState<NotesUpdateParamsTypes>({
    traderId: memberTraderId,
    noteId: selectedRowData.noteId,
    noteTypeId: noteTypeIdValue.value || '',
    priorityLevel: selectedRowData.priorityLevel || '',
    noteDescription: selectedRowData.noteDescription || '',
    creationDate: selectedRowData.lastModifiedDate || '',
    sourceName: selectedRowData.sourceName || '',
    })

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation({
    mutationFn: updateNotesAPI,
    mutationKey: 'updateNotes',
    onMutate: () => {
      notesUpdateModStatusSet('loading')
    },
    onSuccess: (getNotesResponse) => {
      queryClient.invalidateQueries({
        queryKey: ['useNotes'],
      })
      notesUpdateModStatusSet('success')
    },
    onError: (error) => {
      notesUpdateModStatusSet('fail')
    },
  })

  const handleUpdate = useCallback(
    async (updateParams: NotesUpdateParamsTypes) => {
      try {
        const response = await mutateAsync({
          ...notesUpdateParams,
          ...updateParams,
        })
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        toast.success('Updated note succesfully')
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        toast.error(message.outcomeUserMessage)
        // modalClose()
      } finally {
        // console.log('')
        // modalClose()
      }
      modalClose()
    },
    [mutateAsync]
  )

  return {
    memberNotesUpdateModStatus: notesUpdateModStatus,
    memberNotesUpdateParams: notesUpdateParams,
    memberHandleNotesUpdate: handleUpdate,
  }
}
