import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { MemberProfilesAPITypes } from 'types/memberprofiles'

import { ACCESS_CONFIG } from './config'
const projectAllClients = ACCESS_CONFIG.application.clients
const projectAllModules = ACCESS_CONFIG.application.modules

type ModuleTypes = (typeof projectAllModules)[number]['name']
type SubModuleTypes = (typeof projectAllModules)[number]['subs'][number]['name']
type ClientSetupsTypes = (typeof projectAllClients)[number]['setup'][number]

export type AccessStatusTypes =
  (typeof projectAllModules)[number]['access']['status'][number]['status']

interface NavModulesTypes {
  module: string
  route: {
    title: string
    path: {
      path: string
      permissionIds: number[]
    }
    pathname?: string
    icon?: IconDefinition
    className?: string
    childClassName?: string
    iconClassName?: string
    children?: NavModulesTypes[]
  }
  Modal?: ({
    title,
    open,
    onClose,
    member,
  }: {
    title: string | undefined
    open: boolean
    onClose: () => void
    member?: MemberProfilesAPITypes
  }) => JSX.Element
  value?: string
  label?: string
}

interface CurrencyTypes {
  countryCode: string
  currency: string
}

export const accessControlNavigation = (
  projectClient: string | undefined,
  navModules: NavModulesTypes[]
): NavModulesTypes[] => {
  const projectModules = projectAllClients
    .filter((item) => item.name === projectClient?.toLowerCase())
    .map((item) => item.setup)[0]

  const filteredNavModules = navModules.filter((x) =>
    projectModules.some((y) => y.module === x.module.toLowerCase())
  )

  return filteredNavModules
}

export const accessControlSubNavigation = (
  projectClient: string | undefined,
  navModules: NavModulesTypes[],
  navModule: ModuleTypes
): NavModulesTypes[] => {
  const projectModules = projectAllClients
    .filter((item) => item.name === projectClient?.toLowerCase())
    .map((itemClient) => {
      const isAllSubs = itemClient.setup
        .filter((item) => item.module === navModule)
        .map((item) => item.subs)
        .map((item) => item[0])
        .filter((item) => item.name === '*').length
        ? true
        : false

      const excludedSubs = itemClient.setup
        .filter((item) => item.module === navModule)
        .map((item) => item.exclude)[0]
      // .map((item) => item[0])
      // .map((item) => item.name)
      console.log('excludedSubs', excludedSubs)
      if (isAllSubs) {
        // RETURN ALL SUB MODULES FOR THE MODULE ex: member
        const allSubModules = projectAllModules
          .filter((item) => item.name === navModule)
          .map((item) => item.subs)[0]
          console.log("allSubModules", allSubModules)
        const filtered_allSubModules = allSubModules?.filter((item) => {
          return !excludedSubs.some((x) => {
            return x.name === item.name
          })
        })
        console.log({ filtered_allSubModules }, filtered_allSubModules?.length)
        return filtered_allSubModules
      } else {
        const clientSpecificSubModules = itemClient.setup
          .filter((item) => item.module === navModule)
          .map((item) => item.subs)[0]
        const filtered_clientSpecificSubModules =
          clientSpecificSubModules?.filter(
            // (item) => !excludedSubs.includes(item.name)
            (item) => {
              return !excludedSubs.some((x) => {
                return x.name === item.name
              })
            }
          )
        // console.log(
        //   { filtered_clientSpecificSubModules },
        //   filtered_clientSpecificSubModules?.length
        // )
        return filtered_clientSpecificSubModules
      }
    })[0]

  if (projectModules)
    return navModules.filter((x) =>
      projectModules.some((y) => y.name === x.module.toLowerCase())
    )

  return []
}

export const accessControlStatus = (
  navModules: NavModulesTypes[],
  navModule: ModuleTypes,
  accessStatus: AccessStatusTypes
) => {
  if (!accessStatus) return []
  const moduleAccessStatus = projectAllModules
    .filter((item) => item.name === navModule?.toLowerCase())
    .map((item) => item.access.status)[0]

  if (moduleAccessStatus.length) {
    // const subs = moduleAccessStatus.map((item) => {
    //   if (item.subs.length) return item.subs
    // })
    const subs = moduleAccessStatus
      .map((item) => item)
      .filter((item) => item.status === accessStatus.toLowerCase())
      .map((item) => item.subs)
      .filter((item) => item.length)
      .map((item) => item[0])
    const isAllSubs = subs.filter((item) => item?.name === '*')

    if (subs.length) {
      if (isAllSubs.length) {
        const projectModules = projectAllModules
          .filter((item) => item.name === navModule)
          .map((item) => item.subs)[0]
        return navModules.filter((x) =>
          projectModules.some((y) => y && y.name === x.module.toLowerCase())
        )
      } else {
        const projectModules = subs.map((item) => item)
        return navModules.filter((x) =>
          projectModules.some((y) => y && y.name === x.module.toLowerCase())
        )
      }
    } else {
      // const y = moduleAccessStatus
      // .filter((item) => item.module === navModule)
      // .map((item) => item.subs)[0]
      // console.log('subs - no status acccess control available - all')
    }
  } else {
    // console.log('status - no status acccess control available - all')
    // const xxx = navModules.filter((x) =>
    //   projectModules.some((y) => y.name === x.module.toLowerCase())
    // )
  }
  // if no status then return default navModules
  return navModules
}

export const displayBalanceCurrency = (
  projectClient: string | undefined,
  countryIsoCode: CurrencyTypes[]
): CurrencyTypes[] => {
  const projectModules = projectAllClients
    .filter((item) => item.name === projectClient?.toLowerCase())
    .map((item) => item.misc)[0]

  const filteredCountryIsoCode = countryIsoCode.filter((x) =>
    projectModules.some((y) => y.countryCode === x.countryCode)
  )
  return filteredCountryIsoCode
}

// const status = 'Registered'.toLowerCase() as AccessStatusTypes
// accessControlStatus('subsquad', [], 'closed')
