import { Table } from './Table'
import { TableCellButton } from './TableCellButton'
import { TableCellDate } from './TableCellDate'
import { TableCellYesNo } from './TableCellYesNo'

const TSTable = {
  Table,
  TableCellButton,
  TableCellDate,
  TableCellYesNo,
}

export default TSTable
