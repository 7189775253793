import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { SubsquadsDataTypes, SubsquadsAPITypes } from 'types/subsquads'

interface SubsquadsStoreTypes {
  subsquadsModErrors: StatusErrorTypes
  subsquadsModErrorsSet: (subsquadsModErrors: StatusErrorTypes) => void
  subsquadsModStatus: StatusTypes
  subsquadsModStatusSet: (subsquadsStatus: StatusTypes) => void
  subsquads: SubsquadsAPITypes[]
  subsquadsSet: (subsquads: SubsquadsAPITypes[]) => void
  subsquadsLookup: SubsquadsAPITypes[]
  subsquadsLookupSet: (subsquads: SubsquadsAPITypes[]) => void
}

export const useSubsquadsStore = create<SubsquadsStoreTypes>((set) => ({
  subsquadsModErrors: '',
  subsquadsModErrorsSet: (subsquadsModErrors) => {
    set({
      subsquadsModErrors,
    })
  },
  subsquadsModStatus: 'idle',
  subsquadsModStatusSet: (subsquadsModStatus) => {
    set({
      subsquadsModStatus,
    })
  },
  subsquads: [],
  subsquadsSet: (subsquads) => {
    set({
      subsquads,
    })
  },
  subsquadsLookup: [],
  subsquadsLookupSet: (subsquadsLookup) => {
    set({
      subsquadsLookup,
    })
  },
}))

interface SubsquadsLookupStoreTypes {
  subsquadsLookupModErrors: StatusErrorTypes
  subsquadsLookupModErrorsSet: (
    subsquadsLookupModErrors: StatusErrorTypes
  ) => void
  subsquadsLookupModStatus: StatusTypes
  subsquadsLookupModStatusSet: (subsquadsLookupStatus: StatusTypes) => void
  subsquadsLookup: SubsquadsDataTypes[]
  subsquadsLookupSet: (subsquadsLookup: SubsquadsDataTypes[]) => void
}

export const useSubsquadsLookupStore = create<SubsquadsLookupStoreTypes>(
  (set) => ({
    subsquadsLookupModErrors: '',
    subsquadsLookupModErrorsSet: (subsquadsLookupModErrors) => {
      set({
        subsquadsLookupModErrors,
      })
    },
    subsquadsLookupModStatus: 'idle',
    subsquadsLookupModStatusSet: (subsquadsLookupModStatus) => {
      set({
        subsquadsLookupModStatus,
      })
    },
    subsquadsLookup: [],
    subsquadsLookupSet: (subsquadsLookup) => {
      set({
        subsquadsLookup,
      })
    },
  })
)
