import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import TSTable from 'components/Tanstack'
import Modal from 'components/Modal'
import InnerWrapper from '../InnerWrapper'

import { ToggleMenu } from 'components/Layout/ToggleMenu'
import { useMerchantDevicesTable } from './useMerchantDevicesTable'
import { useMerchantDevices } from './useMerchantDevices'

function MerchantDevices(): JSX.Element {
    const {
      merchantDevices,
      merchantDevicesModStatus,
      merchantDevicesFilterParams,
      merchantDevicesHandleFilter,
      merchantDevicesHandleUnfilter,
    } = useMerchantDevices()

    const {
        merchantDevicesSelected,
        merchantDevicesTableColumns,
        merchantDevicesTableData,
    } = useMerchantDevicesTable()

    return(
        <>

<InnerWrapper title="Devices">
        {/* FILTER */}
        {/* <CommsFilterForm
          formStatus={memberCommsModStatus}
          formDefaultValues={memberCommsFilterParams}
          onFilter={memberHandleCommsFilter}
          onFilterClear={memberHandleCommsUnfilter}
        /> */}

        
        {/* ADVANCE SEARCH  
        This was not in the original device maybe keep it out 
        */}
        {/* <div className="pb-6">
          <ToggleMenu
            type="search"
            label="Search Devices"
            isTriggeToggle={merchantDevicesModStatus === 'success' || false}
          >
            <merchantDevicesFilterForm
              formStatus={merchantDevicesModStatus}
              formDefaultValues={merchantDevicesFilterParams}
              onFilter={merchantDevicesHandleFilter}
              onFilterClear={merchantDevicesHandleUnfilter}
            />
          </ToggleMenu>
        </div> */}

        {/* FAIL */}
        {merchantDevicesModStatus === 'fail' ? (
          <NoResults
            header="Failed to load data"
            subtitle="Please refresh the page and try again, or contact
        your system administrator"
            noImage
          />
        ) : null}

        {/* LOADING */}
        {merchantDevicesModStatus === 'loading' ? <Spinner /> : null}

        {/* SUCCESS */}
        {merchantDevices.length && merchantDevicesModStatus === 'success' ? (
          <TSTable.Table
            data={merchantDevicesTableData}
            columns={merchantDevicesTableColumns}
          />
        ) : null}

        {/* EMPTY */}
        {!merchantDevices.length && merchantDevicesModStatus === 'success' ? (
          <NoResults
            header="No available data"
            subtitle="There is no available data to display"
            noImage
          />
        ) : null}
      </InnerWrapper>

  </>
    )
}

export default MerchantDevices;