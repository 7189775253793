import Version from 'components/Version'

const Footer = (): JSX.Element => {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer className="pb-4 text-center">
      <Version />
      <p className="font-light tracking-wider text-xs">
        Copyright &copy; 2002-{year} Tranxactor New Zealand Limited. All rights
        reserved.
      </p>
    </footer>
  )
}

export default Footer
