import Modal from 'components/Modal'
import IcashTopupReversalTx from 'views/MemberProfile/ManualTx/IcashTopupReversalTx'

type Props = {
  title: string | undefined
  open: boolean
  onClose: () => void
}

const ModalIcashTopupReversalTx = ({
  title,
  open,
  onClose,
}: Props): JSX.Element => {
  return (
    <Modal open={open} title={title} onClose={onClose} noFooter>
      <IcashTopupReversalTx onClose={onClose} />
    </Modal>
  )
}

export default ModalIcashTopupReversalTx
