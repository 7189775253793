import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from "types/status"
import { MerchantDevicesDataTypes } from "types/merchantDevices"

interface MerchantDeviceStoreTypes {
    merchantDevicesModErrors: StatusErrorTypes
    merchantDevicesModErrorsSet: (merchantDevicesModErrors: StatusErrorTypes) => void
    merchantDevicesModStatus: StatusTypes
    merchantDevicesModStatusSet: (merchantDevicesModStatus: StatusTypes) => void
    merchantDevices: MerchantDevicesDataTypes[]
    merchantDevicesSet: (merchantDevices: MerchantDevicesDataTypes[]) => void
    merchantDevicesLookup: MerchantDevicesDataTypes[]
    merchantDevicesLookupSet: (merchantDevices: MerchantDevicesDataTypes[]) => void

}

export const useMerchantDevicesStore = create<MerchantDeviceStoreTypes>(
    (set) => ({
        merchantDevicesModErrors: '',
        merchantDevicesModErrorsSet: (merchantDevicesModErrors) => {
            set({
                merchantDevicesModErrors,
            })
        },
        merchantDevicesModStatus: 'idle',
        merchantDevicesModStatusSet: (merchantDevicesModStatus) => {
            set({
                merchantDevicesModStatus,
            })
        },
        merchantDevices: [],
        merchantDevicesSet: (merchantDevices) => {
            set({
                merchantDevices,
            })
        },
        merchantDevicesLookup: [],
        merchantDevicesLookupSet: (merchantDevicesLookup) => {
            set({
                merchantDevicesLookup,
            })
        },
    })
)

interface MerchantDevicesLookupStoreTypes {
    merchantDevicesLookupModErrors: StatusErrorTypes
    merchantDevicesLookupModErrorsSet: (merchantDevicesLookupModErrors: StatusErrorTypes) => void
    merchantDevicesLookupModStatus: StatusTypes
    merchantDevicesLookupModStatusSet: (merchantDevicesLookupModStatus: StatusTypes) => void
    merchantDevicesLookup: MerchantDevicesDataTypes[]
    merchantDevicesLookupSet: (merchantDevicesLookup: MerchantDevicesDataTypes[]) => void
}

export const useMerchantDevicesLookupStore = create<MerchantDevicesLookupStoreTypes>(
    (set) => ({
        merchantDevicesLookupModErrors: '',
        merchantDevicesLookupModErrorsSet: (merchantDevicesLookupModErrors) => {
            set({
                merchantDevicesLookupModErrors,
            })
        },
        merchantDevicesLookupModStatus: 'idle',
        merchantDevicesLookupModStatusSet: (merchantDevicesLookupModStatus) => {
            set({
                merchantDevicesLookupModStatus,
            })
        },
        merchantDevicesLookup: [],
        merchantDevicesLookupSet: (merchantDevicesLookup) => {
            set({
                merchantDevicesLookup,
            })
            
        },
    })
)