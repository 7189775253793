import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Datepicker, { useDatepicker } from 'components/Datepicker'
import { formatDateToShort } from 'services/helpers'

import { useMemberMembershipsFilterForm } from './useMemberMembershipsFilterForm'

import { StatusTypes } from 'types/status'
import { MembershipsFetchParamsTypes } from 'types/memberships'
import { DropDownItem } from 'types/components'

import {
  MEMBERSHIPS_CLASSTYPE_OPTIONS,
  MEMBERSHIPS_GROUP_OPTIONS,
  MEMBERSHIPS_PERIOD_OPTIONS,
} from 'constants/filters'

const Index = ({
  formStatus,
  formDefaultValues,
  onFilter,
  onFilterClear,
}: {
  formStatus: StatusTypes
  formDefaultValues: MembershipsFetchParamsTypes
  onFilter: (filterParams: MembershipsFetchParamsTypes) => void
  onFilterClear: () => void
}): JSX.Element => {
  const { control, handleSubmit, setValue } = useMemberMembershipsFilterForm({
    defaultValues: formDefaultValues,
  })

  const { startDate, endDate, DatepickerInput, handlePick } = useDatepicker()

  const isFiltering = formStatus !== 'success' || false

  return (
    <FormsLayout.Wrapper onSubmit={handleSubmit(onFilter)}>
      <FormsLayout.Item>
        <Datepicker
          title="Date (Start Date - End Date)"
          name="dateRange"
          onChange={(dates: [Date, Date]) => {
            handlePick(dates)
            setValue(
              'startDate',
              dates[0] ? formatDateToShort(dates[0]) : undefined
            )
            setValue(
              'endDate',
              dates[1] ? formatDateToShort(dates[1]) : undefined
            )
          }}
          showYearDropdown
          scrollableYearDropdown
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          className="p-2 border border-gray-400 tracking-wider"
          placeholderText="All"
          customInput={<DatepickerInput />}
          selectsRange
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="groupId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Groups"
              options={MEMBERSHIPS_GROUP_OPTIONS}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) =>
                setValue('groupId', value ? value.value : undefined)
              }
            />
          )}
        />
      </FormsLayout.Item>
      {/* Display this only for Subway */}
      {/* <FormsLayout.Item>
        <Controller
          name="typeId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Types"
              options={MEMBERSHIPS_CLASSTYPE_OPTIONS}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) =>
                setValue('typeId', value ? value.value : undefined)
              }
            />
          )}
        />
      </FormsLayout.Item> */}
      <FormsLayout.Item>
        <Controller
          name="option"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Period"
              options={MEMBERSHIPS_PERIOD_OPTIONS}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) =>
                setValue('option', value ? value.value : undefined)
              }
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          icon={faSearch}
          iconRight={false}
          type="submit"
          disabled={isFiltering}
          loading={isFiltering}
          className="rounded bg-primary tracking-wider font-bold"
        >
          Search
        </Button>
        {/* {isMemberMembershipsFiltered ? (
            <Button
              key="clear"
              iconRight={false}
              type="button"
              className="rounded-lg"
              onClick={() => {
                handleMemberMembershipsFilterClear()
                reset()
              }}
            >
              <span className="tracking-wider font-bold">Clear</span>
            </Button>
          ) : null} */}
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
