import { isNull, isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { Route, RouteProps } from 'react-router'
import useAuthStore from 'store/auth'

type Modify<T, R> = Omit<T, keyof R> & R
type PrivateRouteType = Modify<
  RouteProps,
  {
    permissions: number[]
  }
>

const PrivateRoute = ({
  permissions,
  ...rest
}: PrivateRouteType): JSX.Element | null => {
  const user = useAuthStore((state) => state.user)
  const [isAllowed, setIsAllowed] = useState<boolean>(false)

  useEffect(() => {
    if (!isUndefined(user) && !isNull(user)) {
      const userPermissions: number[] = user.allowed.map(
        (perm): number => perm.id
      )
      setIsAllowed(
        permissions.every((permId: number) => userPermissions.includes(permId))
      )
    } else {
      setIsAllowed(false)
    }
  }, [user, permissions])

  if (isUndefined(user) && isNull(user)) {
    return null
  }

  return isAllowed ? <Route {...rest} /> : <></>
}

export default PrivateRoute
