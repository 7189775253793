import Modal from 'components/Modal'
import PurchaseReversalTx from 'views/MemberProfile/ManualTx/PurchaseReversalTx'

type Props = {
  title: string | undefined
  open: boolean
  onClose: () => void
}

const ModalPurchaseReversal = ({
  title,
  open,
  onClose,
}: Props): JSX.Element => {
  return (
    <Modal open={open} title={title} onClose={onClose} noFooter>
      <PurchaseReversalTx onClose={onClose} />
    </Modal>
  )
}

export default ModalPurchaseReversal
