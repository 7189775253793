import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import {
  CreateMerchantIdentificationTypes,
  CreateMerchantIdentificationFormTypes,
  CreateMerchantIdentificationFormSubmitValuesTypes,
  CreateMerchantIdentificationFormSubmitActionsTypes,
} from 'types/merchant'
import useMerchantStore from 'store/merchant'

const CREATEMERCHANTIDENTIFICATIONINITVAL: CreateMerchantIdentificationTypes = {
  traderId: 0,
  password: '',
  traderTransIdent: '',
  transIdentTypeId: 3,
}

export const useCreateIdentification = (
  onCloseModal: () => void
): {
  isLoading: boolean
  initialValues: CreateMerchantIdentificationTypes
  handleSubmitCreateMerchantIdentification: (
    values: CreateMerchantIdentificationFormSubmitValuesTypes,
    actions: CreateMerchantIdentificationFormSubmitActionsTypes
  ) => void
  // handleClearPurchaseTx: () => void
} => {
  const { traderId } = useParams()
  const merchantTraderId = Number(traderId) || 0
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // MERCHANT CREATE IDENT START
  const createMerchantIdentification = useMerchantStore(
    (state) => state.createMerchantIdentification
  )
  const merchantIndentificationCreateLoading = useMerchantStore(
    (state) => state.merchantIndentificationCreateLoading
  )
  // MERCHANT CREATE IDENT END
  // MISC STATES START
  const [initialValues, initialValuesSet] =
    useState<CreateMerchantIdentificationFormTypes>({
      ...CREATEMERCHANTIDENTIFICATIONINITVAL,
      traderId: merchantTraderId,
    })
  // MISC STATES END

  const isLoading = useMemo(() => {
    return merchantIndentificationCreateLoading ? true : false
  }, [merchantIndentificationCreateLoading])

  const handleSubmitCreateMerchantIdentification = useCallback(
    async (
      values: CreateMerchantIdentificationFormSubmitValuesTypes,
      actions: CreateMerchantIdentificationFormSubmitActionsTypes
    ) => {
      try {
        await createMerchantIdentification(merchantTraderId, values)
        toast.success('Merchant Identification added successful.')
        onCloseModal()
        // actions.setSubmitting(false)
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        if (message.outcomeCode === 'IDENT:ADD_IDENT:6') {
          toast.error(`Merchant Identification already exists.`)
        } else if (
          message.outcomeCode === 'IDENT:ADD_IDENT:7. IDENT:INSIDENT:3'
        ) {
          toast.error(`Merchant Identification name is required.`)
        } else if (
          message.outcomeCode === 'IDENT:ADD_IDENT:7. IDENT:INSIDENT:15'
        ) {
          toast.error(`Merchant Identification password is too long.`)
        } else {
          toast.error(`Merchant Identification error.`)
        }
      }
    },
    [createMerchantIdentification]
  )

  const handleClearPurchaseTx = useCallback(() => {
    initialValuesSet({ ...CREATEMERCHANTIDENTIFICATIONINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    isLoading,
    initialValues,
    handleSubmitCreateMerchantIdentification,
    // handleClearPurchaseTx,
  }
}
