import React from 'react'

import useModalNotifications from './useModalNotifications'
import ModalNotificationNav from './ModalNotificationNav'

const Index = ({
  instance,
  children,
}: {
  instance: string
  children: React.ReactNode
}): JSX.Element => {
  const {
    notifications,
    isNotification,
    isModalOpen,
    handleToggleModal,
    handleCloseModal,
  } = useModalNotifications(instance)

  if (!isNotification) return <></>

  return (
    <>
      <ModalNotificationNav
        notifications={notifications}
        open={isModalOpen}
        onClose={handleCloseModal}
      />
      <div className="relative cursor-pointer" onClick={handleToggleModal}>
        <div className="w-2 h-2 bg-red absolute right-0 top-0 rounded-full">
          &nbsp;
        </div>
        {children}
      </div>
    </>
  )
}

export default Index
