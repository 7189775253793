import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getSubsquadsAPI } from 'api/subsquads'
import { useSubsquadsStore } from 'store/subsquads'
import { SubsquadsFetchParamsTypes } from 'types/subsquads'

import { DATASTATUS } from 'constants/status'

// QUERY
export const useMemberSubsquadsQuery = ({
  subsquadsFetchParams,
}: {
  subsquadsFetchParams: SubsquadsFetchParamsTypes
}) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useSubsquads', subsquadsFetchParams],
    queryFn: async () => {
      return await getSubsquadsAPI(subsquadsFetchParams)
    },
  })
}

// INIT
export const useMemberSubsquadsInit = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId, lastNMemberProfiles: 1 }

  const statusSet = useSubsquadsStore((state) => state.subsquadsModStatusSet)
  const errorSet = useSubsquadsStore((state) => state.subsquadsModErrorsSet)
  const dataSet = useSubsquadsStore((state) => state.subsquadsSet)

  const [subsquadsFetchParams, subsquadsFetchParamsSet] =
    useState<SubsquadsFetchParamsTypes>(defaultParams)

  const { data, isLoading, isSuccess, isError, error } =
    useMemberSubsquadsQuery({ subsquadsFetchParams })

  // EFFECTS DATA
  useEffect(() => {
    if (data) dataSet([data])
  }, [data, dataSet])

  // EFFECTS STATUSES/ERRORS
  useEffect(() => {
    if (isLoading) statusSet('loading')
    if (isSuccess) statusSet('success')
    if (isError) statusSet('fail')
    if (error) errorSet(JSON.stringify(error))
    return () => statusSet('idle')
  }, [isLoading, isSuccess, isError, error, statusSet, errorSet])
}

// DATA
export const useMemberSubsquads = () => {
  const data = useSubsquadsStore((state) => state.subsquads)
  const status = useSubsquadsStore((state) => state.subsquadsModStatus)
  const errors = useSubsquadsStore((state) => state.subsquadsModErrors)

  return {
    memberSubsquads: data,
    memberSubsquadsModStatus: status,
    memberSubsquadsModErrors: errors,
  }
}
