import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const HistoryDefaultSchemas = DefaultSchemas

export const HistoryDataSchemas = zod.object({
  memberHistoryId: zod.number().nullable(),
  traderId: zod.number(),
  dateChanged: zod.string().nullable(),
  note: zod.string().nullable(),
  changedFields: zod.union([zod.string(), zod.null()]).array().nullable(),
  oldValues: zod.string().nullable().array().nullable(),
  newValues: zod.string().nullable().array().nullable(),
  editedBy: zod.string().nullable(),
})
export const HistoryAPISchemas = HistoryDefaultSchemas.merge(
  zod.object({
    memberHistory: zod.array(HistoryDataSchemas),
  })
)

export const HistoryFetchParamsSchemas = zod.object({
  traderId: zod.number(),
  startDate: zod.string().optional(),
  endDate: zod.string().optional(),
  editedBy: zod.string().optional(),
  pSize: zod.number().optional(),
  pNumber: zod.number().optional(),
})

export const HistoryStatusesDataSchemas = zod.object({
  label: zod.string(),
  value: zod.string(),
})

export const HistoryStatusesAPISchemas = HistoryDefaultSchemas.merge(
  zod.object({
    historyStatus: zod.array(HistoryStatusesDataSchemas),
  })
)
