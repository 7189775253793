import { Note, NoteData, NotesUpdateParamsTypes, SourceName } from 'types/notes'
import { http } from 'utils'

import { NotesAPITypes, NotesFetchParamsTypes } from 'types/notes'
import { NotesAPISchemas } from 'schemas/notesSchemas'

import { DATASTATUS } from 'constants/status'
import { formatDateToShort } from 'services/helpers'

export async function getNotesAPI({
  traderId,
  sourceName,
  noteType,
  startDate,
  endDate,
  priorityLevel,
  pSize = 50,
  pNumber = 1,
}: NotesFetchParamsTypes): Promise<NotesAPITypes> {
  let url = `/notes/${traderId}`
  const params = new URLSearchParams()

  if (sourceName) {
    params.set('sourceName', sourceName)
  }

  if (noteType) {
    params.set('noteType', noteType)
  }

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    endDate = new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), new Date(endDate).getDate() ,23,59,59).toString();
    endDate = formatDateToShort(new Date(endDate)) + " " + new Date(endDate).getHours() + ":" + new Date(endDate).getMinutes() + ":" + new Date(endDate).getSeconds()
    params.set('endDate', endDate)
  }

  if (priorityLevel) {
    params.set('priorityLevel', priorityLevel)
  }

  // PAGE SIZE AND NUMBER IS TO DO
  // if (pSize) {
  //   params.set('pSize', pSize.toString())
  // }

  // if (pNumber) {
  //   params.set('pNumber', pNumber.toString())
  // }

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = NotesAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      notes: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

/**
 * Get member's notes.
 *
 * @param traderId trace ID
 * @param token access token
 * @param sourceName source name
 * @param noteType note type
 * @param startDate start date
 * @param endDate end date
 * @param priorityLevel priority level
 * @param pSize parameter size
 * @param pNumber parameter number
 * @returns array of notes
 */
export async function getNotes(
  traderId: number,
  sourceName?: string,
  noteType?: string,
  startDate?: string,
  endDate?: string,
  priorityLevel?: string,
  pSize?: number,
  pNumber?: number
): Promise<Note[]> {
  let url = `/notes/${traderId}`
  const params = new URLSearchParams()

  if (sourceName) {
    params.set('sourceName', sourceName)
  }

  if (noteType) {
    params.set('noteType', noteType)
  }

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  if (priorityLevel) {
    params.set('priorityLevel', priorityLevel)
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<{ notes: Note[] }>(url)

  const items = response.data.notes.map((record: Note) => ({
    noteType: record.noteType,
    priorityLevel: record.priorityLevel,
    sourceName: record.sourceName,
    lastModifiedDate: record.lastModifiedDate.replace(/\s/g, 'T'),
    noteDescription: record.noteDescription,
    noteId: record.noteId,
  }))

  return items
}

/**
 * Add member's notes.
 *
 * @param traderId trace ID
 * @param token access token
 * @param data data note fields
 * @returns Promise resolved to true if operation succeeds.
 */
export async function createNote(
  traderId: number,
  data: NoteData
): Promise<void> {
  const url = `/notes/${traderId}`

  await http.put(url, data)

  return
}

/**
 * Update member's notes.
 *
 * @param traderId trace ID
 * @param token access token
 * @param noteId note ID
 * @param noteTypeId note type Id
 * @param priorityLevel priority level
 * @param noteDescription note description
 * @param creationDate creation date
 * @param sourceName source name
 * @returns Promise resolved to true if operation succeeds.
 */
export async function updateNote(
  traderId: number,
  noteId: number,
  noteTypeId?: number,
  priorityLevel?: string,
  noteDescription?: string,
  creationDate?: string,
  sourceName?: string
): Promise<{
  noteId: number
  noteTypeId: number | undefined
  priorityLevel: string | undefined
  noteDescription: string | undefined
  creationDate: string | undefined
  sourceName: string | undefined
}> {
  let url = `/notes/${traderId}`
  const params = new URLSearchParams()

  if (noteId) {
    params.set('noteId', noteId.toString())
  }

  if (noteTypeId) {
    params.set('noteTypeId', noteTypeId.toString())
  }

  if (priorityLevel) {
    params.set('priorityLevel', priorityLevel)
  }

  if (noteDescription) {
    params.set('noteDescription', noteDescription)
  }

  if (creationDate) {
    params.set('creationDate', creationDate)
  }

  if (sourceName) {
    params.set('sourceName', sourceName)
  }

  url += `?${params.toString()}`

  const data = {
    noteId,
    noteTypeId,
    priorityLevel,
    noteDescription,
    creationDate,
    sourceName,
  }

  const response = await http.patch(url, data)

  return response.data
}

export async function updateNotesAPI({
  traderId,
  noteId,
  noteTypeId,
  priorityLevel,
  noteDescription,
  creationDate,
  sourceName,
}: NotesUpdateParamsTypes): Promise<NotesAPITypes> {
  let url = `/notes/${traderId}/${noteId}`
  const params = new URLSearchParams()

  params.set('noteId', noteId.toString())
  params.set('noteTypeId', noteTypeId.toString())
  params.set('priorityLevel', priorityLevel?.toString())
  params.set('noteDescription', noteDescription?.toString())
  params.set('creationDate', creationDate?.toString())
  params.set('sourceName', sourceName?.toString())

  url += `?${params.toString()}`

  const response = await http.patch(url)

  return response.data
}

/**
 * Get source names.
 *
 * @returns array of source names
 */
export async function getSourceNames(): Promise<SourceName[]> {
  const url = '/notes/source-name'

  const response = await http.get<{ noteSourceName: SourceName[] }>(url)

  const items = response.data.noteSourceName.map(({ id, sourceName }) => ({
    id,
    sourceName,
  }))

  return items
}
