import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import {
  IdentificationsDataTypes,
  IdentificationsStatusesTypes,
  IdentificationsOwnershipsTypes,
  IdentificationsTypesTypes,
} from 'types/identifications'

interface identificationsStoreTypes {
  // MERGE
  identificationsMergeModErrors: StatusErrorTypes
  identificationsMergeModErrorsSet: (
    identificationsModErrors: StatusErrorTypes
  ) => void
  identificationsMergeModStatus: StatusTypes
  identificationsMergeModStatusSet: (
    identificationsMergeStatus: StatusTypes
  ) => void
  // STATUS UPDATE
  identificationsStatusUpdateModErrors: StatusErrorTypes
  identificationsStatusUpdateModErrorsSet: (
    identificationsModErrors: StatusErrorTypes
  ) => void
  identificationsStatusUpdateModStatus: StatusTypes
  identificationsStatusUpdateModStatusSet: (
    identificationsStatusUpdateStatus: StatusTypes
  ) => void
  // DEFAULT
  identificationsModErrors: StatusErrorTypes
  identificationsModErrorsSet: (
    identificationsModErrors: StatusErrorTypes
  ) => void
  identificationsModStatus: StatusTypes
  identificationsModStatusSet: (identificationsStatus: StatusTypes) => void
  identifications: IdentificationsDataTypes[]
  identificationsSet: (identifications: IdentificationsDataTypes[]) => void
  identificationsLookup: IdentificationsDataTypes[]
  identificationsLookupSet: (
    identifications: IdentificationsDataTypes[]
  ) => void
}

export const useIdentificationsStore = create<identificationsStoreTypes>(
  (set) => ({
    // MERGE
    identificationsMergeModErrors: '',
    identificationsMergeModErrorsSet: (identificationsMergeModErrors) => {
      set({
        identificationsMergeModErrors,
      })
    },
    identificationsMergeModStatus: 'idle',
    identificationsMergeModStatusSet: (identificationsMergeModStatus) => {
      set({
        identificationsMergeModStatus,
      })
    },
    // STATUS UPDATE
    identificationsStatusUpdateModErrors: '',
    identificationsStatusUpdateModErrorsSet: (
      identificationsStatusUpdateModErrors
    ) => {
      set({
        identificationsStatusUpdateModErrors,
      })
    },
    identificationsStatusUpdateModStatus: 'idle',
    identificationsStatusUpdateModStatusSet: (
      identificationsStatusUpdateModStatus
    ) => {
      set({
        identificationsStatusUpdateModStatus,
      })
    },
    // DEFAULT
    identificationsModErrors: '',
    identificationsModErrorsSet: (identificationsModErrors) => {
      set({
        identificationsModErrors,
      })
    },
    identificationsModStatus: 'idle',
    identificationsModStatusSet: (identificationsModStatus) => {
      set({
        identificationsModStatus,
      })
    },
    identifications: [],
    identificationsSet: (identifications) => {
      set({
        identifications,
      })
    },
    identificationsLookup: [],
    identificationsLookupSet: (identificationsLookup) => {
      set({
        identificationsLookup,
      })
    },
  })
)

interface IdentificationsLookupStoreTypes {
  identificationsLookupModErrors: StatusErrorTypes
  identificationsLookupModErrorsSet: (
    identificationsLookupModErrors: StatusErrorTypes
  ) => void
  identificationsLookupModStatus: StatusTypes
  identificationsLookupModStatusSet: (
    identificationsLookupStatus: StatusTypes
  ) => void
  identificationsLookup: IdentificationsDataTypes[]
  identificationsLookupSet: (
    identificationsLookup: IdentificationsDataTypes[]
  ) => void
}

export const useIdentificationsLookupStore =
  create<IdentificationsLookupStoreTypes>((set) => ({
    identificationsLookupModErrors: '',
    identificationsLookupModErrorsSet: (identificationsLookupModErrors) => {
      set({
        identificationsLookupModErrors,
      })
    },
    identificationsLookupModStatus: 'idle',
    identificationsLookupModStatusSet: (identificationsLookupModStatus) => {
      set({
        identificationsLookupModStatus,
      })
    },
    identificationsLookup: [],
    identificationsLookupSet: (identificationsLookup) => {
      set({
        identificationsLookup,
      })
    },
  }))

interface IdentificationsStatusesStoreTypes {
  identificationsStatusesModErrors: StatusErrorTypes
  identificationsStatusesModErrorsSet: (
    identificationsStatusesModErrors: StatusErrorTypes
  ) => void
  identificationsStatusesModStatus: StatusTypes
  identificationsStatusesModStatusSet: (
    identificationsStatusesStatus: StatusTypes
  ) => void
  identificationsStatuses: IdentificationsStatusesTypes[]
  identificationsStatusesSet: (
    identifications: IdentificationsStatusesTypes[]
  ) => void
}

export const useIdentificationsStatusesStore =
  create<IdentificationsStatusesStoreTypes>((set) => ({
    identificationsStatusesModErrors: '',
    identificationsStatusesModErrorsSet: (identificationsStatusesModErrors) => {
      set({
        identificationsStatusesModErrors,
      })
    },
    identificationsStatusesModStatus: 'idle',
    identificationsStatusesModStatusSet: (identificationsStatusesModStatus) => {
      set({
        identificationsStatusesModStatus,
      })
    },
    identificationsStatuses: [],
    identificationsStatusesSet: (identificationsStatuses) => {
      set({
        identificationsStatuses,
      })
    },
  }))

interface identificationsOwnershipsStoreTypes {
  identificationsOwnershipsModErrors: StatusErrorTypes
  identificationsOwnershipsModErrorsSet: (
    identificationsOwnershipsModErrors: StatusErrorTypes
  ) => void
  identificationsOwnershipsModStatus: StatusTypes
  identificationsOwnershipsModStatusSet: (
    identificationsOwnershipsStatus: StatusTypes
  ) => void
  identificationsOwnerships: IdentificationsOwnershipsTypes[]
  identificationsOwnershipsSet: (
    identifications: IdentificationsOwnershipsTypes[]
  ) => void
}

export const useIdentificationsOwnershipsStore =
  create<identificationsOwnershipsStoreTypes>((set) => ({
    identificationsOwnershipsModErrors: '',
    identificationsOwnershipsModErrorsSet: (
      identificationsOwnershipsModErrors
    ) => {
      set({
        identificationsOwnershipsModErrors,
      })
    },
    identificationsOwnershipsModStatus: 'idle',
    identificationsOwnershipsModStatusSet: (
      identificationsOwnershipsModStatus
    ) => {
      set({
        identificationsOwnershipsModStatus,
      })
    },
    identificationsOwnerships: [],
    identificationsOwnershipsSet: (identificationsOwnerships) => {
      set({
        identificationsOwnerships,
      })
    },
  }))

interface identificationsTypesStoreTypes {
  identificationsTypesModErrors: StatusErrorTypes
  identificationsTypesModErrorsSet: (
    identificationsTypesModErrors: StatusErrorTypes
  ) => void
  identificationsTypesModStatus: StatusTypes
  identificationsTypesModStatusSet: (
    identificationsTypesStatus: StatusTypes
  ) => void
  identificationsTypes: IdentificationsTypesTypes[]
  identificationsTypesSet: (
    identifications: IdentificationsTypesTypes[]
  ) => void
}

export const useIdentificationsTypesStore =
  create<identificationsTypesStoreTypes>((set) => ({
    identificationsTypesModErrors: '',
    identificationsTypesModErrorsSet: (identificationsTypesModErrors) => {
      set({
        identificationsTypesModErrors,
      })
    },
    identificationsTypesModStatus: 'idle',
    identificationsTypesModStatusSet: (identificationsTypesModStatus) => {
      set({
        identificationsTypesModStatus,
      })
    },
    identificationsTypes: [],
    identificationsTypesSet: (identificationsTypes) => {
      set({
        identificationsTypes,
      })
    },
  }))
