import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { FrequencyDataTypes } from 'types/frequency'

interface FrequencyStoreTypes {
  frequencyModErrors: StatusErrorTypes
  frequencyModErrorsSet: (frequencyModErrors: StatusErrorTypes) => void
  frequencyModStatus: StatusTypes
  frequencyModStatusSet: (frequencyStatus: StatusTypes) => void
  frequency: FrequencyDataTypes[]
  frequencySet: (frequency: FrequencyDataTypes[]) => void
  frequencyLookup: FrequencyDataTypes[]
  frequencyLookupSet: (frequency: FrequencyDataTypes[]) => void
}

export const useFrequencyStore = create<FrequencyStoreTypes>((set) => ({
  frequencyModErrors: '',
  frequencyModErrorsSet: (frequencyModErrors) => {
    set({
      frequencyModErrors,
    })
  },
  frequencyModStatus: 'idle',
  frequencyModStatusSet: (frequencyModStatus) => {
    set({
      frequencyModStatus,
    })
  },
  frequency: [],
  frequencySet: (frequency) => {
    set({
      frequency,
    })
  },
  frequencyLookup: [],
  frequencyLookupSet: (frequencyLookup) => {
    set({
      frequencyLookup,
    })
  },
}))
