import Spinner from 'components/Spinner'

import { useEmailPreview } from './use-email-preview'
import { CommsDataTypes } from 'types/comms'

export const EmailPreview = ({
  commsData,
}: {
  commsData: CommsDataTypes
}): JSX.Element => {
  const { data, memberCommsEmailPreview, memberCommsEmailPreviewModStatus } =
    useEmailPreview({ actionId: commsData.id })

  const isLoading = memberCommsEmailPreviewModStatus

  console.log({ data, commsData }, ' DATA TEST ...')

  return (
    <>
      {isLoading ? (
        <div>
          Spinner
          <Spinner />
        </div>
      ) : (
        <div className="flex justify-center max-h-screen  mt-2 overflow-y-auto">
          {/* {memberCommsEmailPreview.map((item) => (
            <div
              key={item.htmlPreviewUrl}
              className=" bg-white rounded-xl overflow-hidden w-full"
              style={{ height: '700px' }}
              dangerouslySetInnerHTML={{
                __html: `<iframe src='${item.htmlPreviewUrl}'  style="
                top: 0; left: 0; width: 100%; height: 100%;" />`,
              }}
            />
          ))} */}
        </div>
      )}
    </>
  )
}
