import React from 'react'
import { Form, Formik } from 'formik'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Datepicker, { useDatepicker } from 'components/Datepicker'

import { useMemberIdentificationsLookup } from './useMemberIdentificationsLookup'
import { useMemberIdentificationsStatuses } from './useMemberIdentificationsStatuses'
import { useMemberIdentificationsOwnerships } from './useMemberIdentificationsOwnerships'
import { useMemberIdentificationsTypes } from './useMemberIdentificationsTypes'
import { useMemberIdentificationsFilterForm } from './useMemberIdentificationsFilterForm'

import { StatusTypes } from 'types/status'
import { IdentificationsFetchParamsTypes } from 'types/identifications'
import { DropDownItem } from 'types/components'

const Index = ({
  formStatus,
  formDefaultValues,
  onFilter,
  onFilterClear,
}: {
  formStatus: StatusTypes
  formDefaultValues: IdentificationsFetchParamsTypes
  onFilter: (filterParams: IdentificationsFetchParamsTypes) => void
  onFilterClear: () => void
}): JSX.Element => {
  const { control, handleSubmit, setValue } =
    useMemberIdentificationsFilterForm({
      defaultValues: formDefaultValues,
    })

  const {
    memberHandleIdentificationsIdentsLookup,
    memberidentificationsIdentsOptionsLoader,
    memberHandleIdentificationsAccountsLookup,
    memberIdentificationsAccountsOptionsLoader,
  } = useMemberIdentificationsLookup()

  const {
    memberIdentificationsStatusesOptions,
    memberIdentificationsStatusesModStatus,
  } = useMemberIdentificationsStatuses()

  const {
    memberIdentificationsOwnershipsOptions,
    memberIdentificationsOwnershipsModStatus,
  } = useMemberIdentificationsOwnerships()

  const {
    memberIdentificationsTypesOptions,
    memberIdentificationsTypesModStatus,
  } = useMemberIdentificationsTypes()

  const isLoading =
    memberIdentificationsStatusesModStatus !== 'success' ||
    memberIdentificationsOwnershipsModStatus !== 'success' ||
    memberIdentificationsTypesModStatus !== 'success'
      ? true
      : false

  if (isLoading)
    return (
      <span className="tracking-wider font-light">
        Preparing search options ...
      </span>
    )

  const isFiltering = formStatus !== 'success' || false

  return (
    <FormsLayout.Wrapper onSubmit={handleSubmit(onFilter)}>
      <FormsLayout.Item>
        <Controller
          name="accountId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Accounts"
              loadOptions={memberIdentificationsAccountsOptionsLoader}
              onChange={(value: DropDownItem) => {
                setValue('accountId', value ? parseInt(value.value) : undefined)
              }}
              onInputChange={(value) =>
                memberHandleIdentificationsAccountsLookup(value)
              }
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              containerClassName="focus:border-primary"
              placeholder="Accounts ..."
              isClearable
              isSearchable
              async
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="ident"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Identification"
              loadOptions={memberidentificationsIdentsOptionsLoader}
              onChange={(value: DropDownItem) => {
                setValue('ident', value ? value.value : undefined)
              }}
              onInputChange={(value) =>
                memberHandleIdentificationsIdentsLookup(value)
              }
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              containerClassName="focus:border-primary"
              placeholder="Idents..."
              isClearable
              isSearchable
              async
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="identTypeId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Identifier Type"
              // value={values.identTypeId}
              options={memberIdentificationsTypesOptions}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(item: DropDownItem) => {
                setValue('identTypeId', parseInt(item.value) || undefined)
              }}
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="ownershipMode"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Ownership"
              // value={values.ownershipMode}
              options={memberIdentificationsOwnershipsOptions}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(item: DropDownItem) => {
                setValue('ownershipMode', parseInt(item.value) || undefined)
              }}
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="identStatusId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Status"
              // value={values.identStatusId}
              options={memberIdentificationsStatusesOptions}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(item: DropDownItem) => {
                setValue('identStatusId', parseInt(item.value) || undefined)
              }}
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          icon={faSearch}
          iconRight={false}
          type="submit"
          disabled={isFiltering}
          loading={isFiltering}
          className="rounded bg-primary tracking-wider font-bold"
        >
          Search
        </Button>
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
