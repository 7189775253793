import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from 'react-router-dom'

import { FinancialCancelPaymentInstructionTxSchemas } from 'schemas/financialCancelPaymentInstructionTxSchemas'
import { FinancialCancelPaymentInstructionTxTypes } from 'types/financialCancelPaymentInstructionTxTypes'
import { PaymentInstructionsDataTypes } from 'types/financials'
import { useEffect } from 'react'

export const usePaymentInstructionTxForm = ({
  selectedRowData,
}: {
  selectedRowData: PaymentInstructionsDataTypes
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<FinancialCancelPaymentInstructionTxTypes>({
    resolver: zodResolver(FinancialCancelPaymentInstructionTxSchemas),
    defaultValues: {
      paymentId: selectedRowData.id,
      traderId: selectedRowData.traderId,
      instructionName: selectedRowData.instructionName,
      frequency: selectedRowData.frequencyId,
      bankAccountNumber: selectedRowData.bankAccountNumber,
      paymentAmount: selectedRowData.paymentAmount,
      firstPaymentDate: selectedRowData.firstPaymentDate,
      endPaymentDate: selectedRowData.endPaymentDate,
      reasonId: 1,
      comment: '',
    },
  })

  // useEffect(() => {
  //   reset(selectedRowData)
  // }, [reset, selectedRowData])

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
  }
}
