import { DateTime } from 'luxon'

const displayDate = (date: string | undefined | null): string =>
  date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) : '---'

const formatName = (
  firstName: string | null,
  lastName: string | null
): string => {
  const fullName = (firstName || '') + ' ' + (lastName || '')

  return fullName.trim()
}

const getETId = (traderId: string): number => {
  const rand = Math.floor(Math.random() * 9)
  const timeId = new Date().getTime()
  return parseInt(`${rand}${timeId}`)
}

const externalIdentification = (): number => {
  const rand = Math.floor(Math.random() * 9)
  const timeId = new Date().getTime()
  return parseInt(`${rand}${timeId}`)
}

export default { displayDate, formatName, getETId, externalIdentification }
