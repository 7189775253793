import { useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { getReason_API } from 'api/financials'
import { useReasonStore } from 'store/reason'

export const useReasonQuery = () => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useReason'],
    queryFn: async () => {
      return await getReason_API()
    },
  })
}

export const useReason = () => {
  const reasonModStatus = useReasonStore((state) => state.reasonModStatus)
  const reasonModStatusSet = useReasonStore((state) => state.reasonModStatusSet)
  const reasonModErrors = useReasonStore((state) => state.reasonModErrors)
  const reasonModErrorsSet = useReasonStore((state) => state.reasonModErrorsSet)
  const reason = useReasonStore((state) => state.reason)
  const reasonSet = useReasonStore((state) => state.reasonSet)

  const queryClient = useQueryClient()

  const {
    data: dataReason,
    isLoading: isLoadingReason,
    isError: isErrorReason,
    isFetching: isFetchingReason,
    isSuccess: isSuccessReason,
    error: errorReason,
  } = useReasonQuery()

  useEffect(() => {
    if (dataReason) reasonSet(dataReason.directPaymentInstructionFrequency)
  }, [dataReason, reasonSet])

  useEffect(() => {
    if (isFetchingReason) reasonModStatusSet('loading')
    if (isLoadingReason) reasonModStatusSet('loading')
    if (isSuccessReason) reasonModStatusSet('success')
    if (isErrorReason) reasonModStatusSet('fail')
    if (errorReason) reasonModErrorsSet(JSON.stringify(errorReason))
  }, [
    isFetchingReason,
    isLoadingReason,
    isSuccessReason,
    isErrorReason,
    errorReason,
    reasonModStatusSet,
    reasonModErrorsSet,
  ])

  const reasonAsOptions = useMemo(() => {
    return reason.map((item) => ({
      label: `${item.frequencyName}` || '',
      value: item.frequencyId.toString(),
    }))
  }, [reason])

  return {
    reason,
    reasonAsOptions,
    reasonModStatus,
  }
}
