import { http } from 'utils'

import {
  CommsAPITypes,
  CommsFetchParamsTypes,
  CommsStatusesAPITypes,
  CommsChannelsAPITypes,
  CommsEmailPreviewAPITypes,
  CommsParamsTypes,
} from 'types/comms'
import {
  CommsAPISchemas,
  CommsStatusesAPISchemas,
  CommsChannelsAPISchemas,
  CommsEmailPreviewAPISchemas,
} from 'schemas/commsSchemas'

import { DATASTATUS } from 'constants/status'
import { formatDateToShort } from 'services/helpers'

export async function getCommsAPI({
  traderId,
  channelId,
  startDate,
  endDate,
  statusId,
  pSize,
  pNumber,
}: CommsFetchParamsTypes): Promise<CommsAPITypes> {
  let url = `/communications/${traderId}`
  const params = new URLSearchParams()

  // params.set('flEmail', flEmail)
  // params.set('flSms', flSms)
  // params.set('flPush', flPush)

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    endDate = new Date(
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth(),
      new Date(endDate).getDate(),
      23,
      59,
      59
    ).toString()
    endDate =
      formatDateToShort(new Date(endDate)) +
      ' ' +
      new Date(endDate).getHours() +
      ':' +
      new Date(endDate).getMinutes() +
      ':' +
      new Date(endDate).getSeconds()
    params.set('endDate', endDate)
  }

  if (channelId) {
    if (channelId === 1) {
      params.set('flEmail', '1')
      params.set('flSms', '0')
      params.set('flPush', '0')
    }
    if (channelId === 3) {
      params.set('flEmail', '0')
      params.set('flSms', '1')
      params.set('flPush', '0')
    }
    if (channelId === 4) {
      params.set('flEmail', '0')
      params.set('flSms', '0')
      params.set('flPush', '1')
    }
    // params.set('channelId', channelId.toString())
  } else {
    params.set('flEmail', '1')
    params.set('flSms', '1')
    params.set('flPush', '1')
  }

  if (statusId) {
    params.set('statusId', statusId.toString())
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = CommsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      comms: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getCommsStatusesAPI(): Promise<CommsStatusesAPITypes> {
  let url = `/communications/status`
  const params = new URLSearchParams()

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = CommsStatusesAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      commsStatus: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getCommsChannelsAPI(): Promise<CommsChannelsAPITypes> {
  let url = `/communications/channel`
  const params = new URLSearchParams()

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = CommsChannelsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      commsChannel: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getCommsEmailPreviewAPI({
  actionId,
}: {
  actionId: number
}): Promise<CommsEmailPreviewAPITypes> {
  let url = `/communications/emr-actions/${actionId}`
  const params = new URLSearchParams()

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = CommsEmailPreviewAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      actionAttributes: {},
      emailId: '',
      emailSubject: '',
      htmlPreviewUrl: '',
      textPreviewUrl: '',
      status: '',
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function selectEmailPreviewAPI(
  params: CommsParamsTypes
): Promise<CommsEmailPreviewAPITypes> {
  let url = `/communications/emr-actions/${params.actionId}`
  const urlParams = new URLSearchParams()
  url += `?${urlParams.toString()}`

  const response = await http.get<unknown>(url)
  const data = CommsEmailPreviewAPISchemas.safeParse(response.data)
  if (!data.success) {
    return {
      actionAttributes: {},
      emailId: '',
      emailSubject: '',
      htmlPreviewUrl: '',
      textPreviewUrl: '',
      status: '',
      outcomeMessage: JSON.stringify(data.error),
      ...DATASTATUS,
    }
  }

  return data.data
}

async function fetchHtmlContent(url: string) {
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const htmlContent = await response.text()
    return htmlContent
  } catch (error) {
    console.error('Error fetching HTML content:', error)
    return null
  }
}
