import { useState } from 'react'

export const useTableUtils = <TSelectedRowDataTypes, TModalActionTypes>() => {
  const [selectedModal, selectedModalSet] = useState<null | TModalActionTypes>(
    null
  )
  const [selectedRowData, selectedRowDataSet] =
    useState<TSelectedRowDataTypes | null>(null)

  return {
    selectedModal,
    selectedModalSet,
    selectedRowData,
    selectedRowDataSet,
  }
}
