import { getMerchantsTraderLookupOptions, getMerchantsTraderLookupOptionsTX } from 'api/merchant'

import useMerchantStore from 'store/merchant'
import { DropDownItem } from 'types/components'

const useTransactionsMerchantLookup = ({
  memberTraderId,
}: {
  memberTraderId: string
}): {
  merchantLookupDropdownOptions: (inputParam: string) => Promise<DropDownItem[]>
} => {
  // start: Merchant Lookup
  const merchantLookupLoading = useMerchantStore(
    (state) => state.merchantLookupLoading
  )
  const merchantLookupStatus = useMerchantStore(
    (state) => state.merchantLookupStatus
  )
  const merchantLookup = useMerchantStore((state) => state.merchantLookup)
  const getMerchantLookup = useMerchantStore((state) => state.getMerchantLookup)

  const merchantLookupDropdownOptions = async (
    lookupParams: string
  ): Promise<DropDownItem[]> => {
    if (lookupParams.length < 3) return []
    const merchantQuery = `?merchantName=${lookupParams}`
    const merchantLookup = await getMerchantsTraderLookupOptionsTX(merchantQuery)
    return merchantLookup.map((item) => ({
      label: item.merchantName,
      value: String(item.traderId),
    }))
  }

  return {
    merchantLookupDropdownOptions,
  }
}

export default useTransactionsMerchantLookup
