import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { financialUpdateBankAccountTxSchemas } from 'schemas/financialUpdateBankAccountTxSchemas'
import { FinancialUpdateBankAccountTxTypes } from 'types/financialUpdateBankAccountTxTypes'

export const useMemberBankAccountsStatusUpdateForm = ({
  defaultValues,
}: {
  defaultValues: FinancialUpdateBankAccountTxTypes
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<FinancialUpdateBankAccountTxTypes>({
    resolver: zodResolver(financialUpdateBankAccountTxSchemas),
    defaultValues,
  })

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    reset,
    watch,
  }
}
