import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getTransactionsV2API } from 'api/transactions'
import { useTransactionsStore } from 'store/transactions'
import { TransactionsFetchParamsTypes } from 'types/transactions'

import { DATASTATUS } from 'constants/status'

export const useMemberTransactions = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const transactionsModStatus = useTransactionsStore(
    (state) => state.transactionsModStatus
  )
  const transactionsModStatusSet = useTransactionsStore(
    (state) => state.transactionsModStatusSet
  )
  const transactionsModErrors = useTransactionsStore(
    (state) => state.transactionsModErrors
  )
  const transactionsModErrorsSet = useTransactionsStore(
    (state) => state.transactionsModErrorsSet
  )
  const transactions = useTransactionsStore((state) => state.transactions)
  const transactionsSet = useTransactionsStore((state) => state.transactionsSet)
  const [transactionsFilterParams, transactionsFilterParamsSet] =
    useState<TransactionsFetchParamsTypes>(defaultParams)
  const transactionsSummary = useTransactionsStore(
    (state) => state.transactionsSummary
  )
  console.log("txtransactions", transactions)
  const transactionsSummarySet = useTransactionsStore(
    (state) => state.transactionsSummarySet
  )

  const [isTransactionsFiltered, isTransactionsFilteredSet] =
    useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataTransactions,
    isLoading: isLoadingTransactions,
    isError: isErrorTransactions,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useTransactionsDashboard', transactionsFilterParams],
    queryFn: async () => {
      try {
        transactionsModStatusSet('loading')
        const response = await getTransactionsV2API(transactionsFilterParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        transactionsModStatusSet('success')
        return response.transactions
      } catch (e) {
        console.log(e, ' FAIL')
        transactionsModStatusSet('fail')
        transactionsModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataTransactions) {
      transactionsSet(dataTransactions)
      transactionsSummarySet()
    }
  }, [dataTransactions, transactionsSet, transactionsSummarySet])

  return {
    memberTransactionsSummary: transactionsSummary,
    memberTransactions: transactions,
    memberTransactionsModStatus: transactionsModStatus,
  }
}
