import { DropDownItem } from 'types/components'

export const LSKEY_TOKEN = 'token'

export const SEND_OPTIONS: DropDownItem[] = [
  { label: 'Email', value: 'email' },
  // { label: 'Postal', value: 'postal' },
  { label: 'SMS', value: 'sms' },
  { label: 'Push', value: 'push' },
  // { label: 'All Channels', value: 'all' },
]
