import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import Colors from 'colors.json'
import Modal from 'components/Modal'
import Button from 'components/Button'

const WindowClose: React.FC = () => {
  //

  const handleTabClosing = useCallback(() => {
    console.log('Alert this one ...closing window')
  }, [])

  const alertUser = useCallback((event: Event) => {
    event.preventDefault()
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleTabClosing)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener('unload', handleTabClosing)
    }
  }, [])

  return <></>
  // <Modal open={true} title="Looks like you've been idle for a while" noFooter>
  //   <p className="pb-4">
  //     <span className="tracking-wider font-light">
  //       Window close - Would you like to continue using the application?
  //     </span>
  //   </p>
  //   <footer className="flex justify-end pb-4 pl-4 pr-4">
  //     <Button type="submit" className="mr-2 rounded bg-primary">
  //       <span className="tracking-wider font-bold">
  //         Yes, I will continue.
  //       </span>
  //     </Button>
  //     <Button
  //       className="bg-white border rounded border-primary"
  //       style={{ color: Colors.primary }}
  //     >
  //       <span className="tracking-wider font-bold">
  //         No, I want to logout. Window Close
  //       </span>
  //     </Button>
  //   </footer>
  // </Modal>
}

export default WindowClose
