import { http } from 'utils'

import { LoyaltyRedemptionTxServiceTypes } from 'types/loyaltyRedemptionTxTypes'
import { LoyaltyRedemptionReversalTxServiceTypes } from 'types/loyaltyRedemptionReversalTxTypes'
import { LoyaltyAllocationTxServiceTypes } from 'types/loyaltyAllocationTxTypes'
import { LoyaltyAllocationReversalTxServiceTypes } from 'types/loyaltyAllocationReversalTxTypes'

export async function loyaltyRedemptionAPI({
  transactionValue,
  creditValue,
  loyaltyValue,
  externalTransactionId,
  ident,
  storeId,
  deviceCode,
  cashier,
  guid,
  transactionDate,
  comments,
  traceNumber,
  reasonId,
  
}: LoyaltyRedemptionTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/loyalty-redemption'
  const data = {
    transactionValue,
    creditValue,
    loyaltyValue,
    externalTransactionId,
    ident,
    storeId,
    deviceCode,
    cashier,
    guid,
    transactionDate,
    comments,
    traceNumber,
    reasonId,  
  }
  return await http.post(url, data)
}

export async function loyaltyRedemptionReversalAPI({
  externalTransactionId,
  ident,
  storeId,
  cashier,
  comments,
  traceNumber,
  reasonId,
}: LoyaltyRedemptionReversalTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/loyalty-redemption-reversal'
  const data = {
    externalTransactionId,
    ident,
    storeId,
    cashier,
    comments,
    traceNumber,  
    reasonId,
  }
  return await http.post(url, data)
}

export async function loyaltyAllocationAPI({
  transactionValue,
  creditValue,
  loyaltyValue,
  externalTransactionId,
  ident,
  storeId,
  deviceCode,
  cashier,
  guid,
  transactionDate,
  comments,
  traceNumber,
  reasonId,
}: LoyaltyAllocationTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/loyalty-allocation'
  const data = {
    transactionValue,
    creditValue,
    loyaltyValue,
    externalTransactionId,
    ident,
    storeId,
    deviceCode,
    cashier,
    guid,
    transactionDate,
    comments,
    traceNumber,
    reasonId,  
  }
  return await http.post(url, data)
}

export async function loyaltyAllocationReversalAPI({
  externalTransactionId,
  ident,
  comments,
  traceNumber,  
  reasonId,
}: LoyaltyAllocationReversalTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/loyalty-allocation-refund'
  const data = {
    externalTransactionId,
    ident,
    comments,
    traceNumber,  
    reasonId,
  }
  return await http.post(url, data)
}

// OLD APIs

export async function loyaltyRedemption(
  // transactionValue: number,
  // creditValue: number,
  loyaltyValue: number,
  externalTransactionId: number,
  ident: string
): Promise<void> {
  const url = '/manual-transaction/loyalty-redemption'

  const data = {
    transactionValue: 0,
    creditValue: 0,
    loyaltyValue,
    externalTransactionId,
    ident,
  }

  return await http.post(url, data)
}

export async function loyaltyRedemptionReversal(
  externalTransactionId: number,
  ident: string
): Promise<void> {
  const url = '/manual-transaction/loyalty-redemption-reversal'

  const data = {
    externalTransactionId,
    ident,
  }

  return await http.post(url, data)
}

export async function loyaltyAllocation(
  // transactionValue: number,
  // creditValue: number,
  loyaltyValue: number,
  externalTransactionId: number,
  ident: string
): Promise<void> {
  const url = '/manual-transaction/loyalty-allocation'

  const data = {
    transactionValue: 0,
    creditValue: 0,
    loyaltyValue,
    externalTransactionId,
    ident,
  }

  return await http.post(url, data)
}

export async function loyaltyAllocationRefund(
  externalTransactionId: number,
  ident: string
): Promise<void> {
  const url = '/manual-transaction/loyalty-allocation-refund'

  const data = {
    externalTransactionId,
    ident,
  }

  return await http.post(url, data)
}
