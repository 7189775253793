import { formatter } from 'utils'

import { useMemberProfiles } from './useMemberProfiles'

const Index = (): JSX.Element => {
  const { memberMemberProfiles } = useMemberProfiles()

  return (
    <>
      {memberMemberProfiles.map((item) => (
        <div key={item.traderId} className="flex flex-col gap-2">
          <p className="text-xs uppercase font-light tracking-wider">
            Comms. Channels
          </p>
          <p>
            <span className="text-xs uppercase font-light">Email</span>
          </p>
          <div className="flex mb-2 mt-1">
            <span
              className={`text-xs rounded-tl-full rounded-bl-full text-white px-2 py-1 tracking-wider ${
                !item.channelEmail ? 'bg-gray-400' : 'bg-green-200'
              }`}
            >
              Unlimited
            </span>
            <span
              className={`text-xs rounded-tr-full rounded-br-full text-white px-2 py-1 tracking-wider ${
                item.channelEmail ? 'bg-gray-400' : 'bg-red'
              }`}
            >
              Forbidden
            </span>
          </div>
          <p>
            <span className="text-xs uppercase font-light">Postal</span>
          </p>
          <div className="flex mb-2 mt-1">
            <span
              className={`text-xs rounded-tl-full rounded-bl-full text-white px-2 py-1 tracking-wider ${
                !item.channelpostal ? 'bg-gray-400' : 'bg-green-200'
              }`}
            >
              Unlimited
            </span>
            <span
              className={`text-xs rounded-tr-full rounded-br-full text-white px-2 py-1 tracking-wider ${
                item.channelpostal ? 'bg-gray-400' : 'bg-red'
              }`}
            >
              Forbidden
            </span>
          </div>
          <p>
            <span className="text-xs uppercase font-light">SMS</span>
          </p>
          <div className="flex mb-2 mt-1">
            <span
              className={`text-xs rounded-tl-full rounded-bl-full text-white px-2 py-1 tracking-wider ${
                !item.channelSMS ? 'bg-gray-400' : 'bg-green-200'
              }`}
            >
              Unlimited
            </span>
            <span
              className={`text-xs rounded-tr-full rounded-br-full text-white px-2 py-1 tracking-wider ${
                item.channelSMS ? 'bg-gray-400' : 'bg-red'
              }`}
            >
              Forbidden
            </span>
          </div>
          <p>
            <span className="text-xs uppercase font-light">
              Push Notification
            </span>
          </p>
          <div className="flex mb-2 mt-1">
            <span
              className={`text-xs rounded-tl-full rounded-bl-full text-white px-2 py-1 tracking-wider ${
                !item.channelApp ? 'bg-gray-400' : 'bg-green-200'
              }`}
            >
              Unlimited
            </span>
            <span
              className={`text-xs rounded-tr-full rounded-br-full text-white px-2 py-1 tracking-wider ${
                item.channelApp ? 'bg-gray-400' : 'bg-red'
              }`}
            >
              Forbidden
            </span>
          </div>
        </div>
      ))}
    </>
  )
}

export default Index
