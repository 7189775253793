import { faCircle, faLessThan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { useMemberProfileStatus } from './useMemberProfileStatus'

const Index = (): JSX.Element => {
  const { memberMemberProfiles } = useMemberProfileStatus()

  return (
    <>
      {memberMemberProfiles.map((item) => (
        <div key={item.traderId} className="flex flex-col gap-2">
          <p className="text-xs uppercase font-light tracking-wider">Status</p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            <Icon
              icon={faCircle}
              color={item.memberThorStatus === 'Registered' ? 'green' : 'red'}
            />
            {item.memberThorStatus}
          </p>
        </div>
      ))}
    </>
  )
}

export default Index
