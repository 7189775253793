import React from 'react'
import { Form, Formik } from 'formik'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Datepicker, { useDatepicker } from 'components/Datepicker'

import { formatDateToShort } from 'services/helpers'
import { useMemberCommsChannels } from './useMemberCommsChannels'
import { useMemberCommsStatuses } from './useMemberCommsStatuses'
import { useMemberCommsFilterForm } from './useMemberCommsFilterForm'

import { StatusTypes } from 'types/status'
import { CommsFetchParamsTypes } from 'types/comms'
import { DropDownItem } from 'types/components'

const Index = ({
  formStatus,
  formDefaultValues,
  onFilter,
  onFilterClear,
}: {
  formStatus: StatusTypes
  formDefaultValues: CommsFetchParamsTypes
  onFilter: (filterParams: CommsFetchParamsTypes) => void
  onFilterClear: () => void
}): JSX.Element => {
  const { control, handleSubmit, setValue } = useMemberCommsFilterForm({
    defaultValues: formDefaultValues,
  })

  const { memberCommsStatusesOptions, memberCommsStatusesModStatus } =
    useMemberCommsStatuses()

  const { membersCommsChannelsOptions, memberCommsChannelsModStatus } =
    useMemberCommsChannels()

  const { startDate, endDate, DatepickerInput, handlePick } = useDatepicker()

  const isFiltering = formStatus !== 'success' || false
  const isLoading =
    memberCommsStatusesModStatus !== 'success' ||
    memberCommsChannelsModStatus !== 'success'
      ? true
      : false

  if (isLoading)
    return (
      <span className="tracking-wider font-light">
        Preparing search options ...
      </span>
    )

  return (
    <FormsLayout.Wrapper onSubmit={handleSubmit(onFilter)}>
      <FormsLayout.Item>
        <Datepicker
          title="Date"
          name="dateRange"
          onChange={(dates: [Date, Date]) => {
            handlePick(dates)
            setValue(
              'startDate',
              dates[0] ? formatDateToShort(dates[0]) : undefined
            )
            setValue(
              'endDate',
              dates[1] ? formatDateToShort(dates[1]) : undefined
            )
          }}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          showYearDropdown
          scrollableYearDropdown
          className="p-2 border border-gray-400 tracking-wider"
          placeholderText="All"
          customInput={<DatepickerInput />}
          selectsRange
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="statusId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Status"
              options={memberCommsStatusesOptions}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(data) => setValue('statusId', parseInt(data.value))}
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="channelId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Channels"
              options={membersCommsChannelsOptions}
              className="w-full tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(data) => setValue('channelId', parseInt(data.value))}
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          icon={faSearch}
          iconRight={false}
          type="submit"
          disabled={isFiltering}
          loading={isFiltering}
          className="rounded bg-primary tracking-wider font-bold"
        >
          Search
        </Button>
        {/* {isMemberOffersFiltered ? (
            <Button
              key="clear"
              iconRight={false}
              type="button"
              className="rounded-lg"
              onClick={() => {
                handleMemberOffersFilterClear()
                reset()
              }}
            >
              <span className="tracking-wider font-bold">Clear</span>
            </Button>
          ) : null} */}
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
