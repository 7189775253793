import * as Yup from 'yup'
import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const IdentificationsDefaultSchemas = DefaultSchemas

export const IdentificationsDataSchemas = zod.object({
  accountId: zod.number().nullable(),
  identTypeId: zod.number().positive(),
  statusId: zod.number().positive(),
  startDate: zod.string(),
  endDate: zod.string(),
  ident: zod.string(),
  identTypeName: zod.string(),
  statusName: zod.string(),
  traderAccount: zod.string(),
  identId: zod.number(),
})

export const IdentificationsAPISchemas = IdentificationsDefaultSchemas.merge(
  zod.object({
    idents: zod.array(IdentificationsDataSchemas),
  })
)

export const IdentificationsStatusesDataSchemas = zod.object({
  statusId: zod.number().positive(),
  statusName: zod.string(),
})

export const IdentificationsStatusesAPISchemas =
  IdentificationsDefaultSchemas.merge(
    zod.object({
      identStatus: zod.array(IdentificationsStatusesDataSchemas),
    })
  )

export const UpdateIdentificationMergeSchemas = Yup.object().shape({
  identAcceptor: Yup.number().required(),
  identDonor: Yup.number().required('this field is required'),
  addNewCardFlag: Yup.number(),
})

export const UpdateIdentificationStatusSchemas = Yup.object().shape({
  traderId: Yup.number().required(),
  ident: Yup.string().required(),
  identTypeId: Yup.number().required(),
  identStatusId: Yup.number().required(),
})

export const FilterIdentificationSchemas = Yup.object().shape({
  traderId: Yup.number(),
  ownershipMode: Yup.number(),
  ident: Yup.string(),
  identTypeId: Yup.number(),
  identStatusId: Yup.number(),
  accountId: Yup.number(),
})

export const IdentificationsFetchParamsSchemas = zod.object({
  traderId: zod.number(),
  ownershipMode: zod.number().optional(),
  ident: zod.string().optional(),
  identTypeId: zod.number().optional(),
  identStatusId: zod.number().optional(),
  accountId: zod.number().optional(),
  pSize: zod.number().optional(),
  pNumber: zod.number().optional(),
})

export const IdentificationsHampstaAddReplaceParamsSchemas = zod.object({
  traderId: zod.number(),
  flReplace: zod.number(),
  cardColor: zod.string(),
  name: zod.string().min(1),
  oldCard: zod.string(),
})

export const IdentificationsMergeParamsSchemas = zod.object({
  traderId: zod.number(),
  identDonor: zod.string(),
  identAcceptor: zod.string(),
  addNewCardFlag: zod.string(),
})

export const IdentificationsStatusUpdateParamsSchemas = zod.object({
  traderId: zod.number(),
  ident: zod.string(),
  identTypeId: zod.number(),
  identStatusId: zod.number(),
  identId: zod.number(),
})
