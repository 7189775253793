import classnames from 'classnames'
import noResults from 'assets/images/no-data.png'

type Props = {
  header?: string
  subtitle?: string
  containerStyle?: string
  noImage?: boolean
}

const NoResults = ({
  header,
  subtitle,
  containerStyle,
  noImage,
}: Props): JSX.Element => (
  <div className={classnames('', containerStyle)}>
    {noImage ? null : <img src={noResults} alt="No data results" />}
    <h4 className="tracking-wider font-bold">
      {header ? header : 'No Results Found'}
    </h4>
    <p className="">
      <span className="tracking-wider font-light">
        {subtitle
          ? subtitle
          : 'Try adjusting search or filter to find what you are looking for.'}
      </span>
    </p>
  </div>
)

export default NoResults
