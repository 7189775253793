import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import { InputField } from 'components/InputField'

import { BankAccountsDataTypes } from 'types/financials'

import Dropdown from 'components/Dropdown'
import { useMemberBankAccountsStatusUpdate } from './useMemberBankAccountsStatusUpdate'
import { useMemberBankAccountsStatusUpdateForm } from './useMemberBankAccountsStatusesForm'
import { useMemberBankAccountsStatuses } from './useMemberBankAccountsStatuses'

const Index = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: BankAccountsDataTypes
}): JSX.Element => {
  const { memberBankAccountsStatuses } = useMemberBankAccountsStatuses()

  const {
    memberBankAccountsStatusUpdateParams,
    memberHandleBankAccountsStatusUpdate,
  } = useMemberBankAccountsStatusUpdate({
    modalClose,
    selectedRowData,
  })

  const { control, handleSubmit, setValue, watch } =
    useMemberBankAccountsStatusUpdateForm({
      defaultValues: memberBankAccountsStatusUpdateParams,
    })

  return (
    <FormsLayout.Wrapper
      onSubmit={handleSubmit(memberHandleBankAccountsStatusUpdate)}
    >
      <FormsLayout.Item>
        <Controller
          name="accountStatusId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="To Status"
              defaultValue={
                memberBankAccountsStatuses.filter(
                  (item) =>
                    item.value ===
                    memberBankAccountsStatusUpdateParams.accountStatusId
                )[0]
              }
              options={memberBankAccountsStatuses}
              className="w-full menu-outer-top tracking-wider"
              borderColor="#C4C4C4"
              onSelect={(value) => {
                setValue('accountStatusId', value.value)
              }}
              overflowStyle
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.Item>
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <InputField
              onChange={(e) => {
                setValue('note', e.target.value)
              }}
              type="input"
              name="note"
              label="Note"
              containerClassName="flex flex-col flex-grow"
              rounded
            />
          )}
        />
      </FormsLayout.Item>

      <FormsLayout.ActionWrapper>
        <Button key="submit" type="submit" className="mr-2 rounded bg-primary">
          <span className="tracking-wider font-bold">Update status</span>
        </Button>
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
