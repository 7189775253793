import PrivateRoute from 'components/PrivateRoute'
import { ROUTES } from 'constants/routing'
import { useEffect } from 'react'
import { Routes, useParams } from 'react-router'
import useMemberStore from 'store/member'
import Comms from 'views/MemberProfile/Tabs/Comms'
import CommsMainNZ from 'views/MemberProfile/Tabs/CommsMainNZ'
import Dashboard from 'views/MemberProfile/Tabs/Dashboard'
import BankAccounts from './Tabs/BankAccounts'
import DirectPayments from './Tabs/DirectPayments'
import History from 'views/MemberProfile/Tabs/History'
import Identification from 'views/MemberProfile/Tabs/Identification'
import IdentificationHampsta from 'views/MemberProfile/Tabs/IdentificationHampsta'
import IdentificationGeneric from 'views/MemberProfile/Tabs/IdentificationGeneric'
import Memberships from 'views/MemberProfile/Tabs/Memberships'
import MembershipsSubway from 'views/MemberProfile/Tabs/MembershipsSubway'
import Memos from 'views/MemberProfile/Tabs/Memos'
import Transactions from 'views/MemberProfile/Tabs/Transactions'
import Vouchers from 'views/MemberProfile/Tabs/Vouchers'
import Offers from 'views/MemberProfile/Tabs/Offers'

import { Wrapper } from 'views/MemberProfile/Wrapper'

const MemberProfile = (): JSX.Element => {
  const { traderId } = useParams()
  const getMember = useMemberStore((state) => state.getMember)

  useEffect(() => {
    if (traderId) {
      getMember(traderId)
    }
  }, [getMember, traderId])

  return (
    <Wrapper>
      <Routes>
        <PrivateRoute
          path={ROUTES.MemberProfileDashboard.path}
          element={<Dashboard />}
          permissions={ROUTES.MemberProfile.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileTransactions.path}
          element={<Transactions />}
          permissions={ROUTES.MemberProfileTransactions.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileComms.path}
          element={<Comms />}
          permissions={ROUTES.MemberProfileComms.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileCommsMainNZ.path}
          element={<CommsMainNZ />}
          permissions={ROUTES.MemberProfileCommsMainNZ.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileVouchers.path}
          element={<Vouchers />}
          permissions={ROUTES.MemberProfileVouchers.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileOffers.path}
          element={<Offers />}
          permissions={ROUTES.MemberProfileOffers.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileMemos.path}
          element={<Memos />}
          permissions={ROUTES.MemberProfileMemos.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileMemberships.path}
          element={<Memberships />}
          permissions={ROUTES.MemberProfileMemberships.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileMembershipsSubway.path}
          element={<MembershipsSubway />}
          permissions={ROUTES.MemberProfileMembershipsSubway.permissionIds}
        />
        {/* <PrivateRoute
          path={ROUTES.MemberProfileFinancials.path}
          element={<Financial />}
          permissions={ROUTES.MemberProfileFinancials.permissionIds}
        />
         */}
        <PrivateRoute
          path={ROUTES.MemberProfileIdentification.path}
          element={<Identification />}
          permissions={ROUTES.MemberProfileIdentification.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileIdentificationHampsta.path}
          element={<IdentificationHampsta />}
          permissions={ROUTES.MemberProfileIdentificationHampsta.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileIdentificationGeneric.path}
          element={<IdentificationGeneric />}
          permissions={ROUTES.MemberProfileIdentificationGeneric.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileHistory.path}
          element={<History />}
          permissions={ROUTES.MemberProfileHistory.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileBankAccounts.path}
          element={<BankAccounts />}
          permissions={ROUTES.MemberProfileBankAccounts.permissionIds}
        />
        <PrivateRoute
          path={ROUTES.MemberProfileDirectPayments.path}
          element={<DirectPayments />}
          permissions={ROUTES.MemberProfileDirectPayments.permissionIds}
        />
      </Routes>
    </Wrapper>
  )
}
export default MemberProfile
