import * as Yup from 'yup'

export const UpdateMerchantSchemas = Yup.object().shape({
  traderId: Yup.number(),
  name: Yup.string().required('This field is required.'),
  // .moreThan(-1, 'Negative values not accepted'),
  merchantCode: Yup.number()
    .typeError('Please enter number value only')
    .nullable()
    .required('This field is required.')
    // .notRequired()
    // .min(0)
    // .max(100)
    .moreThan(-1, 'Negative values not accepted'),
  externalId: Yup.string().notRequired().nullable(),
  addressLine1: Yup.string().notRequired().nullable(),
  addressLine2: Yup.string().notRequired().nullable(),
  addressLine3: Yup.string().notRequired().nullable(),
  addressLine4: Yup.string().notRequired().nullable(),
  suburb: Yup.string().notRequired().nullable(),
  city: Yup.string().notRequired().nullable(),
  postCode: Yup.string().notRequired().nullable(),
})
