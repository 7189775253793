import { useCallback, useState } from 'react'
import { faIdCard, faSync } from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import TSTable from 'components/Tanstack'
import { useIdentificationsStore } from 'store/identifications'
import { useTableUtils } from 'hooks/useTableUtils'
import {
  IdentificationsDataTypes,
  IdentificationsModalActionsTypes,
} from 'types/identifications'

export const useMemberIdentificationsTable = () => {
  const identifications = useIdentificationsStore(
    (state) => state.identifications
  )

  const {
    selectedModal,
    selectedModalSet,
    selectedRowData,
    selectedRowDataSet,
  } = useTableUtils<
    IdentificationsDataTypes,
    IdentificationsModalActionsTypes
  >()

  const handleActionModalClose = useCallback(() => {
    selectedRowDataSet(null)
    selectedModalSet(null)
  }, [selectedModalSet, selectedRowDataSet])

  const columnHelper = createColumnHelper<IdentificationsDataTypes>()
  const columns = [
    columnHelper.accessor('ident', {
      header: () => 'Identifiers',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('identTypeName', {
      header: () => 'Identifier Type',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('traderAccount', {
      header: () => 'Account',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('startDate', {
      header: () => 'Start Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue()} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('endDate', {
      header: () => 'End Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue()} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('statusName', {
      header: () => 'Status',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => '',
      // cell: (info) => info.getValue(),
      cell: ({ row }) => (
        <div className="flex gap-2 px-4">
          <TSTable.TableCellButton
            onClick={() => {
              selectedRowDataSet(row.original)
              selectedModalSet('idents_status_update')
            }}
            label="Status Update"
            icon={faIdCard}
          />
        </div>
      ),
    }),
  ] as ColumnDef<IdentificationsDataTypes>[]

  return {
    memberIdentificationsSelected: selectedRowData,
    memberIdentificationsActionModal: selectedModal,
    memberIdentificationsTableData: identifications,
    memberIdentificationsTableColumns: columns,
    handleMemberActionModalClose: handleActionModalClose,
  }
}
