import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from 'react-router-dom'

import { FinancialAddPaymentInstructionTxSchemas } from 'schemas/financialAddPaymentInstructionTxSchemas'
import { FinancialAddPaymentInstructionTxTypes } from 'types/financialAddPaymentInstructionTxTypes'

export const usePaymentInstructionTxForm = () => {
  const { traderId } = useParams()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<FinancialAddPaymentInstructionTxTypes>({
    resolver: zodResolver(FinancialAddPaymentInstructionTxSchemas),
    defaultValues: {
      instructionName: '',
      paymentAmount: 0,
      bankAccountNumber: '',
      frequency: 0,
      firstPaymentDate: '',
      endPaymentDate: '',
      traderId: traderId ? parseInt(traderId) : 0,
    },
  })

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
  }
}
