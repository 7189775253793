import React from 'react'
import { Form, Formik } from 'formik'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import { InputField } from 'components/InputField'
import { InputMaskField } from 'components/InputMaskField'
import Datepicker, { useDatepicker } from 'components/Datepicker'

import { formatDateToShort } from 'services/helpers'

import { useBanksThor } from './useBanksThor'
import { useBanks } from './useBanks'
import { useBankAccountTx } from './useBankAccountTx'
import { useBankAccountTxForm } from './useBankAccountTxForm'
// import { useMemberCommsStatuses } from './useMemberCommsStatuses'
// import { useMemberCommsFilterForm } from './useMemberCommsFilterForm'

import { StatusTypes } from 'types/status'
import { CommsFetchParamsTypes } from 'types/comms'
import { DropDownItem } from 'types/components'

import { BANKSTATUS_OPTIONS } from 'constants/filters'

const Index = ({ onCloseModal }: { onCloseModal: () => void }): JSX.Element => {
  const { bankThorNames } = useBanksThor()
  const {
    memberBankName,
    memberBanksIsLoading,
    memberBanksIsSuccess,
    memberBanksIsError,
    onSearchBank,
  } = useBanks()
  const { onSubmitAddAccount } = useBankAccountTx({
    onSubmitSuccess: onCloseModal,
  })
  const { control, errors, handleSubmit, setValue, watch } =
    useBankAccountTxForm()

  return (
    <FormsLayout.Wrapper onSubmit={handleSubmit(onSubmitAddAccount)}>
      <FormsLayout.Item>
        <Controller
          name="bankAccountName"
          control={control}
          render={({ field }) => (
            <InputField
              {...field}
              type="input"
              name="bankAccountNumber"
              label="Account Name"
              containerClassName="flex flex-col flex-grow"
              rounded
              error={errors.bankAccountName?.message}
              onChange={(e) => {
                field.onChange(String(e.target.value))
              }}
            />
          )}
        />
      </FormsLayout.Item>

      <div className="flex gap-4">
        <FormsLayout.Item>
          <Controller
            name="bankAccountNumber"
            control={control}
            render={({ field }) => (
              <>
                <InputMaskField
                  {...field}
                  readOnly={memberBanksIsLoading}
                  mask="99-9999-9999999-999"
                  type="input"
                  name="bankAccountNumber"
                  label={`Account Number`}
                  containerClassName="flex flex-col flex-grow"
                  rounded
                  error={errors.bankAccountNumber?.message}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    onSearchBank(e.target.value, bankThorNames)
                  }}
                />
                <p className="tracking-wide text-xs text-gray-500 my-1">
                  * If the suffix consists of two digits, please add a zero at
                  front of it to maintain the standard format.
                </p>
              </>
            )}
          />
        </FormsLayout.Item>

        {memberBankName ? (
          <FormsLayout.Item>
            <>
              <p className="mb-2">
                <span className="text-gray-500 tracking-wider">Bank</span>
              </p>
              <p>
                <span className=" text-gray-500 tracking-wider">
                  <b>{memberBankName}</b>
                </span>
              </p>
            </>
          </FormsLayout.Item>
        ) : null}
      </div>

      <div className="flex gap-4">
        <FormsLayout.Item>
          <Controller
            name="accountStatusId"
            control={control}
            render={({ field }) => (
              <Dropdown
                title="Status"
                // value={values.status}
                options={BANKSTATUS_OPTIONS}
                onSelect={(item: DropDownItem) =>
                  field.onChange(parseInt(item.value))
                }
                className="w-full tracking-wider"
                containerClassName="mb-5"
                borderColor="#C4C4C4"
              />
            )}
          />
        </FormsLayout.Item>
        {/* <FormsLayout.Item>
          <Controller
            name="bankId"
            control={control}
            render={({ field }) => (
              <Dropdown
                title="Bank"
                // value={values.status}
                options={bankAsOptions}
                onSelect={(item: DropDownItem) =>
                  field.onChange(parseInt(item.value))
                }
                className="w-full tracking-wider"
                containerClassName="mb-5"
                borderColor="#C4C4C4"
              />
            )}
          />
        </FormsLayout.Item> */}
      </div>

      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          iconRight={false}
          type="submit"
          // disabled={}
          // loading={}
          className="rounded bg-primary tracking-wider font-bold"
        >
          Add
        </Button>
        {/* {isMemberOffersFiltered ? (
            <Button
              key="clear"
              iconRight={false}
              type="button"
              className="rounded-lg"
              onClick={() => {
                handleMemberOffersFilterClear()
                reset()
              }}
            >
              <span className="tracking-wider font-bold">Clear</span>
            </Button>
          ) : null} */}
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
