import InnerWrapper from '../InnerWrapper'
import Modal from 'components/Modal'
import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import TSTable from 'components/Tanstack'
import { ToggleMenu } from 'components/Layout/ToggleMenu'

import NotesFilterForm from './NotesFilterForm'
import UpdateNotesModalForm from './NotesUpdateModalForm'
import { useMemberNotes } from './useMemberNotes'
import { useMemberNotesTable } from './useMemberNotesTable'

const Index: React.FC = (): JSX.Element => {
  const {
    memberNotes,
    memberNotesModStatus,
    memberNotesFilterParams,
    memberHandleNotesFilter,
    memberHandleNotesUnfilter,
  } = useMemberNotes()

  const {
    memberNotesActionModal,
    memberNotesSelected,
    memberNotesTableData,
    memberNotesTableColumns,
    handleMemberActionModalClose,
  } = useMemberNotesTable()

  return (
    <>
      <InnerWrapper title="Notes">
        {/* FILTER */}
        {/* <NotesFilterForm
        formStatus={memberNotesModStatus}
        formDefaultValues={memberNotesFilterParams}
        onFilter={memberHandleNotesFilter}
        onFilterClear={memberHandleNotesUnfilter}
      /> */}

        {/* ADVANCE SEARCH  */}
        <div className="pb-6">
          <ToggleMenu
            type="search"
            label="Search Memos/Notes"
            isTriggeToggle={memberNotesModStatus === 'success' || false}
          >
            <NotesFilterForm
              formStatus={memberNotesModStatus}
              formDefaultValues={memberNotesFilterParams}
              onFilter={memberHandleNotesFilter}
              onFilterClear={memberHandleNotesUnfilter}
            />
          </ToggleMenu>
        </div>

        {/* FAIL */}
        {memberNotesModStatus === 'fail' ? (
          <NoResults
            header="Failed to load data"
            subtitle="Please refresh the page and try again, or contact
        your system administrator"
            noImage
          />
        ) : null}

        {/* LOADING */}
        {memberNotesModStatus === 'loading' ? <Spinner /> : null}

        {/* SUCCESS */}
        {memberNotes.length && memberNotesModStatus === 'success' ? (
          <TSTable.Table
            data={memberNotesTableData}
            columns={memberNotesTableColumns}
          />
        ) : null}

        {/* EMPTY */}
        {!memberNotes.length && memberNotesModStatus === 'success' ? (
          <NoResults
            header="No available data"
            subtitle="There is no available data to display"
            noImage
          />
        ) : null}
      </InnerWrapper>

      {/* VIEW NOTE */}
      {memberNotesActionModal === 'notes_preview' && memberNotesSelected ? (
        <Modal
          open={true}
          title="View Note"
          onClose={handleMemberActionModalClose}
          noFooter={false}
          cancelButtonLabel="Close"
        >
          <div className="p-4">
            {/* <h4>Note Description</h4> */}
            <p>{memberNotesSelected.noteDescription}</p>
          </div>
        </Modal>
      ) : null}

      {/* UPDATE NOTE */}
      {memberNotesActionModal === 'notes_update' && memberNotesSelected ? (
        <Modal
          open={true}
          title="Update Notes"
          onClose={handleMemberActionModalClose}
          noFooter
          modalSize="regular"
        >
          <UpdateNotesModalForm
            modalClose={handleMemberActionModalClose}
            selectedRowData={memberNotesSelected}
          />
        </Modal>
      ) : null}
    </>
  )
}

export default Index
