import { Communications } from './Communications'
import { Notes } from './Notes'
import { Transactions } from './Transactions'
import { Vouchers } from './Vouchers'

const excluded = process.env.REACT_APP_EXCLUDED_MODULES
const EXCLUDEDMODULES = excluded?.split('/')
const isVouchers = EXCLUDEDMODULES?.includes('Vouchers')

const Dashboard = (): JSX.Element => (
  <div className="grid w-full grid-cols-1 gap-4 p-4 lg:grid-cols-2">
    <Transactions />
    <Communications />
    {/* Not Displaying Vouchers pane in Generic and Westfield */}
    <Vouchers />
    <Notes />
  </div>
)
export default Dashboard
