import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import { getIdentificationsAPI } from 'api/identifications'
import { useIdentificationsStore } from 'store/identifications'
import { IdentificationsFetchParamsTypes } from 'types/identifications'

import { DATASTATUS } from 'constants/status'

export const useMemberIdentifications = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')
  const defaultParams = { traderId: memberTraderId }

  const identificationsModStatus = useIdentificationsStore(
    (state) => state.identificationsModStatus
  )
  const identificationsModStatusSet = useIdentificationsStore(
    (state) => state.identificationsModStatusSet
  )
  const identificationsModErrors = useIdentificationsStore(
    (state) => state.identificationsModErrors
  )
  const identificationsModErrorsSet = useIdentificationsStore(
    (state) => state.identificationsModErrorsSet
  )
  const identifications = useIdentificationsStore(
    (state) => state.identifications
  )
  const identificationsSet = useIdentificationsStore(
    (state) => state.identificationsSet
  )
  const [identificationsFilterParams, identificationsFilterParamsSet] =
    useState<IdentificationsFetchParamsTypes>(defaultParams)
  const [isIdentificationsFiltered, isIdentificationsFilteredSet] =
    useState<boolean>(false)

  const queryClient = useQueryClient()
  const {
    data: dataIdentifications,
    isLoading: isLoadingIdentifications,
    isError: isErrorIdentifications,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useIdentifications', identificationsFilterParams],
    queryFn: async () => {
      try {
        identificationsModStatusSet('loading')
        const response = await getIdentificationsAPI(
          identificationsFilterParams
        )
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        identificationsModStatusSet('success')
        return response.idents
      } catch (e) {
        identificationsModStatusSet('fail')
        identificationsModErrorsSet(JSON.stringify(e))
      }
    },
  })

  const handleFilter = useCallback(
    async (filterParams: IdentificationsFetchParamsTypes) => {
      identificationsFilterParamsSet(filterParams)
      isIdentificationsFilteredSet(true)
    },
    [identificationsFilterParamsSet, isIdentificationsFilteredSet]
  )

  const handleUnfilter = useCallback(() => {
    const test = { traderId: memberTraderId }
    identificationsFilterParamsSet(test)
    isIdentificationsFilteredSet(false)
  }, [identificationsFilterParamsSet, isIdentificationsFilteredSet])

  useEffect(() => {
    if (dataIdentifications) identificationsSet(dataIdentifications)
  }, [dataIdentifications, identificationsSet])

  return {
    memberHandleIdentificationsFilter: handleFilter,
    memberHandleIdentificationsUnfilter: handleUnfilter,
    memberIdentificationsFilterParams: identificationsFilterParams,
    memberIdentifications: identifications,
    memberIdentificationsModStatus: identificationsModStatus,
  }
}
