import { isUndefined } from 'lodash'
import React, { useCallback } from 'react'

type Props = {
  title: string
  index: number
  setSelectedTab: (index: number) => void
  selectedTab: number
  count: number | undefined
}

function TabTitle({
  title,
  setSelectedTab,
  index,
  selectedTab,
  count,
}: Props): React.ReactElement {
  const onClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  let className = 'inline mr-3 '

  if (index === selectedTab) className += 'border-b-4 py-2 border-primary'

  return (
    <>
      <li className={className}>
        <button
          onClick={onClick}
          className="focus:outline-none hover:text-primary"
        >
          <p>
            <span className="tracking-wider font-light">
              {title}
              &nbsp;
              <span className="text-gray-500">
                {isUndefined(count) ? '0' : count}
              </span>
            </span>
          </p>
        </button>
      </li>
    </>
  )
}

export default TabTitle
