import { isUndefined } from 'lodash'
import { CommChannel, CommStatus, EMRAction } from 'types/communications'
import { http } from 'utils'

/**
 * Get member's communications.
 * @param traderId trace ID
 * @param flEmail result size
 * @param flSms result size
 * @param flPush result size
 * @param token access token
 * @param startDate start date
 * @param endDate end date
 * @param channels channel
 * @param status status
 * @param pSize result size
 * @returns array of communications
 */
export async function getCommunications(
  traderId: number,
  flEmail: string,
  flSms: string,
  flPush: string,
  startDate?: string,
  endDate?: string,
  // channelId?: string,
  status?: string,
  pSize?: number,
  pNumber?: number
): Promise<[[], number, number, number]> {
  let url = `/communications/${traderId}`
  const params = new URLSearchParams()

  params.set('flEmail', flEmail)
  params.set('flSms', flSms)
  params.set('flPush', flPush)

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  // if (channelId) {
  //   params.set('channelId', channelId)
  // }

  if (status) {
    params.set('statusId', status)
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get(url)

  const items = response.data.comms
  // if (!channelId) {
  //   items = [..., ...response.data.pushNotifications]
  // } else {
  //   items = !isUndefined(response.data.pushNotifications)
  //     ? response.data.pushNotifications
  //     : response.data.comms
  // }

  // if query string is empty
  // added channelId=4 to include the push notification channel

  // const isQuery = url.split('?')[1] !== '' || false
  // if (!isQuery) {
  //   params.set('channelId', '4')
  //   url += `${params.toString()}`
  //   const response = await http.get(url)
  //   items = [...items, ...response.data.pushNotifications]
  // }

  // const items = result.map((record) => ({
  //   id: record.id,
  //   action: record.action,
  //   channel: record.channel,
  //   status: record.status,
  //   broadcastDate: record.broadcastDate,
  //   opened: record.opened,
  //   clicked: record.clicked,
  //   actionId: record.actionId,
  //   traderId: record.traderId,
  //   actionType: record.actionType,
  //   email: record.email,
  //   sendDate: record.sendDate,
  //   statusId: record.statusId,
  // }))

  let sentCount = 0
  let openedCount = 0
  let clickedCount = 0

  items.forEach((item: { status: string; opened: number; clicked: number }) => {
    if (item.status && item.status.toLowerCase() === 'sent') {
      sentCount++
    }
    if (item.opened === 1) {
      openedCount++
    }
    if (item.clicked === 1) {
      clickedCount++
    }
  })

  // const sentCount = items.filter(
  //   (item: Communication) => item.status.toLowerCase() === 'sent'
  // ).length
  // const openedCount = items.filter(
  //   (item: Communication) => item.opened === 1
  // ).length
  // const clickedCount = items.filter(
  //   (item: Communication) => item.clicked === 1
  // ).length

  return [items, sentCount, openedCount, clickedCount]
}

/**
 * Send email.
 *
 * @param traderId trace ID
 * @param actionId action Id
 * @param email email
 * @param broadcastTime broadcast time
 * @returns Promise resolved to true if operation succeeds.
 */
export async function sendEmail(
  traderId: number,
  actionId: number,
  email: string,
  broadcastTime: string
): Promise<void> {
  let url = `/communications/${traderId}`
  const params = new URLSearchParams()

  if (actionId) {
    params.set('actionId', actionId.toString())
  }

  if (email) {
    params.set('email', email)
  }

  if (broadcastTime) {
    params.set('broadcastTime', broadcastTime)
  }

  url += `?${params.toString()}`

  const data = {
    actionId,
    email,
    broadcastTime,
  }

  await http.post(url, data)

  return // response.data
}

/**
 * Get action details.
 *
 * @param token access token
 * @returns array of actions
 */
export async function getActionDetails(): Promise<EMRAction[]> {
  const url = '/communications/emr-actions'

  const response = await http.get<{ emrActions: EMRAction[] }>(url)

  const items = response.data.emrActions.map((record: EMRAction) => ({
    actionId: record.actionId,
    actionName: record.actionName,
    actionDescription: record.actionDescription,
    actionType: record.actionType,
    actionTypeId: record.actionTypeId,
  }))

  return items
}

/**
 * Get communication status.
 *
 * @param token access token
 * @returns array of communication status
 */
export async function getCommStatus(): Promise<CommStatus[]> {
  const url = '/communications/status'

  const response = await http.get<{ commsStatus: CommStatus[] }>(url)

  const items = response.data.commsStatus.map((record: CommStatus) => ({
    statusId: record.statusId,
    statusName: record.statusName,
    statusDescription: record.statusDescription,
  }))

  return items
}

/**
 * Get communication channels.
 *
 * @param token access token
 * @returns array of communication channels
 */
export async function getCommChannels(): Promise<CommChannel[]> {
  const url = '/communications/channel'

  const response = await http.get<{ commsChannel: CommChannel[] }>(url)

  const items = response.data.commsChannel.map(
    ({ channelId, channelName, channelDescription }) => ({
      channelId,
      channelName,
      channelDescription,
    })
  )

  return items
}

/**
 * Get communication Email Preview.
 *
 * @param token access token
 * @param actionId access token
 * @returns array of communication status
 */
export async function getEmailPreview(actionId: number): Promise<string> {
  const url = `/communications/emr-actions/${actionId}`
  const response = await http.get(url)
  return response.data.htmlPreviewUrl
}
