import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const MemberProfilesDefaultSchemas = DefaultSchemas

export const MemberProfilesDataSchemas = zod.object({
  traderId: zod.number().nullable().optional(),
  firstName: zod.string().nullable().optional(),
  lastName: zod.string().nullable().optional(),
  cardNumber: zod.string().nullable().optional(),
  ranking: zod.string().nullable().optional(),
  rankingId: zod.number().nullable().optional(),
  memberThorStatusId: zod.number().nullable().optional(),
  memberThorStatus: zod.string().nullable().optional(),
  memberStatusId: zod.number().nullable().optional(),
  memberStatus: zod.string().nullable().optional(),
  loyaltyBalance: zod.number().nullable().optional(),
  creditBalance: zod.number().nullable().optional(),
  enrollmentDate: zod.string().nullable().optional(),
  enrollmentTraderName: zod.string().nullable().optional(),
  enrollmentTraderId: zod.number().nullable().optional(),
  birthDate: zod.string().nullable().optional(),
  gender: zod.string().nullable().optional(),
  genderId: zod.number().nullable().optional(),
  email: zod.string().nullable().optional(),
  mobile: zod.string().nullable().optional(),
  address1: zod.string().nullable().optional(),
  address2: zod.string().nullable().optional(),
  address3: zod.string().nullable().optional(),
  address4: zod.string().nullable().optional(),
  state: zod.string().nullable().optional(),
  city: zod.string().nullable().optional(),
  suburb: zod.string().nullable().optional(),
  postCode: zod.string().nullable().optional(),
  country: zod.string().nullable().optional(),
  countryIsoCode: zod.string().nullable().optional(),
  flInfo: zod.number().nullable().optional(),
  flMarketing: zod.number().nullable().optional(),
  flNewsletter: zod.number().nullable().optional(),
  flInterest: zod.number().nullable().optional(),
  flThirdparty: zod.number().nullable().optional(),
  flCompetition: zod.number().nullable().optional(),
  channelApp: zod.number().nullable().optional(),
  channelEmail: zod.number().nullable().optional(),
  channelSMS: zod.number().nullable().optional(),
  channelpostal: zod.string().nullable().optional(),
  lastModifiedDate: zod.string().nullable().optional(),
  tncVersion: zod.string().nullable().optional(),
  privacyVersion: zod.string().nullable().optional(),
  traderGroupId: zod.number().nullable().optional(),
  promoCode: zod.string().nullable().optional(),
  website: zod.string().nullable().optional(),
  traderType: zod.string().nullable().optional(),
  mailingPreferenceName: zod.string().nullable().optional(),
})

export const MemberProfilesAPISchemas = MemberProfilesDefaultSchemas.merge(
  MemberProfilesDataSchemas
)

export const MemberProfilesFetchParamsSchemas = zod.object({
  traderId: zod.number(),
})
