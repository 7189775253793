import * as Yup from 'yup'

export const CreateMerchantIdentificationSchemas = Yup.object().shape({
  traderId: Yup.number(),
  password: Yup.string()
    .required('This field is required.')
    // .test(
    //   'len',
    //   'Must be exactly 5 characters',
    //   (password) => password?.length === 5
    // )
    .matches(
      /^[a-zA-Z0-9]+$/,
      'This field cannot contain white space and special character'
    ),
  traderTransIdent: Yup.string().required('This field is required.'),
  transIdentTypeId: Yup.number().notRequired(),
})
