import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'

type Props = {
  variant?: 'error' | 'success' | 'info'
  className?: string
}

function Alert({
  variant,
  className,
  children,
}: PropsWithChildren<Props>): React.ReactElement {
  return (
    <div
      role="alert"
      className={classnames(
        'rounded-md text-white text-sm w-full p-4 mb-4',
        {
          'bg-red': !variant || variant === 'error',
          'bg-primary-light': variant === 'success',
          'bg-blue-300': variant === 'info',
        },
        className
      )}
    >
      <Icon icon={faExclamationTriangle} className="mr-2" />
      {children}
    </div>
  )
}

export default Alert
