import { FC } from 'react'
import Card from 'components/Card'
import { Logo } from 'components/ui'
import Version from 'components/Version'

const LoginShell: FC = ({ children }): JSX.Element => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <div className="container flex flex-col items-center h-full max-w-full min-h-screen bg-primary">
      <div className="flex flex-col justify-between w-full h-full min-h-screen md:w-6/12 lg:w-5/12 xl:w-1/3">
        <div className="flex items-center justify-center mt-16 text-white">
          <Logo width={250} />
        </div>

        <Card className="flex flex-col items-center p-10 bg-white">
          {children}
        </Card>

        <div className="container flex flex-col items-center my-6 text-white gap-4">
          <Version />
          <p className="font-light tracking-wider text-xs text-center">
            Copyright &copy; 2002-{year} Tranxactor New Zealand Limited. All
            rights reserved.
          </p>
          <Logo width={140} className="m-4" />
        </div>
      </div>
    </div>
  )
}

export default LoginShell
