import SecureLS from 'secure-ls'

const ls = new SecureLS()

const add = (key: string, value: string): void => ls.set(key, value)

const setWithExpiry = (key: string, value: string, ttl: number): void => {
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl,
  }
  add(key, JSON.stringify(item))
}

const remove = (key: string): void => ls.remove(key)

const get = (key: string): string | null => {
  try {
    return ls.get(key)
  } catch (e) {
    remove(key)
    return null
  }
}

const getWithExpiry = (key: string): string | null => {
  const itemStr = get(key)
  // if the item doesn't exist, return null
  if (!itemStr) return null

  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    remove(key)
    return null
  }
  return item.value
}

const localStorage = {
  add,
  get,
  remove,
  setWithExpiry,
  getWithExpiry,
}

export default localStorage
