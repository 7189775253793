import { getSubsquads } from 'api/subsquad'
import NoResults from 'components/NoResults'
import SearchBar from 'components/SearchBar'
import Spinner from 'components/Spinner'
import { isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { SubsquadData } from 'types/subsquad'
import ResultTable from './ResultTable'

// const perPage = 10

function MemberLookup(): React.ReactElement {
  // const [showNoResults, setShowNoResults] = useState(false)
  const [loading, setLoading] = useState(false)

  // const [offset, setOffset] = useState(0)
  // const [perPage] = useState(10)
  // const [pageCount, setPageCount] = useState(0)
  const [postData, setPostData] = useState<SubsquadData[] | null>(null)

  // const [searchValues, setSearchValues] = useState(undefined)
  // const [, setCloseForm] = useState(() => {})

  // const [members, setMembers] = useState<MemberData[] | null>(null)

  const fetchMembers = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (dataArray: any): Promise<void> => {
      if (!dataArray) {
        return
      }

      // setSearchValues(dataArray)

      try {
        await getSubsquads(
          dataArray.gname || dataArray.first_name,
          dataArray.gurn || dataArray.last_name,
          dataArray.id || dataArray.trader_id,
          dataArray.card || dataArray.card_number
        )
          .then((list) => {
            // setMembers(list)
            // if (list.length === 0) {
            //   setShowNoResults(true)
            // }

            // const slice = list.slice(offset, offset + perPage)
            setPostData(list[0])
            // setPageCount(Math.ceil(list.length / perPage))
          })
          .finally(() => {
            setLoading(false)
          })
      } catch (error) {
        // error.message
        toast.error('An unknown error has occured. Please try again.')
      }
    },
    []
  )

  // useEffect(() => {
  //   fetchMembers(searchValues)
  // }, [fetchMembers, searchValues])

  return (
    <div className="p-12 bg-white content-container">
      <div className="flex flex-col lg:flex-row lg:justify-between gap-2">
        <div>
          <h3>
            <span className="tracking-wider font-bold text-xl">Subsquad</span>
          </h3>
          <p>
            <span className="tracking-wider font-light">
              Look up for subsquad using URN
            </span>
          </p>
          <p className="text-gray-500">
            <span className="tracking-wider font-light text-xs">
              * Use the searchbar to lookup for subsquad information
            </span>
          </p>
        </div>

        <div className="w-3/4 lg:w-1/2">
          <SearchBar
            placeholder="Search for subsquad URN ..."
            startFetch={fetchMembers}
            startLoading={() => setLoading(true)}
            stopLoading={() => setLoading(false)}
            closeSearchForm={() => {
              return
            }}
            isSubsquadLookup
          />
        </div>
      </div>

      <div className="my-6">
        {loading ? (
          <Spinner />
        ) : postData == null ? (
          <></>
        ) : isEmpty(postData) ? (
          <NoResults noImage />
        ) : (
          <ResultTable postData={postData} />
        )}
      </div>
    </div>
  )
}

export default MemberLookup
