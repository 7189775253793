import { getTransactionsAPI } from 'api/transactions'
import { getMerchants } from 'api/merchant'
import { MerchantType, Transaction, TransactionLoyaltyAllocationCampaign } from 'types/transactions'
import create from 'zustand'

interface TransactionStoreTypes {
  transactionsStatus: boolean
  transactionsLoading: boolean
  transactions: Transaction[]
  transaction: MerchantType[]
  getTransactions: (
    memberTraderId: string,
    lastNTransactions?: string,
    transactionTypeId?: string,
    merchantId?: string,
    startDate?: string,
    endDate?: string
  ) => void
  getTransaction: () => void
  setTransaction: (transaction: MerchantType[]) => void
  isFilteredTransactions: boolean
  setFilteredTransactionsStatus: (isFilteredTransaction: boolean) => void
}

const useTransactionStore = create<TransactionStoreTypes>((set) => ({
  transactionsLoading: true,
  transactionsStatus: true,
  transactions: [],
  getTransactions: async (
    memberTraderId,
    lastNTransactions,
    transactionTypeId,
    merchantId,
    startDate,
    endDate
  ) => {
    try {
      set({ transactionsLoading: true })
      const transactions = await getTransactionsAPI({
        memberTraderId,
        lastNTransactions,
        transactionTypeId,
        merchantId,
        startDate,
        endDate,
      })
      set((state) => ({
        ...state,
        transactions,
        transactionsLoading: false,
      }))
    } catch (error) {
      set((state) => ({
        ...state,
        transactionsStatus: false,
        transactionsLoading: false,
      }))
    }
  },
  transaction: [],
  getTransaction: () => {
    return getMerchants()
      .then((transaction: MerchantType[]) => {
        set({
          transaction,
        })
        return transaction
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  },
  setTransaction: (transaction: MerchantType[]) => {
    set({
      transaction,
    })
  },
  isFilteredTransactions: false,
  setFilteredTransactionsStatus: (isFilteredTransactions) => {
    set({
      isFilteredTransactions,
    })
  },
}))

export default useTransactionStore
