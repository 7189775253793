import { useCallback, useState } from 'react'
import { faIdCard, faEye } from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import TSTable from 'components/Tanstack'
import { useOffersStore } from 'store/offers'
import { useTableUtils } from 'hooks/useTableUtils'
import { OffersDataTypes, OffersModalActionsTypes } from 'types/offers'

export const useMemberOffersTable = () => {
  const offers = useOffersStore((state) => state.offers)

  const {
    selectedModal,
    selectedModalSet,
    selectedRowData,
    selectedRowDataSet,
  } = useTableUtils<OffersDataTypes, OffersModalActionsTypes>()

  const handleActionModalClose = useCallback(() => {
    selectedRowDataSet(null)
    selectedModalSet(null)
  }, [selectedModalSet, selectedRowDataSet])

  const columnHelper = createColumnHelper<OffersDataTypes>()
  const columns = [
    columnHelper.accessor('offerId', {
      header: () => 'ID',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('offerName', {
      header: () => 'Offer Name',
      cell: (info) => (
        <p className="flex flex-col">
          <span>{info.getValue()}</span>
          <small className="text-primary text-opacity-40">
            {info.row.original.description}
          </small>
        </p>
      ),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('voucherName', {
      header: () => 'Voucher Name',
      cell: (info) => (
        <p className="flex flex-col">
          <span>{info.getValue()}</span>
          <span>{info.row.original.voucherCode}</span>
        </p>
      ),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('startDate', {
      header: () => 'Start Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('endDate', {
      header: () => 'End Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('cmsCode', {
      header: () => 'CMS',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => '',
      // cell: (info) => info.getValue(),
      cell: ({ row }) => (
        <div className="flex gap-2 px-4">
          {/* <TSTable.TableCellButton
            onClick={() => {
              selectedOffersSet(row.original)
              selectedActionModalSet('preview')
            }}
            label="Preview"
            icon={faEye}
          /> */}
        </div>
      ),
    }),
  ] as ColumnDef<OffersDataTypes>[]

  return {
    memberOffersSelected: selectedRowData,
    memberOffersActionModal: selectedModal,
    memberOffersTableData: offers,
    memberOffersTableColumns: columns,
    handleMemberActionModalClose: handleActionModalClose,
  }
}
