import { 
         MerchantDevicesAPITypes, 
         MerchantDevicesFetchParamsTypes,
         AddMerchantDevicesAPITypes,
         AddMerchantDevicesFetchParamsTypes,
         UpdateMerchantDevicesAPITypes,
         UpdateMerchantDevicesFetchParamsTypes,
       } from "types/merchantDevices"
import { MerchantDevicesAPISchemas } from "schemas/merchantDevicesSchemas"

import { http } from 'utils'
import { DATASTATUS } from "constants/status"

export async function getMerchantDevicesAPI({
    traderId,
    pSize = 10,
    pNumber = 0,
    merchantCode,
    deviceTypeId,
    
}: MerchantDevicesFetchParamsTypes): Promise<MerchantDevicesAPITypes> {
    let url = `/merchant/devices`
    const params = new URLSearchParams()

    if(traderId) {
        params.set('traderId', traderId.toString())
    }

    if(merchantCode) {
        params.set('merchantCode', merchantCode)
    }

    if(deviceTypeId) {
        params.set('deviceTypeId', deviceTypeId.toString())
    }

    url += `?${params.toString()}`

    const response = await http.get<unknown>(url)
    const validatedData = MerchantDevicesAPISchemas.safeParse(response.data)

    if(!validatedData.success) {
        return {
            devices: [],
            outcomeMessage:JSON.stringify(validatedData.error),
            ...DATASTATUS,
        }
    }

    return validatedData.data
}

export async function addMerchantDevices({
    traderId,
    deviceCode,
    deviceTypeId,
    startDate,
    endDate
}: AddMerchantDevicesFetchParamsTypes): Promise<AddMerchantDevicesAPITypes> {
 let url = `/merchant/add-device`
 const params = new URLSearchParams()

 if(traderId) {
    params.set('traderId', traderId.toString())
 }

 if(deviceCode) {
    params.set('deviceCode', deviceCode.toString())
 }

 if(deviceTypeId) {
    params.set('deviceTypeId', deviceTypeId.toString())
 }

 if(startDate) {
    params.set('startDate', startDate)
 }

 if(endDate) {
    params.set('endDate', endDate)
 }

 url += `?${params.toString()}`

 const data = {
    traderId,
    deviceCode,
    deviceTypeId,
    startDate,
    endDate,
 }
 const response = await http.post(url, data)
 return response.data
}

export async function updateMerchantDevices({
    traderId,
    deviceCode,
    startDate,
    endDate,
}: UpdateMerchantDevicesFetchParamsTypes): Promise<UpdateMerchantDevicesAPITypes> {
    let url = `/merchant/update-device`
    const params = new URLSearchParams()

    if(traderId) {
        params.set('traderId', traderId.toString())
    }

    if(deviceCode) {
        params.set('deviceCode', deviceCode.toString())
    }

    if(startDate) {
        params.set('startDate', startDate)
    }

    if(endDate) {
        params.set('endDate', endDate)
    }

    url += `?${params.toString()}`

    const data = {
        traderId,
        deviceCode,
        startDate,
        endDate,
    }
    
    const response = await http.put(url, data)
    return response.data
}