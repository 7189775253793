import React from 'react'
import { Form, Formik } from 'formik'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'

import { useMemberOffersLookup } from './useMemberOffersLookup'
import { useMemberOffersFilterForm } from './useMemberOffersFilterForm'

import { StatusTypes } from 'types/status'
import { OffersFetchParamsTypes } from 'types/offers'
import { DropDownItem } from 'types/components'

const Index = ({
  formStatus,
  formDefaultValues,
  onFilter,
  onFilterClear,
}: {
  formStatus: StatusTypes
  formDefaultValues: OffersFetchParamsTypes
  onFilter: (filterParams: OffersFetchParamsTypes) => void
  onFilterClear: () => void
}): JSX.Element => {
  const { control, handleSubmit, setValue } = useMemberOffersFilterForm({
    defaultValues: formDefaultValues,
  })

  const {
    memberHandleOffersIdLookup,
    memberHandleOffersNameLookup,
    memberoffersIdOptionsLoader,
    memberOffersNameOptionsLoader,
  } = useMemberOffersLookup()

  const isFiltering = formStatus !== 'success' || false

  return (
    <FormsLayout.Wrapper onSubmit={handleSubmit(onFilter)}>
      <FormsLayout.Item>
        <Controller
          name="offerId"
          control={control}
          render={({ field }) => (
            <Dropdown
              title="Offer Id"
              value={field.value}
              loadOptions={memberoffersIdOptionsLoader}
              onChange={(value: DropDownItem) => {
                setValue('offerId', value ? parseInt(value.value) : undefined)
              }}
              onInputChange={(value) => memberHandleOffersIdLookup(value)}
              className="w-60 tracking-wider"
              borderColor="#C4C4C4"
              containerClassName="focus:border-primary"
              placeholder="Offers ids..."
              isClearable
              isSearchable
              async
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.Item>
        <Controller
          name="offerName"
          control={control}
          render={({ field }) => (
            <Dropdown
              value={field.value}
              title="Name"
              loadOptions={memberOffersNameOptionsLoader}
              onChange={(value: DropDownItem) => {
                setValue('offerName', value ? value.value : undefined)
              }}
              onInputChange={(value) => memberHandleOffersNameLookup(value)}
              className="w-60 tracking-wider"
              borderColor="#C4C4C4"
              containerClassName="focus:border-primary "
              placeholder="Offer names..."
              isClearable
              isSearchable
              async
            />
          )}
        />
      </FormsLayout.Item>
      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          icon={faSearch}
          iconRight={false}
          type="submit"
          disabled={isFiltering}
          loading={isFiltering}
          className="rounded bg-primary tracking-wider font-bold"
        >
          Search
        </Button>
        {/* {isMemberOffersFiltered ? (
            <Button
              key="clear"
              iconRight={false}
              type="button"
              className="rounded-lg"
              onClick={() => {
                handleMemberOffersFilterClear()
                reset()
              }}
            >
              <span className="tracking-wider font-bold">Clear</span>
            </Button>
          ) : null} */}
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
