import { formatter } from 'utils'

import { useMemberProfiles } from './useMemberProfiles'

const Index = (): JSX.Element => {
  const { memberMemberProfiles } = useMemberProfiles()

  return (
    <>
      {memberMemberProfiles.map((item) => (
        <div key={item.traderId} className="flex flex-col gap-2">
          <p className="text-xs uppercase font-light tracking-wider">
            Birthday
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {formatter.displayDate(item.birthDate)}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">Email</p>
          <p className="flex gap-2 items-center tracking-wider">
            {item.email || '---'}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">Mobile</p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.mobile || '---'}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">Address</p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.address1 || '---'}
            <br></br>
            {item.address2 || '---'}
            <br></br>
            {item.address3 || '---'}
            <br></br>
            {item.address4 || '---'}
            <br></br>
            {item.suburb || '---'}
            <br></br>
            {item.city || '---'}
            <br></br>
            {item.state || '---'}

          </p>
        </div>
      ))}
    </>
  )
}

export default Index
