import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { OffersFetchParamsSchemas } from 'schemas/offersSchemas'
import { OffersFetchParamsTypes } from 'types/offers'

export const useMemberOffersFilterForm = ({
  defaultValues,
}: {
  defaultValues: OffersFetchParamsTypes
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<OffersFetchParamsTypes>({
    resolver: zodResolver(OffersFetchParamsSchemas),
    defaultValues,
  })

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    reset,
    watch,
  }
}
