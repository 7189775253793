const MerchantSearchTips = (): JSX.Element => (
  <div className="box-border absolute flex w-3/4 p-4 my-1 text-base bg-white border border-gray-500 top-12">
    <div>
      <p>
        <span className="tracking-wider font-bold">Search Tips</span>
      </p>
      <ul className="flex flex-col gap-2 list-disc pl-4 py-4">
        <li className="tracking-wider font-light">
          Use <span className="font-bold">id:</span> to search by trader id
        </li>
        <li className="tracking-wider font-light">
          Use <span className="font-bold">name:</span> to search by merchant
          name
        </li>
        <li className="tracking-wider font-light">
          Use <span className="font-bold">mcode:</span> to search by merchant
          code
        </li>
      </ul>
    </div>
  </div>
)

export default MerchantSearchTips
