import {
  faCalendarAlt,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { getActionDetails, sendEmail } from 'api/communications'
import Colors from 'colors.json'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Modal from 'components/Modal'
import { Form, Formik } from 'formik'
import { isUndefined } from 'lodash'
import { DateTime } from 'luxon'
import { PropsWithChildren, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useMemberStore from 'store/member'
import useSWR from 'swr'
import { CommData } from 'types/communications'
import { DropDownItem } from 'types/components'
import * as Yup from 'yup'

interface Props {
  open: boolean
  onClose: () => void
}

type DateInputProps = {
  value?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  icon?: IconDefinition
}

const ModalSendEmail = ({ open, onClose }: Props): JSX.Element => {
  const { traderId } = useParams()
  const member = useMemberStore((state) => state.member)

  const { data: actions } = useSWR(
    // , isValidating: isActionsValidating
    ['/actions'],
    (_) => getActionDetails()
  )

  const options = !isUndefined(actions)
    ? actions.map(({ actionName, actionId }) => ({
        label: actionName,
        value: actionId.toString(),
      }))
    : []

  const sendEmailInitialValues: CommData = {
    traderId: traderId ? parseInt(traderId) : 0,
    actionType: options ? options[0] : null,
    action: null,
    actionId: null,
    email: member ? member.email : '',
    broadcastTime: new Date(),
    channel: 'Email',

    message: null,
    titleMessage: null,
  }

  const validateSendEmail = Yup.object().shape({
    actionType: Yup.object().required(
      'Action Type is required. Please choose an action.'
    ),
    broadcastTime: Yup.string().required(
      'Date is required. Please select a date.'
    ),
  })

  const [sendCommOption, setSendCommOption] = useState<string>('immediately')

  const CustomDateInput = ({
    value,
    onClick,
    icon,
  }: PropsWithChildren<DateInputProps>): JSX.Element => (
    <button
      className="flex p-2 pr-10 ml-2 text-left border border-gray-400 rounded-md"
      style={{ minWidth: '144px', minHeight: '38px' }}
      onClick={onClick}
      type="button"
    >
      {value}
      {icon && (
        <Icon
          icon={icon}
          className="absolute top-0 right-0 h-full ml-4 mr-2 text-gray-500"
        />
      )}
    </button>
  )

  return (
    <Modal
      open={open}
      title="Send Email"
      onClose={onClose}
      confirmButtonLabel="Send"
      confirmButtonClassName="px-8"
      noFooter
    >
      <div>
        <Formik
          initialValues={sendEmailInitialValues}
          validationSchema={validateSendEmail}
          onSubmit={async (values, actions) => {
            try {
              await sendEmail(
                values.traderId,
                parseInt(values.actionType ? values.actionType.value : '0'),
                values.email,
                DateTime.fromJSDate(values.broadcastTime).toISODate()
              )

              onClose()
              toast.success('Send Email Sucessful!')
              actions.setSubmitting(false)
            } catch (error) {
              if (error instanceof Error) {
                toast.error(error.message)
              }
            }
          }}
        >
          {({ values, isSubmitting, setFieldValue, errors, touched }) => (
            <Form>
              <p className="mb-3 text-base">
                Type:
                <span className="ml-2 text-base text-gray-500">
                  {values.channel}
                </span>
              </p>
              {member ? (
                <p className="mb-6">
                  *This email will be sent to: &nbsp;
                  <a className="text-primary" href={`mailto: ${member.email}`}>
                    {member.email}
                  </a>
                </p>
              ) : null}
              <Dropdown
                title="Action:"
                placeholder="Select Action Type"
                value={values.actionType}
                options={options}
                className="mb-6"
                borderColor="#C4C4C4"
                roundedCorners={true}
                containerMinWidth="600px"
                titleStyle="text-base"
                onSelect={(item: DropDownItem) => {
                  setFieldValue('actionType', item)
                }}
                overflowStyle
              />
              {errors.actionType && touched.actionType ? (
                <p className="mt-2 text-red">{errors.actionType}</p>
              ) : null}
              <div className="flex flex-row">
                <p className="text-base">Send Comm:</p>
                <div className="flex flex-col mt-1 ml-2">
                  <div role="group">
                    <div className="flex-row">
                      <input
                        type="radio"
                        value="immediately"
                        name="broadcast_option"
                        checked={sendCommOption === 'immediately'}
                        onChange={(e) => setSendCommOption(e.target.value)}
                      />
                      <label
                        htmlFor="immediately"
                        className="ml-2 text-gray-500"
                      >
                        Immediately
                      </label>
                    </div>
                    <div className="flex-row">
                      <input
                        type="radio"
                        value="send_at"
                        name="broadcast_option"
                        checked={sendCommOption === 'send_at'}
                        onChange={(e) => setSendCommOption(e.target.value)}
                      />
                      <label htmlFor="send_at" className="ml-2 text-gray-500">
                        Send at
                      </label>
                    </div>
                  </div>
                  {sendCommOption === 'send_at' ? (
                    <div className="flex flex-row mt-3 mb-10">
                      <DatePicker
                        name="broadcastTime"
                        selected={values.broadcastTime}
                        onChange={(value: Date) =>
                          setFieldValue('broadcastTime', value)
                        }
                        startDate={values.broadcastTime}
                        className="p-2 border border-gray-400"
                        showYearDropdown
                        showMonthDropdown
                        // popperModifiers={{
                        //   preventOverflow: {
                        //     enabled: true,
                        //   },
                        // }}
                        customInput={<CustomDateInput icon={faCalendarAlt} />}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        minDate={new Date()}
                        showTimeInput
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              {errors.broadcastTime && touched.broadcastTime ? (
                <p className="mt-2 text-red">{errors.broadcastTime}</p>
              ) : null}
              <footer className="flex justify-end pb-4 pl-4 pr-4">
                <Button
                  type="submit"
                  className="mr-2 rounded bg-primary"
                  disabled={isSubmitting}
                >
                  Send
                </Button>
                <Button
                  className="bg-white border rounded border-primary"
                  onClick={onClose}
                  style={{ color: Colors.primary }}
                >
                  Cancel
                </Button>
              </footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default ModalSendEmail
