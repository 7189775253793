import { SVGProps } from 'react'

const Logo = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg x="0px" y="0px" viewBox="0 0 571.3 58.8" {...props}>
    <path
      fill="currentColor"
      d="M61.8,22.2v36.6h-8.3v-45h8.3v7.9c0.1-1.1,0.3-2.1,0.8-3.1c0.5-1,1.1-1.8,1.8-2.5c0.7-0.7,1.6-1.3,2.6-1.7
        c1-0.4,2-0.6,3.2-0.6H79c1.2,0,2.2,0.2,3.2,0.7c1,0.4,1.9,1,2.6,1.8c0.8,0.7,1.4,1.6,1.8,2.6c0.4,1,0.7,2.1,0.7,3.2v7.6H79v-7.6
        H61.8z"
    />
    <path
      fill="currentColor"
      d="M210.1,58.8h-8.3V22.2h-23.2v36.6h-8.3v-45h8.3v7.9c0.1-1.1,0.3-2.1,0.8-3.1c0.5-1,1-1.8,1.8-2.5
        c0.7-0.7,1.6-1.3,2.6-1.7c1-0.4,2-0.6,3.2-0.6h14.9c1.2,0,2.2,0.2,3.2,0.7c1,0.4,1.9,1,2.6,1.8c0.7,0.7,1.3,1.6,1.8,2.6
        c0.4,1,0.7,2.1,0.7,3.2V58.8z"
    />
    <path
      fill="currentColor"
      d="M365.2,22.2v28.3h21.4v-7.6h8.3v7.6c0,1.2-0.2,2.2-0.7,3.3c-0.4,1-1,1.9-1.8,2.6c-0.7,0.7-1.6,1.3-2.6,1.8
        c-1,0.4-2.1,0.7-3.3,0.7h-21.4c-1.1,0-2.2-0.2-3.2-0.7c-1-0.4-1.9-1-2.6-1.8c-0.7-0.7-1.3-1.6-1.8-2.6c-0.4-1-0.7-2.1-0.7-3.3V22.2
        c0-1.2,0.2-2.2,0.7-3.2c0.4-1,1-1.9,1.8-2.6c0.7-0.7,1.6-1.3,2.6-1.8c1-0.4,2.1-0.7,3.2-0.7h21.4c1.2,0,2.2,0.2,3.3,0.7
        c1,0.4,1.9,1,2.6,1.8c0.7,0.7,1.3,1.6,1.8,2.6c0.4,1,0.7,2.1,0.7,3.2v7.6h-8.3v-7.6H365.2z"
    />
    <path
      fill="currentColor"
      d="M545.9,22.2v36.6h-8.3v-45h8.3v7.9c0.1-1.1,0.3-2.1,0.8-3.1s1-1.8,1.8-2.5c0.7-0.7,1.6-1.3,2.6-1.7
        c1-0.4,2-0.6,3.2-0.6h8.8c1.2,0,2.2,0.2,3.2,0.7c1,0.4,1.9,1,2.6,1.8c0.8,0.7,1.4,1.6,1.8,2.6c0.4,1,0.7,2.1,0.7,3.2v7.6H563v-7.6
        H545.9z"
    />
    <path
      fill="#aa2239"
      d="M258.3,36l16.7-22.2h-10.3l-11.6,15.4l-8.7-11.6c-1.8-2.4-4.6-3.8-7.6-3.8h-5.5L248,36l-17.1,22.7h10.3l12-15.9
        l9,11.9c1.9,2.5,4.8,4,8,4h5.3L258.3,36z"
    />
    <path
      fill="currentColor"
      d="M451,22.2v-8.3h-11.4c-1.1,0-2.2,0.2-3.2,0.6c-1,0.4-1.8,1-2.6,1.7c-0.7,0.7-1.3,1.6-1.8,2.5
        c-0.4,0.9-0.7,1.9-0.7,2.9v-7.8V0H423v13.8v8.3v28.3c0,1.2,0.2,2.2,0.7,3.3c0.4,1,1,1.9,1.8,2.6c0.8,0.7,1.6,1.3,2.7,1.8
        c1,0.4,2.1,0.7,3.2,0.7h14.8v-8.3h-14.8V22.2H451z"
    />
    <path
      fill="currentColor"
      d="M28,22.2v-8.3H16.6c-1.1,0-2.2,0.2-3.2,0.6c-1,0.4-1.8,1-2.6,1.7c-0.7,0.7-1.3,1.6-1.8,2.5
        c-0.4,0.9-0.7,1.9-0.7,2.9v-7.8V0H0v13.8v8.3v28.3c0,1.2,0.2,2.2,0.7,3.3c0.4,1,1,1.9,1.8,2.6c0.8,0.7,1.6,1.3,2.7,1.8
        c1,0.4,2.1,0.7,3.2,0.7h14.8v-8.3H8.3V22.2H28z"
    />
    <path
      fill="currentColor"
      d="M512.4,18.9c-0.4-1-1-1.9-1.8-2.6c-0.7-0.7-1.6-1.3-2.6-1.8c-1-0.4-2.1-0.7-3.2-0.7h-5.9v0h-8.1
        c-1.1,0-2.2,0.2-3.2,0.6c-1,0.4-1.9,1-2.6,1.7c-0.7,0.7-1.3,1.6-1.8,2.5c-0.5,1-0.7,2-0.8,3.1v0.4h-8.3v28.3c0,1.2,0.2,2.2,0.7,3.2
        c0.4,1,1,1.9,1.8,2.6c0.7,0.7,1.6,1.3,2.6,1.8c1,0.4,2.1,0.7,3.2,0.7h22.3c1.2,0,2.2-0.2,3.2-0.7c1-0.4,1.9-1,2.6-1.8
        c0.7-0.7,1.3-1.6,1.8-2.6c0.4-1,0.7-2.1,0.7-3.2V22.2C513.1,21,512.9,19.9,512.4,18.9 M504.8,50.5h-22.3V22.2h19.7h2.7V50.5z"
    />
    <path
      fill="currentColor"
      d="M332,18.9c-0.4-1-1-1.9-1.8-2.6s-1.6-1.3-2.6-1.8c-1-0.4-2.1-0.7-3.2-0.7h-27.1v8.3h27.1v8.7h0h-21.5
        c-1.1,0-2.2,0.2-3.2,0.7c-1,0.4-1.9,1-2.6,1.8c-0.7,0.8-1.3,1.6-1.8,2.6c-0.4,1-0.7,2.1-0.7,3.2v11.3c0,1.2,0.2,2.2,0.7,3.2
        c0.4,1,1,1.9,1.8,2.6c0.7,0.7,1.6,1.3,2.6,1.8c1,0.4,2.1,0.7,3.2,0.7H316c1.1,0,2.1-0.2,3-0.6c1-0.4,1.8-0.9,2.6-1.6
        s1.4-1.5,1.8-2.4c0.5-0.9,0.8-1.9,0.8-3v7.6h8.3V22.2C332.6,21,332.4,19.9,332,18.9 M324.3,50.5h-21.4V39.2v0h13.3
        c1.1,0,2.1-0.2,3-0.6c1-0.4,1.9-1,2.6-1.7c0.7-0.7,1.3-1.6,1.8-2.5c0.4-0.9,0.7-1.8,0.7-2.8v7.7h0V50.5z"
    />
    <path
      fill="currentColor"
      d="M146.2,18.9c-0.4-1-1-1.9-1.8-2.6c-0.7-0.7-1.6-1.3-2.6-1.8c-1-0.4-2.1-0.7-3.2-0.7h-27.1v8.3h27.1v8.7h0h-21.5
        c-1.1,0-2.2,0.2-3.2,0.7c-1,0.4-1.9,1-2.6,1.8c-0.7,0.8-1.3,1.6-1.8,2.6c-0.4,1-0.7,2.1-0.7,3.2v11.3c0,1.2,0.2,2.2,0.7,3.2
        c0.4,1,1,1.9,1.8,2.6c0.7,0.7,1.6,1.3,2.6,1.8c1,0.4,2.1,0.7,3.2,0.7h13.2c1.1,0,2.1-0.2,3-0.6c1-0.4,1.8-0.9,2.6-1.6
        s1.4-1.5,1.8-2.4c0.5-0.9,0.8-1.9,0.8-3v7.6h8.3V22.2C146.8,21,146.6,19.9,146.2,18.9 M138.5,50.5h-21.4V39.2v0h13.3
        c1.1,0,2.1-0.2,3-0.6c1-0.4,1.9-1,2.6-1.7c0.7-0.7,1.3-1.6,1.8-2.5c0.4-0.9,0.7-1.8,0.7-2.8v7.7h0V50.5z"
    />
  </svg>
)

export default Logo
