import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const FinancialsDefaultSchemas = DefaultSchemas

export const BankAccountsDataSchemas = zod.object({
  apId: zod.number(),
  bankAccountId: zod.number(),
  bankAccountName: zod.string().nullable(),
  bankAccountNumber: zod.string(),
  bankName: zod.string().nullable(),
  firstTrans: zod.string().nullable(),
  lastTrans: zod.string().nullable(),
  note: zod.string().nullable(),
  status: zod.string(),
  transSum: zod.number().nullable(),
})

export const BankAccountsAPISchemas = FinancialsDefaultSchemas.merge(
  zod.object({
    bankAccounts: zod.array(BankAccountsDataSchemas),
  })
)

export const BankAccountsFetchDataSchemas = zod.object({
  traderId: zod.number(),
})

export const PaymentInstructionsDataSchemas = zod.object({
  id: zod.number(),
  traderId: zod.number(),
  instructionName: zod.string(),
  bankAccountName: zod.string(),
  bankAccountNumber: zod.string(),
  paymentAmount: zod.number(),
  frequencyId: zod.number(),
  frequencyName: zod.string(),
  firstPaymentDate: zod.string(),
  endPaymentDate: zod.string(),
  nextPaymentDate: zod.string(),
  optMode: zod.number(),
  suspendFrom: zod.string().nullable(),
  suspendTo: zod.string().nullable(),
  reminderToContact: zod.string().nullable(),
  skipFrom: zod.string().nullable(),
  reasonId: zod.number(),
  reasonName: zod.string().nullable(),
  comment: zod.string().nullable(),
})

export const PaymentInstructionsAPISchemas = FinancialsDefaultSchemas.merge(
  zod.object({
    directPaymentInstruction: zod.array(PaymentInstructionsDataSchemas),
  })
)

export const PaymentInstructionsFetchDataSchemas = zod.object({
  traderId: zod.number(),
})
