import { faCircle, faLessThan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import useMerchantStore from 'store/merchant'

const Index = (): JSX.Element => {
  const merchantProfile = useMerchantStore((state) => state.merchantProfile)
  const merchantProfileModStatus = useMerchantStore(
    (state) => state.merchantProfileModStatus
  )

  if (merchantProfileModStatus !== 'success') {
    return <></>
  }

  return (
    <div className="flex flex-col gap-1.5 mb-6">
      <p className="flex flex-col gap-0">
        <span className="text-xs uppercase font-light tracking-wider">
          Merchant Status
        </span>
        {merchantProfile.map((item) => (
          <span key={item.merchantTraderId} className="font-bold uppercase">
            {/* 1 = status registered/current */}
            {[1].includes(item.memberThorStatusId) ? (
              <Icon icon={faCircle} color="green" />
            ) : (
              <Icon icon={faCircle} color="red" />
            )}
            &nbsp; {item.memberThorStatus}
          </span>
        ))}
      </p>
    </div>
  )
}

export default Index
