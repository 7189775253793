import DashboardLoader from 'components/DashboardLoader'
import Counter from '../Counter'
import CountersContainer from '../CountersContainer'
import DashboardCard from '../DashboardCard'
import SeeAll from '../SeeAll'

import TransactionsItem from './TransactionsItem'

import { useMemberTransactions } from './useMemberTransactions'

export const Transactions = (): JSX.Element => {
  const {
    memberTransactionsSummary,
    memberTransactions,
    memberTransactionsModStatus,
  } = useMemberTransactions()

  const isLoading = memberTransactionsModStatus === 'loading' || false
  const transactionsRecent = memberTransactions.slice(0, 3)
  const isTransactions = transactionsRecent.length ? true : false

  return (
    <DashboardCard title="Transactions">
      <CountersContainer>
        <Counter
          isValidating={isLoading}
          label="Total transactions"
          value={memberTransactionsSummary.overallCount.toString()}
        />

        <Counter
          isValidating={isLoading}
          label="Transactions last 30 days"
          value={memberTransactionsSummary.last30daysCount.toString()}
        />
      </CountersContainer>

      <div className="flex flex-col flex-grow gap-1">
        <p className="tracking-wider font-light mb-4">Recent Transactions</p>

        {isLoading ? (
          <DashboardLoader />
        ) : !isTransactions ? (
          <p className="py-2">
            <span className="tracking-wider font-light">
              No transactions available
            </span>
          </p>
        ) : (
          <>
            {transactionsRecent.map((item) => (
              <div
                key={item.transactionId}
                className="flex justify-between p-4 hover:bg-primary hover:bg-opacity-10 rounded-lg"
              >
                <TransactionsItem transaction={item} />
              </div>
            ))}
          </>
        )}
      </div>
      {isTransactions ? (
        <SeeAll url={'transactions'} label="Transactions" />
      ) : null}
    </DashboardCard>
  )
}
