import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import { updateMemberStatusAPI } from 'api/members'
import {
  MemberStatusUpdateFormTypes,
  MemberStatusUpdateFormSubmitValuesTypes,
  MemberStatusUpdateFormSubmitActionsTypes,
  MemberStatuses,
} from 'types/member'
import { MemberDetail } from 'types/member'
import useMemberStore from 'store/member'

const UPDATESTATUSINITVAL: MemberStatusUpdateFormTypes = {
  newStatusId: undefined,
}

export const useProfileUpdate = (
  traderId: number
): {
  member: MemberDetail | undefined
  memberStatuses: MemberStatuses[]
  isLoading: boolean
  initialValues: MemberStatusUpdateFormTypes
  handleSubmitStatusUpdate: (
    values: MemberStatusUpdateFormSubmitValuesTypes,
    actions: MemberStatusUpdateFormSubmitActionsTypes
  ) => void
  handleClearProfileUpdate: () => void
} => {
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // STATES
  const [isLoading, isLoadingSet] = useState<boolean>(false)
  const member = useMemberStore((state) => state.member)
  const memberStatuses = useMemberStore((state) => state.memberStatuses)
  const getMemberStore = useMemberStore((state) => state.getMember)
  const getMemberStatuses = useMemberStore((state) => state.getMemberStatuses)
  const [initialValues, initialValuesSet] =
    useState<MemberStatusUpdateFormTypes>({
      ...UPDATESTATUSINITVAL,
      newStatusId: member?.memberStatusId,
    })

  useEffect(() => {
    getMemberStatuses(String(traderId))
  }, [])

  const handleSubmitStatusUpdate = useCallback(
    async (
      values: MemberStatusUpdateFormSubmitValuesTypes,
      actions: MemberStatusUpdateFormSubmitActionsTypes
    ) => {
      isLoadingSet(true)
      try {
        // if status is not cancelled / terminated
        await updateMemberStatusAPI({
          traderId: String(traderId),
          newStatusId: String(values.newStatusId),
        })
        await getMemberStore(String(traderId))
        // initialValuesSet(values)
        toast.success('Merchant status update successful.')
        // actions.setSubmitting(false)
      } catch (error) {
        // const message = JSON.parse(JSON.stringify(error))
        toast.error(`Merchant status update error.`)
      }
      isLoadingSet(false)
    },
    [isLoadingSet, getMemberStore, initialValuesSet]
  )

  const handleClearProfileUpdate = useCallback(() => {
    initialValuesSet({ ...UPDATESTATUSINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    member,
    memberStatuses,
    isLoading,
    initialValues,
    handleSubmitStatusUpdate,
    handleClearProfileUpdate,
  }
}
