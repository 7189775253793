import { Controller } from 'react-hook-form'

import { FormsLayout } from 'components/Layout/Forms'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import { InputField } from 'components/InputField'
import Datepicker, { useDatepicker } from 'components/Datepicker'

import { formatDateToShort } from 'services/helpers'

import { useFrequency } from './useFrequency'
import { useBankAccount } from './useBankAccount'
import { usePaymentInstructionTxForm } from './usePaymentInstructionTxForm'
import { usePaymentInstructionTx } from './usePaymentInstructionTx'

import { DropDownItem } from 'types/components'

const Index = ({ onCloseModal }: { onCloseModal: () => void }): JSX.Element => {
  const { frequency, frequencyAsOptions } = useFrequency()
  const { bankaccountsAsOptions } = useBankAccount()
  const { control, errors, handleSubmit, setValue, watch } =
    usePaymentInstructionTxForm()
  const { onSubmitAddPaymentInstruction, onLoadingAddPaymentInstruction } =
    usePaymentInstructionTx({
      onSubmitSuccess: onCloseModal,
      paymentFrequency: frequency,
    })

  const { startDate, endDate, DatepickerInput, handlePick } = useDatepicker()

  return (
    <FormsLayout.Wrapper onSubmit={handleSubmit(onSubmitAddPaymentInstruction)}>
      <div className="flex gap-4">
        <FormsLayout.Item>
          <Controller
            name="instructionName"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                type="input"
                name="bankAccountNumber"
                label="Instruction Name"
                containerClassName="flex flex-col flex-grow"
                rounded
                error={errors.instructionName?.message}
                onChange={(e) => {
                  field.onChange(String(e.target.value))
                }}
              />
            )}
          />
        </FormsLayout.Item>
      </div>

      <div className="flex gap-4">
        <FormsLayout.Item>
          <Controller
            name="paymentAmount"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                type="input"
                name="bankAccountNumber"
                label="Amount"
                containerClassName="flex flex-col flex-grow"
                rounded
                error={errors.paymentAmount?.message}
                onChange={(e) => {
                  field.onChange(parseFloat(e.target.value))
                }}
              />
            )}
          />
        </FormsLayout.Item>
        <FormsLayout.Item>
          <>
            <Controller
              name="bankAccountNumber"
              control={control}
              render={({ field }) => (
                <Dropdown
                  title="Bank Account"
                  // value={values.status}
                  options={bankaccountsAsOptions}
                  onSelect={(item: DropDownItem) =>
                    field.onChange(String(item.value))
                  }
                  className="w-full tracking-wider"
                  containerClassName="mb-5"
                  borderColor="#C4C4C4"
                />
              )}
            />
            {errors.paymentAmount ? (
              <span className="text-xs text-red tracking-wider">
                {errors.bankAccountNumber?.message}
              </span>
            ) : null}
          </>
        </FormsLayout.Item>
      </div>

      <FormsLayout.Item>
        <>
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <Dropdown
                title="Payment Frequency"
                // value={values.status}
                options={frequencyAsOptions}
                onSelect={(item: DropDownItem) =>
                  field.onChange(parseInt(item.value))
                }
                className="w-full tracking-wider"
                containerClassName="mb-5"
                borderColor="#C4C4C4"
              />
            )}
          />
          {errors.frequency ? (
            <span className="text-xs text-red tracking-wider">
              {errors.frequency?.message}
            </span>
          ) : null}
        </>
      </FormsLayout.Item>

      <FormsLayout.Item>
        <>
          <Controller
            name="firstPaymentDate"
            control={control}
            render={({ field }) => (
              <Datepicker
                title="Payment Date Start to End"
                name="dateRange"
                onChange={(dates: [Date, Date]) => {
                  handlePick(dates)
                  setValue(
                    'firstPaymentDate',
                    dates[0] ? formatDateToShort(dates[0]) : ''
                  )
                  setValue(
                    'endPaymentDate',
                    dates[1] ? formatDateToShort(dates[1]) : ''
                  )
                }}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                showYearDropdown
                scrollableYearDropdown
                className="p-2 border border-gray-400 tracking-wider z-50"
                placeholderText="--"
                customInput={<DatepickerInput />}
                selectsRange
              />
            )}
          />
          {errors.firstPaymentDate || errors.endPaymentDate ? (
            <div className="mt-5">
              <span className="text-xs text-red tracking-wider">
                Payment Dates are Required
              </span>
            </div>
          ) : null}
        </>
      </FormsLayout.Item>

      <FormsLayout.ActionWrapper>
        <Button
          key="submit"
          iconRight={false}
          type="submit"
          // disabled={}
          // loading={}
          className="rounded bg-primary tracking-wider font-bold"
        >
          {onLoadingAddPaymentInstruction ? 'Adding ...' : 'Add'}
        </Button>
        {/* {isMemberOffersFiltered ? (
            <Button
              key="clear"
              iconRight={false}
              type="button"
              className="rounded-lg"
              onClick={() => {
                handleMemberOffersFilterClear()
                reset()
              }}
            >
              <span className="tracking-wider font-bold">Clear</span>
            </Button>
          ) : null} */}
      </FormsLayout.ActionWrapper>
    </FormsLayout.Wrapper>
  )
}

export default Index
