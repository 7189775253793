import { useCallback, useEffect, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { FormikHelpers } from 'formik'

import { TRANSACTION_TYPES } from 'constants/filters'
import { formatDateToShort } from 'services/helpers'
import {
  TransactionFilterFormSubmitValuesTypes,
  TransactionFilterFormTypes,
} from 'types/transactions'
import useMerchantStore from 'store/merchant'
import { MerchantIdentificationTypes } from 'types/merchant'

const TransactionFilterINITVAL: TransactionFilterFormTypes = {
  transactionType: TRANSACTION_TYPES[0],
  merchant: '',
  startDateRange: new Date(),
  endDateRange: new Date(),
}

const useTransactions = ({
  merchantTraderId,
}: {
  merchantTraderId: number
}) => {
  const [isMerchantIdentificationEdit, isMerchantIdentificationEditSet] =
    useState<boolean>(false)
  const [selectedMerchantIdentification, selectedMerchantIdentificationSet] =
    useState<MerchantIdentificationTypes | null>(null)
  const merchantIdentification = useMerchantStore(
    (state) => state.merchantIdentification
  )
  const merchantIdentificationLoading = useMerchantStore(
    (state) => state.merchantIdentificationLoading
  )
  const getMerchantIdentification = useMerchantStore(
    (state) => state.getMerchantIdentification
  )
  const merchantIdentificationStatuses = useMerchantStore(
    (state) => state.merchantIdentificationStatuses
  )
  const merchantIdentificationStatusesLoading = useMerchantStore(
    (state) => state.merchantIdentificationStatusesLoading
  )
  const getMerchantIdentificationStatuses = useMerchantStore(
    (state) => state.getMerchantIdentificationStatuses
  )
  // const setFilteredTransactionsStatus = useMerchantStore(
  //   (state) => state.setFilteredTransactionsStatus
  // )

  useEffect(() => {
    getMerchantIdentificationStatuses()
    getMerchantIdentification(merchantTraderId)
    // setFilteredTransactionsStatus(false)
  }, [])

  return {
    isMerchantIdentificationEdit,
    isMerchantIdentificationEditSet,
    selectedMerchantIdentification,
    selectedMerchantIdentificationSet,
    merchantIdentification,
    merchantIdentificationLoading,
    merchantIdentificationStatuses,
    merchantIdentificationStatusesLoading,
  }
}

export default useTransactions
