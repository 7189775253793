import InnerWrapper from '../InnerWrapper'
import Modal from 'components/Modal'
import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import TSTable from 'components/Tanstack'
import { ToggleMenu } from 'components/Layout/ToggleMenu'

import PaymentInstructionUpdateModalForm from './PaymentInstructionUpdateModalForm'
import PaymentInstructionSkipModalForm from './PaymentInstructionSkipModalForm'
import PaymentInstructionCancelModalForm from './PaymentInstructionCancelModalForm'
import { useMemberDirectPayments } from './useMemberDirectPayments'
import { useMemberDirectPaymentsTable } from './useMemberDirectPaymentsTable'

import Actions from './Actions'

function DirectPayments(): JSX.Element {
  const { memberDirectPayments, memberDirectPaymentsModStatus } =
    useMemberDirectPayments()

  const {
    memberDirectPaymentsSelected,
    memberDirectPaymentsActionModal,
    memberDirectPaymentsTableData,
    memberDirectPaymentsTableColumns,
    handleMemberActionModalClose,
  } = useMemberDirectPaymentsTable()

  return (
    <>
      <InnerWrapper title="Direct Payment Instructions">
        {/* FAIL */}
        {memberDirectPaymentsModStatus === 'fail' ? (
          <NoResults
            header="Failed to load data"
            subtitle="Please refresh the page and try again, or contact
        your system administrator"
            noImage
          />
        ) : null}

        {/* LOADING */}
        {memberDirectPaymentsModStatus === 'loading' ? <Spinner /> : null}

        {/* SUCCESS */}
        {memberDirectPayments.length &&
        memberDirectPaymentsModStatus === 'success' ? (
          <>
            <Actions />
            <TSTable.Table
              data={memberDirectPaymentsTableData}
              columns={memberDirectPaymentsTableColumns}
            />
          </>
        ) : null}

        {/* EMPTY */}
        {!memberDirectPayments.length &&
        memberDirectPaymentsModStatus === 'success' ? (
          <><Actions />
            <NoResults
              header="No available data"
              subtitle="There is no available data to display"
              noImage /></>
        ) : null}
      </InnerWrapper>

      {/* UPDATE PAYMENT INSTRUCTIONS */}
      {memberDirectPaymentsActionModal ===
        'financials_paymentinstruction_update' &&
      memberDirectPaymentsSelected ? (
        <Modal
          open={true}
          title="Update Payment Instruction"
          onClose={handleMemberActionModalClose}
          noFooter
        >
          <PaymentInstructionUpdateModalForm
            modalClose={handleMemberActionModalClose}
            selectedRowData={memberDirectPaymentsSelected}
          />
        </Modal>
      ) : null}

      {/* SKIP PAYMENT INSTRUCTIONS */}
      {memberDirectPaymentsActionModal ===
        'financials_paymentinstruction_skip' && memberDirectPaymentsSelected ? (
        <Modal
          open={true}
          title="Skip Payment Instruction"
          onClose={handleMemberActionModalClose}
          noFooter
        >
          <PaymentInstructionSkipModalForm
            modalClose={handleMemberActionModalClose}
            selectedRowData={memberDirectPaymentsSelected}
          />
        </Modal>
      ) : null}

      {/* CANCEL PAYMENT INSTRUCTIONS */}
      {memberDirectPaymentsActionModal ===
        'financials_paymentinstruction_cancel' &&
      memberDirectPaymentsSelected ? (
        <Modal
          open={true}
          title="Cancel Payment Instruction"
          onClose={handleMemberActionModalClose}
          noFooter
        >
          <PaymentInstructionCancelModalForm
            modalClose={handleMemberActionModalClose}
            selectedRowData={memberDirectPaymentsSelected}
          />
        </Modal>
      ) : null}
    </>
  )
}

export default DirectPayments
