import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import { purchaseRedemptionReversal } from 'api/purchase'
import { MerchantLookupTypes } from 'types/merchanTxTypes'
import {
  PurchaseReversalTxFormTypes,
  PurchaseReversalTxFormSubmitValuesTypes,
  PurchaseReversalTxFormSubmitActionsTypes,
} from 'types/purchaseReversalTxTypes'
import { MemberDetail } from 'types/member'
import useMemberStore from 'store/member'

const PURCHASEREDEMPTIONTXINITVAL: PurchaseReversalTxFormTypes = {
  externalTransactionId: '',
  ident: '',
  storeId: 0,
  cashier: '',
  comments: '',
  traceNumber: 0,
  reasonId: 0,
}

export const usePurchaseReversalTx = (
  traderId: number,
  onCloseModal: () => void
): {
  member: MemberDetail | undefined
  isLoading: boolean
  initialValues: PurchaseReversalTxFormTypes
  isMerchantLookupToggle: boolean
  handleIsMerchantLookupToggle: () => void
  handleSubmitPurchaseReversalTx: (
    values: PurchaseReversalTxFormSubmitValuesTypes,
    actions: PurchaseReversalTxFormSubmitActionsTypes
  ) => void
  handleClearPurchaseReversalTx: () => void
} => {
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // STATES
  const [isMerchantLookupToggle, isMerchantLookupToggleSet] =
    useState<boolean>(false)
  const [isLoading, isLoadingSet] = useState<boolean>(false)
  const [purchaseTxMerchant, purchaseTxMerchantSet] = useState<
    MerchantLookupTypes[]
  >([])
  const member = useMemberStore((state) => state.member)
  const [initialValues, initialValuesSet] =
    useState<PurchaseReversalTxFormTypes>({
      ...PURCHASEREDEMPTIONTXINITVAL,
      cashier: authTokenUsername,
      externalTransactionId: '',
      ident: String(member?.cardNumber) || '',
    })

  const handleIsMerchantLookupToggle = useCallback(() => {
    isMerchantLookupToggleSet(!isMerchantLookupToggle)
  }, [isMerchantLookupToggle, isMerchantLookupToggleSet])

  const handleSubmitPurchaseReversalTx = useCallback(
    async (
      values: PurchaseReversalTxFormSubmitValuesTypes,
      actions: PurchaseReversalTxFormSubmitActionsTypes
    ) => {
      isLoadingSet(true)
      try {
        await purchaseRedemptionReversal(values)
        toast.success('Purchase reversal successful.')
        // actions.setSubmitting(false)
        onCloseModal()
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        toast.error(`ERR: ${message.outcomeUserMessage}`)
      }
      isLoadingSet(false)
    },
    [isLoadingSet]
  )

  const handleClearPurchaseReversalTx = useCallback(() => {
    initialValuesSet({ ...PURCHASEREDEMPTIONTXINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    member,
    isLoading,
    initialValues,
    isMerchantLookupToggle,
    handleIsMerchantLookupToggle,
    handleSubmitPurchaseReversalTx,
    handleClearPurchaseReversalTx,
  }
}
