import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { HistoryDataTypes, HistoryStatusesDataTypes } from 'types/histories'

interface HistoryStoreTypes {
  historyModErrors: StatusErrorTypes
  historyModErrorsSet: (historyModErrors: StatusErrorTypes) => void
  historyModStatus: StatusTypes
  historyModStatusSet: (historyStatus: StatusTypes) => void
  histories: HistoryDataTypes[]
  historySet: (histories: HistoryDataTypes[]) => void
  historyLookup: HistoryDataTypes[]
  historyLookupSet: (histories: HistoryDataTypes[]) => void
}

export const useHistoryStore = create<HistoryStoreTypes>((set) => ({
  historyModErrors: '',
  historyModErrorsSet: (historyModErrors) => {
    set({
      historyModErrors,
    })
  },
  historyModStatus: 'idle',
  historyModStatusSet: (historyModStatus) => {
    set({
      historyModStatus,
    })
  },
  histories: [],
  historySet: (histories) => {
    set({
      histories,
    })
  },
  historyLookup: [],
  historyLookupSet: (historyLookup) => {
    set({
      historyLookup,
    })
  },
}))

interface HistoryLookupStoreTypes {
  historyLookupModErrors: StatusErrorTypes
  historyLookupModErrorsSet: (historyLookupModErrors: StatusErrorTypes) => void
  historyLookupModStatus: StatusTypes
  historyLookupModStatusSet: (historyLookupStatus: StatusTypes) => void
  historyLookup: HistoryDataTypes[]
  historyLookupSet: (historyLookup: HistoryDataTypes[]) => void
}

export const useHistoryLookupStore = create<HistoryLookupStoreTypes>((set) => ({
  historyLookupModErrors: '',
  historyLookupModErrorsSet: (historyLookupModErrors) => {
    set({
      historyLookupModErrors,
    })
  },
  historyLookupModStatus: 'idle',
  historyLookupModStatusSet: (historyLookupModStatus) => {
    set({
      historyLookupModStatus,
    })
  },
  historyLookup: [],
  historyLookupSet: (historyLookup) => {
    set({
      historyLookup,
    })
  },
}))

interface HistoryStatusesStoreTypes {
  historyStatusesModErrors: StatusErrorTypes
  historyStatusesModErrorsSet: (
    historyStatusesModErrors: StatusErrorTypes
  ) => void
  historyStatusesModStatus: StatusTypes
  historyStatusesModStatusSet: (historyStatusesStatus: StatusTypes) => void
  historyStatuses: HistoryStatusesDataTypes[]
  historyStatusesSet: (historyStatuses: HistoryStatusesDataTypes[]) => void
  historyStatusesLookup: HistoryStatusesDataTypes[]
  historyStatusesLookupSet: (
    historyStatuses: HistoryStatusesDataTypes[]
  ) => void
}

export const useHistoryStatusesStore = create<HistoryStatusesStoreTypes>(
  (set) => ({
    historyStatusesModErrors: '',
    historyStatusesModErrorsSet: (historyStatusesModErrors) => {
      set({
        historyStatusesModErrors,
      })
    },
    historyStatusesModStatus: 'idle',
    historyStatusesModStatusSet: (historyStatusesModStatus) => {
      set({
        historyStatusesModStatus,
      })
    },
    historyStatuses: [],
    historyStatusesSet: (historyStatuses) => {
      set({
        historyStatuses,
      })
    },
    historyStatusesLookup: [],
    historyStatusesLookupSet: (historyStatusesLookup) => {
      set({
        historyStatusesLookup,
      })
    },
  })
)
