import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getVouchersStatusesAPI } from 'api/vouchers'
import { useVouchersStatusesStore } from 'store/vouchers'
import { DropDownItem } from 'types/components'

import { DATASTATUS } from 'constants/status'

export const useMemberVouchersStatuses = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const vouchersStatusesModStatus = useVouchersStatusesStore(
    (state) => state.vouchersStatusesModStatus
  )
  const vouchersStatusesModStatusSet = useVouchersStatusesStore(
    (state) => state.vouchersStatusesModStatusSet
  )
  const vouchersStatusesModErrors = useVouchersStatusesStore(
    (state) => state.vouchersStatusesModErrors
  )
  const vouchersStatusesModErrorsSet = useVouchersStatusesStore(
    (state) => state.vouchersStatusesModErrorsSet
  )
  const vouchersStatuses = useVouchersStatusesStore(
    (state) => state.vouchersStatuses
  )
  const vouchersStatusesSet = useVouchersStatusesStore(
    (state) => state.vouchersStatusesSet
  )

  const {
    data: dataVouchers,
    isLoading: isLoadingVouchers,
    isError: isErrorVouchers,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useVouchersStatuses'],
    queryFn: async () => {
      try {
        vouchersStatusesModStatusSet('loading')
        const response = await getVouchersStatusesAPI()
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        vouchersStatusesModStatusSet('success')
        return response.vouchersStatus
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)), ' ERR')
        vouchersStatusesModStatusSet('fail')
        vouchersStatusesModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataVouchers) vouchersStatusesSet(dataVouchers)
  }, [dataVouchers, vouchersStatusesSet])

  const vouchersStatusesOptions: DropDownItem[] = useMemo(() => {
    return vouchersStatuses.map((item) => ({
      label: String(item.label),
      value: String(item.label),
    }))
  }, [vouchersStatuses])

  return {
    memberVouchersStatusesOptions: vouchersStatusesOptions,
    memberVouchersStatuses: vouchersStatuses,
    memberVouchersStatusesModStatus: vouchersStatusesModStatus,
  }
}
