import classnames from 'classnames'

export const TextareaField = ({
  name,
  label,
  placeholder,
  error,
  defaultValue,
  helper,
  helperDescription,
  rounded,
  className,
  filter,
  containerClassName,
  readOnly,
  onChange,
}: {
  name: string
  label: string
  placeholder?: string
  error?: string
  defaultValue?: string
  helper?: string
  helperDescription?: string
  options?: { name: string; value: string }[]
  rounded?: boolean
  className?: string
  filter?: boolean
  containerClassName?: string
  readOnly?: boolean
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onValueChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}): JSX.Element => {
  return (
    <div className="flex flex-col gap-2 w-full">
      {label ? (
        <label
          htmlFor={name}
          className="flex flex-col gap-2 text-sm tracking-wider font-light w-full"
        >
          <span>{label}</span>
        </label>
      ) : null}
      {helper ? (
        <label
          htmlFor={name}
          className="flex flex-col gap-2 text-sm tracking-wider font-light w-full"
        >
          <span>{helper}</span>
        </label>
      ) : null}
      <textarea
        readOnly={readOnly}
        defaultValue={defaultValue}
        onChange={onChange}
        id={name}
        placeholder={placeholder}
        className="block border p-2 text-black w-full tracking-wider rounded"
      />
      {/* {error && <label className="text-red-400">{error}</label>} */}
      {error ? (
        <div className={'text-red text-xs mt-1 tracking-wider'} role="alert">
          {error}
        </div>
      ) : null}
    </div>
  )
}
