import { http } from 'utils'

import {
  IdentificationsAPITypes,
  IdentificationsDataTypes,
  IdentificationsFetchParamsTypes,
  IdentificationsHampstaAddReplaceParamsTypes,
  IdentificationsMergeParamsTypes,
  UpdateIdentificationsStatusTypes,
  IdentificationsStatusTypes,
  IdentificationsStatusesAPITypes,
  IdentificationsOwnershipsTypes,
  IdentificationsTypesTypes,
  UpdateIdentificationsMergeTypes,
  IdentificationsDefaultTypes,
} from 'types/identifications'
import {
  IdentificationsAPISchemas,
  IdentificationsStatusesAPISchemas,
} from 'schemas/identificationsSchemas'

import { DATASTATUS } from 'constants/status'

export async function getIdentificationsAPI({
  traderId,
  ownershipMode,
  ident,
  identTypeId,
  identStatusId,
  accountId,
  pSize,
  pNumber,
}: IdentificationsFetchParamsTypes): Promise<IdentificationsAPITypes> {
  let url = `/idents/${traderId}`
  const params = new URLSearchParams()

  if (ownershipMode) {
    params.set('ownershipMode', ownershipMode.toString())
  }

  if (ident) {
    params.set('ident', ident)
  }

  if (identTypeId) {
    params.set('identTypeId', identTypeId.toString())
  }

  if (identStatusId) {
    params.set('identStatusId', identStatusId.toString())
  }

  if (accountId) {
    params.set('accountId', accountId.toString())
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = IdentificationsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      idents: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getIdentificationsStatusesAPI(): Promise<IdentificationsStatusesAPITypes> {
  let url = `/idents/status`
  const params = new URLSearchParams()

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = IdentificationsStatusesAPISchemas.safeParse(
    response.data
  )
  if (!validatedData.success) {
    return {
      identStatus: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getIdentificationsOwnershipsAPI(): Promise<
  IdentificationsOwnershipsTypes[]
> {
  let url = `/idents/ownership-mode`
  const params = new URLSearchParams()

  url += `?${params.toString()}`

  const response = await http.get<{
    identOwnershipMode: IdentificationsOwnershipsTypes[]
  }>(url)
  const items = response.data.identOwnershipMode.map((record) => record)

  return items
}

export async function getIdentificationsTypesAPI(): Promise<
  IdentificationsTypesTypes[]
> {
  let url = `/idents/type`
  const params = new URLSearchParams()

  url += `?${params.toString()}`

  const response = await http.get<{
    identType: IdentificationsTypesTypes[]
  }>(url)
  const items = response.data.identType.map((record) => record)

  return items
}

export async function updateIdentificationsStatusAPI({
  traderId,
  ident,
  identTypeId,
  identStatusId,
  identId,
}: UpdateIdentificationsStatusTypes): Promise<IdentificationsDefaultTypes> {
  let url = `/idents/${traderId}/changeStatus`
  const params = new URLSearchParams()

  params.set('ident', ident)
  params.set('identTypeId', identTypeId.toString())
  params.set('identStatusId', identStatusId.toString())
  params.set('identId', identId.toString())

  url += `?${params.toString()}`

  const response = await http.put(url)

  return response.data
}

export async function updateIdentificationsMergeAPI({
  traderId,
  identAcceptor,
  identDonor,
  addNewCardFlag,
}: IdentificationsMergeParamsTypes): Promise<IdentificationsDefaultTypes> {
  let url = `/idents/merge-customer`
  const params = new URLSearchParams()

  params.set('identAcceptor', String(identAcceptor))
  params.set('identDonor', String(identDonor))
  params.set('addNewCardFlag', String(addNewCardFlag))

  url += `?${params.toString()}`

  const response = await http.put(url)

  return response.data
}

export async function updateIdentificationsHampstaAddReplaceAPI({
  traderId,
  flReplace,
  cardColor,
  name,
  oldCard,
}: IdentificationsHampstaAddReplaceParamsTypes): Promise<IdentificationsDefaultTypes> {
  const url = `/idents/${traderId}/createPrintCard`

  const data = { flReplace, cardColor, name, oldCard }

  const response = await http.post(url, data)

  return response.data
}
