import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { BankAccountsDataTypes } from 'types/financials'
import { PaymentInstructionsDataTypes } from 'types/financials'

interface BankAccountsStoreTypes {
  bankaccountsModErrors: StatusErrorTypes
  bankaccountsModErrorsSet: (bankaccountsModErrors: StatusErrorTypes) => void
  bankaccountsModStatus: StatusTypes
  bankaccountsModStatusSet: (bankaccountsStatus: StatusTypes) => void
  bankaccounts: BankAccountsDataTypes[]
  bankaccountsSet: (bankaccounts: BankAccountsDataTypes[]) => void
  bankaccountsLookup: BankAccountsDataTypes[]
  bankaccountsLookupSet: (bankaccounts: BankAccountsDataTypes[]) => void
}

export const useBankAccountsStore = create<BankAccountsStoreTypes>((set) => ({
  bankaccountsModErrors: '',
  bankaccountsModErrorsSet: (bankaccountsModErrors) => {
    set({
      bankaccountsModErrors,
    })
  },
  bankaccountsModStatus: 'idle',
  bankaccountsModStatusSet: (bankaccountsModStatus) => {
    set({
      bankaccountsModStatus,
    })
  },
  bankaccounts: [],
  bankaccountsSet: (bankaccounts) => {
    set({
      bankaccounts,
    })
  },
  bankaccountsLookup: [],
  bankaccountsLookupSet: (bankaccountsLookup) => {
    set({
      bankaccountsLookup,
    })
  },
}))

export interface BankAccountsStatusesTypes {
  accountStatusId: number
  statusName: string
}

interface BankAccountsStatusesStoreTypes {
  bankAccountsStatusesModErrors: StatusErrorTypes
  bankAccountsStatusesModErrorsSet: (
    bankAccountsStatusesModErrors: StatusErrorTypes
  ) => void
  bankAccountsStatusesModStatus: StatusTypes
  bankAccountsStatusesModStatusSet: (
    bankAccountsStatusesStatus: StatusTypes
  ) => void
  bankAccountsStatuses: BankAccountsStatusesTypes[]
  bankAccountsStatusesSet: (bankAccounts: BankAccountsStatusesTypes[]) => void
}

export const useBankAccountsStatusesStore =
  create<BankAccountsStatusesStoreTypes>((set) => ({
    bankAccountsStatusesModErrors: '',
    bankAccountsStatusesModErrorsSet: (bankAccountsStatusesModErrors) => {
      set({
        bankAccountsStatusesModErrors,
      })
    },
    bankAccountsStatusesModStatus: 'idle',
    bankAccountsStatusesModStatusSet: (bankAccountsStatusesModStatus) => {
      set({
        bankAccountsStatusesModStatus,
      })
    },
    bankAccountsStatuses: [],
    bankAccountsStatusesSet: (bankAccountsStatuses) => {
      set({
        bankAccountsStatuses,
      })
    },
  }))

interface DirectPaymentsStoreTypes {
  directpaymentsaccountsModErrors: StatusErrorTypes
  directpaymentsModErrorsSet: (
    directpaymentsaccountsModErrors: StatusErrorTypes
  ) => void
  directpaymentsModStatus: StatusTypes
  directpaymentsModStatusSet: (directpaymentsStatus: StatusTypes) => void
  directpayments: PaymentInstructionsDataTypes[]
  directpaymentsSet: (directpayments: PaymentInstructionsDataTypes[]) => void
  directpaymentsLookup: PaymentInstructionsDataTypes[]
  directpaymentsLookupSet: (
    directpayments: PaymentInstructionsDataTypes[]
  ) => void
}

export const useDirectPaymentsStore = create<DirectPaymentsStoreTypes>(
  (set) => ({
    directpaymentsaccountsModErrors: '',
    directpaymentsModErrorsSet: (directpaymentsaccountsModErrors) => {
      set({
        directpaymentsaccountsModErrors,
      })
    },
    directpaymentsModStatus: 'idle',
    directpaymentsModStatusSet: (directpaymentsModStatus) => {
      set({
        directpaymentsModStatus,
      })
    },
    directpayments: [],
    directpaymentsSet: (directpayments) => {
      set({
        directpayments,
      })
    },
    directpaymentsLookup: [],
    directpaymentsLookupSet: (directpaymentsLookup) => {
      set({
        directpaymentsLookup,
      })
    },
  })
)
