import { useCallback, useState } from 'react'
import { faIdCard, faSync } from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import TSTable from 'components/Tanstack'
import { useBankAccountsStore } from 'store/financials'
import { useTableUtils } from 'hooks/useTableUtils'
import {
  BankAccountsDataTypes,
  FinancialsModalActionsTypes,
} from 'types/financials'
import { hasingOut } from 'services/helpers'

export const useMemberBankAccountsTable = () => {
  const bankAccounts = useBankAccountsStore((state) => state.bankaccounts)

  const {
    selectedModal,
    selectedModalSet,
    selectedRowData,
    selectedRowDataSet,
  } = useTableUtils<BankAccountsDataTypes, FinancialsModalActionsTypes>()

  const handleActionModalClose = useCallback(() => {
    selectedRowDataSet(null)
    selectedModalSet(null)
  }, [selectedModalSet, selectedRowDataSet])

  const columnHelper = createColumnHelper<BankAccountsDataTypes>()
  const columns = [
    columnHelper.accessor('bankAccountName', {
      header: () => 'Account Name',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('bankAccountNumber', {
      header: () => 'Account Number',
      cell: (info) => hasingOut(info.getValue()),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('apId', {
      header: () => 'APID',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('bankName', {
      header: () => 'Bank Name',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('firstTrans', {
      header: () => 'First Trans',
      cell: (info) => info.getValue() || '---',
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('lastTrans', {
      header: () => 'Last Trans',
      cell: (info) => info.getValue() || '---',
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('transSum', {
      header: () => 'Trans Sum',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => '',
      cell: ({ row }) => (
        <div className="flex gap-2 px-4">
          <TSTable.TableCellButton
            onClick={() => {
              selectedRowDataSet(row.original)
              selectedModalSet('financials_status_update')
            }}
            label="Status Update"
            icon={faIdCard}
          />
        </div>
      ),
    }),
  ] as ColumnDef<BankAccountsDataTypes>[]

  return {
    memberBankAccountsSelected: selectedRowData,
    memberBankAccountsActionModal: selectedModal,
    memberBankAccountsTableData: bankAccounts,
    memberBankAccountsTableColumns: columns,
    handleMemberActionModalClose: handleActionModalClose,
  }
}
