import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getCommsChannelsAPI } from 'api/comms'
import { useCommsChannelsStore } from 'store/comms'
import { DropDownItem } from 'types/components'

import { DATASTATUS } from 'constants/status'

export const useMemberCommsChannels = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const commsChannelsModStatus = useCommsChannelsStore(
    (state) => state.commsChannelsModStatus
  )
  const commsChannelsModStatusSet = useCommsChannelsStore(
    (state) => state.commsChannelsModStatusSet
  )
  const commsChannelsModErrors = useCommsChannelsStore(
    (state) => state.commsChannelsModErrors
  )
  const commsChannelsModErrorsSet = useCommsChannelsStore(
    (state) => state.commsChannelsModErrorsSet
  )
  const commsChannels = useCommsChannelsStore((state) => state.commsChannels)
  const commsChannelsSet = useCommsChannelsStore(
    (state) => state.commsChannelsSet
  )

  const {
    data: dataComms,
    isLoading: isLoadingComms,
    isError: isErrorComms,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useCommsChannels'],
    queryFn: async () => {
      try {
        commsChannelsModStatusSet('loading')
        const response = await getCommsChannelsAPI()
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        commsChannelsModStatusSet('success')
        return response.commsChannel.splice(0, 1).concat(response.commsChannel.splice(1, 4))
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)), ' ERR')
        commsChannelsModStatusSet('fail')
        commsChannelsModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataComms) commsChannelsSet(dataComms)
  }, [dataComms, commsChannelsSet])

  const commsChannelsOptions: DropDownItem[] = useMemo(() => {
    return commsChannels.map((item) => ({
      label: String(item.channelName),
      value: String(item.channelId),
    }))
  }, [commsChannels])

  return {
    membersCommsChannelsOptions: commsChannelsOptions,
    memberCommsChannels: commsChannels,
    memberCommsChannelsModStatus: commsChannelsModStatus,
  }
}
