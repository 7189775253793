import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const MembershipsDefaultSchemas = DefaultSchemas

export const MembershipsDataSchemas = zod.object({
  groupId: zod.number(),
  groupName: zod.string(),
  classId: zod.number(),
  membershipId: zod.number(),
  startDate: zod.string(),
  endDate: zod.string(),
  creationDate: zod.string(),
  groupType: zod.string(),
})

export const MembershipsAPISchemas = MembershipsDefaultSchemas.merge(
  zod.object({
    memberGroups: zod.array(MembershipsDataSchemas),
  })
)

export const MembershipsFetchParamsSchemas = zod.object({
  traderId: zod.number(),
  groupId: zod.string().optional(),
  typeId: zod.string().optional(),
  option: zod.string().optional(),
  startDate: zod.string().optional(),
  endDate: zod.string().optional(),
  pSize: zod.number().optional(),
  pNumber: zod.number().optional(),
})
