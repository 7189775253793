export const STATUS = {
  STATUS_IDLE: 'idle',
  STATUS_SUCCESS: 'success',
  STATUS_FAIL: 'fail',
  STATUS_LOADING: 'loading',
  STATUS_DELAY: 'delay',
} as const

export const DATASTATUS = {
  outcomeCode: 'DATAFORMAT_ERR',
  outcomeUserMessage: 'API DATA FORMAT ERROR',
} as const
