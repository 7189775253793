import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
// import { toast } from 'react-toastify'
import { useMutation } from 'react-query'

import { getVouchersAPI } from 'api/vouchers'
import { useVouchersLookupStore } from 'store/vouchers'
import { DropDownItem } from 'types/components'
import { VouchersFetchParamsTypes } from 'types/vouchers'

export const useMemberVouchersLookup = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const vouchersLookupModStatus = useVouchersLookupStore(
    (state) => state.vouchersLookupModStatus
  )
  const vouchersLookupModStatusSet = useVouchersLookupStore(
    (state) => state.vouchersLookupModStatusSet
  )
  const vouchersLookupModErrors = useVouchersLookupStore(
    (state) => state.vouchersLookupModErrors
  )
  const vouchersLookupModErrorsSet = useVouchersLookupStore(
    (state) => state.vouchersLookupModErrorsSet
  )
  const vouchersLookup = useVouchersLookupStore((state) => state.vouchersLookup)
  const vouchersLookupSet = useVouchersLookupStore(
    (state) => state.vouchersLookupSet
  )

  const mutation = useMutation({
    mutationFn: getVouchersAPI,
    mutationKey: 'useVouchersLookup',
    onMutate: (values) => {
      vouchersLookupModStatusSet('loading')
    },
    onSuccess: (getVouchersIdentsResponse) => {
      vouchersLookupSet(getVouchersIdentsResponse.vouchers)
      vouchersLookupModStatusSet('success')
    },
    onError: (error) => {
      vouchersLookupSet([])
      vouchersLookupModStatusSet('fail')
    },
  })

  const handleVouchersLookup = useCallback(
    async (getVouchersIdentsParams: VouchersFetchParamsTypes) => {
      const vouchersLookupData = await mutation.mutateAsync(
        getVouchersIdentsParams
      )
      return vouchersLookupData.vouchers
    },
    [mutation]
  )

  const vouchersCodeOptionsLoader = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      if (inputValue.length < 3) return []
      const vouchersLookupData = await handleVouchersLookup({
        traderId: memberTraderId,
        voucherCode: inputValue,
      })
      return vouchersLookupData.map((item) => ({
        label: String(item.voucherCode),
        value: String(item.voucherCode),
      }))
    },
    [handleVouchersLookup]
  )

  const vouchersNameOptionsLoader = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      if (inputValue.length < 3) return []
      const vouchersLookupData = await handleVouchersLookup({
        traderId: memberTraderId,
        voucherName: inputValue,
      })
      return vouchersLookupData.map((item) => ({
        label: String(item.name),
        value: String(item.name),
      }))
    },
    [handleVouchersLookup]
  )

  return {
    memberVouchersCodeOptionsLoader: vouchersCodeOptionsLoader,
    memberVouchersNameOptionsLoader: vouchersNameOptionsLoader,
    memberVouchersIdents: vouchersLookup,
    memberVouchersIdentsModStatus: vouchersLookupModStatus,
    memberVouchersIdentsModErrors: vouchersLookupModErrors,
  }
}
