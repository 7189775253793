import { useMemberProfiles } from 'views/MemberProfile/useMemberProfiles'

export const useMemberProfile = () => {
  const {
    memberMemberProfiles,
    memberMemberProfilesModStatus,
    memberMemberProfilesModErrors,
  } = useMemberProfiles()

  return {
    memberMemberProfiles,
    memberMemberProfilesModStatus,
    memberMemberProfilesModErrors,
  }
}
