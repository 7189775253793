import { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
// import { toast } from 'react-toastify'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { getOffersAPI } from 'api/offers'
import { useOffersLookupStore } from 'store/offers'
import { DropDownItem } from 'types/components'
import { OffersFetchParamsTypes } from 'types/offers'

import { DATASTATUS } from 'constants/status'

export const useMemberOffersLookup = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const offersLookupModStatus = useOffersLookupStore(
    (state) => state.offersLookupModStatus
  )
  const offersLookupModStatusSet = useOffersLookupStore(
    (state) => state.offersLookupModStatusSet
  )
  const offersLookupModErrors = useOffersLookupStore(
    (state) => state.offersLookupModErrors
  )
  const offersLookupModErrorsSet = useOffersLookupStore(
    (state) => state.offersLookupModErrorsSet
  )
  const offersLookup = useOffersLookupStore((state) => state.offersLookup)
  const offersLookupSet = useOffersLookupStore((state) => state.offersLookupSet)

  const [offersFetchParams, offersFetchParamsSet] =
    useState<OffersFetchParamsTypes>({
      traderId: memberTraderId,
    })

  const {
    data: dataOffersLookup,
    isLoading: isLoadingOffersLookup,
    isError: isErrorOffersLookup,
  } = useQuery({
    // enabled: false,
    refetchOnWindowFocus: false,
    queryKey: ['useOffersLookup', offersFetchParams],
    queryFn: async () => {
      try {
        offersLookupModStatusSet('loading')
        const response = await getOffersAPI(offersFetchParams)
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        offersLookupModStatusSet('success')
        return response.offers
      } catch (e) {
        offersLookupModStatusSet('fail')
        offersLookupModErrorsSet(JSON.stringify(e))
      }
    },
  })

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation({
    mutationFn: getOffersAPI,
    mutationKey: 'useOffersLookup',
    onMutate: (values) => {
      offersLookupModStatusSet('loading')
    },
    onSuccess: (getOffersIdentsResponse) => {
      queryClient.invalidateQueries({
        queryKey: ['useOffersLookup', offersFetchParams],
      })
      offersLookupModStatusSet('success')
    },
    onError: (error) => {
      offersLookupSet([])
      offersLookupModStatusSet('fail')
    },
  })

  const offersIdOptionsLoader = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      if (inputValue.length < 3) return []
      const data = await mutateAsync(offersFetchParams)
      return data.offers.map((item) => ({
        label: String(item.offerId),
        value: String(item.offerId),
      }))
    },
    [mutateAsync, offersFetchParams]
  )

  const handleOffersIdLookup = useCallback(
    async (inputValue: string) => {
      if (inputValue.length > 2)
        offersFetchParamsSet({
          ...offersFetchParams,
          offerId: parseInt(inputValue),
        })
    },
    [offersFetchParams, offersFetchParamsSet]
  )

  const offersNameOptionsLoader = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      if (inputValue.length < 3) return []
      const data = await mutateAsync(offersFetchParams)
      return data.offers.map((item) => ({
        label: String(item.offerName),
        value: String(item.offerName),
      }))
    },
    [mutateAsync, offersFetchParams]
  )

  const handleOffersNameLookup = useCallback(
    async (inputValue: string) => {
      if (inputValue.length > 2)
        offersFetchParamsSet({ ...offersFetchParams, offerName: inputValue })
    },
    [offersFetchParams, offersFetchParamsSet]
  )

  return {
    memberHandleOffersIdLookup: handleOffersIdLookup,
    memberHandleOffersNameLookup: handleOffersNameLookup,
    memberoffersIdOptionsLoader: offersIdOptionsLoader,
    memberOffersNameOptionsLoader: offersNameOptionsLoader,
    memberOffers: offersLookup,
    memberOffersModStatus: offersLookupModStatus,
    memberOffersModErrors: offersLookupModErrors,
  }
}
