import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import Table from 'components/Table'
import { ROUTES } from 'constants/routing'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { MerchantLookupTypes } from 'types/merchanTxTypes'

const columns = ['Identifier', 'Merchant Name', 'Merchant Code', 'External Id']

interface Props {
  postData: MerchantLookupTypes[]
}

const ResultTable = ({ postData }: Props): JSX.Element => {
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)

  function handlePageClick({ selected }: { selected: number }): void {
    // setLoading(true)
    // setOffset(selected * perPage)
    setCurrentPage(selected + 1)
  }

  return (
    <Table
      data={postData}
      columns={columns}
      pageCount={0}
      showActions
      currentPage={currentPage}
      perPage={10}
      onPageChange={handlePageClick}
      itemCount={postData.length}
    >
      {postData.map((merchant) => (
        <tr
          key={merchant.traderId}
          className="py-2 text-black bg-white cursor-pointer hover:bg-gray-600"
          // redirect to merchant profile
          onClick={() =>
            navigate(
              `${ROUTES.Merchant.path}/${merchant.traderId}/transactions`
            )
          }
        >
          <td className="py-2 pl-6">
            <p>{merchant.traderId}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{merchant.merchantName}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{merchant.merchantCode}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{merchant.externalId}</p>
          </td>
          <td className="float-right py-2 pl-6 mr-4">
            <Icon icon={faChevronRight} />
          </td>
        </tr>
      ))}
    </Table>
  )
}

export default ResultTable
