import {
  faDownload,
  faEdit,
  faPaperPlane,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import {
  MemberProfileSaveToPDF,
  MemberResetPassword,
  MemberUpdateProfile,
  MemberUpdateStatus,
} from 'views/MemberProfile/Sidebar/Member/MemberProfileActionsModals'
import { MemberUpdateProfileGeneric } from 'views/MemberProfileGeneric/Sidebar/Member/MemberProfileActionsModals'

import { accessControlSubNavigation } from 'setups/controller'

const projectClient = process.env.REACT_APP_PROJ

export const DROPDOWN_OPTIONS = [
  {
    module: 'Send email for password reset',
    label: 'Send email for password reset',
    value: 'email',
    route: {
      title: 'send email for password reset',
      path: {
        path: '',
        permissionIds: [],
      },
      icon: faPaperPlane,
    },
    Modal: MemberResetPassword,
  },
  {
    module: 'Save to PDF',
    label: 'Save to PDF',
    value: 'pdf',
    route: {
      title: 'save to pdf',
      path: {
        path: '',
        permissionIds: [],
      },
      icon: faDownload,
    },
    Modal: MemberProfileSaveToPDF,
  },
  {
    module: 'Update status',
    label: 'Update status',
    value: 'status',
    route: {
      title: 'update status',
      path: {
        path: '',
        permissionIds: [],
      },
      icon: faEdit,
    },
    Modal: MemberUpdateStatus,
  },
  {
    module: 'Update Member Details',
    label: 'Update Member Details',
    value: 'member',
    route: {
      title: 'update member details',
      path: {
        path: '',
        permissionIds: [],
      },
      icon: faUser,
    },
    Modal: MemberUpdateProfile,
  },
  {
    module: 'Update Member Details Generic',
    label: 'Update Member Details',
    value: 'member',
    route: {
      title: 'update member details',
      path: {
        path: '',
        permissionIds: [],
      },
      icon: faUser,
    },
    Modal: MemberUpdateProfileGeneric,
  },
]

export const MEMBERELLIPSISNAVIGATION = accessControlSubNavigation(
  projectClient,
  DROPDOWN_OPTIONS,
  'member'
)
