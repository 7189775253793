import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { MembershipsDataTypes } from 'types/memberships'

interface MembershipsStoreTypes {
  membershipsModErrors: StatusErrorTypes
  membershipsModErrorsSet: (membershipsModErrors: StatusErrorTypes) => void
  membershipsModStatus: StatusTypes
  membershipsModStatusSet: (membershipsStatus: StatusTypes) => void
  memberships: MembershipsDataTypes[]
  membershipsSet: (memberships: MembershipsDataTypes[]) => void
  membershipsLookup: MembershipsDataTypes[]
  membershipsLookupSet: (memberships: MembershipsDataTypes[]) => void
}

export const useMembershipsStore = create<MembershipsStoreTypes>((set) => ({
  membershipsModErrors: '',
  membershipsModErrorsSet: (membershipsModErrors) => {
    set({
      membershipsModErrors,
    })
  },
  membershipsModStatus: 'idle',
  membershipsModStatusSet: (membershipsModStatus) => {
    set({
      membershipsModStatus,
    })
  },
  memberships: [],
  membershipsSet: (memberships) => {
    set({
      memberships,
    })
  },
  membershipsLookup: [],
  membershipsLookupSet: (membershipsLookup) => {
    set({
      membershipsLookup,
    })
  },
}))

interface MembershipsLookupStoreTypes {
  membershipsLookupModErrors: StatusErrorTypes
  membershipsLookupModErrorsSet: (
    membershipsLookupModErrors: StatusErrorTypes
  ) => void
  membershipsLookupModStatus: StatusTypes
  membershipsLookupModStatusSet: (membershipsLookupStatus: StatusTypes) => void
  membershipsLookup: MembershipsDataTypes[]
  membershipsLookupSet: (membershipsLookup: MembershipsDataTypes[]) => void
}

export const useMembershipsLookupStore = create<MembershipsLookupStoreTypes>(
  (set) => ({
    membershipsLookupModErrors: '',
    membershipsLookupModErrorsSet: (membershipsLookupModErrors) => {
      set({
        membershipsLookupModErrors,
      })
    },
    membershipsLookupModStatus: 'idle',
    membershipsLookupModStatusSet: (membershipsLookupModStatus) => {
      set({
        membershipsLookupModStatus,
      })
    },
    membershipsLookup: [],
    membershipsLookupSet: (membershipsLookup) => {
      set({
        membershipsLookup,
      })
    },
  })
)
