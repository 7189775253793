import { formatter } from 'utils'

import useMerchantStore from 'store/merchant'

const MerchantProfileInfo = (): JSX.Element => {
  const merchantProfile = useMerchantStore((state) => state.merchantProfile)
  const merchantProfileModStatus = useMerchantStore(
    (state) => state.merchantProfileModStatus
  )

  if (merchantProfileModStatus !== 'success') {
    return <></>
  }

  return (
    <div className="flex flex-col gap-1.5 mb-6">
      <p className="flex flex-col gap-0">
        <span className="text-xl uppercase font-bold">
          {merchantProfile.map((merchant) => merchant.merchantName)}
        </span>
        <span className="font-bold uppercase">
          {merchantProfile.map((merchant) => merchant.merchantTraderId)}
        </span>
      </p>
    </div>
  )
}

export default MerchantProfileInfo
